.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #ffff76;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #006edc;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.react-date-picker {
  display: inline-flex;
  position: relative;
}
.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
  box-sizing: border-box;
}
.react-date-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}
.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: thin solid gray;
}
.react-date-picker__inputGroup {
  min-width: calc((4px * 3) +  0.54em * 8  +  0.217em * 2);
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;
}
.react-date-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}
.react-date-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
}
.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-date-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}
.react-date-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px +  0.54em);
}
.react-date-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}
.react-date-picker__button:enabled {
  cursor: pointer;
}
.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: #0078d7;
}
.react-date-picker__button:disabled .react-date-picker__button__icon {
  stroke: #6d6d6d;
}
.react-date-picker__button svg {
  display: inherit;
}
.react-date-picker__calendar {
  width: 350px;
  max-width: 100vw;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}
.react-date-picker__calendar--closed {
  display: none;
}
.react-date-picker__calendar .react-calendar {
  border-width: thin;
}

.ReactCrop {
  position: relative;
  display: inline-block;
  cursor: crosshair;
  overflow: hidden;
  max-width: 100%;
  background-color: #000; }
  .ReactCrop:focus {
    outline: none; }
  .ReactCrop--disabled, .ReactCrop--locked {
    cursor: inherit; }
  .ReactCrop__image {
    /* autoprefixer: off */
    display: block;
    max-width: 100%;
    max-height: -webkit-fill-available;
    max-height: -moz-available;
    max-height: fill-available; }
  .ReactCrop--crop-invisible .ReactCrop__image {
    opacity: 0.5; }
  .ReactCrop__crop-selection {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate3d(0, 0, 0);
    box-sizing: border-box;
    cursor: move;
    box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.5);
    border: 1px solid;
    border-image-source: url("data:image/gif;base64,R0lGODlhCgAKAJECAAAAAP///////wAAACH/C05FVFNDQVBFMi4wAwEAAAAh/wtYTVAgRGF0YVhNUDw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OEI5RDc5MTFDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OEI5RDc5MTBDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEBQoAAgAsAAAAAAoACgAAAhWEERkn7W3ei7KlagMWF/dKgYeyGAUAIfkEBQoAAgAsAAAAAAoACgAAAg+UYwLJ7RnQm7QmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYgLJHdiinNSAVfOEKoUCACH5BAUKAAIALAAAAAAKAAoAAAIRVISAdusPo3RAzYtjaMIaUQAAIfkEBQoAAgAsAAAAAAoACgAAAg+MDiem7Q8bSLFaG5il6xQAIfkEBQoAAgAsAAAAAAoACgAAAg+UYRLJ7QnQm7SmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYBLJDdiinNSEVfOEKoECACH5BAUKAAIALAAAAAAKAAoAAAIRFISBdusPo3RBzYsjaMIaUQAAOw==");
    border-image-slice: 1;
    border-image-repeat: repeat; }
    .ReactCrop--disabled .ReactCrop__crop-selection {
      cursor: inherit; }
  .ReactCrop__drag-handle {
    position: absolute;
    width: 9px;
    height: 9px;
    background-color: rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.7);
    box-sizing: border-box;
    outline: 1px solid transparent; }
  .ReactCrop .ord-nw {
    top: 0;
    left: 0;
    margin-top: -5px;
    margin-left: -5px;
    cursor: nw-resize; }
  .ReactCrop .ord-n {
    top: 0;
    left: 50%;
    margin-top: -5px;
    margin-left: -5px;
    cursor: n-resize; }
  .ReactCrop .ord-ne {
    top: 0;
    right: 0;
    margin-top: -5px;
    margin-right: -5px;
    cursor: ne-resize; }
  .ReactCrop .ord-e {
    top: 50%;
    right: 0;
    margin-top: -5px;
    margin-right: -5px;
    cursor: e-resize; }
  .ReactCrop .ord-se {
    bottom: 0;
    right: 0;
    margin-bottom: -5px;
    margin-right: -5px;
    cursor: se-resize; }
  .ReactCrop .ord-s {
    bottom: 0;
    left: 50%;
    margin-bottom: -5px;
    margin-left: -5px;
    cursor: s-resize; }
  .ReactCrop .ord-sw {
    bottom: 0;
    left: 0;
    margin-bottom: -5px;
    margin-left: -5px;
    cursor: sw-resize; }
  .ReactCrop .ord-w {
    top: 50%;
    left: 0;
    margin-top: -5px;
    margin-left: -5px;
    cursor: w-resize; }
  .ReactCrop__disabled .ReactCrop__drag-handle {
    cursor: inherit; }
  .ReactCrop__drag-bar {
    position: absolute; }
    .ReactCrop__drag-bar.ord-n {
      top: 0;
      left: 0;
      width: 100%;
      height: 6px;
      margin-top: -3px; }
    .ReactCrop__drag-bar.ord-e {
      right: 0;
      top: 0;
      width: 6px;
      height: 100%;
      margin-right: -3px; }
    .ReactCrop__drag-bar.ord-s {
      bottom: 0;
      left: 0;
      width: 100%;
      height: 6px;
      margin-bottom: -3px; }
    .ReactCrop__drag-bar.ord-w {
      top: 0;
      left: 0;
      width: 6px;
      height: 100%;
      margin-left: -3px; }
  .ReactCrop--new-crop .ReactCrop__drag-bar,
  .ReactCrop--new-crop .ReactCrop__drag-handle,
  .ReactCrop--fixed-aspect .ReactCrop__drag-bar {
    display: none; }
  .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-n,
  .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-e,
  .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-s,
  .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-w {
    display: none; }
  @media (max-width: 768px), (pointer: coarse) {
    .ReactCrop__drag-handle {
      width: 17px;
      height: 17px; }
    .ReactCrop .ord-nw {
      margin-top: -9px;
      margin-left: -9px; }
    .ReactCrop .ord-n {
      margin-top: -9px;
      margin-left: -9px; }
    .ReactCrop .ord-ne {
      margin-top: -9px;
      margin-right: -9px; }
    .ReactCrop .ord-e {
      margin-top: -9px;
      margin-right: -9px; }
    .ReactCrop .ord-se {
      margin-bottom: -9px;
      margin-right: -9px; }
    .ReactCrop .ord-s {
      margin-bottom: -9px;
      margin-left: -9px; }
    .ReactCrop .ord-sw {
      margin-bottom: -9px;
      margin-left: -9px; }
    .ReactCrop .ord-w {
      margin-top: -9px;
      margin-left: -9px; }
    .ReactCrop__drag-bar.ord-n {
      height: 14px;
      margin-top: -7px; }
    .ReactCrop__drag-bar.ord-e {
      width: 14px;
      margin-right: -7px; }
    .ReactCrop__drag-bar.ord-s {
      height: 14px;
      margin-bottom: -7px; }
    .ReactCrop__drag-bar.ord-w {
      width: 14px;
      margin-left: -7px; } }

.EZDrawer .EZDrawer__checkbox {
  display: none; }
  .EZDrawer .EZDrawer__checkbox:checked ~ .EZDrawer__overlay {
    display: block;
    opacity: 1; }
  .EZDrawer .EZDrawer__checkbox:checked ~ .EZDrawer__container {
    visibility: visible;
    transform: translate3d(0, 0, 0) !important; }

.EZDrawer .EZDrawer__overlay {
  display: none;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%; }

.EZDrawer .EZDrawer__container {
  position: fixed;
  visibility: hidden;
  background: white;
  transition: all;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1); }

.rc-switch {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 44px;
  height: 22px;
  line-height: 20px;
  padding: 0;
  vertical-align: middle;
  border-radius: 20px 20px;
  border: 1px solid #ccc;
  background-color: #ccc;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
.rc-switch-inner {
  color: #fff;
  font-size: 12px;
  position: absolute;
  left: 24px;
  top: 0;
}
.rc-switch:after {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 2px;
  top: 1px;
  border-radius: 50% 50%;
  background-color: #fff;
  content: " ";
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
  transform: scale(1);
  transition: left 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
  animation-duration: 0.3s;
  animation-name: rcSwitchOff;
}
.rc-switch:hover:after {
  transform: scale(1.1);
  animation-name: rcSwitchOn;
}
.rc-switch:focus {
  box-shadow: 0 0 0 2px #d5f1fd;
  outline: none;
}
.rc-switch-checked {
  border: 1px solid #87d068;
  background-color: #87d068;
}
.rc-switch-checked .rc-switch-inner {
  left: 6px;
}
.rc-switch-checked:after {
  left: 22px;
}
.rc-switch-disabled {
  cursor: no-drop;
  background: #ccc;
  border-color: #ccc;
}
.rc-switch-disabled:after {
  background: #9e9e9e;
  animation-name: none;
  cursor: no-drop;
}
.rc-switch-disabled:hover:after {
  transform: scale(1);
  animation-name: none;
}
.rc-switch-label {
  display: inline-block;
  line-height: 20px;
  font-size: 14px;
  padding-left: 10px;
  vertical-align: middle;
  white-space: normal;
  pointer-events: none;
  user-select: text;
}
@keyframes rcSwitchOn {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1.1);
  }
}
@keyframes rcSwitchOff {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index) px);
  position: fixed;
  padding: 4px;
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
}
.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}
.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}
.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}
.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0;
  }
  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }
  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }
  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}
.Toastify__toast {
  position: relative;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  max-height: var(--toastify-toast-max-height);
  overflow: hidden;
  font-family: var(--toastify-font-family);
  cursor: pointer;
  direction: ltr;
}
.Toastify__toast--rtl {
  direction: rtl;
}
.Toastify__toast-body {
  margin: auto 0;
  flex: 1 1 auto;
  padding: 6px;
  display: flex;
  align-items: center;
}
.Toastify__toast-body > div:last-child {
  flex: 1 1;
}
.Toastify__toast-icon {
  margin-inline-end: 10px;
  width: 20px;
  flex-shrink: 0;
  display: flex;
}

.Toastify--animate {
  animation-fill-mode: both;
  animation-duration: 0.7s;
}

.Toastify--animate-icon {
  animation-fill-mode: both;
  animation-duration: 0.3s;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast {
    margin-bottom: 0;
    border-radius: 0;
  }
}
.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}
.Toastify__toast-theme--light {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}
.Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}
.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}
.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}
.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}
.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}
.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}
.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}
.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  align-self: flex-start;
}
.Toastify__close-button--light {
  color: #000;
  opacity: 0.3;
}
.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}
.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}
.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: var(--toastify-z-index);
  opacity: 0.7;
  transform-origin: left;
}
.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear 1 forwards;
}
.Toastify__progress-bar--controlled {
  transition: transform 0.2s;
}
.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: right;
}

.Toastify__spinner {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 2px solid;
  border-radius: 100%;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  animation: Toastify__spin 0.65s linear infinite;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}
@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  75% {
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    transform: translate3d(5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}
@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  75% {
    transform: translate3d(0, 10px, 0);
  }
  90% {
    transform: translate3d(0, -5px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}
@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }
  75% {
    transform: translate3d(0, -10px, 0);
  }
  90% {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}
.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}
.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}
.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}
.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}
.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}
.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}
.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    transform: perspective(400px);
  }
}
@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}
@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}
@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}
@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}
.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}
.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}
.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}
.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}
.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}
.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}
.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*# sourceMappingURL=ReactToastify.css.map */
.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
  transition: all .25s ease-in;
  opacity: .4;
  filter: alpha(opacity=40);
  position: absolute;
  z-index: 2;
  top: 20px;
  background: none;
  border: 0;
  font-size: 32px;
  cursor: pointer; }

.carousel .control-arrow:focus, .carousel .control-arrow:hover {
  opacity: 1;
  filter: alpha(opacity=100); }

.carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
  margin: 0 5px;
  display: inline-block;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  content: ''; }

.carousel .control-disabled.control-arrow {
  opacity: 0;
  filter: alpha(opacity=0);
  cursor: inherit;
  display: none; }

.carousel .control-prev.control-arrow {
  left: 0; }

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #fff; }

.carousel .control-next.control-arrow {
  right: 0; }

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #fff; }

.carousel-root {
  outline: none; }

.carousel {
  position: relative;
  width: 100%; }

.carousel * {
  box-sizing: border-box; }

.carousel img {
  width: 100%;
  display: inline-block;
  pointer-events: none; }

.carousel .carousel {
  position: relative; }

.carousel .control-arrow {
  outline: 0;
  border: 0;
  background: none;
  top: 50%;
  margin-top: -13px;
  font-size: 18px; }

.carousel .thumbs-wrapper {
  margin: 20px;
  overflow: hidden; }

.carousel .thumbs {
  transition: all .15s ease-in;
  transform: translate3d(0, 0, 0);
  position: relative;
  list-style: none;
  white-space: nowrap; }

.carousel .thumb {
  transition: border .15s ease-in;
  display: inline-block;
  margin-right: 6px;
  white-space: nowrap;
  overflow: hidden;
  border: 3px solid #fff;
  padding: 2px; }

.carousel .thumb:focus {
  border: 3px solid #ccc;
  outline: none; }

.carousel .thumb.selected, .carousel .thumb:hover {
  border: 3px solid #333; }

.carousel .thumb img {
  vertical-align: top; }

.carousel.carousel-slider {
  position: relative;
  margin: 0;
  overflow: hidden; }

.carousel.carousel-slider .control-arrow {
  top: 0;
  color: #fff;
  font-size: 26px;
  bottom: 0;
  margin-top: 0;
  padding: 5px; }

.carousel.carousel-slider .control-arrow:hover {
  background: rgba(0, 0, 0, 0.2); }

.carousel .slider-wrapper {
  overflow: hidden;
  margin: auto;
  width: 100%;
  transition: height .15s ease-in; }

.carousel .slider-wrapper.axis-horizontal .slider {
  -ms-box-orient: horizontal;
  display: -moz-flex;
  display: flex; }

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  flex-direction: column;
  flex-flow: column; }

.carousel .slider-wrapper.axis-vertical {
  -ms-box-orient: horizontal;
  display: -moz-flex;
  display: flex; }

.carousel .slider-wrapper.axis-vertical .slider {
  flex-direction: column; }

.carousel .slider {
  margin: 0;
  padding: 0;
  position: relative;
  list-style: none;
  width: 100%; }

.carousel .slider.animated {
  transition: all .35s ease-in-out; }

.carousel .slide {
  min-width: 100%;
  margin: 0;
  position: relative;
  text-align: center; }

.carousel .slide img {
  width: 100%;
  vertical-align: top;
  border: 0; }

.carousel .slide iframe {
  display: inline-block;
  width: calc(100% - 80px);
  margin: 0 40px 40px;
  border: 0; }

.carousel .slide .legend {
  transition: all .5s ease-in-out;
  position: absolute;
  bottom: 40px;
  left: 50%;
  margin-left: -45%;
  width: 90%;
  border-radius: 10px;
  background: #000;
  color: #fff;
  padding: 10px;
  font-size: 12px;
  text-align: center;
  opacity: 0.25;
  transition: opacity .35s ease-in-out; }

.carousel .control-dots {
  position: absolute;
  bottom: 0;
  margin: 10px 0;
  padding: 0;
  text-align: center;
  width: 100%;
  z-index: 1; }

@media (min-width: 960px) {
  .carousel .control-dots {
    bottom: 0; } }

.carousel .control-dots .dot {
  transition: opacity .25s ease-in;
  opacity: .3;
  filter: alpha(opacity=30);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
  background: #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px; }

.carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
  opacity: 1;
  filter: alpha(opacity=100); }

.carousel .carousel-status {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  font-size: 10px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
  color: #fff; }

.carousel:hover .slide .legend {
  opacity: 1; }

:root,
.rs-theme-light {
  --rs-gray-50: #f7f7fa;
  --rs-gray-100: #f2f2f5;
  --rs-gray-200: #e5e5ea;
  --rs-gray-300: #d9d9d9;
  --rs-gray-400: #c5c6c7;
  --rs-gray-500: #a6a6a6;
  --rs-gray-600: #8e8e93;
  --rs-gray-700: #7a7a7a;
  --rs-gray-800: #575757;
  --rs-gray-900: #272c36;
  --rs-primary-50: #f2faff;
  --rs-primary-100: #cce9ff;
  --rs-primary-200: #a6d7ff;
  --rs-primary-300: #80c4ff;
  --rs-primary-400: #59afff;
  --rs-primary-500: #3498ff;
  --rs-primary-600: #2589f5;
  --rs-primary-700: #1675e0;
  --rs-primary-800: #0a5dc2;
  --rs-primary-900: #004299;
  --rs-red-50: #fff2f2;
  --rs-red-100: #fccfcf;
  --rs-red-200: #faa9a7;
  --rs-red-300: #fa8682;
  --rs-red-400: #f7635c;
  --rs-red-500: #f44336;
  --rs-red-600: #eb3626;
  --rs-red-700: #d62915;
  --rs-red-800: #b81c07;
  --rs-red-900: #8f1300;
  --rs-orange-50: #fff8f2;
  --rs-orange-100: #ffdfc2;
  --rs-orange-200: #fcc690;
  --rs-orange-300: #fcb160;
  --rs-orange-400: #fa9b2f;
  --rs-orange-500: #fa8900;
  --rs-orange-600: #f08800;
  --rs-orange-700: #db8000;
  --rs-orange-800: #bd7100;
  --rs-orange-900: #945b00;
  --rs-yellow-50: #fffaf2;
  --rs-yellow-100: #ffe9c2;
  --rs-yellow-200: #ffd991;
  --rs-yellow-300: #ffca61;
  --rs-yellow-400: #ffbe30;
  --rs-yellow-500: #ffb300;
  --rs-yellow-600: #f5af00;
  --rs-yellow-700: #e0a500;
  --rs-yellow-800: #c29100;
  --rs-yellow-900: #997500;
  --rs-green-50: #eeffed;
  --rs-green-100: #c8f0c7;
  --rs-green-200: #a5e0a4;
  --rs-green-300: #82cf82;
  --rs-green-400: #65bf67;
  --rs-green-500: #4caf50;
  --rs-green-600: #37ab3c;
  --rs-green-700: #22a12a;
  --rs-green-800: #0f9119;
  --rs-green-900: #007d0c;
  --rs-cyan-50: #f2ffff;
  --rs-cyan-100: #bcf4f7;
  --rs-cyan-200: #87e6ed;
  --rs-cyan-300: #57dae6;
  --rs-cyan-400: #2acadb;
  --rs-cyan-500: #00bcd4;
  --rs-cyan-600: #00b1cc;
  --rs-cyan-700: #00a0bd;
  --rs-cyan-800: #008aa6;
  --rs-cyan-900: #006e87;
  --rs-blue-50: #f0f9ff;
  --rs-blue-100: #c5e7fc;
  --rs-blue-200: #9bd4fa;
  --rs-blue-300: #72c0f7;
  --rs-blue-400: #49abf5;
  --rs-blue-500: #2196f3;
  --rs-blue-600: #1787e8;
  --rs-blue-700: #0d73d4;
  --rs-blue-800: #045cb5;
  --rs-blue-900: #00448c;
  --rs-violet-50: #f6f2ff;
  --rs-violet-100: #d5c9f0;
  --rs-violet-200: #b6a1e3;
  --rs-violet-300: #987bd4;
  --rs-violet-400: #805ac7;
  --rs-violet-500: #673ab7;
  --rs-violet-600: #5f2bb3;
  --rs-violet-700: #531ba8;
  --rs-violet-800: #470c99;
  --rs-violet-900: #390085;
  --rs-state-success: #4caf50;
  --rs-state-info: #2196f3;
  --rs-state-warning: #ffb300;
  --rs-state-error: #f44336;
  --rs-body: #fff;
  --rs-bg-success: #edfae1;
  --rs-bg-info: #e9f5fe;
  --rs-bg-warning: #fff9e6;
  --rs-bg-error: #fde9ef;
  --rs-text-link: #1675e0;
  --rs-text-link-hover: #0a5dc2;
  --rs-text-link-active: #004299;
  --rs-text-primary: #575757;
  --rs-text-secondary: #8e8e93;
  --rs-text-tertiary: #a6a6a6;
  --rs-text-heading: #272c36;
  --rs-text-inverse: #f7f7fa;
  --rs-text-heading-inverse: #fff;
  --rs-text-active: #1675e0;
  --rs-text-disabled: #c5c6c7;
  --rs-text-error: #f44336;
  --rs-border-primary: #e5e5ea;
  --rs-border-secondary: #f2f2f5;
  --rs-bg-card: #fff;
  --rs-bg-overlay: #fff;
  --rs-bg-well: #f7f7fa;
  --rs-bg-active: #3498ff;
  --rs-bg-backdrop: rgba(39, 44, 54, 0.3);
  --rs-state-hover-bg: #f2faff;
  --rs-color-focus-ring: rgba(52, 152, 255, 0.25);
  --rs-state-focus-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
  --rs-state-focus-outline: 3px solid rgba(52, 152, 255, 0.25);
  --rs-shadow-overlay: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-btn-default-bg: #f7f7fa;
  --rs-btn-default-text: #575757;
  --rs-btn-default-hover-bg: #e5e5ea;
  --rs-btn-default-active-bg: #d9d9d9;
  --rs-btn-default-active-text: #272c36;
  --rs-btn-default-disabled-bg: #f7f7fa;
  --rs-btn-default-disabled-text: #c5c6c7;
  --rs-btn-primary-bg: #3498ff;
  --rs-btn-primary-text: #fff;
  --rs-btn-primary-hover-bg: #2589f5;
  --rs-btn-primary-active-bg: #1675e0;
  --rs-btn-subtle-text: #8e8e93;
  --rs-btn-subtle-hover-bg: #e5e5ea;
  --rs-btn-subtle-hover-text: #575757;
  --rs-btn-subtle-active-bg: #e5e5ea;
  --rs-btn-subtle-active-text: #272c36;
  --rs-btn-subtle-disabled-text: #c5c6c7;
  --rs-btn-ghost-border: #1675e0;
  --rs-btn-ghost-text: #1675e0;
  --rs-btn-ghost-hover-border: #0a5dc2;
  --rs-btn-ghost-hover-text: #0a5dc2;
  --rs-btn-ghost-active-border: #004299;
  --rs-btn-ghost-active-text: #004299;
  --rs-btn-link-text: #1675e0;
  --rs-btn-link-hover-text: #0a5dc2;
  --rs-btn-link-active-text: #004299;
  --rs-iconbtn-addon: #f2f2f5;
  --rs-iconbtn-activated-addon: #d9d9d9;
  --rs-iconbtn-pressed-addon: #c5c6c7;
  --rs-iconbtn-primary-addon: #2589f5;
  --rs-iconbtn-primary-activated-addon: #1675e0;
  --rs-iconbtn-primary-pressed-addon: #0a5dc2;
  --rs-divider-border: #e5e5ea;
  --rs-loader-ring: rgba(247, 247, 250, 0.8);
  --rs-loader-rotor: #a6a6a6;
  --rs-loader-backdrop: rgba(255, 255, 255, 0.9);
  --rs-loader-ring-inverse: rgba(247, 247, 250, 0.3);
  --rs-loader-rotor-inverse: #fff;
  --rs-loader-backdrop-inverse: rgba(39, 44, 54, 0.83);
  --rs-message-success-header: var(--rs-text-heading);
  --rs-message-success-text: var(--rs-text-primary);
  --rs-message-success-icon: #4caf50;
  --rs-message-success-bg: #eeffed;
  --rs-message-info-header: var(--rs-text-heading);
  --rs-message-info-text: var(--rs-text-primary);
  --rs-message-info-icon: #2196f3;
  --rs-message-info-bg: #f0f9ff;
  --rs-message-warning-header: var(--rs-text-heading);
  --rs-message-warning-text: var(--rs-text-primary);
  --rs-message-warning-icon: #ffb300;
  --rs-message-warning-bg: #fffaf2;
  --rs-message-error-header: var(--rs-text-heading);
  --rs-message-error-text: var(--rs-text-primary);
  --rs-message-error-icon: #f44336;
  --rs-message-error-bg: #fff2f2;
  --rs-tooltip-bg: #272c36;
  --rs-tooltip-text: #fff;
  --rs-progress-bg: #e5e5ea;
  --rs-progress-bar: #3498ff;
  --rs-progress-bar-success: #4caf50;
  --rs-progress-bar-fail: #f44336;
  --rs-placeholder: #f2f2f5;
  --rs-placeholder-active: #e5e5ea;
  --rs-breadcrumb-item-active-text: #272c36;
  --rs-dropdown-divider: #e5e5ea;
  --rs-dropdown-item-bg-hover: rgba(204, 233, 255, 0.5);
  --rs-dropdown-item-bg-active: #f2faff;
  --rs-dropdown-item-text-active: #1675e0;
  --rs-dropdown-header-text: #a6a6a6;
  --rs-dropdown-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
  --rs-menuitem-active-bg: rgba(204, 233, 255, 0.5);
  --rs-menuitem-active-text: #1675e0;
  --rs-steps-border: #8e8e93;
  --rs-steps-state-finish: #3498ff;
  --rs-steps-border-state-finish: #3498ff;
  --rs-steps-state-wait: #8e8e93;
  --rs-steps-state-process: #3498ff;
  --rs-steps-state-error: #f44336;
  --rs-steps-border-state-error: #f44336;
  --rs-steps-icon-state-process: #3498ff;
  --rs-steps-icon-state-error: #f44336;
  --rs-navs-text: #8e8e93;
  --rs-navs-text-hover: #575757;
  --rs-navs-bg-hover: #e5e5ea;
  --rs-navs-text-active: #272c36;
  --rs-navs-bg-active: #e5e5ea;
  --rs-navs-tab-border: #d9d9d9;
  --rs-navs-subtle-border: #f7f7fa;
  --rs-navs-selected: #1675e0;
  --rs-navbar-default-bg: #f7f7fa;
  --rs-navbar-default-text: #575757;
  --rs-navbar-default-selected-text: #1675e0;
  --rs-navbar-default-hover-bg: #e5e5ea;
  --rs-navbar-default-hover-text: #575757;
  --rs-navbar-inverse-bg: #3498ff;
  --rs-navbar-inverse-text: #fff;
  --rs-navbar-inverse-selected-bg: #1675e0;
  --rs-navbar-inverse-hover-bg: #2589f5;
  --rs-navbar-inverse-hover-text: #fff;
  --rs-navbar-subtle-bg: #fff;
  --rs-navbar-subtle-text: #8e8e93;
  --rs-navbar-subtle-selected-text: #1675e0;
  --rs-navbar-subtle-hover-bg: #f7f7fa;
  --rs-navbar-subtle-hover-text: #575757;
  --rs-sidenav-default-bg: #f7f7fa;
  --rs-sidenav-default-text: #575757;
  --rs-sidenav-default-selected-text: #1675e0;
  --rs-sidenav-default-hover-bg: #e5e5ea;
  --rs-sidenav-default-hover-text: #575757;
  --rs-sidenav-default-footer-border: #e5e5ea;
  --rs-sidenav-inverse-bg: #3498ff;
  --rs-sidenav-inverse-text: #fff;
  --rs-sidenav-inverse-selected-bg: #1675e0;
  --rs-sidenav-inverse-hover-bg: #2589f5;
  --rs-sidenav-inverse-footer-border: #2589f5;
  --rs-sidenav-subtle-bg: #fff;
  --rs-sidenav-subtle-text: #8e8e93;
  --rs-sidenav-subtle-selected-text: #1675e0;
  --rs-sidenav-subtle-hover-bg: #f7f7fa;
  --rs-sidenav-subtle-hover-text: #575757;
  --rs-sidenav-subtle-footer-border: #e5e5ea;
  --rs-input-bg: #fff;
  --rs-input-focus-border: #3498ff;
  --rs-input-disabled-bg: #f7f7fa;
  --rs-listbox-option-hover-bg: rgba(204, 233, 255, 0.5);
  --rs-listbox-option-hover-text: #1675e0;
  --rs-listbox-option-selected-text: #1675e0;
  --rs-listbox-option-selected-bg: #f2faff;
  --rs-listbox-option-disabled-text: #c5c6c7;
  --rs-listbox-option-disabled-selected-text: #a6d7ff;
  --rs-checkbox-icon: #fff;
  --rs-checkbox-border: #d9d9d9;
  --rs-checkbox-checked-bg: #3498ff;
  --rs-checkbox-disabled-bg: #f7f7fa;
  --rs-radio-marker: #fff;
  --rs-radio-border: #d9d9d9;
  --rs-radio-checked-bg: #3498ff;
  --rs-radio-disabled-bg: #f7f7fa;
  --rs-radio-tile-border: #d9d9d9;
  --rs-radio-tile-bg: #fff;
  --rs-radio-tile-checked-color: #3498ff;
  --rs-radio-tile-checked-mark-color: #fff;
  --rs-radio-tile-checked-disabled-color: #cce9ff;
  --rs-radio-tile-icon-size: 32px;
  --rs-rate-symbol: #8e8e93;
  --rs-rate-symbol-checked: #ffb300;
  --rs-toggle-bg: #d9d9d9;
  --rs-toggle-thumb: #fff;
  --rs-toggle-loader-ring: rgba(247, 247, 250, 0.3);
  --rs-toggle-loader-rotor: #fff;
  --rs-toggle-hover-bg: #c5c6c7;
  --rs-toggle-disabled-bg: #f7f7fa;
  --rs-toggle-disabled-thumb: #fff;
  --rs-toggle-checked-bg: #3498ff;
  --rs-toggle-checked-thumb: #fff;
  --rs-toggle-checked-hover-bg: #2589f5;
  --rs-toggle-checked-disabled-bg: #cce9ff;
  --rs-toggle-checked-disabled-thumb: #fff;
  --rs-slider-bar: #f2f2f5;
  --rs-slider-hover-bar: #e5e5ea;
  --rs-slider-thumb-border: #3498ff;
  --rs-slider-thumb-bg: #fff;
  --rs-slider-thumb-hover-shadow: 0 0 0 8px rgba(52, 152, 255, 0.25);
  --rs-slider-progress: #3498ff;
  --rs-uploader-item-bg: #d9d9d9;
  --rs-uploader-item-hover-bg: #f7f7fa;
  --rs-uploader-overlay-bg: rgba(255, 255, 255, 0.8);
  --rs-uploader-dnd-bg: #fff;
  --rs-uploader-dnd-border: #e5e5ea;
  --rs-uploader-dnd-hover-border: #3498ff;
  --rs-avatar-bg: #d9d9d9;
  --rs-avatar-text: #fff;
  --rs-badge-bg: #f44336;
  --rs-badge-text: #fff;
  --rs-tag-bg: #f7f7fa;
  --rs-tag-close: #f44336;
  --rs-carousel-bg: #8e8e93;
  --rs-carousel-indicator: rgba(255, 255, 255, 0.4);
  --rs-carousel-indicator-hover: #fff;
  --rs-carousel-indicator-active: #3498ff;
  --rs-panel-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-list-bg: #fff;
  --rs-list-border: #e5e5ea;
  --rs-list-hover-bg: #f2faff;
  --rs-list-placeholder-bg: rgba(242, 250, 255, 0.5);
  --rs-list-placeholder-border: #3498ff;
  --rs-timeline-indicator-bg: #d9d9d9;
  --rs-timeline-indicator-active-bg: #3498ff;
  --rs-table-shadow: rgba(9, 9, 9, 0.08);
  --rs-table-sort: #3498ff;
  --rs-table-resize: #3498ff;
  --rs-table-scrollbar-track: #e5e5ea;
  --rs-table-scrollbar-thumb: #575757;
  --rs-table-scrollbar-thumb-active: #272c36;
  --rs-table-scrollbar-vertical-track: rgba(229, 229, 234, 0.4);
  --rs-drawer-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-modal-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-form-errormessage-text: #f44336;
  --rs-form-errormessage-bg: #fff;
  --rs-form-errormessage-border: #e5e5ea;
  --rs-picker-value: #1675e0;
  --rs-picker-count-bg: #3498ff;
  --rs-picker-count-text: #fff;
  --rs-calendar-today-bg: #3498ff;
  --rs-calendar-today-text: #fff;
  --rs-calendar-range-bg: rgba(204, 233, 255, 0.5);
  --rs-calendar-time-unit-bg: #f7f7fa;
  --rs-calendar-date-selected-text: #fff;
  --rs-calendar-cell-selected-hover-bg: #1675e0;
  --rs-popover-shadow: 0 1px 8px rgba(0, 0, 0, 0.12); }

.rs-theme-dark {
  --rs-gray-50: #e9ebf0;
  --rs-gray-100: #cbced4;
  --rs-gray-200: #a4a9b3;
  --rs-gray-300: #858b94;
  --rs-gray-400: #6a6f76;
  --rs-gray-500: #5c6066;
  --rs-gray-600: #3c3f43;
  --rs-gray-700: #292d33;
  --rs-gray-800: #1a1d24;
  --rs-gray-900: #0f131a;
  --rs-primary-50: #f2fcff;
  --rs-primary-100: #ccf3ff;
  --rs-primary-200: #a6e9ff;
  --rs-primary-300: #80ddff;
  --rs-primary-400: #59d0ff;
  --rs-primary-500: #34c3ff;
  --rs-primary-600: #25b3f5;
  --rs-primary-700: #169de0;
  --rs-primary-800: #0a81c2;
  --rs-primary-900: #006199;
  --rs-red-50: #ffeded;
  --rs-red-100: #fccaca;
  --rs-red-200: #faa9a7;
  --rs-red-300: #f58884;
  --rs-red-400: #f26a63;
  --rs-red-500: #f04f43;
  --rs-red-600: #e63f30;
  --rs-red-700: #d12f1d;
  --rs-red-800: #b3200c;
  --rs-red-900: #8a1200;
  --rs-orange-50: #fff9f2;
  --rs-orange-100: #ffe2c2;
  --rs-orange-200: #ffce91;
  --rs-orange-300: #ffba61;
  --rs-orange-400: #ffa930;
  --rs-orange-500: #ff9800;
  --rs-orange-600: #f59700;
  --rs-orange-700: #e08e00;
  --rs-orange-800: #c27e00;
  --rs-orange-900: #996600;
  --rs-yellow-50: #fff9f0;
  --rs-yellow-100: #ffedd1;
  --rs-yellow-200: #ffe2b3;
  --rs-yellow-300: #ffd894;
  --rs-yellow-400: #ffcf75;
  --rs-yellow-500: #ffc757;
  --rs-yellow-600: #f5bb3d;
  --rs-yellow-700: #e0a824;
  --rs-yellow-800: #c28f0e;
  --rs-yellow-900: #997000;
  --rs-green-50: #f3fff2;
  --rs-green-100: #cff0ce;
  --rs-green-200: #aee0ad;
  --rs-green-300: #8ccf8c;
  --rs-green-400: #71bf72;
  --rs-green-500: #58b15b;
  --rs-green-600: #3fab45;
  --rs-green-700: #27a12f;
  --rs-green-800: #10911b;
  --rs-green-900: #007d0c;
  --rs-cyan-50: #f2ffff;
  --rs-cyan-100: #bcf4f7;
  --rs-cyan-200: #87e6ed;
  --rs-cyan-300: #57dae6;
  --rs-cyan-400: #2acadb;
  --rs-cyan-500: #00bcd4;
  --rs-cyan-600: #00b1cc;
  --rs-cyan-700: #00a0bd;
  --rs-cyan-800: #008aa6;
  --rs-cyan-900: #006e87;
  --rs-blue-50: #edf9ff;
  --rs-blue-100: #c0e8fc;
  --rs-blue-200: #93d6fa;
  --rs-blue-300: #67c1f5;
  --rs-blue-400: #3dadf2;
  --rs-blue-500: #1499ef;
  --rs-blue-600: #0e8ce6;
  --rs-blue-700: #087ad1;
  --rs-blue-800: #0464b3;
  --rs-blue-900: #00498a;
  --rs-violet-50: #f6f2ff;
  --rs-violet-100: #d5c9f0;
  --rs-violet-200: #b6a1e3;
  --rs-violet-300: #987bd4;
  --rs-violet-400: #805ac7;
  --rs-violet-500: #673ab7;
  --rs-violet-600: #5f2bb3;
  --rs-violet-700: #531ba8;
  --rs-violet-800: #470c99;
  --rs-violet-900: #390085;
  --rs-state-success: #58b15b;
  --rs-state-info: #1499ef;
  --rs-state-warning: #ffc757;
  --rs-state-error: #f04f43;
  --rs-body: #0f131a;
  --rs-text-link: #34c3ff;
  --rs-text-link-hover: #59d0ff;
  --rs-text-link-active: #80ddff;
  --rs-text-primary: #e9ebf0;
  --rs-text-secondary: #a4a9b3;
  --rs-text-tertiary: #858b94;
  --rs-text-heading: #fff;
  --rs-text-inverse: #1a1d24;
  --rs-text-heading-inverse: #0f131a;
  --rs-text-active: #34c3ff;
  --rs-text-disabled: #5c6066;
  --rs-border-primary: #3c3f43;
  --rs-border-secondary: #292d33;
  --rs-bg-card: #1a1d24;
  --rs-bg-overlay: #292d33;
  --rs-bg-well: #0f131a;
  --rs-bg-active: #169de0;
  --rs-bg-backdrop: rgba(15, 19, 26, 0.8);
  --rs-state-hover-bg: #3c3f43;
  --rs-color-focus-ring: 0 0 0 3px rgba(52, 195, 255, 0.25);
  --rs-state-focus-shadow: 0 0 0 3px rgba(52, 195, 255, 0.25);
  --rs-state-focus-outline: 3px solid rgba(52, 195, 255, 0.25);
  --rs-shadow-overlay: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-btn-default-bg: #3c3f43;
  --rs-btn-default-text: #e9ebf0;
  --rs-btn-default-hover-bg: #5c6066;
  --rs-btn-default-active-bg: #858b94;
  --rs-btn-default-active-text: #fff;
  --rs-btn-default-disabled-bg: #3c3f43;
  --rs-btn-default-disabled-text: #6a6f76;
  --rs-btn-primary-bg: #169de0;
  --rs-btn-primary-text: #fff;
  --rs-btn-primary-hover-bg: #25b3f5;
  --rs-btn-primary-active-bg: #59d0ff;
  --rs-btn-subtle-text: #a4a9b3;
  --rs-btn-subtle-hover-bg: #5c6066;
  --rs-btn-subtle-hover-text: #e9ebf0;
  --rs-btn-subtle-active-bg: #6a6f76;
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-subtle-disabled-text: #5c6066;
  --rs-btn-ghost-border: #34c3ff;
  --rs-btn-ghost-text: #34c3ff;
  --rs-btn-ghost-hover-border: #59d0ff;
  --rs-btn-ghost-hover-text: #59d0ff;
  --rs-btn-ghost-active-border: #a6e9ff;
  --rs-btn-ghost-active-text: #a6e9ff;
  --rs-btn-link-text: #34c3ff;
  --rs-btn-link-hover-text: #59d0ff;
  --rs-btn-link-active-text: #a6e9ff;
  --rs-iconbtn-addon: #5c6066;
  --rs-iconbtn-activated-addon: #6a6f76;
  --rs-iconbtn-pressed-addon: #a4a9b3;
  --rs-iconbtn-primary-addon: #25b3f5;
  --rs-iconbtn-primary-activated-addon: #34c3ff;
  --rs-iconbtn-primary-pressed-addon: #59d0ff;
  --rs-divider-border: #3c3f43;
  --rs-loader-ring: rgba(233, 235, 240, 0.3);
  --rs-loader-rotor: #fff;
  --rs-loader-backdrop: rgba(15, 19, 26, 0.83);
  --rs-loader-ring-inverse: rgba(233, 235, 240, 0.8);
  --rs-loader-rotor-inverse: #5c6066;
  --rs-loader-backdrop-inverse: rgba(255, 255, 255, 0.9);
  --rs-message-success-header: #fff;
  --rs-message-success-text: #fff;
  --rs-message-success-icon: #fff;
  --rs-message-success-bg: #4caf50;
  --rs-message-info-header: #fff;
  --rs-message-info-text: #fff;
  --rs-message-info-icon: #fff;
  --rs-message-info-bg: #2196f3;
  --rs-message-warning-header: #0f131a;
  --rs-message-warning-text: #0f131a;
  --rs-message-warning-icon: #0f131a;
  --rs-message-warning-bg: #ffb300;
  --rs-message-error-header: #fff;
  --rs-message-error-text: #fff;
  --rs-message-error-icon: #fff;
  --rs-message-error-bg: #f44336;
  --rs-tooltip-bg: #5c6066;
  --rs-tooltip-text: #fff;
  --rs-progress-bg: #292d33;
  --rs-progress-bar: #34c3ff;
  --rs-progress-bar-success: #4caf50;
  --rs-progress-bar-fail: #f44336;
  --rs-placeholder: #3c3f43;
  --rs-placeholder-active: #484c50;
  --rs-breadcrumb-item-active-text: #fff;
  --rs-dropdown-divider: #3c3f43;
  --rs-dropdown-item-bg-hover: #3c3f43;
  --rs-dropdown-item-bg-active: rgba(0, 97, 153, 0.2);
  --rs-dropdown-item-text-active: #34c3ff;
  --rs-dropdown-header-text: #5c6066;
  --rs-dropdown-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2), 0 4px 4px 3px rgba(0, 0, 0, 0.24);
  --rs-menuitem-active-bg: #3c3f43;
  --rs-menuitem-active-text: currentColor;
  --rs-steps-border: #a4a9b3;
  --rs-steps-state-finish: #34c3ff;
  --rs-steps-border-state-finish: #34c3ff;
  --rs-steps-state-wait: #a4a9b3;
  --rs-steps-state-process: #169de0;
  --rs-steps-state-error: #f44336;
  --rs-steps-border-state-error: #f44336;
  --rs-steps-icon-state-process: #34c3ff;
  --rs-steps-icon-state-error: #f44336;
  --rs-navs-text: #a4a9b3;
  --rs-navs-text-hover: #cbced4;
  --rs-navs-bg-hover: #6a6f76;
  --rs-navs-text-active: #fff;
  --rs-navs-bg-active: #6a6f76;
  --rs-navs-tab-border: #3c3f43;
  --rs-navs-subtle-border: #3c3f43;
  --rs-navs-selected: #34c3ff;
  --rs-navbar-default-bg: #1a1d24;
  --rs-navbar-default-text: #a4a9b3;
  --rs-navbar-default-selected-text: #34c3ff;
  --rs-navbar-default-hover-bg: #292d33;
  --rs-navbar-default-hover-text: #e9ebf0;
  --rs-navbar-inverse-bg: #169de0;
  --rs-navbar-inverse-text: #fff;
  --rs-navbar-inverse-selected-bg: #59d0ff;
  --rs-navbar-inverse-hover-bg: #25b3f5;
  --rs-navbar-inverse-hover-text: #fff;
  --rs-navbar-subtle-bg: transparent;
  --rs-navbar-subtle-text: #a4a9b3;
  --rs-navbar-subtle-selected-text: #34c3ff;
  --rs-navbar-subtle-hover-bg: #292d33;
  --rs-navbar-subtle-hover-text: #e9ebf0;
  --rs-sidenav-default-bg: #1a1d24;
  --rs-sidenav-default-text: #a4a9b3;
  --rs-sidenav-default-selected-text: #34c3ff;
  --rs-sidenav-default-hover-bg: #292d33;
  --rs-sidenav-default-hover-text: #e9ebf0;
  --rs-sidenav-default-footer-border: #3c3f43;
  --rs-sidenav-inverse-bg: #169de0;
  --rs-sidenav-inverse-text: #fff;
  --rs-sidenav-inverse-selected-bg: #59d0ff;
  --rs-sidenav-inverse-hover-bg: #25b3f5;
  --rs-sidenav-inverse-footer-border: #25b3f5;
  --rs-sidenav-subtle-bg: transparent;
  --rs-sidenav-subtle-text: #a4a9b3;
  --rs-sidenav-subtle-selected-text: #34c3ff;
  --rs-sidenav-subtle-hover-bg: #292d33;
  --rs-sidenav-subtle-hover-text: #e9ebf0;
  --rs-sidenav-subtle-footer-border: #3c3f43;
  --rs-input-bg: #1a1d24;
  --rs-input-focus-border: #34c3ff;
  --rs-input-disabled-bg: #292d33;
  --rs-listbox-option-hover-bg: #3c3f43;
  --rs-listbox-option-hover-text: currentColor;
  --rs-listbox-option-selected-text: #34c3ff;
  --rs-listbox-option-selected-bg: rgba(0, 97, 153, 0.2);
  --rs-listbox-option-disabled-text: #5c6066;
  --rs-listbox-option-disabled-selected-text: #a6e9ff;
  --rs-checkbox-icon: #1a1d24;
  --rs-checkbox-border: #6a6f76;
  --rs-checkbox-checked-bg: #34c3ff;
  --rs-checkbox-disabled-bg: #5c6066;
  --rs-radio-marker: #1a1d24;
  --rs-radio-border: #6a6f76;
  --rs-radio-checked-bg: #34c3ff;
  --rs-radio-disabled-bg: #5c6066;
  --rs-radio-tile-border: #858b94;
  --rs-radio-tile-bg: #fff;
  --rs-radio-tile-checked-color: #34c3ff;
  --rs-radio-tile-checked-mark-color: #1a1d24;
  --rs-radio-tile-checked-disabled-color: #006199;
  --rs-rate-symbol: #3c3f43;
  --rs-rate-symbol-checked: #ffb300;
  --rs-toggle-bg: #6a6f76;
  --rs-toggle-thumb: #fff;
  --rs-toggle-hover-bg: #858b94;
  --rs-toggle-disabled-bg: #3c3f43;
  --rs-toggle-disabled-thumb: #5c6066;
  --rs-toggle-checked-bg: #169de0;
  --rs-toggle-checked-thumb: #fff;
  --rs-toggle-checked-hover-bg: #25b3f5;
  --rs-toggle-checked-disabled-bg: #006199;
  --rs-toggle-checked-disabled-thumb: #858b94;
  --rs-slider-bar: #3c3f43;
  --rs-slider-hover-bar: #3c3f43;
  --rs-slider-thumb-border: #34c3ff;
  --rs-slider-thumb-bg: #292d33;
  --rs-slider-thumb-hover-shadow: 0 0 0 8px rgba(52, 195, 255, 0.25);
  --rs-slider-progress: #34c3ff;
  --rs-uploader-item-bg: #858b94;
  --rs-uploader-item-hover-bg: #3c3f43;
  --rs-uploader-overlay-bg: rgba(60, 63, 67, 0.8);
  --rs-uploader-dnd-bg: #292d33;
  --rs-uploader-dnd-border: #a4a9b3;
  --rs-uploader-dnd-hover-border: #34c3ff;
  --rs-avatar-bg: #6a6f76;
  --rs-avatar-text: #fff;
  --rs-badge-bg: #f44336;
  --rs-badge-text: #fff;
  --rs-tag-bg: #3c3f43;
  --rs-tag-close: #f44336;
  --rs-carousel-bg: #3c3f43;
  --rs-carousel-indicator: rgba(255, 255, 255, 0.4);
  --rs-carousel-indicator-hover: #fff;
  --rs-carousel-indicator-active: #34c3ff;
  --rs-panel-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-list-bg: #0f131a;
  --rs-list-border: #292d33;
  --rs-list-hover-bg: #3c3f43;
  --rs-list-placeholder-bg: rgba(0, 97, 153, 0.2);
  --rs-list-placeholder-border: #34c3ff;
  --rs-timeline-indicator-bg: #5c6066;
  --rs-timeline-indicator-active-bg: #34c3ff;
  --rs-table-shadow: rgba(9, 9, 9, 0.99);
  --rs-table-sort: #34c3ff;
  --rs-table-resize: #34c3ff;
  --rs-table-scrollbar-track: #292d33;
  --rs-table-scrollbar-thumb: #a4a9b3;
  --rs-table-scrollbar-thumb-active: #cbced4;
  --rs-table-scrollbar-vertical-track: #292d33;
  --rs-drawer-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-modal-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-form-errormessage-text: #fff;
  --rs-form-errormessage-bg: #f44336;
  --rs-form-errormessage-border: #f44336;
  --rs-picker-value: #34c3ff;
  --rs-picker-count-bg: #169de0;
  --rs-picker-count-text: #fff;
  --rs-calendar-today-bg: #169de0;
  --rs-calendar-today-text: #fff;
  --rs-calendar-range-bg: rgba(0, 97, 153, 0.5);
  --rs-calendar-time-unit-bg: #3c3f43;
  --rs-calendar-date-selected-text: #fff;
  --rs-calendar-cell-selected-hover-bg: #1675e0;
  --rs-popover-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); }

.rs-theme-high-contrast {
  --rs-gray-50: #e9ebf0;
  --rs-gray-100: #cbced4;
  --rs-gray-200: #a4a9b3;
  --rs-gray-300: #858b94;
  --rs-gray-400: #6a6f76;
  --rs-gray-500: #5c6066;
  --rs-gray-600: #3c3f43;
  --rs-gray-700: #292d33;
  --rs-gray-800: #1a1d24;
  --rs-gray-900: #0f131a;
  --rs-primary-50: #fffef2;
  --rs-primary-100: #fffbc2;
  --rs-primary-200: #fffa91;
  --rs-primary-300: #fffa61;
  --rs-primary-400: #fffc30;
  --rs-primary-500: #ffff00;
  --rs-primary-600: #f1f500;
  --rs-primary-700: #d9e000;
  --rs-primary-800: #b8c200;
  --rs-primary-900: #8f9900;
  --rs-red-50: #fff2f5;
  --rs-red-100: #f2c2cd;
  --rs-red-200: #e691a3;
  --rs-red-300: #d6637a;
  --rs-red-400: #c93a55;
  --rs-red-500: #bd1732;
  --rs-red-600: #b51029;
  --rs-red-700: #a60a1f;
  --rs-red-800: #8f0414;
  --rs-red-900: #70000b;
  --rs-orange-50: #fff9f2;
  --rs-orange-100: #ffe2c2;
  --rs-orange-200: #ffce91;
  --rs-orange-300: #ffba61;
  --rs-orange-400: #ffa930;
  --rs-orange-500: #ff9800;
  --rs-orange-600: #f59700;
  --rs-orange-700: #e08e00;
  --rs-orange-800: #c27e00;
  --rs-orange-900: #996600;
  --rs-yellow-50: #fff9f0;
  --rs-yellow-100: #ffedd1;
  --rs-yellow-200: #ffe2b3;
  --rs-yellow-300: #ffd894;
  --rs-yellow-400: #ffcf75;
  --rs-yellow-500: #ffc757;
  --rs-yellow-600: #f5bb3d;
  --rs-yellow-700: #e0a824;
  --rs-yellow-800: #c28f0e;
  --rs-yellow-900: #997000;
  --rs-green-50: #f2fff5;
  --rs-green-100: #b3e6bd;
  --rs-green-200: #7ccc8e;
  --rs-green-300: #50b567;
  --rs-green-400: #2a9c46;
  --rs-green-500: #0d822c;
  --rs-green-600: #09802b;
  --rs-green-700: #057a28;
  --rs-green-800: #017325;
  --rs-green-900: #006923;
  --rs-cyan-50: #f2ffff;
  --rs-cyan-100: #bcf4f7;
  --rs-cyan-200: #87e6ed;
  --rs-cyan-300: #57dae6;
  --rs-cyan-400: #2acadb;
  --rs-cyan-500: #00bcd4;
  --rs-cyan-600: #00b1cc;
  --rs-cyan-700: #00a0bd;
  --rs-cyan-800: #008aa6;
  --rs-cyan-900: #006e87;
  --rs-blue-50: #edf9ff;
  --rs-blue-100: #c0e8fc;
  --rs-blue-200: #93d6fa;
  --rs-blue-300: #67c1f5;
  --rs-blue-400: #3dadf2;
  --rs-blue-500: #1499ef;
  --rs-blue-600: #0e8ce6;
  --rs-blue-700: #087ad1;
  --rs-blue-800: #0464b3;
  --rs-blue-900: #00498a;
  --rs-violet-50: #f6f2ff;
  --rs-violet-100: #d5c9f0;
  --rs-violet-200: #b6a1e3;
  --rs-violet-300: #987bd4;
  --rs-violet-400: #805ac7;
  --rs-violet-500: #673ab7;
  --rs-violet-600: #5f2bb3;
  --rs-violet-700: #531ba8;
  --rs-violet-800: #470c99;
  --rs-violet-900: #390085;
  --rs-state-success: #0d822c;
  --rs-state-info: #1499ef;
  --rs-state-warning: #ffc757;
  --rs-state-error: #bd1732;
  --rs-body: #0f131a;
  --rs-text-link: #ffff00;
  --rs-text-link-hover: #fffc30;
  --rs-text-link-active: #fffa61;
  --rs-text-primary: #e9ebf0;
  --rs-text-secondary: #a4a9b3;
  --rs-text-tertiary: #858b94;
  --rs-text-heading: #fff;
  --rs-text-inverse: #1a1d24;
  --rs-text-heading-inverse: #0f131a;
  --rs-text-active: #ffff00;
  --rs-text-disabled: #5c6066;
  --rs-border-primary: #cbced4;
  --rs-border-secondary: #292d33;
  --rs-bg-card: #1a1d24;
  --rs-bg-overlay: #1a1d24;
  --rs-bg-well: #0f131a;
  --rs-bg-active: #ffff00;
  --rs-bg-backdrop: rgba(15, 19, 26, 0.8);
  --rs-state-hover-bg: #3c3f43;
  --rs-color-focus-ring: #fff;
  --rs-state-focus-shadow: 0 0 0 3px #0f131a, 0 0 0 5px #fff;
  --rs-state-focus-shadow-slim: 0 0 0 2px #fff;
  --rs-state-focus-outline: 3px solid rgba(255, 255, 0, 0.25);
  --rs-shadow-overlay: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-btn-default-bg: transparent;
  --rs-btn-default-text: #ffff00;
  --rs-btn-default-border: 1px solid #ffff00;
  --rs-btn-default-hover-bg: transparent;
  --rs-btn-default-hover-text: #fffc30;
  --rs-btn-default-active-bg: transparent;
  --rs-btn-default-active-text: #fffa91;
  --rs-btn-default-disabled-bg: transparent;
  --rs-btn-default-disabled-text: #ffff00;
  --rs-btn-primary-bg: #ffff00;
  --rs-btn-primary-text: #0f131a;
  --rs-btn-primary-hover-bg: #fffc30;
  --rs-btn-primary-active-bg: #fffa91;
  --rs-btn-subtle-text: #ffff00;
  --rs-btn-subtle-hover-bg: transparent;
  --rs-btn-subtle-hover-text: #fffc30;
  --rs-btn-subtle-active-bg: transparent;
  --rs-btn-subtle-active-text: #fffa91;
  --rs-btn-subtle-disabled-text: #5c6066;
  --rs-btn-ghost-border: #ffff00;
  --rs-btn-ghost-text: #ffff00;
  --rs-btn-ghost-hover-border: #fffc30;
  --rs-btn-ghost-hover-text: #fffc30;
  --rs-btn-ghost-active-border: #fffa91;
  --rs-btn-ghost-active-text: #fffa91;
  --rs-btn-link-text: #ffff00;
  --rs-btn-link-hover-text: #fffc30;
  --rs-btn-link-active-text: #fffa91;
  --rs-iconbtn-addon: transparent;
  --rs-iconbtn-activated-addon: transparent;
  --rs-iconbtn-pressed-addon: transparent;
  --rs-iconbtn-primary-addon: #fffc30;
  --rs-iconbtn-primary-activated-addon: #fffa61;
  --rs-iconbtn-primary-pressed-addon: #fffbc2;
  --rs-divider-border: #3c3f43;
  --rs-loader-ring: rgba(233, 235, 240, 0.3);
  --rs-loader-rotor: #fff;
  --rs-loader-backdrop: rgba(15, 19, 26, 0.83);
  --rs-loader-ring-inverse: rgba(233, 235, 240, 0.8);
  --rs-loader-rotor-inverse: #5c6066;
  --rs-loader-backdrop-inverse: rgba(255, 255, 255, 0.9);
  --rs-message-success-header: #fff;
  --rs-message-success-text: #fff;
  --rs-message-success-icon: #fff;
  --rs-message-success-bg: #007d0c;
  --rs-message-success-border: #82cf82;
  --rs-message-info-header: #fff;
  --rs-message-info-text: #fff;
  --rs-message-info-icon: #fff;
  --rs-message-info-bg: #00448c;
  --rs-message-info-border: #2196f3;
  --rs-message-warning-header: #fff;
  --rs-message-warning-text: #fff;
  --rs-message-warning-icon: #fff;
  --rs-message-warning-bg: #997500;
  --rs-message-warning-border: #ffb300;
  --rs-message-error-header: #fff;
  --rs-message-error-text: #fff;
  --rs-message-error-icon: #fff;
  --rs-message-error-bg: #8f1300;
  --rs-message-error-border: #fa8682;
  --rs-tooltip-bg: #1a1d24;
  --rs-tooltip-text: #fff;
  --rs-progress-bg: #292d33;
  --rs-progress-bar: #ffff00;
  --rs-progress-bar-success: #4caf50;
  --rs-progress-bar-fail: #f44336;
  --rs-placeholder: #3c3f43;
  --rs-placeholder-active: #484c50;
  --rs-breadcrumb-item-active-text: #fff;
  --rs-dropdown-divider: #3c3f43;
  --rs-dropdown-item-bg-hover: #3c3f43;
  --rs-dropdown-item-bg-active: rgba(143, 153, 0, 0.2);
  --rs-dropdown-item-text-active: #ffff00;
  --rs-dropdown-header-text: #5c6066;
  --rs-dropdown-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2), 0 4px 4px 3px rgba(0, 0, 0, 0.24);
  --rs-menuitem-active-bg: transparent;
  --rs-menuitem-active-text: #ffff00;
  --rs-steps-border: #a4a9b3;
  --rs-steps-state-finish: #ffff00;
  --rs-steps-border-state-finish: #ffff00;
  --rs-steps-state-wait: #a4a9b3;
  --rs-steps-state-process: #d9e000;
  --rs-steps-state-error: #f44336;
  --rs-steps-border-state-error: #f44336;
  --rs-steps-icon-state-process: #ffff00;
  --rs-steps-icon-state-error: #f44336;
  --rs-navs-text: #a4a9b3;
  --rs-navs-text-hover: #ffff00;
  --rs-navs-bg-hover: transparent;
  --rs-navs-text-active: #ffff00;
  --rs-navs-bg-active: #6a6f76;
  --rs-navs-tab-border: #3c3f43;
  --rs-navs-subtle-border: #3c3f43;
  --rs-navs-selected: #ffff00;
  --rs-navbar-default-bg: #1a1d24;
  --rs-navbar-default-text: #e9ebf0;
  --rs-navbar-default-selected-text: #ffff00;
  --rs-navbar-default-hover-bg: transparent;
  --rs-navbar-default-hover-text: #ffff00;
  --rs-navbar-inverse-bg: #1a1d24;
  --rs-navbar-inverse-text: #e9ebf0;
  --rs-navbar-inverse-selected-bg: transparent;
  --rs-navbar-inverse-selected-text: #ffff00;
  --rs-navbar-inverse-hover-bg: transparent;
  --rs-navbar-inverse-hover-text: #ffff00;
  --rs-navbar-subtle-bg: #1a1d24;
  --rs-navbar-subtle-text: #e9ebf0;
  --rs-navbar-subtle-selected-text: #ffff00;
  --rs-navbar-subtle-hover-bg: transparent;
  --rs-navbar-subtle-hover-text: #ffff00;
  --rs-sidenav-default-bg: #1a1d24;
  --rs-sidenav-default-text: #e9ebf0;
  --rs-sidenav-default-selected-text: #ffff00;
  --rs-sidenav-default-hover-bg: transparent;
  --rs-sidenav-default-hover-text: #ffff00;
  --rs-sidenav-default-footer-border: #e9ebf0;
  --rs-sidenav-inverse-bg: #1a1d24;
  --rs-sidenav-inverse-text: #e9ebf0;
  --rs-sidenav-inverse-selected-bg: transparent;
  --rs-sidenav-inverse-selected-text: #ffff00;
  --rs-sidenav-inverse-hover-bg: transparent;
  --rs-sidenav-inverse-footer-border: #e9ebf0;
  --rs-sidenav-subtle-bg: #1a1d24;
  --rs-sidenav-subtle-text: #e9ebf0;
  --rs-sidenav-subtle-selected-text: #ffff00;
  --rs-sidenav-subtle-hover-bg: transparent;
  --rs-sidenav-subtle-hover-text: #ffff00;
  --rs-sidenav-subtle-footer-border: #e9ebf0;
  --rs-input-bg: #1a1d24;
  --rs-input-focus-border: #ffff00;
  --rs-input-disabled-bg: #292d33;
  --rs-listbox-option-hover-bg: transparent;
  --rs-listbox-option-hover-text: #ffff00;
  --rs-listbox-option-selected-text: #ffff00;
  --rs-listbox-option-selected-bg: transparent;
  --rs-listbox-option-disabled-text: #5c6066;
  --rs-listbox-option-disabled-selected-text: #fffa91;
  --rs-checkbox-icon: #1a1d24;
  --rs-checkbox-border: #cbced4;
  --rs-checkbox-checked-bg: #ffff00;
  --rs-checkbox-disabled-bg: #5c6066;
  --rs-radio-marker: #1a1d24;
  --rs-radio-border: #cbced4;
  --rs-radio-checked-bg: #ffff00;
  --rs-radio-disabled-bg: #5c6066;
  --rs-radio-tile-border: #858b94;
  --rs-radio-tile-bg: #fff;
  --rs-radio-tile-checked-color: #ffff00;
  --rs-radio-tile-checked-mark-color: #1a1d24;
  --rs-radio-tile-checked-disabled-color: #8f9900;
  --rs-rate-symbol: #cbced4;
  --rs-rate-symbol-checked: #ffff00;
  --rs-toggle-bg: #1a1d24;
  --rs-toggle-thumb: #cbced4;
  --rs-toggle-hover-bg: #1a1d24;
  --rs-toggle-disabled-bg: #1a1d24;
  --rs-toggle-disabled-thumb: #858b94;
  --rs-toggle-checked-bg: #ffff00;
  --rs-toggle-checked-thumb: #1a1d24;
  --rs-toggle-checked-hover-bg: #fffc30;
  --rs-toggle-checked-disabled-bg: #8f9900;
  --rs-toggle-checked-disabled-thumb: #1a1d24;
  --rs-slider-bar: #3c3f43;
  --rs-slider-hover-bar: #3c3f43;
  --rs-slider-thumb-border: #ffff00;
  --rs-slider-thumb-bg: #292d33;
  --rs-slider-thumb-hover-shadow: 0 0 0 8px rgba(255, 255, 0, 0.25);
  --rs-slider-progress: #ffff00;
  --rs-uploader-item-bg: #858b94;
  --rs-uploader-item-hover-bg: #1a1d24;
  --rs-uploader-item-hover-text: #ffff00;
  --rs-uploader-overlay-bg: rgba(60, 63, 67, 0.8);
  --rs-uploader-dnd-bg: #292d33;
  --rs-uploader-dnd-border: #a4a9b3;
  --rs-uploader-dnd-hover-border: #ffff00;
  --rs-avatar-bg: #6a6f76;
  --rs-avatar-text: #fff;
  --rs-badge-bg: #f44336;
  --rs-badge-text: #fff;
  --rs-tag-bg: #3c3f43;
  --rs-tag-close: #f44336;
  --rs-carousel-bg: #3c3f43;
  --rs-carousel-indicator: rgba(255, 255, 255, 0.4);
  --rs-carousel-indicator-hover: #fff;
  --rs-carousel-indicator-active: #ffff00;
  --rs-panel-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-pagination-item-text: #e9ebf0;
  --rs-pagination-item-current-text: #ffff00;
  --rs-list-bg: transparent;
  --rs-list-border: #292d33;
  --rs-list-hover-bg: #3c3f43;
  --rs-list-placeholder-bg: rgba(143, 153, 0, 0.2);
  --rs-list-placeholder-border: #ffff00;
  --rs-timeline-indicator-bg: #5c6066;
  --rs-timeline-indicator-active-bg: #ffff00;
  --rs-table-shadow: rgba(9, 9, 9, 0.99);
  --rs-table-sort: #ffff00;
  --rs-table-resize: #ffff00;
  --rs-table-scrollbar-track: #292d33;
  --rs-table-scrollbar-thumb: #a4a9b3;
  --rs-table-scrollbar-thumb-active: #cbced4;
  --rs-table-scrollbar-vertical-track: #292d33;
  --rs-drawer-bg: #292d33;
  --rs-drawer-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-modal-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-modal-border: 1px solid #cbced4;
  --rs-form-errormessage-text: #fff;
  --rs-form-errormessage-bg: #f44336;
  --rs-form-errormessage-border: #f44336;
  --rs-picker-value: #ffff00;
  --rs-picker-count-bg: #ffff00;
  --rs-picker-count-text: #0f131a;
  --rs-calendar-today-bg: #ffff00;
  --rs-calendar-today-text: #0f131a;
  --rs-calendar-range-bg: rgba(143, 153, 0, 0.5);
  --rs-calendar-time-unit-bg: #0f131a;
  --rs-calendar-date-selected-text: #0f131a;
  --rs-calendar-cell-selected-hover-bg: #e9ebf0;
  --rs-popover-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); }

/* stylelint-disable */
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

b,
strong {
  font-weight: bold; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button {
  border-radius: 0; }

button,
select {
  text-transform: none; }

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: not-allowed; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type='checkbox'],
input[type='radio'] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0; }

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  height: auto; }

textarea {
  overflow: auto; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

[tabindex='-1'] {
  outline: none; }

input[type='button'],
input[type='submit'],
input[type='reset'],
button {
  border-width: 0; }

input::-ms-clear {
  display: none; }

input[type='file']::-webkit-file-upload-button,
input[type='file']::-ms-browse {
  border-width: 0;
  background: transparent;
  color: currentColor; }

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

body {
  font-family: Apple-System, Arial, Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', STXihei, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #575757;
  color: var(--rs-text-primary);
  background-color: #fff;
  background-color: var(--rs-body); }

a {
  color: #1675e0;
  color: var(--rs-text-link);
  text-decoration: none; }

a:hover,
a:focus {
  color: #0a5dc2;
  color: var(--rs-text-link-hover);
  text-decoration: underline; }

a:active {
  color: #004299;
  color: var(--rs-text-link-active);
  text-decoration: underline; }

img {
  vertical-align: middle; }

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #e5e5ea;
  border-top: 1px solid var(--rs-border-primary); }

[role='button'] {
  cursor: pointer; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: bolder;
  color: inherit;
  margin: 0; }

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  font-weight: normal;
  line-height: 1;
  color: #c5c6c7;
  color: var(--rs-text-disabled); }

h1 {
  font-size: 46px;
  line-height: 62px; }

h2 {
  font-size: 36px;
  line-height: 50px; }

h3 {
  font-size: 28px;
  line-height: 42px; }

h4 {
  font-size: 22px;
  line-height: 34px; }

h5 {
  font-size: 18px;
  line-height: 24px; }

h6 {
  font-size: 16px;
  line-height: 22px; }

p {
  margin: 0; }

p + p {
  margin-top: 8px; }

small {
  font-size: 85%; }

mark {
  background-color: #fff9e6;
  background-color: var(--rs-bg-warning);
  padding: 0.2em;
  font-weight: bolder; }

ul,
ol {
  margin-top: 0;
  margin-bottom: 10px; }

ul ul,
ol ul,
ul ol,
ol ol {
  margin-bottom: 0; }

dl {
  margin-top: 0;
  margin-bottom: 10px; }

dt,
dd {
  line-height: 1.42857143; }

dt {
  font-weight: bold; }

dd {
  margin-left: 0;
  margin-bottom: 10px; }

@font-face {
  font-family: Apple-System;
  src: local(-apple-system), local(BlinkMacSystemFont), local(system-ui); }

@-webkit-keyframes slideInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes slideInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@-webkit-keyframes slideOutLeft {
  from {
    opacity: 1;
    -webkit-transform: none;
    transform: none; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    opacity: 1;
    -webkit-transform: none;
    transform: none; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@-webkit-keyframes slideInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes slideInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@-webkit-keyframes slideOutRight {
  from {
    opacity: 1;
    -webkit-transform: none;
    transform: none; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

@keyframes slideOutRight {
  from {
    opacity: 1;
    -webkit-transform: none;
    transform: none; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

@-webkit-keyframes slideInTop {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes slideInTop {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@-webkit-keyframes slideOutTop {
  from {
    opacity: 1;
    -webkit-transform: none;
    transform: none; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes slideOutTop {
  from {
    opacity: 1;
    -webkit-transform: none;
    transform: none; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@-webkit-keyframes slideInBottom {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes slideInBottom {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@-webkit-keyframes slideOutBottom {
  from {
    opacity: 1;
    -webkit-transform: none;
    transform: none; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes slideOutBottom {
  from {
    opacity: 1;
    -webkit-transform: none;
    transform: none; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.rs-anim-slide-out {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
  animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards; }

.rs-anim-slide-in {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards; }

.rs-anim-right.rs-anim-slide-in {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight; }

.rs-anim-right.rs-anim-slide-out {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight; }

.rs-anim-left.rs-anim-slide-in {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft; }

.rs-anim-left.rs-anim-slide-out {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft; }

.rs-anim-top.rs-anim-slide-in {
  -webkit-animation-name: slideInTop;
  animation-name: slideInTop; }

.rs-anim-top.rs-anim-slide-out {
  -webkit-animation-name: slideOutTop;
  animation-name: slideOutTop; }

.rs-anim-bottom.rs-anim-slide-in {
  -webkit-animation-name: slideInBottom;
  animation-name: slideInBottom; }

.rs-anim-bottom.rs-anim-slide-out {
  -webkit-animation-name: slideOutBottom;
  animation-name: slideOutBottom; }

.rs-anim-bounce-in {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards; }

@-webkit-keyframes bounceIn {
  from {
    opacity: 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes bounceIn {
  from {
    opacity: 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1); } }

.rs-anim-bounce-out {
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
  animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards; }

@-webkit-keyframes bounceOut {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); } }

@keyframes bounceOut {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); } }

.rs-anim-fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
  pointer-events: none; }

.rs-anim-fade.rs-anim-in {
  opacity: 1;
  pointer-events: unset; }

.rs-anim-collapse {
  display: none; }

.rs-anim-collapse.rs-anim-in {
  display: block; }

tr.rs-anim-collapse.rs-anim-in {
  display: table-row; }

tbody.rs-anim-collapse.rs-anim-in {
  display: table-row-group; }

.rs-anim-collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease, width 0.35s ease, visibility 0.35s ease;
  transition: height 0.35s ease, width 0.35s ease, visibility 0.35s ease; }

.rs-auto-complete-menu {
  margin-bottom: 6px;
  overflow-y: auto;
  margin-top: 6px; }

.rs-auto-complete-menu ul {
  list-style: none;
  margin: 0;
  padding: 0; }

.rs-auto-complete-item {
  display: block;
  padding: 8px 12px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #575757;
  color: var(--rs-text-primary);
  cursor: pointer;
  text-decoration: none;
  width: 100%; }

.rs-auto-complete-item:hover,
.rs-auto-complete-item:focus,
.rs-auto-complete-item-focus {
  background-color: rgba(204, 233, 255, 0.5);
  background-color: var(--rs-listbox-option-hover-bg);
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text); }

.rs-theme-high-contrast .rs-auto-complete-item:hover,
.rs-theme-high-contrast .rs-auto-complete-item:focus,
.rs-theme-high-contrast .rs-auto-complete-item-focus {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  outline-width: 2px;
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text);
  text-decoration: underline; }

.rs-theme-high-contrast .rs-theme-high-contrast .rs-auto-complete-item:hover,
.rs-theme-high-contrast .rs-theme-high-contrast .rs-auto-complete-item:focus,
.rs-theme-high-contrast .rs-theme-high-contrast .rs-auto-complete-item-focus {
  outline-offset: 2px; }

.rs-auto-complete .rs-input:focus::-webkit-textfield-decoration-container {
  visibility: hidden;
  pointer-events: none; }

.rs-avatar {
  background-color: #d9d9d9;
  background-color: var(--rs-avatar-bg);
  color: #fff;
  color: var(--rs-avatar-text);
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  width: 40px;
  height: 40px;
  font-size: 18px; }

.rs-avatar > .rs-icon {
  font-size: 29px;
  height: 29px; }

.rs-avatar > .rs-avatar-image {
  width: 40px;
  height: 40px;
  line-height: 40px; }

.rs-avatar > .rs-icon {
  vertical-align: middle; }

.rs-avatar-image::before {
  content: attr(alt);
  position: absolute;
  width: 100%;
  height: inherit;
  top: 0;
  left: 0;
  background: #d9d9d9;
  background: var(--rs-avatar-bg);
  text-align: center;
  padding: 0 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.rs-avatar-circle {
  border-radius: 50%; }

.rs-avatar-lg {
  width: 60px;
  height: 60px;
  font-size: 26px; }

.rs-avatar-lg > .rs-icon {
  font-size: 43px;
  height: 43px; }

.rs-avatar-lg > .rs-avatar-image {
  width: 60px;
  height: 60px;
  line-height: 60px; }

.rs-avatar-sm {
  width: 30px;
  height: 30px;
  font-size: 14px; }

.rs-avatar-sm > .rs-icon {
  font-size: 22px;
  height: 22px; }

.rs-avatar-sm > .rs-avatar-image {
  width: 30px;
  height: 30px;
  line-height: 30px; }

.rs-avatar-xs {
  width: 20px;
  height: 20px;
  font-size: 12px; }

.rs-avatar-xs > .rs-icon {
  font-size: 16px;
  height: 16px; }

.rs-avatar-xs > .rs-avatar-image {
  width: 20px;
  height: 20px;
  line-height: 20px; }

.rs-avatar-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: end;
  align-items: flex-end; }

.rs-avatar-group-stack .rs-avatar {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  margin-right: -10px;
  -webkit-transition: margin 0.15s;
  transition: margin 0.15s; }

.rs-avatar-group-stack .rs-avatar:last-child {
  margin-right: 0; }

.rs-avatar-group-stack .rs-avatar:hover {
  margin-right: 0; }

.rs-badge-wrapper {
  position: relative; }

.rs-badge {
  display: inline-block; }

.rs-badge-independent,
.rs-badge-content {
  background-color: #f44336;
  background-color: var(--rs-badge-bg);
  color: #fff;
  color: var(--rs-badge-text);
  border-radius: 8px;
  font-size: 12px;
  line-height: 16px;
  padding: 0 5px; }

.rs-badge-independent.rs-badge-dot,
.rs-badge-wrapper .rs-badge-content:empty {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  padding: 0; }

.rs-badge-wrapper .rs-badge-content {
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  z-index: 1; }

.rs-badge-red.rs-badge-independent,
.rs-badge-red .rs-badge-content {
  background-color: #f44336;
  background-color: var(--rs-red-500); }

.rs-badge-orange.rs-badge-independent,
.rs-badge-orange .rs-badge-content {
  background-color: #fa8900;
  background-color: var(--rs-orange-500); }

.rs-badge-yellow.rs-badge-independent,
.rs-badge-yellow .rs-badge-content {
  background-color: #ffb300;
  background-color: var(--rs-yellow-500); }

.rs-badge-green.rs-badge-independent,
.rs-badge-green .rs-badge-content {
  background-color: #4caf50;
  background-color: var(--rs-green-500); }

.rs-badge-cyan.rs-badge-independent,
.rs-badge-cyan .rs-badge-content {
  background-color: #00bcd4;
  background-color: var(--rs-cyan-500); }

.rs-badge-blue.rs-badge-independent,
.rs-badge-blue .rs-badge-content {
  background-color: #2196f3;
  background-color: var(--rs-blue-500); }

.rs-badge-violet.rs-badge-independent,
.rs-badge-violet .rs-badge-content {
  background-color: #673ab7;
  background-color: var(--rs-violet-500); }

.rs-breadcrumb {
  margin-bottom: 20px;
  font-size: 12px;
  color: #8e8e93;
  color: var(--rs-text-secondary); }

.rs-breadcrumb-item {
  -webkit-transition: color 0.3s linear;
  transition: color 0.3s linear; }

.rs-breadcrumb-item:focus {
  outline: 0; }

.rs-breadcrumb-item-active {
  color: #272c36;
  color: var(--rs-breadcrumb-item-active-text); }

.rs-breadcrumb-separator {
  margin: 0 4px; }

.rs-btn {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  white-space: nowrap;
  -webkit-transition: color 0.15s ease-out, background-color 0.15s ease-out;
  transition: color 0.15s ease-out, background-color 0.15s ease-out;
  border: none;
  border: none;
  border: var(--rs-btn-default-border, none);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  color: #575757;
  color: var(--rs-btn-default-text);
  background-color: #f7f7fa;
  background-color: var(--rs-btn-default-bg);
  border-radius: 6px;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 12px;
  overflow: hidden;
  position: relative; }

.rs-theme-high-contrast .rs-btn {
  -webkit-transition: none;
  transition: none; }

.rs-btn-ghost.rs-btn {
  padding: 7px 11px; }

.rs-btn-icon.rs-btn {
  padding: 10px 10px;
  line-height: 16px; }

.rs-btn-icon.rs-btn > .rs-icon {
  font-size: 16px; }

.rs-btn-icon-with-text.rs-btn {
  line-height: 20px; }

.rs-btn-icon-with-text.rs-btn > .rs-icon {
  padding: 10px 10px;
  width: 36px;
  height: 36px; }

.rs-btn-icon-with-text.rs-btn.rs-btn-icon-placement-left {
  padding: 8px 12px 8px 48px; }

.rs-btn-icon-with-text.rs-btn.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: none;
  border-right: none;
  border-right: var(--rs-btn-default-border, none); }

.rs-btn-icon-with-text.rs-btn.rs-btn-icon-placement-right {
  padding: 8px 48px 8px 12px; }

.rs-btn-icon-with-text.rs-btn.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: none;
  border-left: none;
  border-left: var(--rs-btn-default-border, none); }

.rs-btn:focus-visible {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring); }

.rs-theme-high-contrast .rs-btn:focus-visible {
  outline-offset: 2px; }

.rs-btn:hover,
.rs-btn:focus {
  color: var(--rs-btn-default-hover-text);
  background-color: #e5e5ea;
  background-color: var(--rs-btn-default-hover-bg);
  text-decoration: none; }

.rs-btn:active,
.rs-btn.rs-btn-active {
  color: #272c36;
  color: var(--rs-btn-default-active-text);
  background-color: #d9d9d9;
  background-color: var(--rs-btn-default-active-bg); }

.rs-btn:disabled,
.rs-btn.rs-btn-disabled {
  cursor: not-allowed;
  color: #c5c6c7;
  color: var(--rs-btn-default-disabled-text);
  background-color: #f7f7fa;
  background-color: var(--rs-btn-default-disabled-bg); }

.rs-theme-high-contrast .rs-btn:disabled,
.rs-theme-high-contrast .rs-btn.rs-btn-disabled {
  opacity: 0.5; }

@media not all and (min-resolution: 0.001dpcm) {
  .rs-btn {
    /* stylelint-disable */
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    /* stylelint-enable */ } }

.rs-btn-start-icon {
  line-height: 0;
  margin-right: 5px; }

.rs-btn-end-icon {
  line-height: 0;
  margin-left: 5px; }

.rs-btn-primary {
  color: #fff;
  color: var(--rs-btn-primary-text);
  background-color: #3498ff;
  background-color: var(--rs-btn-primary-bg);
  border: none; }

.rs-btn-primary:hover,
.rs-btn-primary:focus {
  color: #fff;
  color: var(--rs-btn-primary-text);
  background-color: #2589f5;
  background-color: var(--rs-btn-primary-hover-bg); }

.rs-btn-primary:active,
.rs-btn-primary.rs-btn-active {
  color: #fff;
  color: var(--rs-btn-primary-text);
  background-color: #1675e0;
  background-color: var(--rs-btn-primary-active-bg); }

.rs-btn-primary:disabled,
.rs-btn-primary.rs-btn-disabled {
  color: #fff;
  color: var(--rs-btn-primary-text);
  background-color: #3498ff;
  background-color: var(--rs-btn-primary-bg);
  opacity: 0.3; }

.rs-theme-high-contrast .rs-btn-primary:disabled,
.rs-theme-high-contrast .rs-btn-primary.rs-btn-disabled {
  opacity: 0.5; }

.rs-btn-subtle {
  color: #8e8e93;
  color: var(--rs-btn-subtle-text);
  background-color: transparent;
  border: none; }

.rs-btn-subtle:hover,
.rs-btn-subtle:focus {
  color: #575757;
  color: var(--rs-btn-subtle-hover-text);
  background-color: #e5e5ea;
  background-color: var(--rs-btn-subtle-hover-bg); }

.rs-btn-subtle:active,
.rs-btn-subtle.rs-btn-active {
  color: #272c36;
  color: var(--rs-btn-subtle-active-text);
  background-color: #e5e5ea;
  background-color: var(--rs-btn-subtle-active-bg); }

.rs-btn-subtle:disabled,
.rs-btn-subtle.rs-btn-disabled {
  color: #c5c6c7;
  color: var(--rs-btn-subtle-disabled-text);
  background: none; }

.rs-theme-high-contrast .rs-btn-subtle:disabled,
.rs-theme-high-contrast .rs-btn-subtle.rs-btn-disabled {
  opacity: 0.5; }

.rs-btn-link {
  color: #1675e0;
  color: var(--rs-btn-link-text);
  background-color: transparent;
  border: none; }

.rs-btn-link:hover,
.rs-btn-link:focus {
  color: #0a5dc2;
  color: var(--rs-btn-link-hover-text);
  background-color: transparent;
  text-decoration: underline; }

.rs-btn-link:active,
.rs-btn-link.rs-btn-active {
  color: #004299;
  color: var(--rs-btn-link-active-text);
  background-color: transparent; }

.rs-btn-link:disabled,
.rs-btn-link.rs-btn-disabled {
  color: #0a5dc2;
  color: var(--rs-btn-link-hover-text);
  background-color: transparent;
  text-decoration: none;
  opacity: 0.3; }

.rs-theme-high-contrast .rs-btn-link:disabled,
.rs-theme-high-contrast .rs-btn-link.rs-btn-disabled {
  opacity: 0.5; }

.rs-btn-ghost {
  color: #1675e0;
  color: var(--rs-btn-ghost-text);
  background-color: transparent;
  border: 1px solid #1675e0;
  border: 1px solid var(--rs-btn-ghost-border); }

.rs-btn-ghost:hover,
.rs-btn-ghost:focus {
  color: #0a5dc2;
  color: var(--rs-btn-ghost-hover-text);
  background-color: transparent;
  border-color: #0a5dc2;
  border-color: var(--rs-btn-ghost-hover-border);
  -webkit-box-shadow: 0 0 0 1px #0a5dc2;
  -webkit-box-shadow: 0 0 0 1px var(--rs-btn-ghost-hover-border);
  box-shadow: 0 0 0 1px #0a5dc2;
  box-shadow: 0 0 0 1px var(--rs-btn-ghost-hover-border); }

.rs-btn-ghost:active,
.rs-btn-ghost.rs-btn-active {
  color: #004299;
  color: var(--rs-btn-ghost-active-text);
  background-color: transparent;
  border-color: #004299;
  border-color: var(--rs-btn-ghost-active-border); }

.rs-btn-ghost:disabled,
.rs-btn-ghost.rs-btn-disabled {
  color: #1675e0;
  color: var(--rs-btn-ghost-text);
  background-color: transparent;
  opacity: 0.3;
  border-color: #1675e0;
  border-color: var(--rs-btn-ghost-border);
  -webkit-box-shadow: none;
  box-shadow: none; }

.rs-theme-high-contrast .rs-btn-ghost:disabled,
.rs-theme-high-contrast .rs-btn-ghost.rs-btn-disabled {
  opacity: 0.5; }

.rs-btn-red {
  --rs-btn-primary-bg: var(--rs-red-500);
  --rs-btn-primary-hover-bg: var(--rs-red-700);
  --rs-btn-primary-active-bg: var(--rs-red-800);
  --rs-btn-subtle-hover-bg: var(--rs-red-500);
  --rs-btn-subtle-hover-text: #e5e5ea;
  --rs-btn-subtle-active-bg: var(--rs-red-600);
  --rs-btn-subtle-active-text: #575757;
  --rs-btn-ghost-border: var(--rs-red-700);
  --rs-btn-ghost-text: var(--rs-red-700);
  --rs-btn-ghost-hover-border: var(--rs-red-900);
  --rs-btn-ghost-hover-text: var(--rs-red-800);
  --rs-btn-ghost-active-border: var(--rs-red-900);
  --rs-btn-ghost-active-text: var(--rs-red-900);
  --rs-btn-link-text: var(--rs-red-700);
  --rs-btn-link-hover-text: var(--rs-red-800);
  --rs-btn-link-active-text: var(--rs-red-900);
  --rs-iconbtn-primary-addon: var(--rs-red-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-red-800);
  --rs-iconbtn-primary-pressed-addon: var(--rs-red-900); }

.rs-theme-dark .rs-btn-red {
  --rs-btn-primary-bg: var(--rs-red-700);
  --rs-btn-primary-hover-bg: var(--rs-red-500);
  --rs-btn-primary-active-bg: var(--rs-red-400);
  --rs-btn-subtle-hover-bg: var(--rs-red-600);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-red-400);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-red-500);
  --rs-btn-ghost-text: var(--rs-red-500);
  --rs-btn-ghost-hover-border: var(--rs-red-400);
  --rs-btn-ghost-hover-text: var(--rs-red-400);
  --rs-btn-ghost-active-border: var(--rs-red-200);
  --rs-btn-ghost-active-text: var(--rs-red-200);
  --rs-btn-link-text: var(--rs-red-500);
  --rs-btn-link-hover-text: var(--rs-red-400);
  --rs-btn-link-active-text: var(--rs-red-200);
  --rs-iconbtn-primary-addon: var(--rs-red-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-red-400);
  --rs-iconbtn-primary-pressed-addon: var(--rs-red-300); }

.rs-theme-high-contrast .rs-btn-red {
  --rs-btn-primary-bg: var(--rs-red-700);
  --rs-btn-primary-hover-bg: var(--rs-red-600);
  --rs-btn-primary-active-bg: var(--rs-red-400);
  --rs-btn-subtle-hover-bg: var(--rs-red-600);
  --rs-btn-subtle-hover-text: var(--rs-gray-900);
  --rs-btn-subtle-active-bg: var(--rs-red-400);
  --rs-btn-subtle-active-text: var(--rs-gray-900);
  --rs-btn-ghost-border: var(--rs-red-500);
  --rs-btn-ghost-text: var(--rs-red-500);
  --rs-btn-ghost-hover-border: var(--rs-red-400);
  --rs-btn-ghost-hover-text: var(--rs-red-400);
  --rs-btn-ghost-active-border: var(--rs-red-200);
  --rs-btn-ghost-active-text: var(--rs-red-200);
  --rs-btn-link-text: var(--rs-red-500);
  --rs-btn-link-hover-text: var(--rs-red-400);
  --rs-btn-link-active-text: var(--rs-red-200); }

.rs-btn-orange {
  --rs-btn-primary-bg: var(--rs-orange-500);
  --rs-btn-primary-hover-bg: var(--rs-orange-700);
  --rs-btn-primary-active-bg: var(--rs-orange-800);
  --rs-btn-subtle-hover-bg: var(--rs-orange-500);
  --rs-btn-subtle-hover-text: #e5e5ea;
  --rs-btn-subtle-active-bg: var(--rs-orange-600);
  --rs-btn-subtle-active-text: #575757;
  --rs-btn-ghost-border: var(--rs-orange-700);
  --rs-btn-ghost-text: var(--rs-orange-700);
  --rs-btn-ghost-hover-border: var(--rs-orange-900);
  --rs-btn-ghost-hover-text: var(--rs-orange-800);
  --rs-btn-ghost-active-border: var(--rs-orange-900);
  --rs-btn-ghost-active-text: var(--rs-orange-900);
  --rs-btn-link-text: var(--rs-orange-700);
  --rs-btn-link-hover-text: var(--rs-orange-800);
  --rs-btn-link-active-text: var(--rs-orange-900);
  --rs-iconbtn-primary-addon: var(--rs-orange-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-orange-800);
  --rs-iconbtn-primary-pressed-addon: var(--rs-orange-900); }

.rs-theme-dark .rs-btn-orange {
  --rs-btn-primary-bg: var(--rs-orange-700);
  --rs-btn-primary-hover-bg: var(--rs-orange-500);
  --rs-btn-primary-active-bg: var(--rs-orange-400);
  --rs-btn-subtle-hover-bg: var(--rs-orange-600);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-orange-400);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-orange-500);
  --rs-btn-ghost-text: var(--rs-orange-500);
  --rs-btn-ghost-hover-border: var(--rs-orange-400);
  --rs-btn-ghost-hover-text: var(--rs-orange-400);
  --rs-btn-ghost-active-border: var(--rs-orange-200);
  --rs-btn-ghost-active-text: var(--rs-orange-200);
  --rs-btn-link-text: var(--rs-orange-500);
  --rs-btn-link-hover-text: var(--rs-orange-400);
  --rs-btn-link-active-text: var(--rs-orange-200);
  --rs-iconbtn-primary-addon: var(--rs-orange-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-orange-400);
  --rs-iconbtn-primary-pressed-addon: var(--rs-orange-300); }

.rs-theme-high-contrast .rs-btn-orange {
  --rs-btn-primary-bg: var(--rs-orange-700);
  --rs-btn-primary-hover-bg: var(--rs-orange-600);
  --rs-btn-primary-active-bg: var(--rs-orange-400);
  --rs-btn-subtle-hover-bg: var(--rs-orange-600);
  --rs-btn-subtle-hover-text: var(--rs-gray-900);
  --rs-btn-subtle-active-bg: var(--rs-orange-400);
  --rs-btn-subtle-active-text: var(--rs-gray-900);
  --rs-btn-ghost-border: var(--rs-orange-500);
  --rs-btn-ghost-text: var(--rs-orange-500);
  --rs-btn-ghost-hover-border: var(--rs-orange-400);
  --rs-btn-ghost-hover-text: var(--rs-orange-400);
  --rs-btn-ghost-active-border: var(--rs-orange-200);
  --rs-btn-ghost-active-text: var(--rs-orange-200);
  --rs-btn-link-text: var(--rs-orange-500);
  --rs-btn-link-hover-text: var(--rs-orange-400);
  --rs-btn-link-active-text: var(--rs-orange-200); }

.rs-btn-yellow {
  --rs-btn-primary-bg: var(--rs-yellow-500);
  --rs-btn-primary-hover-bg: var(--rs-yellow-700);
  --rs-btn-primary-active-bg: var(--rs-yellow-800);
  --rs-btn-subtle-hover-bg: var(--rs-yellow-500);
  --rs-btn-subtle-hover-text: #e5e5ea;
  --rs-btn-subtle-active-bg: var(--rs-yellow-600);
  --rs-btn-subtle-active-text: #575757;
  --rs-btn-ghost-border: var(--rs-yellow-700);
  --rs-btn-ghost-text: var(--rs-yellow-700);
  --rs-btn-ghost-hover-border: var(--rs-yellow-900);
  --rs-btn-ghost-hover-text: var(--rs-yellow-800);
  --rs-btn-ghost-active-border: var(--rs-yellow-900);
  --rs-btn-ghost-active-text: var(--rs-yellow-900);
  --rs-btn-link-text: var(--rs-yellow-700);
  --rs-btn-link-hover-text: var(--rs-yellow-800);
  --rs-btn-link-active-text: var(--rs-yellow-900);
  --rs-iconbtn-primary-addon: var(--rs-yellow-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-yellow-800);
  --rs-iconbtn-primary-pressed-addon: var(--rs-yellow-900); }

.rs-theme-dark .rs-btn-yellow {
  --rs-btn-primary-bg: var(--rs-yellow-700);
  --rs-btn-primary-hover-bg: var(--rs-yellow-500);
  --rs-btn-primary-active-bg: var(--rs-yellow-400);
  --rs-btn-subtle-hover-bg: var(--rs-yellow-600);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-yellow-400);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-yellow-500);
  --rs-btn-ghost-text: var(--rs-yellow-500);
  --rs-btn-ghost-hover-border: var(--rs-yellow-400);
  --rs-btn-ghost-hover-text: var(--rs-yellow-400);
  --rs-btn-ghost-active-border: var(--rs-yellow-200);
  --rs-btn-ghost-active-text: var(--rs-yellow-200);
  --rs-btn-link-text: var(--rs-yellow-500);
  --rs-btn-link-hover-text: var(--rs-yellow-400);
  --rs-btn-link-active-text: var(--rs-yellow-200);
  --rs-iconbtn-primary-addon: var(--rs-yellow-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-yellow-400);
  --rs-iconbtn-primary-pressed-addon: var(--rs-yellow-300); }

.rs-theme-high-contrast .rs-btn-yellow {
  --rs-btn-primary-bg: var(--rs-yellow-700);
  --rs-btn-primary-hover-bg: var(--rs-yellow-600);
  --rs-btn-primary-active-bg: var(--rs-yellow-400);
  --rs-btn-subtle-hover-bg: var(--rs-yellow-600);
  --rs-btn-subtle-hover-text: var(--rs-gray-900);
  --rs-btn-subtle-active-bg: var(--rs-yellow-400);
  --rs-btn-subtle-active-text: var(--rs-gray-900);
  --rs-btn-ghost-border: var(--rs-yellow-500);
  --rs-btn-ghost-text: var(--rs-yellow-500);
  --rs-btn-ghost-hover-border: var(--rs-yellow-400);
  --rs-btn-ghost-hover-text: var(--rs-yellow-400);
  --rs-btn-ghost-active-border: var(--rs-yellow-200);
  --rs-btn-ghost-active-text: var(--rs-yellow-200);
  --rs-btn-link-text: var(--rs-yellow-500);
  --rs-btn-link-hover-text: var(--rs-yellow-400);
  --rs-btn-link-active-text: var(--rs-yellow-200); }

.rs-btn-green {
  --rs-btn-primary-bg: var(--rs-green-500);
  --rs-btn-primary-hover-bg: var(--rs-green-700);
  --rs-btn-primary-active-bg: var(--rs-green-800);
  --rs-btn-subtle-hover-bg: var(--rs-green-500);
  --rs-btn-subtle-hover-text: #e5e5ea;
  --rs-btn-subtle-active-bg: var(--rs-green-600);
  --rs-btn-subtle-active-text: #575757;
  --rs-btn-ghost-border: var(--rs-green-700);
  --rs-btn-ghost-text: var(--rs-green-700);
  --rs-btn-ghost-hover-border: var(--rs-green-900);
  --rs-btn-ghost-hover-text: var(--rs-green-800);
  --rs-btn-ghost-active-border: var(--rs-green-900);
  --rs-btn-ghost-active-text: var(--rs-green-900);
  --rs-btn-link-text: var(--rs-green-700);
  --rs-btn-link-hover-text: var(--rs-green-800);
  --rs-btn-link-active-text: var(--rs-green-900);
  --rs-iconbtn-primary-addon: var(--rs-green-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-green-800);
  --rs-iconbtn-primary-pressed-addon: var(--rs-green-900); }

.rs-theme-dark .rs-btn-green {
  --rs-btn-primary-bg: var(--rs-green-700);
  --rs-btn-primary-hover-bg: var(--rs-green-500);
  --rs-btn-primary-active-bg: var(--rs-green-400);
  --rs-btn-subtle-hover-bg: var(--rs-green-600);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-green-400);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-green-500);
  --rs-btn-ghost-text: var(--rs-green-500);
  --rs-btn-ghost-hover-border: var(--rs-green-400);
  --rs-btn-ghost-hover-text: var(--rs-green-400);
  --rs-btn-ghost-active-border: var(--rs-green-200);
  --rs-btn-ghost-active-text: var(--rs-green-200);
  --rs-btn-link-text: var(--rs-green-500);
  --rs-btn-link-hover-text: var(--rs-green-400);
  --rs-btn-link-active-text: var(--rs-green-200);
  --rs-iconbtn-primary-addon: var(--rs-green-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-green-400);
  --rs-iconbtn-primary-pressed-addon: var(--rs-green-300); }

.rs-theme-high-contrast .rs-btn-green {
  --rs-btn-primary-bg: var(--rs-green-700);
  --rs-btn-primary-hover-bg: var(--rs-green-600);
  --rs-btn-primary-active-bg: var(--rs-green-400);
  --rs-btn-subtle-hover-bg: var(--rs-green-600);
  --rs-btn-subtle-hover-text: var(--rs-gray-900);
  --rs-btn-subtle-active-bg: var(--rs-green-400);
  --rs-btn-subtle-active-text: var(--rs-gray-900);
  --rs-btn-ghost-border: var(--rs-green-500);
  --rs-btn-ghost-text: var(--rs-green-500);
  --rs-btn-ghost-hover-border: var(--rs-green-400);
  --rs-btn-ghost-hover-text: var(--rs-green-400);
  --rs-btn-ghost-active-border: var(--rs-green-200);
  --rs-btn-ghost-active-text: var(--rs-green-200);
  --rs-btn-link-text: var(--rs-green-500);
  --rs-btn-link-hover-text: var(--rs-green-400);
  --rs-btn-link-active-text: var(--rs-green-200); }

.rs-btn-cyan {
  --rs-btn-primary-bg: var(--rs-cyan-500);
  --rs-btn-primary-hover-bg: var(--rs-cyan-700);
  --rs-btn-primary-active-bg: var(--rs-cyan-800);
  --rs-btn-subtle-hover-bg: var(--rs-cyan-500);
  --rs-btn-subtle-hover-text: #e5e5ea;
  --rs-btn-subtle-active-bg: var(--rs-cyan-600);
  --rs-btn-subtle-active-text: #575757;
  --rs-btn-ghost-border: var(--rs-cyan-700);
  --rs-btn-ghost-text: var(--rs-cyan-700);
  --rs-btn-ghost-hover-border: var(--rs-cyan-900);
  --rs-btn-ghost-hover-text: var(--rs-cyan-800);
  --rs-btn-ghost-active-border: var(--rs-cyan-900);
  --rs-btn-ghost-active-text: var(--rs-cyan-900);
  --rs-btn-link-text: var(--rs-cyan-700);
  --rs-btn-link-hover-text: var(--rs-cyan-800);
  --rs-btn-link-active-text: var(--rs-cyan-900);
  --rs-iconbtn-primary-addon: var(--rs-cyan-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-cyan-800);
  --rs-iconbtn-primary-pressed-addon: var(--rs-cyan-900); }

.rs-theme-dark .rs-btn-cyan {
  --rs-btn-primary-bg: var(--rs-cyan-700);
  --rs-btn-primary-hover-bg: var(--rs-cyan-500);
  --rs-btn-primary-active-bg: var(--rs-cyan-400);
  --rs-btn-subtle-hover-bg: var(--rs-cyan-600);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-cyan-400);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-cyan-500);
  --rs-btn-ghost-text: var(--rs-cyan-500);
  --rs-btn-ghost-hover-border: var(--rs-cyan-400);
  --rs-btn-ghost-hover-text: var(--rs-cyan-400);
  --rs-btn-ghost-active-border: var(--rs-cyan-200);
  --rs-btn-ghost-active-text: var(--rs-cyan-200);
  --rs-btn-link-text: var(--rs-cyan-500);
  --rs-btn-link-hover-text: var(--rs-cyan-400);
  --rs-btn-link-active-text: var(--rs-cyan-200);
  --rs-iconbtn-primary-addon: var(--rs-cyan-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-cyan-400);
  --rs-iconbtn-primary-pressed-addon: var(--rs-cyan-300); }

.rs-theme-high-contrast .rs-btn-cyan {
  --rs-btn-primary-bg: var(--rs-cyan-700);
  --rs-btn-primary-hover-bg: var(--rs-cyan-600);
  --rs-btn-primary-active-bg: var(--rs-cyan-400);
  --rs-btn-subtle-hover-bg: var(--rs-cyan-600);
  --rs-btn-subtle-hover-text: var(--rs-gray-900);
  --rs-btn-subtle-active-bg: var(--rs-cyan-400);
  --rs-btn-subtle-active-text: var(--rs-gray-900);
  --rs-btn-ghost-border: var(--rs-cyan-500);
  --rs-btn-ghost-text: var(--rs-cyan-500);
  --rs-btn-ghost-hover-border: var(--rs-cyan-400);
  --rs-btn-ghost-hover-text: var(--rs-cyan-400);
  --rs-btn-ghost-active-border: var(--rs-cyan-200);
  --rs-btn-ghost-active-text: var(--rs-cyan-200);
  --rs-btn-link-text: var(--rs-cyan-500);
  --rs-btn-link-hover-text: var(--rs-cyan-400);
  --rs-btn-link-active-text: var(--rs-cyan-200); }

.rs-btn-blue {
  --rs-btn-primary-bg: var(--rs-blue-500);
  --rs-btn-primary-hover-bg: var(--rs-blue-700);
  --rs-btn-primary-active-bg: var(--rs-blue-800);
  --rs-btn-subtle-hover-bg: var(--rs-blue-500);
  --rs-btn-subtle-hover-text: #e5e5ea;
  --rs-btn-subtle-active-bg: var(--rs-blue-600);
  --rs-btn-subtle-active-text: #575757;
  --rs-btn-ghost-border: var(--rs-blue-700);
  --rs-btn-ghost-text: var(--rs-blue-700);
  --rs-btn-ghost-hover-border: var(--rs-blue-900);
  --rs-btn-ghost-hover-text: var(--rs-blue-800);
  --rs-btn-ghost-active-border: var(--rs-blue-900);
  --rs-btn-ghost-active-text: var(--rs-blue-900);
  --rs-btn-link-text: var(--rs-blue-700);
  --rs-btn-link-hover-text: var(--rs-blue-800);
  --rs-btn-link-active-text: var(--rs-blue-900);
  --rs-iconbtn-primary-addon: var(--rs-blue-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-blue-800);
  --rs-iconbtn-primary-pressed-addon: var(--rs-blue-900); }

.rs-theme-dark .rs-btn-blue {
  --rs-btn-primary-bg: var(--rs-blue-700);
  --rs-btn-primary-hover-bg: var(--rs-blue-500);
  --rs-btn-primary-active-bg: var(--rs-blue-400);
  --rs-btn-subtle-hover-bg: var(--rs-blue-600);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-blue-400);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-blue-500);
  --rs-btn-ghost-text: var(--rs-blue-500);
  --rs-btn-ghost-hover-border: var(--rs-blue-400);
  --rs-btn-ghost-hover-text: var(--rs-blue-400);
  --rs-btn-ghost-active-border: var(--rs-blue-200);
  --rs-btn-ghost-active-text: var(--rs-blue-200);
  --rs-btn-link-text: var(--rs-blue-500);
  --rs-btn-link-hover-text: var(--rs-blue-400);
  --rs-btn-link-active-text: var(--rs-blue-200);
  --rs-iconbtn-primary-addon: var(--rs-blue-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-blue-400);
  --rs-iconbtn-primary-pressed-addon: var(--rs-blue-300); }

.rs-theme-high-contrast .rs-btn-blue {
  --rs-btn-primary-bg: var(--rs-blue-700);
  --rs-btn-primary-hover-bg: var(--rs-blue-600);
  --rs-btn-primary-active-bg: var(--rs-blue-400);
  --rs-btn-subtle-hover-bg: var(--rs-blue-600);
  --rs-btn-subtle-hover-text: var(--rs-gray-900);
  --rs-btn-subtle-active-bg: var(--rs-blue-400);
  --rs-btn-subtle-active-text: var(--rs-gray-900);
  --rs-btn-ghost-border: var(--rs-blue-500);
  --rs-btn-ghost-text: var(--rs-blue-500);
  --rs-btn-ghost-hover-border: var(--rs-blue-400);
  --rs-btn-ghost-hover-text: var(--rs-blue-400);
  --rs-btn-ghost-active-border: var(--rs-blue-200);
  --rs-btn-ghost-active-text: var(--rs-blue-200);
  --rs-btn-link-text: var(--rs-blue-500);
  --rs-btn-link-hover-text: var(--rs-blue-400);
  --rs-btn-link-active-text: var(--rs-blue-200); }

.rs-btn-violet {
  --rs-btn-primary-bg: var(--rs-violet-500);
  --rs-btn-primary-hover-bg: var(--rs-violet-700);
  --rs-btn-primary-active-bg: var(--rs-violet-800);
  --rs-btn-subtle-hover-bg: var(--rs-violet-500);
  --rs-btn-subtle-hover-text: #e5e5ea;
  --rs-btn-subtle-active-bg: var(--rs-violet-600);
  --rs-btn-subtle-active-text: #575757;
  --rs-btn-ghost-border: var(--rs-violet-700);
  --rs-btn-ghost-text: var(--rs-violet-700);
  --rs-btn-ghost-hover-border: var(--rs-violet-900);
  --rs-btn-ghost-hover-text: var(--rs-violet-800);
  --rs-btn-ghost-active-border: var(--rs-violet-900);
  --rs-btn-ghost-active-text: var(--rs-violet-900);
  --rs-btn-link-text: var(--rs-violet-700);
  --rs-btn-link-hover-text: var(--rs-violet-800);
  --rs-btn-link-active-text: var(--rs-violet-900);
  --rs-iconbtn-primary-addon: var(--rs-violet-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-violet-800);
  --rs-iconbtn-primary-pressed-addon: var(--rs-violet-900); }

.rs-theme-dark .rs-btn-violet {
  --rs-btn-primary-bg: var(--rs-violet-700);
  --rs-btn-primary-hover-bg: var(--rs-violet-500);
  --rs-btn-primary-active-bg: var(--rs-violet-400);
  --rs-btn-subtle-hover-bg: var(--rs-violet-600);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-violet-400);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-violet-500);
  --rs-btn-ghost-text: var(--rs-violet-500);
  --rs-btn-ghost-hover-border: var(--rs-violet-400);
  --rs-btn-ghost-hover-text: var(--rs-violet-400);
  --rs-btn-ghost-active-border: var(--rs-violet-200);
  --rs-btn-ghost-active-text: var(--rs-violet-200);
  --rs-btn-link-text: var(--rs-violet-500);
  --rs-btn-link-hover-text: var(--rs-violet-400);
  --rs-btn-link-active-text: var(--rs-violet-200);
  --rs-iconbtn-primary-addon: var(--rs-violet-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-violet-400);
  --rs-iconbtn-primary-pressed-addon: var(--rs-violet-300); }

.rs-theme-high-contrast .rs-btn-violet {
  --rs-btn-primary-bg: var(--rs-violet-700);
  --rs-btn-primary-hover-bg: var(--rs-violet-600);
  --rs-btn-primary-active-bg: var(--rs-violet-400);
  --rs-btn-subtle-hover-bg: var(--rs-violet-600);
  --rs-btn-subtle-hover-text: var(--rs-gray-900);
  --rs-btn-subtle-active-bg: var(--rs-violet-400);
  --rs-btn-subtle-active-text: var(--rs-gray-900);
  --rs-btn-ghost-border: var(--rs-violet-500);
  --rs-btn-ghost-text: var(--rs-violet-500);
  --rs-btn-ghost-hover-border: var(--rs-violet-400);
  --rs-btn-ghost-hover-text: var(--rs-violet-400);
  --rs-btn-ghost-active-border: var(--rs-violet-200);
  --rs-btn-ghost-active-text: var(--rs-violet-200);
  --rs-btn-link-text: var(--rs-violet-500);
  --rs-btn-link-hover-text: var(--rs-violet-400);
  --rs-btn-link-active-text: var(--rs-violet-200); }

.rs-btn-lg {
  font-size: 16px;
  line-height: 22px;
  padding: 10px 16px; }

.rs-btn-ghost.rs-btn-lg {
  padding: 9px 15px; }

.rs-btn-icon.rs-btn-lg {
  padding: 11px 11px;
  line-height: 20px; }

.rs-btn-icon.rs-btn-lg > .rs-icon {
  font-size: 20px; }

.rs-btn-icon-with-text.rs-btn-lg {
  line-height: 22px; }

.rs-btn-icon-with-text.rs-btn-lg > .rs-icon {
  padding: 11px 11px;
  width: 42px;
  height: 42px; }

.rs-btn-icon-with-text.rs-btn-lg.rs-btn-icon-placement-left {
  padding: 10px 16px 10px 58px; }

.rs-btn-icon-with-text.rs-btn-lg.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: none;
  border-right: none;
  border-right: var(--rs-btn-default-border, none); }

.rs-btn-icon-with-text.rs-btn-lg.rs-btn-icon-placement-right {
  padding: 10px 58px 10px 16px; }

.rs-btn-icon-with-text.rs-btn-lg.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: none;
  border-left: none;
  border-left: var(--rs-btn-default-border, none); }

.rs-btn-md {
  font-size: 14px;
  line-height: 20px;
  padding: 8px 12px; }

.rs-btn-ghost.rs-btn-md {
  padding: 7px 11px; }

.rs-btn-icon.rs-btn-md {
  padding: 10px 10px;
  line-height: 16px; }

.rs-btn-icon.rs-btn-md > .rs-icon {
  font-size: 16px; }

.rs-btn-icon-with-text.rs-btn-md {
  line-height: 20px; }

.rs-btn-icon-with-text.rs-btn-md > .rs-icon {
  padding: 10px 10px;
  width: 36px;
  height: 36px; }

.rs-btn-icon-with-text.rs-btn-md.rs-btn-icon-placement-left {
  padding: 8px 12px 8px 48px; }

.rs-btn-icon-with-text.rs-btn-md.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: none;
  border-right: none;
  border-right: var(--rs-btn-default-border, none); }

.rs-btn-icon-with-text.rs-btn-md.rs-btn-icon-placement-right {
  padding: 8px 48px 8px 12px; }

.rs-btn-icon-with-text.rs-btn-md.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: none;
  border-left: none;
  border-left: var(--rs-btn-default-border, none); }

.rs-btn-sm {
  font-size: 14px;
  line-height: 20px;
  padding: 5px 10px; }

.rs-btn-ghost.rs-btn-sm {
  padding: 4px 9px; }

.rs-btn-icon.rs-btn-sm {
  padding: 7px 7px;
  line-height: 16px; }

.rs-btn-icon.rs-btn-sm > .rs-icon {
  font-size: 16px; }

.rs-btn-icon-with-text.rs-btn-sm {
  line-height: 20px; }

.rs-btn-icon-with-text.rs-btn-sm > .rs-icon {
  padding: 7px 7px;
  width: 30px;
  height: 30px; }

.rs-btn-icon-with-text.rs-btn-sm.rs-btn-icon-placement-left {
  padding: 5px 10px 5px 40px; }

.rs-btn-icon-with-text.rs-btn-sm.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: none;
  border-right: none;
  border-right: var(--rs-btn-default-border, none); }

.rs-btn-icon-with-text.rs-btn-sm.rs-btn-icon-placement-right {
  padding: 5px 40px 5px 10px; }

.rs-btn-icon-with-text.rs-btn-sm.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: none;
  border-left: none;
  border-left: var(--rs-btn-default-border, none); }

.rs-btn-xs {
  font-size: 12px;
  line-height: 20px;
  padding: 2px 8px; }

.rs-btn-ghost.rs-btn-xs {
  padding: 1px 7px; }

.rs-btn-icon.rs-btn-xs {
  padding: 6px 6px;
  line-height: 12px; }

.rs-btn-icon.rs-btn-xs > .rs-icon {
  font-size: 12px; }

.rs-btn-icon-with-text.rs-btn-xs {
  line-height: 20px; }

.rs-btn-icon-with-text.rs-btn-xs > .rs-icon {
  padding: 6px 6px;
  width: 24px;
  height: 24px; }

.rs-btn-icon-with-text.rs-btn-xs.rs-btn-icon-placement-left {
  padding: 2px 8px 2px 32px; }

.rs-btn-icon-with-text.rs-btn-xs.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: none;
  border-right: none;
  border-right: var(--rs-btn-default-border, none); }

.rs-btn-icon-with-text.rs-btn-xs.rs-btn-icon-placement-right {
  padding: 2px 32px 2px 8px; }

.rs-btn-icon-with-text.rs-btn-xs.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: none;
  border-left: none;
  border-left: var(--rs-btn-default-border, none); }

.rs-btn-block {
  display: block;
  width: 100%; }

.rs-btn-block + .rs-btn-block {
  margin-top: 5px; }

.rs-btn-loading {
  color: transparent !important;
  position: relative;
  cursor: default;
  pointer-events: none; }

.rs-btn-loading > .rs-btn-spin::before,
.rs-btn-loading > .rs-btn-spin::after {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  z-index: 1; }

.rs-btn-xs.rs-btn-loading > .rs-btn-spin::before,
.rs-btn-xs.rs-btn-loading > .rs-btn-spin::after {
  width: 16px;
  height: 16px; }

.rs-btn-loading > .rs-btn-spin::before {
  border: 3px solid rgba(247, 247, 250, 0.8);
  border: 3px solid var(--rs-loader-ring); }

.rs-btn-primary.rs-btn-loading > .rs-btn-spin::before {
  border-color: rgba(248, 247, 250, 0.3); }

.rs-theme-high-contrast .rs-btn-primary.rs-btn-loading > .rs-btn-spin::before {
  border-color: rgba(247, 247, 250, 0.3);
  border-color: var(--rs-loader-ring-inverse); }

.rs-btn-loading > .rs-btn-spin::after {
  border-width: 3px;
  border-color: #a6a6a6 transparent transparent;
  border-color: var(--rs-loader-rotor) transparent transparent;
  border-style: solid;
  -webkit-animation: buttonSpin 0.6s infinite linear;
  animation: buttonSpin 0.6s infinite linear; }

.rs-btn-primary.rs-btn-loading > .rs-btn-spin::after {
  border-top-color: #fff; }

.rs-theme-high-contrast .rs-btn-primary.rs-btn-loading > .rs-btn-spin::after {
  border-top-color: #fff;
  border-top-color: var(--rs-loader-rotor-inverse); }

@-webkit-keyframes buttonSpin {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes buttonSpin {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.rs-btn-group {
  position: relative;
  display: inline-block;
  vertical-align: middle; }

.rs-btn-group > .rs-btn {
  position: relative; }

.rs-btn-group > .rs-btn:focus,
.rs-btn-group > .rs-btn:active {
  z-index: 2; }

.rs-btn-group:not(.rs-btn-group-vertical) > .rs-btn {
  float: left; }

.rs-btn-group:not(.rs-btn-group-vertical) > .rs-btn:not(:last-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0; }

.rs-btn-group:not(.rs-btn-group-vertical) > .rs-btn:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0; }

.rs-btn-group:not(.rs-btn-group-vertical) > .rs-btn-ghost + .rs-btn-ghost {
  margin-left: -1px; }

.rs-btn-group-vertical > .rs-btn {
  display: block;
  width: 100%;
  max-width: 100%; }

.rs-btn-group-vertical > .rs-btn:not(:last-child) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.rs-btn-group-vertical > .rs-btn:not(:first-child) {
  border-top-right-radius: 0;
  border-top-left-radius: 0; }

.rs-btn-group-vertical > .rs-btn-ghost + .rs-btn-ghost {
  margin-top: -1px; }

.rs-btn-group-justified {
  display: -ms-flexbox;
  display: flex;
  width: 100%; }

.rs-btn-group-justified > .rs-btn {
  -ms-flex: 1 1 1%;
  flex: 1 1 1%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.rs-btn-toolbar {
  line-height: 0; }

.rs-picker-subtle .picker-subtle-toggle {
  position: relative;
  z-index: 5;
  padding-right: 32px;
  display: inline-block; }

.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle {
  cursor: not-allowed; }

.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:hover,
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:focus,
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:active {
  background: none; }

.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:hover::after,
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:focus::after,
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:active::after {
  display: none; }

.rs-calendar {
  padding-top: 12px;
  position: relative;
  min-height: 266px;
  overflow: hidden; }

.rs-calendar-bordered .rs-calendar-table {
  border: 1px solid #e5e5ea;
  border: 1px solid var(--rs-border-primary);
  border-radius: 6px; }

.rs-calendar-bordered .rs-calendar-table-row:not(:last-child) .rs-calendar-table-cell,
.rs-calendar-bordered .rs-calendar-table-header-row .rs-calendar-table-header-cell {
  border-bottom: 1px solid #f2f2f5;
  border-bottom: 1px solid var(--rs-border-secondary); }

.rs-calendar-bordered .rs-calendar-month-dropdown {
  border: 1px solid #e5e5ea;
  border: 1px solid var(--rs-border-primary);
  border-radius: 6px; }

.rs-calendar-panel .rs-calendar-header {
  width: 100%;
  padding-bottom: 6px; }

.rs-calendar-panel .rs-calendar-header::before,
.rs-calendar-panel .rs-calendar-header::after {
  content: ' ';
  display: table; }

.rs-calendar-panel .rs-calendar-header::after {
  clear: both; }

.rs-calendar-panel .rs-calendar-header .rs-calendar-btn-today {
  float: right; }

.rs-calendar-panel .rs-calendar-header-forward,
.rs-calendar-panel .rs-calendar-header-backward {
  float: none !important; }

.rs-calendar-panel.rs-calendar-month-view .rs-calendar-header-forward,
.rs-calendar-panel.rs-calendar-month-view .rs-calendar-header-backward {
  display: inline-block;
  visibility: hidden;
  pointer-events: none; }

.rs-calendar-panel .rs-calendar-header-month-toolbar {
  width: auto !important; }

.rs-calendar-panel.rs-calendar-month-view .rs-calendar-header-month-toolbar {
  padding-left: 0;
  padding-right: 0; }

.rs-calendar-panel .rs-calendar-month-view {
  padding-left: 0;
  padding-right: 0; }

.rs-calendar-panel .rs-calendar-table-header-row .rs-calendar-table-header-cell-content {
  padding-top: 8px;
  padding-bottom: 8px; }

.rs-calendar-panel .rs-calendar-table-cell-is-today .rs-calendar-table-cell-day {
  color: #fff;
  color: var(--rs-calendar-today-text);
  width: 20px;
  height: 20px;
  background-color: #3498ff;
  background-color: var(--rs-calendar-today-bg);
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto; }

.rs-calendar-panel .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  -webkit-box-shadow: none;
  box-shadow: none; }

.rs-calendar-panel .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: inherit;
  color: inherit;
  -webkit-box-shadow: inset 0 0 0 1px #3498ff;
  -webkit-box-shadow: inset 0 0 0 1px var(--rs-input-focus-border);
  box-shadow: inset 0 0 0 1px #3498ff;
  box-shadow: inset 0 0 0 1px var(--rs-input-focus-border); }

.rs-calendar-panel .rs-calendar-table-cell-day {
  margin-top: 3px;
  display: block;
  text-align: center; }

.rs-calendar-panel .rs-calendar-month-dropdown {
  margin-top: -2px;
  margin-left: 12px;
  margin-right: 12px;
  top: 54px;
  width: calc(100% - 24px); }

.rs-calendar-panel:not(.rs-calendar-compact) .rs-calendar-month-dropdown-scroll {
  height: 644px; }

.rs-calendar-panel.rs-calendar-compact .rs-calendar-month-dropdown-scroll {
  height: 344px; }

.rs-calendar-panel:not(.rs-calendar-compact) .rs-calendar-table-row:not(.rs-calendar-table-header-row) .rs-calendar-table-cell-content {
  width: 100%;
  height: 100px;
  overflow: hidden; }

.rs-calendar-panel:not(.rs-calendar-compact) .rs-calendar-table-cell {
  vertical-align: top; }

.rs-calendar-panel .rs-calendar-table-cell {
  padding: 0 1px;
  line-height: 0; }

.rs-calendar-panel .rs-calendar-table-cell-content {
  width: 100%;
  border-radius: 0; }

.rs-calendar-panel .rs-calendar-table-row:last-child :first-child .rs-calendar-table-cell-content {
  border-bottom-left-radius: 6px; }

.rs-calendar-panel .rs-calendar-table-row:last-child :last-child .rs-calendar-table-cell-content {
  border-bottom-right-radius: 6px; }

.rs-calendar-panel.rs-calendar-compact .rs-calendar-table-row:not(.rs-calendar-table-header-row) .rs-calendar-table-cell-content {
  height: 50px; }

.rs-calendar-btn-close {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 14px;
  padding: 0;
  border: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  background: #fff;
  background: var(--rs-bg-overlay);
  z-index: 1; }

.rs-calendar-panel .rs-calendar-btn-close {
  bottom: 11px;
  margin: 0 20px; }

.rs-calendar-month-view .rs-calendar-header-month-toolbar {
  padding-left: 24px;
  padding-right: 24px; }

.rs-calendar-month-view .rs-calendar-header-backward,
.rs-calendar-month-view .rs-calendar-header-forward {
  display: none; }

.rs-calendar-month-view .rs-calendar-header-title-date {
  color: #1675e0;
  color: var(--rs-text-active);
  background: transparent; }

.rs-calendar-month-view .rs-calendar-header-title-date.rs-calendar-header-error {
  color: #f44336;
  color: var(--rs-text-error); }

.rs-calendar-month-view .rs-calendar-header-title-date.rs-calendar-header-error:focus,
.rs-calendar-month-view .rs-calendar-header-title-date.rs-calendar-header-error:hover:active {
  color: #fff !important; }

.rs-calendar-time-view .rs-calendar-header-title-time {
  color: #1675e0;
  color: var(--rs-text-active);
  background: transparent; }

.rs-calendar-time-view .rs-calendar-header-title-time.rs-calendar-header-error {
  color: #f44336;
  color: var(--rs-text-error); }

.rs-calendar-time-view .rs-calendar-header-title-time.rs-calendar-header-error:hover:active {
  color: #fff; }

.rs-calendar-table-cell-selected,
.rs-calendar-table-cell-in-range {
  position: relative; }

.rs-calendar-table-cell-selected::before,
.rs-calendar-table-cell-in-range::before {
  content: '';
  display: block;
  width: 100%;
  margin-top: 4px;
  height: 24px;
  position: absolute;
  z-index: 0;
  top: 0; }

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
.rs-calendar-table-cell-in-range .rs-calendar-table-cell-content {
  z-index: 1;
  position: relative; }

.rs-calendar-table-cell-in-range::before {
  background-color: rgba(204, 233, 255, 0.5);
  background-color: var(--rs-calendar-range-bg); }

.rs-calendar-table-cell-in-range:hover::before {
  display: none; }

.rs-calendar-month-dropdown-scroll div:focus {
  outline: none; }

.rs-calendar-header {
  width: 280px;
  padding-left: 12px;
  padding-right: 12px; }

.rs-calendar-header::before,
.rs-calendar-header::after {
  content: ' ';
  display: table; }

.rs-calendar-header::after {
  clear: both; }

.rs-calendar-header-month-toolbar,
.rs-calendar-header-time-toolbar {
  display: inline-block; }

.rs-calendar-header-month-toolbar {
  float: left; }

.rs-calendar-header-time-toolbar {
  float: right; }

.rs-calendar-header-title {
  margin: 0 4px; }

.rs-calendar-header-meridian {
  font-size: 12px;
  margin-left: 4px; }

.rs-calendar-header-error {
  color: #8e8e93;
  color: var(--rs-btn-subtle-text);
  background-color: transparent;
  border: none;
  --rs-btn-subtle-text: var(--rs-red-500);
  --rs-btn-subtle-hover-bg: var(--rs-red-500);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-red-600);
  --rs-btn-subtle-active-text: #fff; }

.rs-calendar-header-error:hover,
.rs-calendar-header-error:focus {
  color: #575757;
  color: var(--rs-btn-subtle-hover-text);
  background-color: #e5e5ea;
  background-color: var(--rs-btn-subtle-hover-bg); }

.rs-calendar-header-error:active,
.rs-calendar-header-error.rs-btn-active {
  color: #272c36;
  color: var(--rs-btn-subtle-active-text);
  background-color: #e5e5ea;
  background-color: var(--rs-btn-subtle-active-bg); }

.rs-calendar-header-error:disabled,
.rs-calendar-header-error.rs-btn-disabled {
  color: #c5c6c7;
  color: var(--rs-btn-subtle-disabled-text);
  background: none; }

.rs-theme-high-contrast .rs-calendar-header-error:disabled,
.rs-theme-high-contrast .rs-calendar-header-error.rs-btn-disabled {
  opacity: 0.5; }

.rs-theme-dark .rs-calendar-header-error {
  --rs-btn-subtle-hover-bg: var(--rs-red-600);
  --rs-btn-subtle-active-bg: var(--rs-red-400); }

.rs-calendar-header-btn-disabled,
.rs-calendar-header-btn-disabled:hover,
.rs-calendar-header-btn-disabled:hover:focus {
  cursor: not-allowed;
  opacity: 0.3;
  background: none; }

.rs-calendar-header-btn-disabled::after {
  display: none !important; }

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) {
  margin: 0 auto;
  padding-left: 12px;
  padding-right: 12px; }

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar {
  display: block;
  text-align: center;
  width: 100%; }

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar::before,
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar::after {
  content: ' ';
  display: table; }

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar::after {
  clear: both; }

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-backward {
  float: left; }

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-forward {
  float: right; }

.rs-calendar-header-has-time:not(.rs-calendar-header-has-month) {
  margin: 0 auto;
  text-align: center; }

.rs-calendar-header-has-time:not(.rs-calendar-header-has-month) .rs-calendar-header-time-toolbar {
  float: none;
  display: inline-block; }

.rs-calendar-header-has-month.rs-calendar-header-has-time {
  margin: 0 auto; }

/* rtl:begin:ignore */
/* stylelint-disable-next-line */
[dir='rtl'] .rs-calendar-header-backward .rs-icon,
[dir='rtl'] .rs-calendar-header-forward .rs-icon {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg); }

/* rtl:end:ignore */
.rs-calendar-body {
  padding: 4px 12px 12px; }

.rs-calendar-table {
  display: table;
  table-layout: fixed;
  width: 100%; }

.rs-calendar-table-row {
  display: table-row; }

.rs-calendar-table-row:nth-child(2) .rs-calendar-table-cell-week-number {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px; }

.rs-calendar-table-row:last-child .rs-calendar-table-cell-week-number {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px; }

.rs-calendar-table-cell,
.rs-calendar-table-header-cell {
  display: table-cell;
  width: 1%;
  padding: 1px 0;
  text-align: center;
  vertical-align: middle; }

.rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content,
.rs-calendar-table-cell-un-same-month .rs-calendar-table-header-cell-content,
.rs-calendar-table-header-cell-un-same-month .rs-calendar-table-cell-content,
.rs-calendar-table-header-cell-un-same-month .rs-calendar-table-header-cell-content,
.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content,
.rs-calendar-table-cell-disabled .rs-calendar-table-header-cell-content,
.rs-calendar-table-header-cell-disabled .rs-calendar-table-cell-content,
.rs-calendar-table-header-cell-disabled .rs-calendar-table-header-cell-content,
.rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell-un-same-month .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-header-cell-un-same-month .rs-calendar-table-cell-content:hover,
.rs-calendar-table-header-cell-un-same-month .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell-disabled .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-header-cell-disabled .rs-calendar-table-cell-content:hover,
.rs-calendar-table-header-cell-disabled .rs-calendar-table-header-cell-content:hover {
  color: #c5c6c7;
  color: var(--rs-text-disabled); }

.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content,
.rs-calendar-table-cell-disabled .rs-calendar-table-header-cell-content,
.rs-calendar-table-header-cell-disabled .rs-calendar-table-cell-content,
.rs-calendar-table-header-cell-disabled .rs-calendar-table-header-cell-content,
.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell-disabled .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-header-cell-disabled .rs-calendar-table-cell-content:hover,
.rs-calendar-table-header-cell-disabled .rs-calendar-table-header-cell-content:hover {
  background: none;
  text-decoration: line-through;
  cursor: not-allowed; }

.rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content:hover,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content:hover,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content:hover {
  color: #fff;
  color: var(--rs-calendar-date-selected-text);
  background-color: #3498ff;
  background-color: var(--rs-bg-active);
  -webkit-box-shadow: none;
  box-shadow: none; }

.rs-theme-high-contrast .rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content:hover {
  text-decoration: underline; }

.rs-calendar-table-cell-disabled.rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
.rs-calendar-table-cell-disabled.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content,
.rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content,
.rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content {
  opacity: 0.3; }

.rs-calendar-table-cell-content {
  display: inline-block;
  font-size: 14px;
  line-height: 1.42857143;
  padding: 5px;
  cursor: pointer;
  border-radius: 6px; }

.rs-calendar-table-cell-content:hover {
  background-color: #f2faff;
  background-color: var(--rs-state-hover-bg); }

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  -webkit-box-shadow: inset 0 0 0 1px #3498ff;
  -webkit-box-shadow: inset 0 0 0 1px var(--rs-input-focus-border);
  box-shadow: inset 0 0 0 1px #3498ff;
  box-shadow: inset 0 0 0 1px var(--rs-input-focus-border); }

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  color: #fff;
  color: var(--rs-calendar-date-selected-text);
  background-color: #3498ff;
  background-color: var(--rs-bg-active);
  -webkit-box-shadow: none;
  box-shadow: none; }

.rs-theme-high-contrast .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  text-decoration: underline; }

.rs-calendar-table-header-cell-content {
  display: inline-block;
  color: #8e8e93;
  color: var(--rs-text-secondary);
  font-size: 12px;
  line-height: 1.66666667;
  padding-top: 2px;
  padding-bottom: 2px; }

.rs-calendar-table-header-cell-content,
.rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-header-cell-content:focus,
.rs-calendar-table-header-cell-content:hover:focus {
  background: none;
  cursor: auto; }

.rs-calendar-table-cell-week-number {
  display: table-cell;
  min-width: 30px;
  padding: 1px;
  text-align: center;
  vertical-align: middle;
  color: #8e8e93;
  color: var(--rs-text-secondary);
  background-color: #f7f7fa;
  background-color: var(--rs-bg-well);
  font-size: 12px; }

.rs-calendar-month-dropdown {
  display: none;
  position: absolute;
  top: 44px;
  border-top: 1px solid #e5e5ea;
  border-top: 1px solid var(--rs-border-primary);
  margin-top: 0;
  width: 100%;
  background-color: #fff;
  background-color: var(--rs-bg-overlay);
  overflow: hidden;
  z-index: 1; }

.rs-calendar-month-view .rs-calendar-month-dropdown {
  display: block; }

.rs-calendar-month-view .rs-calendar-month-dropdown-content {
  -webkit-animation: 0.3s linear slideDown;
  animation: 0.3s linear slideDown; }

.rs-calendar-month-dropdown-scroll {
  height: 230px; }

.rs-calendar-month-dropdown-row {
  position: relative;
  padding-left: 50px;
  padding-right: 12px;
  padding-top: 5px;
  padding-bottom: 5px; }

.rs-calendar-month-dropdown-row:not(:last-child) {
  border-bottom: 1px dashed #e5e5ea;
  border-bottom: 1px dashed var(--rs-border-primary); }

.rs-calendar-month-dropdown-year {
  position: absolute;
  top: calc(50% - 0.5em);
  left: 12px; }

.rs-calendar-month-dropdown-year-active {
  color: #1675e0;
  color: var(--rs-text-active); }

.rs-calendar-month-dropdown-list {
  display: block;
  min-width: 200px; }

.rs-calendar-month-dropdown-list::before,
.rs-calendar-month-dropdown-list::after {
  content: ' ';
  display: table; }

.rs-calendar-month-dropdown-list::after {
  clear: both; }

.rs-calendar-month-dropdown-cell {
  display: inline-block;
  float: left;
  margin: 1px;
  text-align: center;
  vertical-align: middle; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-calendar-month-dropdown-cell {
    width: calc((100% - 1px * 12 - 1px) / 6); } }

@supports (-ms-ime-align: auto) {
  .rs-calendar-month-dropdown-cell {
    width: calc((100% - 1px * 12 - 1px) / 6); } }

.rs-calendar-month-dropdown-cell-content {
  display: inline-block;
  font-size: 14px;
  line-height: 1.42857143;
  padding: 5px;
  cursor: pointer;
  border-radius: 6px; }

.rs-calendar-month-dropdown-cell-content:hover {
  background-color: #f2faff;
  background-color: var(--rs-state-hover-bg); }

.rs-calendar-month-dropdown-cell:not(.rs-calendar-month-dropdown-cell-active).disabled .rs-calendar-month-dropdown-cell-content {
  color: #c5c6c7;
  color: var(--rs-text-disabled);
  background: none;
  text-decoration: line-through;
  cursor: not-allowed; }

.rs-calendar-month-dropdown-cell-active.disabled .rs-calendar-month-dropdown-cell-content {
  opacity: 0.3;
  cursor: not-allowed; }

.rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content {
  color: #fff;
  color: var(--rs-calendar-date-selected-text);
  background-color: #3498ff;
  background-color: var(--rs-bg-active);
  -webkit-box-shadow: none;
  box-shadow: none; }

.rs-theme-high-contrast .rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content {
  text-decoration: underline; }

.rs-calendar-time-dropdown {
  display: none;
  position: absolute;
  top: 44px;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
  background-color: #fff;
  background-color: var(--rs-bg-overlay);
  color: #575757;
  color: var(--rs-text-primary);
  overflow: hidden;
  z-index: 1; }

.rs-calendar-time-view .rs-calendar-time-dropdown {
  display: block; }

.rs-calendar-time-view .rs-calendar-time-dropdown-content {
  -webkit-animation: 0.3s linear slideDown;
  animation: 0.3s linear slideDown; }

.rs-calendar-time-dropdown-row {
  display: table;
  width: 100%; }

.rs-calendar-time-dropdown-column {
  display: table-cell;
  width: 1%; }

.rs-calendar-time-dropdown-column > ul,
.rs-calendar-time-dropdown-column > ul > li {
  list-style: none;
  margin: 0;
  padding: 0; }

.rs-calendar-time-dropdown-column > ul {
  height: 230px;
  overflow-y: auto;
  padding-bottom: 200px; }

.rs-calendar-time-dropdown-column-title {
  width: 100%;
  background-color: #f7f7fa;
  background-color: var(--rs-calendar-time-unit-bg);
  color: #8e8e93;
  color: var(--rs-text-secondary);
  text-align: center;
  font-size: 12px;
  line-height: 1.66666667;
  padding-top: 2px;
  padding-bottom: 2px; }

.rs-calendar-time-dropdown-cell {
  font-size: 14px;
  line-height: 20px;
  color: inherit;
  padding: 5px;
  display: block;
  text-align: center;
  cursor: pointer; }

.rs-calendar-time-dropdown-cell,
.rs-calendar-time-dropdown-cell:hover,
.rs-calendar-time-dropdown-cell:focus {
  text-decoration: none; }

.rs-calendar-time-dropdown-cell:hover {
  background-color: rgba(204, 233, 255, 0.5);
  background-color: var(--rs-listbox-option-hover-bg);
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text); }

.rs-theme-high-contrast .rs-calendar-time-dropdown-cell:hover {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  outline-width: 2px;
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text);
  text-decoration: underline; }

.rs-theme-high-contrast .rs-theme-high-contrast .rs-calendar-time-dropdown-cell:hover {
  outline-offset: 2px; }

.rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active {
  background-color: #3498ff;
  background-color: var(--rs-bg-active);
  color: #fff;
  color: var(--rs-calendar-date-selected-text); }

.rs-theme-high-contrast .rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active:hover {
  background-color: rgba(204, 233, 255, 0.5);
  background-color: var(--rs-listbox-option-hover-bg);
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text); }

.rs-theme-high-contrast .rs-theme-high-contrast .rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active:hover {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  outline-width: 2px;
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text);
  text-decoration: underline; }

.rs-theme-high-contrast .rs-theme-high-contrast .rs-theme-high-contrast .rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active:hover {
  outline-offset: 2px; }

.rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-disabled {
  color: #c5c6c7;
  color: var(--rs-text-disabled);
  background: none;
  cursor: not-allowed;
  text-decoration: line-through; }

.rs-calendar-time-dropdown-cell-active.rs-calendar-time-dropdown-cell-disabled.rs-calendar-time-dropdown-cell {
  opacity: 0.3;
  cursor: not-allowed; }

@-webkit-keyframes slideDown {
  from {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%); }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes slideDown {
  from {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%); }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

.rs-carousel {
  position: relative;
  height: 400px;
  overflow: hidden;
  background-color: #8e8e93;
  background-color: var(--rs-carousel-bg); }

.rs-carousel-content {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden; }

.rs-carousel-slider {
  position: relative;
  left: 0;
  height: 100%;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  will-change: transform; }

.rs-carousel-slider-item {
  background-color: #8e8e93;
  background-color: var(--rs-carousel-bg);
  float: left;
  height: 100%;
  width: 100%; }

.rs-carousel-slider-after {
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #8e8e93;
  background-color: var(--rs-carousel-bg);
  top: 0;
  -webkit-animation: moveLeftHalf 0.3s ease forwards;
  animation: moveLeftHalf 0.3s ease forwards; }

.rs-carousel-slider-after-vertical {
  -webkit-animation: moveLeftHalf-vertical 0.3s ease forwards;
  animation: moveLeftHalf-vertical 0.3s ease forwards; }

.rs-carousel-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute; }

.rs-carousel-toolbar > ul {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0; }

.rs-carousel-label-wrapper {
  margin: 3px; }

.rs-carousel-label-wrapper input {
  width: 0;
  height: 0;
  position: absolute;
  opacity: 0; }

.rs-carousel-label-wrapper input:checked ~ label {
  background-color: #3498ff;
  background-color: var(--rs-carousel-indicator-active); }

.rs-carousel-label {
  cursor: pointer;
  display: block;
  background-color: rgba(255, 255, 255, 0.4);
  background-color: var(--rs-carousel-indicator);
  -webkit-transition: background 0.3s linear;
  transition: background 0.3s linear;
  -webkit-transition-property: background, width, height;
  transition-property: background, width, height;
  position: relative; }

.rs-carousel-label::after {
  content: '';
  position: absolute;
  top: -3px;
  right: -3px;
  bottom: -3px;
  left: -3px; }

.rs-carousel-label:hover {
  background-color: #fff;
  background-color: var(--rs-carousel-indicator-hover); }

.rs-carousel-shape-dot .rs-carousel-label {
  width: 10px;
  height: 10px;
  border-radius: 50%; }

.rs-carousel-shape-bar .rs-carousel-label {
  border-radius: 2px; }

.rs-carousel-placement-top.rs-carousel-shape-bar .rs-carousel-label,
.rs-carousel-placement-bottom.rs-carousel-shape-bar .rs-carousel-label {
  width: 18px;
  height: 4px; }

.rs-carousel-placement-top.rs-carousel-shape-bar .rs-carousel-label-wrapper input:checked ~ label,
.rs-carousel-placement-bottom.rs-carousel-shape-bar .rs-carousel-label-wrapper input:checked ~ label {
  width: 28px; }

.rs-carousel-placement-left.rs-carousel-shape-bar .rs-carousel-label,
.rs-carousel-placement-right.rs-carousel-shape-bar .rs-carousel-label {
  width: 4px;
  height: 18px; }

.rs-carousel-placement-left.rs-carousel-shape-bar .rs-carousel-label-wrapper input:checked ~ label,
.rs-carousel-placement-right.rs-carousel-shape-bar .rs-carousel-label-wrapper input:checked ~ label {
  height: 28px; }

.rs-carousel-placement-top .rs-carousel-toolbar,
.rs-carousel-placement-bottom .rs-carousel-toolbar {
  left: 0;
  width: 100%; }

.rs-carousel-placement-top .rs-carousel-toolbar > ul,
.rs-carousel-placement-bottom .rs-carousel-toolbar > ul {
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center; }

.rs-carousel-placement-top .rs-carousel-toolbar {
  top: 17px; }

.rs-carousel-placement-bottom .rs-carousel-toolbar {
  bottom: 17px; }

.rs-carousel-placement-left .rs-carousel-toolbar,
.rs-carousel-placement-right .rs-carousel-toolbar {
  top: 0;
  width: 1.2vw;
  height: 100%; }

.rs-carousel-placement-left .rs-carousel-toolbar > ul,
.rs-carousel-placement-right .rs-carousel-toolbar > ul {
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-direction: column;
  flex-direction: column; }

.rs-carousel-placement-left .rs-carousel-toolbar {
  left: 17px; }

.rs-carousel-placement-right .rs-carousel-toolbar {
  right: 17px; }

@-webkit-keyframes moveLeftHalf {
  0% {
    -webkit-transform: none;
    transform: none; }
  100% {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%); } }

@keyframes moveLeftHalf {
  0% {
    -webkit-transform: none;
    transform: none; }
  100% {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%); } }

@-webkit-keyframes moveLeftHalf-vertical {
  0% {
    -webkit-transform: none;
    transform: none; }
  100% {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%); } }

@keyframes moveLeftHalf-vertical {
  0% {
    -webkit-transform: none;
    transform: none; }
  100% {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%); } }

.rs-picker-cascader-menu-items > div::before,
.rs-picker-cascader-menu-items > div::after {
  content: ' ';
  display: table; }

.rs-picker-cascader-menu-items > div::after {
  clear: both; }

.rs-picker-cascader-search-panel {
  max-height: 300px;
  overflow: auto; }

.rs-picker-cascader-row {
  display: block;
  padding: 8px 12px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #575757;
  color: var(--rs-text-primary);
  cursor: pointer;
  text-decoration: none;
  width: 100%; }

.rs-picker-cascader-row:hover {
  background-color: rgba(204, 233, 255, 0.5);
  background-color: var(--rs-listbox-option-hover-bg);
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text); }

.rs-theme-high-contrast .rs-picker-cascader-row:hover {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  outline-width: 2px;
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text);
  text-decoration: underline; }

.rs-theme-high-contrast .rs-theme-high-contrast .rs-picker-cascader-row:hover {
  outline-offset: 2px; }

.rs-picker-cascader-row.rs-picker-cascader-row-focus {
  background-color: rgba(204, 233, 255, 0.5);
  background-color: var(--rs-listbox-option-hover-bg);
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text); }

.rs-theme-high-contrast .rs-picker-cascader-row.rs-picker-cascader-row-focus {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  outline-width: 2px;
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text);
  text-decoration: underline; }

.rs-theme-high-contrast .rs-theme-high-contrast .rs-picker-cascader-row.rs-picker-cascader-row-focus {
  outline-offset: 2px; }

.rs-picker-cascader-row:disabled,
.rs-picker-cascader-row.rs-picker-cascader-row-disabled {
  color: #c5c6c7;
  color: var(--rs-listbox-option-disabled-text);
  background: none;
  cursor: not-allowed; }

.rs-picker-cascader-row:disabled .rs-picker-cascader-search-match,
.rs-picker-cascader-row.rs-picker-cascader-row-disabled .rs-picker-cascader-search-match {
  opacity: 0.7;
  font-weight: normal; }

.rs-picker-cascader-col {
  display: inline-block; }

.rs-picker-cascader-col::after {
  content: '\A0/\A0'; }

.rs-picker-cascader-col:last-child::after {
  display: none; }

.rs-picker-cascader-search-match {
  color: #3498ff;
  color: var(--rs-primary-500);
  font-weight: bold; }

.rs-picker-cascader-menu-column {
  float: left;
  overflow-y: auto; }

.rs-picker-cascader-menu-column-loading {
  padding: 4px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center; }

.rs-picker-cascader-menu-column-loading .rs-icon {
  margin: 4px; }

.rs-picker-cascader-menu-items {
  padding: 6px 0; }

.rs-picker-cascader-menu-items ul,
.rs-picker-cascader-menu-items li {
  margin: 0;
  padding: 0;
  list-style: none; }

.rs-picker-cascader-menu-item {
  display: block;
  padding: 8px 12px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #575757;
  color: var(--rs-text-primary);
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  position: relative;
  padding: 8px 28px 8px 12px;
  word-break: break-word; }

.rs-picker-cascader-menu-items-has-children .rs-picker-cascader-menu-item {
  padding-right: 32px; }

.rs-picker-cascader-menu-item:hover,
.rs-picker-cascader-menu-item:focus,
.rs-picker-cascader-menu-item.rs-picker-select-menu-item-focus,
.rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-focus {
  background-color: rgba(204, 233, 255, 0.5);
  background-color: var(--rs-listbox-option-hover-bg);
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text); }

.rs-theme-high-contrast .rs-picker-cascader-menu-item:hover,
.rs-theme-high-contrast .rs-picker-cascader-menu-item:focus,
.rs-theme-high-contrast .rs-picker-cascader-menu-item.rs-picker-select-menu-item-focus,
.rs-theme-high-contrast .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-focus {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  outline-width: 2px;
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text);
  text-decoration: underline; }

.rs-theme-high-contrast .rs-theme-high-contrast .rs-picker-cascader-menu-item:hover,
.rs-theme-high-contrast .rs-theme-high-contrast .rs-picker-cascader-menu-item:focus,
.rs-theme-high-contrast .rs-theme-high-contrast .rs-picker-cascader-menu-item.rs-picker-select-menu-item-focus,
.rs-theme-high-contrast .rs-theme-high-contrast .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-focus {
  outline-offset: 2px; }

.rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-active {
  background-color: #f2faff;
  background-color: var(--rs-listbox-option-selected-bg);
  font-weight: bold;
  color: #1675e0;
  color: var(--rs-listbox-option-selected-text); }

.rs-theme-high-contrast .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-active {
  text-decoration: underline; }

.rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-active .rs-picker-cascader-menu-caret {
  color: #575757;
  color: var(--rs-text-primary); }

.rs-picker-cascader-menu-item:disabled,
.rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-disabled {
  color: #c5c6c7;
  color: var(--rs-listbox-option-disabled-text);
  background: none;
  cursor: not-allowed; }

.rs-picker-cascader-menu-caret {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 2px;
  position: absolute;
  top: 8px;
  right: 12px;
  height: 20px;
  font-size: 12px; }

.rs-checkbox {
  position: relative;
  display: inline-block;
  vertical-align: middle; }

.rs-checkbox-disabled label {
  cursor: not-allowed; }

.rs-checkbox label {
  line-height: 1.14285714;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer; }

.rs-checkbox-inner::before {
  border-color: #3498ff;
  border-color: var(--rs-checkbox-checked-bg); }

.rs-checkbox.rs-checkbox-disabled label {
  cursor: not-allowed; }

.rs-checkbox-disabled > .rs-checkbox-checker > label {
  color: #c5c6c7;
  color: var(--rs-text-disabled); }

.rs-checkbox-inline {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: normal;
  margin-top: 0;
  margin-right: 10px; }

.rs-plaintext .rs-checkbox-inline:first-child {
  margin-left: 0; }

.rs-checkbox [type='checkbox']:focus-visible ~ .rs-checkbox-inner::before {
  -webkit-box-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
  -webkit-box-shadow: var(--rs-state-focus-shadow);
  box-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
  box-shadow: var(--rs-state-focus-shadow); }

.rs-checkbox-checker {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 36px;
  min-height: 36px;
  line-height: 1;
  position: relative; }

/* rtl:begin:ignore */
.rs-checkbox-wrapper::before,
.rs-checkbox-wrapper::after,
.rs-checkbox-wrapper .rs-checkbox-inner::before,
.rs-checkbox-wrapper .rs-checkbox-inner::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  display: block; }

.rs-checkbox-wrapper .rs-checkbox-inner::after {
  opacity: 0;
  -webkit-transform: rotate(45deg) scale(0);
  transform: rotate(45deg) scale(0);
  -webkit-transition: opacity 0.2s ease-in, -webkit-transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  transition: opacity 0.2s ease-in, -webkit-transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  transition: opacity 0.2s ease-in, transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  transition: opacity 0.2s ease-in, transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46), -webkit-transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46); }

.rs-theme-high-contrast .rs-checkbox-wrapper .rs-checkbox-inner::after {
  -webkit-transition: none;
  transition: none; }

.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::after,
.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::after {
  border: solid #fff;
  border: solid var(--rs-checkbox-icon);
  width: 6px;
  height: 9px;
  margin-top: 2px;
  margin-left: 5px;
  opacity: 1; }

.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::after {
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1); }

.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::after {
  border-width: 0 0 2px;
  -webkit-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  width: 10px;
  margin-top: 0;
  margin-left: 3px; }

/* rtl:end:ignore */
/* stylelint-disable-next-line */
.rs-checkbox-wrapper {
  position: absolute;
  width: 16px;
  height: 16px;
  display: inline-block;
  left: 10px;
  top: 10px; }

.rs-checkbox-wrapper [type='checkbox'] {
  width: 0;
  height: 0;
  opacity: 0; }

.rs-checkbox-wrapper::before,
.rs-checkbox-wrapper .rs-checkbox-inner::before,
.rs-checkbox-wrapper .rs-checkbox-inner::after {
  width: 16px;
  height: 16px; }

.rs-checkbox-wrapper::before {
  border: 1px solid #3498ff;
  border: 1px solid var(--rs-checkbox-checked-bg);
  background-color: transparent;
  border-radius: 3px;
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 0.7;
  visibility: hidden;
  -webkit-transition: opacity 0.2s linear, -webkit-transform 0.2s linear;
  transition: opacity 0.2s linear, -webkit-transform 0.2s linear;
  transition: transform 0.2s linear, opacity 0.2s linear;
  transition: transform 0.2s linear, opacity 0.2s linear, -webkit-transform 0.2s linear; }

.rs-theme-high-contrast .rs-checkbox-wrapper::before {
  -webkit-transition: none;
  transition: none; }

.rs-checkbox-checked .rs-checkbox-wrapper::before {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  opacity: 0;
  visibility: visible; }

.rs-checkbox-wrapper::after {
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px; }

.rs-checkbox-wrapper .rs-checkbox-inner::before {
  border: 1px solid #d9d9d9;
  border: 1px solid var(--rs-checkbox-border);
  background-color: transparent;
  border-radius: 3px;
  -webkit-transition: background 0.2s linear, border 0.2s linear, -webkit-box-shadow 0.2s linear;
  transition: background 0.2s linear, border 0.2s linear, -webkit-box-shadow 0.2s linear;
  transition: background 0.2s linear, border 0.2s linear, box-shadow 0.2s linear;
  transition: background 0.2s linear, border 0.2s linear, box-shadow 0.2s linear, -webkit-box-shadow 0.2s linear; }

.rs-theme-high-contrast .rs-checkbox-wrapper .rs-checkbox-inner::before {
  -webkit-transition: none;
  transition: none; }

label:hover .rs-checkbox-wrapper .rs-checkbox-inner::before {
  border-color: #3498ff;
  border-color: var(--rs-checkbox-checked-bg); }

.rs-checkbox-disabled:not(.rs-checkbox-checked):not(.rs-checkbox-indeterminate) .rs-checkbox-wrapper .rs-checkbox-inner::before {
  border-color: #f7f7fa;
  border-color: var(--rs-checkbox-disabled-bg);
  background-color: #f7f7fa;
  background-color: var(--rs-checkbox-disabled-bg); }

.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before,
.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before {
  border-color: #3498ff;
  border-color: var(--rs-checkbox-checked-bg);
  background-color: #3498ff;
  background-color: var(--rs-checkbox-checked-bg); }

.rs-checkbox-disabled.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before,
.rs-checkbox-disabled.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before {
  opacity: 0.3; }

.rs-theme-high-contrast .rs-checkbox-disabled.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before,
.rs-theme-high-contrast .rs-checkbox-disabled.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before {
  opacity: 0.5; }

.rs-checkbox-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }

.rs-checkbox-group > .rs-checkbox {
  margin-left: -10px; }

.rs-checkbox-group-inline {
  -ms-flex-direction: row;
  flex-direction: row; }

.rs-picker-check-menu-items {
  margin-bottom: 6px;
  overflow-y: auto; }

.rs-picker-check-menu-items ul {
  list-style: none;
  margin: 0;
  padding: 0; }

.rs-picker-check-menu-items .rs-picker-menu-group:not(:first-child) {
  margin-top: 6px;
  border-top: 1px solid #e5e5ea;
  border-top: 1px solid var(--rs-border-primary);
  padding-top: 6px; }

.rs-picker-check-menu-items .rs-picker-menu-group-title {
  padding: 8px 12px;
  padding-right: 32px;
  position: relative;
  cursor: pointer;
  color: #272c36;
  color: var(--rs-text-heading); }

.rs-picker-check-menu-items .rs-picker-menu-group-title .rs-picker-menu-group-caret {
  display: inline-block;
  margin-left: 2px;
  position: absolute;
  top: 8px;
  right: 12px;
  padding: 3px;
  color: #8e8e93;
  color: var(--rs-text-secondary); }

.rs-picker-check-menu-items .rs-picker-menu-group.folded .rs-picker-menu-group-caret {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg); }

.rs-picker-check-menu-items .rs-check-item.rs-checkbox-checked .rs-checkbox-checker > label {
  font-weight: bold; }

.rs-picker-check-menu-items.rs-picker-check-menu-grouped .rs-check-item .rs-checkbox-checker > label {
  padding-left: 52px; }

.rs-picker-check-menu-items.rs-picker-check-menu-grouped .rs-check-item .rs-checkbox-checker .rs-checkbox-wrapper {
  left: 26px; }

.rs-check-tree {
  max-height: 360px;
  height: 100%;
  overflow-y: auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto; }

.rs-check-tree.rs-check-tree-virtualized {
  overflow: hidden; }

.rs-check-tree .rs-check-item .rs-checkbox-checker > label {
  text-align: left;
  position: relative;
  margin: 0;
  padding: 8px 12px;
  padding-left: 50px; }

.rs-check-tree .rs-check-item .rs-checkbox-checker > label::before {
  content: '';
  position: absolute;
  width: 46px;
  height: 100%;
  top: 0;
  margin-left: -52px; }

.rs-check-tree .rs-check-item .rs-checkbox-checker .rs-checkbox-wrapper {
  left: 20px; }

.rs-check-tree-without-children .rs-check-item .rs-checkbox-checker > label {
  padding-left: 32px; }

.rs-check-tree-without-children .rs-check-item .rs-checkbox-checker > label::before {
  width: 28px;
  margin-left: -34px; }

.rs-check-tree-without-children .rs-check-item .rs-checkbox-checker .rs-checkbox-wrapper {
  left: 0; }

.rs-check-tree-node {
  position: relative;
  font-size: 14px;
  line-height: 22px; }

.rs-check-tree-node .rs-check-item {
  display: inline-block; }

.rs-picker-menu .rs-check-tree-node .rs-check-item {
  display: block; }

.rs-check-tree-node:focus .rs-check-item .rs-checkbox-checker > label {
  color: #575757;
  color: var(--rs-text-primary);
  background-color: #f2faff;
  background-color: var(--rs-state-hover-bg); }

.rs-check-tree-node .rs-check-item.rs-checkbox-checked .rs-checkbox-checker > label {
  font-weight: bold; }

.rs-check-tree-node-all-uncheckable .rs-check-item .rs-checkbox-checker > label {
  padding-left: 22px; }

.rs-check-tree-node-all-uncheckable .rs-check-item .rs-checkbox-checker > label::before {
  width: 14px;
  margin-left: 0;
  left: 0; }

.rs-check-tree-node-expand-icon-wrapper {
  display: inline-block;
  position: absolute;
  cursor: pointer;
  z-index: 1; }

.rs-check-tree-node-expand-icon-wrapper > .rs-check-tree-node-expand-icon {
  display: inline-block;
  padding: 8px 0;
  padding-right: 8px;
  height: 36px;
  font-size: 16px;
  line-height: 22px;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: 1.5px 14px;
  transform-origin: 1.5px 14px;
  margin-top: 6px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.rs-check-tree-open > .rs-check-tree-node .rs-check-tree-node-expand-icon-wrapper > .rs-check-tree-node-expand-icon {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  margin-left: 0;
  margin-top: 0; }

.rs-check-tree-node-expand-icon-wrapper > .rs-check-tree-node-expanded {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  margin-left: 0;
  margin-top: 0; }

.rs-check-tree-node-expand-icon-wrapper > .rs-check-tree-node-custom-icon,
.rs-check-tree-node-expand-icon-wrapper > .rs-check-tree-node-loading-icon {
  width: 14px;
  height: 36px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px; }

.rs-check-tree-node-children > .rs-check-tree-children {
  position: relative;
  display: none; }

.rs-check-tree-open.rs-check-tree-node-children > .rs-check-tree-children {
  display: block; }

.rs-check-tree-indent-line {
  height: 100%;
  width: 1px;
  position: absolute;
  top: 0;
  margin-left: -13px;
  border-left: 1px solid rgba(0, 0, 0, 0.1); }

.rs-picker-menu {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }

.rs-picker-menu.rs-check-tree-menu {
  padding-top: 12px; }

.rs-picker-menu.rs-check-tree-menu .rs-picker-search-bar {
  padding-top: 0; }

.rs-picker-menu .rs-check-tree {
  padding: 0 12px 12px 0; }

.rs-picker-menu .rs-check-tree-node > .rs-check-tree-node-label .rs-check-tree-node-text-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  display: inline-block;
  vertical-align: top; }

/* rtl:begin:ignore */
[dir='rtl'] .rs-check-tree-node-expand-icon-wrapper {
  right: 0;
  padding-right: inherit; }

/* rtl:end:ignore */
.rs-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: auto;
  flex: auto; }

.rs-container-has-sidebar {
  -ms-flex-direction: row;
  flex-direction: row; }

.rs-content {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto; }

.rs-form-control-label {
  margin-bottom: 4px; }

.rs-form-plaintext .rs-form-control-label {
  color: #8e8e93;
  color: var(--rs-text-secondary); }

.rs-picker-date.rs-picker-default .rs-picker-toggle-caret,
.rs-picker-daterange.rs-picker-default .rs-picker-toggle-caret,
.rs-picker-date.rs-picker-default .rs-picker-toggle-clean,
.rs-picker-daterange.rs-picker-default .rs-picker-toggle-clean {
  top: 7px; }

.rs-picker-toolbar {
  padding: 12px;
  border-top: 1px solid #e5e5ea;
  border-top: 1px solid var(--rs-border-primary); }

.rs-picker-date-inline {
  height: 299px; }

.rs-picker-date-inline .rs-calendar {
  height: 286px; }

.rs-picker-date-predefined {
  height: 325px;
  border-right: 1px solid #e5e5ea;
  border-right: 1px solid var(--rs-border-primary);
  padding: 4px 0; }

.rs-picker-date-predefined .rs-btn {
  display: block; }

.rs-picker-menu .rs-picker-toolbar {
  max-width: 100%; }

.rs-picker-menu .rs-picker-toolbar-ranges {
  max-width: 400px; }

.rs-picker-menu .rs-calendar {
  min-width: 264px;
  display: block;
  margin: 0 auto; }

.rs-picker-menu .rs-calendar-show-week-numbers {
  min-width: 278px; }

.rs-picker-menu .rs-calendar-show-week-numbers .rs-calendar-body {
  padding-left: 12px;
  padding-right: 12px; }

.rs-picker-menu .rs-calendar-header {
  width: 100%; }

.rs-picker-menu .rs-calendar-body {
  padding-left: 15px;
  padding-right: 15px; }

.rs-picker-menu .rs-calendar-table {
  width: unset; }

.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content,
.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
  width: 30px;
  height: 30px; }

.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content:hover {
  background-color: rgba(204, 233, 255, 0.5);
  background-color: var(--rs-listbox-option-hover-bg);
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text); }

.rs-theme-high-contrast .rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content:hover {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  outline-width: 2px;
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text);
  text-decoration: underline; }

.rs-theme-high-contrast .rs-theme-high-contrast .rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content:hover {
  outline-offset: 2px; }

.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content:hover {
  background-color: #1675e0;
  background-color: var(--rs-calendar-cell-selected-hover-bg);
  color: #fff;
  color: var(--rs-calendar-date-selected-text); }

.rs-theme-high-contrast .rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content:hover {
  color: #fff;
  color: var(--rs-calendar-date-selected-text); }

.rs-picker-menu .rs-calendar .rs-calendar-table-header-row .rs-calendar-table-cell-content {
  height: 24px;
  padding-top: 0; }

.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
  padding-left: 0;
  padding-right: 0;
  display: inline-block; }

.rs-picker-menu .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
  background-color: rgba(204, 233, 255, 0.5);
  background-color: var(--rs-listbox-option-hover-bg);
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text); }

.rs-theme-high-contrast .rs-picker-menu .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  outline-width: 2px;
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text);
  text-decoration: underline; }

.rs-theme-high-contrast .rs-theme-high-contrast .rs-picker-menu .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
  outline-offset: 2px; }

.rs-picker-menu .rs-calendar .rs-calendar-table-cell-selected:hover .rs-calendar-table-cell-content {
  background-color: #1675e0;
  background-color: var(--rs-calendar-cell-selected-hover-bg);
  color: #fff;
  color: var(--rs-calendar-date-selected-text); }

.rs-theme-high-contrast .rs-picker-menu .rs-calendar .rs-calendar-table-cell-selected:hover .rs-calendar-table-cell-content {
  color: #fff;
  color: var(--rs-calendar-date-selected-text); }

.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-scroll {
  height: 227px; }

.rs-picker-menu .rs-calendar .rs-calendar-time-dropdown-column > ul {
  height: 214px;
  padding-bottom: 184px; }

.rs-picker-daterange-menu .rs-calendar {
  display: inline-block;
  height: 274px;
  padding-bottom: 12px; }

.rs-picker-daterange-menu .rs-calendar:first-child {
  border-right: 1px solid #e5e5ea;
  border-right: 1px solid var(--rs-border-primary); }

.rs-picker-daterange-menu .rs-calendar-header {
  width: 100%;
  text-align: center; }

.rs-picker-daterange-menu .rs-calendar-header-month-toolbar {
  float: none; }

.rs-picker-daterange-menu .rs-calendar-month-dropdown {
  z-index: 1; }

.rs-picker-daterange-menu .rs-calendar-month-dropdown-list {
  width: 185px; }

.rs-picker-daterange-menu .rs-picker-daterange-panel-show-one-calendar .rs-picker-toolbar {
  max-width: 255px; }

.rs-picker-daterange-menu .rs-picker-daterange-panel-show-one-calendar .rs-picker-toolbar-ranges {
  width: 190px; }

.rs-picker-daterange-menu .rs-picker-daterange-calendar-single .rs-calendar {
  border: 0;
  display: block;
  margin: auto; }

.rs-picker-daterange-header {
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-bottom: 1px solid #e5e5ea;
  border-bottom: 1px solid var(--rs-border-primary); }

.rs-picker-daterange-calendar-group {
  height: 274px;
  min-width: 492px; }

.rs-picker-daterange-predefined {
  height: 366px;
  border-right: 1px solid #e5e5ea;
  border-right: 1px solid var(--rs-border-primary);
  padding: 4px 0; }

.rs-picker-daterange-predefined .rs-btn {
  display: block; }

.rs-divider {
  background-color: #e5e5ea;
  background-color: var(--rs-divider-border); }

.rs-divider-vertical {
  display: inline-block;
  height: 1em;
  width: 1px;
  vertical-align: middle;
  margin: 0 12px; }

.rs-divider-horizontal {
  height: 1px;
  margin: 24px 0; }

.rs-divider-horizontal.rs-divider-with-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
  background: transparent;
  margin: 30px 0; }

.rs-divider-horizontal.rs-divider-with-text::before,
.rs-divider-horizontal.rs-divider-with-text::after {
  content: '';
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  border-top: 1px solid #e5e5ea;
  border-top: 1px solid var(--rs-divider-border); }

.rs-divider-inner-text {
  padding: 0 12px;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto; }

@-webkit-keyframes shakeHead {
  0%,
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  25%,
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  50% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); } }

@keyframes shakeHead {
  0%,
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  25%,
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  50% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); } }

.rs-drawer-wrapper {
  position: fixed;
  z-index: 1050;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.rs-drawer {
  display: none;
  overflow: hidden;
  position: fixed;
  z-index: 1050;
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: var(--rs-drawer-shadow);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  box-shadow: var(--rs-drawer-shadow);
  outline: 0; }

.rs-drawer-open.rs-drawer-has-backdrop {
  overflow: hidden; }

.rs-drawer-left,
.rs-drawer-right {
  top: 0;
  height: 100%; }

.rs-drawer-left.rs-drawer-lg,
.rs-drawer-right.rs-drawer-lg {
  width: 968px; }

.rs-drawer-left.rs-drawer-md,
.rs-drawer-right.rs-drawer-md {
  width: 800px; }

.rs-drawer-left.rs-drawer-sm,
.rs-drawer-right.rs-drawer-sm {
  width: 600px; }

.rs-drawer-left.rs-drawer-xs,
.rs-drawer-right.rs-drawer-xs {
  width: 400px; }

.rs-drawer-top,
.rs-drawer-bottom {
  width: 100%; }

.rs-drawer-top.rs-drawer-lg,
.rs-drawer-bottom.rs-drawer-lg {
  height: 568px; }

.rs-drawer-top.rs-drawer-md,
.rs-drawer-bottom.rs-drawer-md {
  height: 480px; }

.rs-drawer-top.rs-drawer-sm,
.rs-drawer-bottom.rs-drawer-sm {
  height: 400px; }

.rs-drawer-top.rs-drawer-xs,
.rs-drawer-bottom.rs-drawer-xs {
  height: 290px; }

.rs-drawer-full.rs-drawer-top,
.rs-drawer-full.rs-drawer-bottom {
  height: calc(100% - 60px); }

.rs-drawer-full.rs-drawer-top .rs-drawer-content,
.rs-drawer-full.rs-drawer-bottom .rs-drawer-content {
  height: 100%; }

.rs-drawer-full.rs-drawer-left,
.rs-drawer-full.rs-drawer-right {
  width: calc(100% - 60px); }

.rs-drawer-right {
  right: 0; }

.rs-drawer-left {
  left: 0; }

.rs-drawer-top {
  top: 0; }

.rs-drawer-bottom {
  bottom: 0; }

.rs-drawer-open .rs-drawer {
  overflow: visible; }

.rs-drawer-dialog {
  position: relative;
  width: 100%;
  height: 100%; }

.rs-drawer-shake .rs-drawer-dialog {
  -webkit-animation: 0.3s linear shakeHead;
  animation: 0.3s linear shakeHead; }

.rs-drawer-content {
  position: absolute;
  background-color: #fff;
  background-color: var(--rs-bg-overlay);
  outline: 0;
  width: 100%;
  height: 100%; }

.rs-theme-high-contrast .rs-drawer-content {
  background-color: var(--rs-drawer-bg); }

.rs-drawer-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  background-color: rgba(39, 44, 54, 0.3);
  background-color: var(--rs-bg-backdrop); }

.rs-drawer-backdrop.rs-anim-fade {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in;
  transition: opacity 0.3s ease-in; }

.rs-drawer-backdrop.rs-anim-in {
  opacity: 1; }

.rs-drawer-header {
  position: relative;
  padding: 20px 40px 20px 60px;
  border-bottom: 1px solid #e5e5ea;
  border-bottom: 1px solid var(--rs-border-primary);
  display: -ms-flexbox;
  display: flex; }

.rs-drawer-header::before,
.rs-drawer-header::after {
  content: ' ';
  display: table; }

.rs-drawer-header::after {
  clear: both; }

.rs-drawer-header .rs-drawer-header-close {
  position: absolute;
  left: 15px;
  top: 23px; }

.rs-drawer-title {
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  margin: 0;
  color: #272c36;
  color: var(--rs-text-heading);
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.rs-drawer-actions {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  text-align: right;
  border-top: none;
  margin-left: auto; }

.rs-drawer-actions::before,
.rs-drawer-actions::after {
  content: ' ';
  display: table; }

.rs-drawer-actions::after {
  clear: both; }

.rs-drawer-title ~ .rs-drawer-actions {
  margin-left: 10px; }

.rs-drawer-actions .rs-btn + .rs-btn {
  margin-left: 10px;
  margin-bottom: 0; }

.rs-drawer-actions .rs-btn-group .rs-btn + .rs-btn {
  margin-left: -1px; }

.rs-drawer-actions .rs-btn-block + .rs-btn-block {
  margin-left: 0; }

.rs-drawer-body {
  position: relative;
  padding: 30px 60px;
  height: 100%;
  overflow: auto; }

.rs-drawer-header + .rs-drawer-body {
  height: calc(100% - 76px); }

.rs-drawer-body-close {
  position: absolute;
  left: 15px;
  top: 25px; }

.rs-drawer-header ~ .rs-drawer-body .rs-drawer-body-close {
  display: none; }

.rs-drawer-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

.rs-drawer-footer {
  text-align: right;
  border-top: none;
  margin: 0 20px 20px; }

.rs-drawer-footer::before,
.rs-drawer-footer::after {
  content: ' ';
  display: table; }

.rs-drawer-footer::after {
  clear: both; }

.rs-drawer-footer .rs-btn + .rs-btn {
  margin-left: 10px;
  margin-bottom: 0; }

.rs-drawer-footer .rs-btn-group .rs-btn + .rs-btn {
  margin-left: -1px; }

.rs-drawer-footer .rs-btn-block + .rs-btn-block {
  margin-left: 0; }

.rs-dropdown-toggle-caret {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 2px;
  position: absolute;
  top: 8px;
  right: 12px;
  height: 20px;
  font-size: 12px; }

.rs-dropdown {
  position: relative;
  display: inline-block;
  vertical-align: middle; }

.rs-dropdown .rs-btn > .rs-dropdown-toggle-icon {
  margin-right: 6px; }

.rs-dropdown .rs-btn > .rs-dropdown-toggle-caret {
  margin-left: 4px; }

.rs-dropdown-disabled .rs-dropdown-toggle.rs-btn {
  cursor: not-allowed !important;
  -webkit-box-shadow: none;
  box-shadow: none; }

.rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-subtle,
.rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-subtle:focus,
.rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-subtle:active {
  color: #c5c6c7;
  color: var(--rs-btn-subtle-disabled-text);
  background: none; }

.rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-primary,
.rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-primary:focus,
.rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-primary:active {
  opacity: 0.3;
  background-color: #3498ff;
  background-color: var(--rs-btn-primary-bg); }

.rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-link,
.rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-link:focus,
.rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-link:active {
  opacity: 0.3;
  text-decoration: none; }

.rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-ghost,
.rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-ghost:focus,
.rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-ghost:active {
  opacity: 0.3; }

.rs-dropdown-disabled .rs-dropdown-toggle.rs-btn .rs-ripple-pond {
  display: none !important; }

.rs-dropdown-toggle.rs-btn-lg {
  padding-right: 36px !important; }

.rs-dropdown-toggle.rs-btn-lg .rs-dropdown-toggle-caret {
  top: 10px;
  right: 16px;
  font-size: 14px; }

.rs-dropdown-toggle.rs-btn-sm {
  padding-right: 30px !important; }

.rs-dropdown-toggle.rs-btn-sm .rs-dropdown-toggle-caret {
  top: 5px;
  right: 10px;
  font-size: 10px; }

.rs-dropdown-toggle.rs-btn-xs {
  padding-right: 28px !important; }

.rs-dropdown-toggle.rs-btn-xs .rs-dropdown-toggle-caret {
  top: 2px;
  right: 8px;
  font-size: 8px; }

.rs-dropdown-toggle,
.rs-dropdown-toggle.rs-btn {
  position: relative;
  z-index: 5;
  padding-right: 32px;
  display: inline-block; }

.rs-dropdown-toggle.rs-dropdown-toggle-no-caret {
  padding-right: 12px; }

.rs-dropdown-menu {
  margin: 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #fff;
  background-color: var(--rs-bg-overlay);
  border-radius: 6px;
  padding: 6px 0;
  outline: 0; }

.rs-dropdown-menu:focus-visible {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring); }

.rs-theme-high-contrast .rs-dropdown-menu:focus-visible {
  outline-offset: 2px; }

.rs-dropdown .rs-dropdown-menu {
  position: absolute;
  z-index: 6;
  float: left;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: var(--rs-dropdown-shadow);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
  box-shadow: var(--rs-dropdown-shadow);
  outline: none; }

.rs-theme-high-contrast .rs-dropdown .rs-dropdown-menu {
  border: 1px solid #e5e5ea;
  border: 1px solid var(--rs-border-primary); }

.rs-dropdown-menu[hidden] {
  display: none; }

.rs-dropdown-item {
  display: block;
  padding: 8px 12px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #575757;
  color: var(--rs-text-primary);
  white-space: nowrap;
  cursor: pointer;
  text-decoration: none; }

.rs-dropdown-item:hover,
.rs-dropdown-item:focus,
.rs-dropdown-item:active {
  text-decoration: none; }

.rs-dropdown-item > .rs-icon {
  width: 14px;
  text-align: center;
  margin-right: 6px; }

.rs-dropdown-item.rs-dropdown-item-active {
  outline: 0;
  font-weight: bold; }

.rs-dropdown-item.rs-dropdown-item-active,
.rs-dropdown-item.rs-dropdown-item-active:hover,
.rs-dropdown-item.rs-dropdown-item-active:focus {
  color: #1675e0;
  color: var(--rs-dropdown-item-text-active);
  background-color: #f2faff;
  background-color: var(--rs-dropdown-item-bg-active); }

.rs-dropdown-item:focus-visible,
.rs-dropdown-item.rs-dropdown-item-focus {
  background-color: rgba(204, 233, 255, 0.5);
  background-color: var(--rs-menuitem-active-bg);
  color: #1675e0;
  color: var(--rs-menuitem-active-text); }

.rs-theme-high-contrast .rs-dropdown-item:focus-visible,
.rs-theme-high-contrast .rs-dropdown-item.rs-dropdown-item-focus {
  text-decoration: underline;
  -webkit-box-shadow: inset 0 0 0 2px #fff;
  box-shadow: inset 0 0 0 2px #fff; }

.rs-dropdown-item:focus {
  outline: 0; }

.rs-dropdown-item-disabled {
  color: #c5c6c7;
  color: var(--rs-text-disabled);
  cursor: not-allowed;
  pointer-events: none; }

.rs-dropdown-item-disabled:hover,
.rs-dropdown-item-disabled:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none; }

.rs-dropdown-item-divider {
  height: 1px;
  margin: 6px 0;
  overflow: hidden;
  background-color: #e5e5ea;
  background-color: var(--rs-dropdown-divider); }

.rs-dropdown-item-submenu {
  position: relative; }

.rs-dropdown-item-submenu:hover > .rs-dropdown-item-toggle {
  background-color: rgba(204, 233, 255, 0.5);
  background-color: var(--rs-menuitem-active-bg);
  color: #1675e0;
  color: var(--rs-menuitem-active-text); }

.rs-theme-high-contrast .rs-dropdown-item-submenu:hover > .rs-dropdown-item-toggle {
  text-decoration: underline;
  -webkit-box-shadow: inset 0 0 0 2px #fff;
  box-shadow: inset 0 0 0 2px #fff; }

.rs-dropdown-item-submenu.rs-dropdown-item {
  position: relative; }

.rs-dropdown-item-submenu.rs-dropdown-item > .rs-dropdown-item-toggle .rs-dropdown-menu-toggle-icon {
  position: absolute;
  top: 11px;
  margin: 0; }

.rs-dropdown-item-submenu.rs-dropdown-item > .rs-dropdown-menu {
  position: absolute;
  top: -6px; }

.rs-dropdown-item.rs-dropdown-item-submenu {
  padding: 0; }

.rs-dropdown-item.rs-dropdown-item-submenu .rs-dropdown-item-toggle {
  padding: 8px 12px;
  padding-right: 27px; }

.rs-dropdown-item.rs-dropdown-item-submenu .rs-dropdown-menu-toggle-icon {
  right: 9px; }

.rs-dropdown-item-submenu .rs-dropdown-menu {
  left: 100%; }

.rs-dropdown-item-submenu .rs-dropdown-menu[data-direction='start'] {
  left: unset;
  right: 100%; }

.rs-dropdown-item-submenu.rs-dropdown-item-open > .rs-dropdown-menu {
  display: table;
  -webkit-animation-name: slideUpIn;
  animation-name: slideUpIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.rs-dropdown-menu > .rs-dropdown-menu-item-focus {
  outline: 0;
  background-color: #f2faff;
  background-color: var(--rs-dropdown-item-bg-active); }

.rs-dropdown-menu > .rs-dropdown-menu-item-focus:hover,
.rs-dropdown-menu > .rs-dropdown-menu-item-focus:focus {
  background-color: rgba(204, 233, 255, 0.5);
  background-color: var(--rs-menuitem-active-bg);
  color: #1675e0;
  color: var(--rs-menuitem-active-text); }

.rs-theme-high-contrast .rs-dropdown-menu > .rs-dropdown-menu-item-focus:hover,
.rs-theme-high-contrast .rs-dropdown-menu > .rs-dropdown-menu-item-focus:focus {
  text-decoration: underline;
  -webkit-box-shadow: inset 0 0 0 2px #fff;
  box-shadow: inset 0 0 0 2px #fff; }

.rs-dropdown-open > .rs-dropdown-menu {
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.rs-dropdown-open.rs-dropdown-placement-top-start > .rs-dropdown-menu,
.rs-dropdown-open.rs-dropdown-placement-top-end > .rs-dropdown-menu {
  bottom: 100%; }

.rs-dropdown-open.rs-dropdown-placement-left-start > .rs-dropdown-menu,
.rs-dropdown-open.rs-dropdown-placement-left-end > .rs-dropdown-menu {
  right: 100%; }

.rs-dropdown-open.rs-dropdown-placement-right-start > .rs-dropdown-menu,
.rs-dropdown-open.rs-dropdown-placement-right-end > .rs-dropdown-menu {
  left: 100%; }

.rs-dropdown-open.rs-dropdown-placement-bottom-start > .rs-dropdown-menu,
.rs-dropdown-open.rs-dropdown-placement-bottom-end > .rs-dropdown-menu {
  top: 100%; }

.rs-dropdown-open.rs-dropdown-placement-top-start > .rs-dropdown-menu,
.rs-dropdown-open.rs-dropdown-placement-bottom-start > .rs-dropdown-menu {
  left: 0; }

.rs-dropdown-open.rs-dropdown-placement-top-end > .rs-dropdown-menu,
.rs-dropdown-open.rs-dropdown-placement-bottom-end > .rs-dropdown-menu {
  right: 0; }

.rs-dropdown-open.rs-dropdown-placement-left-start > .rs-dropdown-menu,
.rs-dropdown-open.rs-dropdown-placement-right-start > .rs-dropdown-menu {
  top: 0; }

.rs-dropdown-open.rs-dropdown-placement-right-end > .rs-dropdown-menu,
.rs-dropdown-open.rs-dropdown-placement-left-end > .rs-dropdown-menu {
  bottom: 0; }

.rs-dropdown-open > a {
  outline: 0; }

.rs-dropdown-header {
  display: block;
  padding: 8px 12px;
  line-height: 1.42857143;
  color: #a6a6a6;
  color: var(--rs-dropdown-header-text);
  border-bottom: 1px solid #e5e5ea;
  border-bottom: 1px solid var(--rs-border-primary);
  white-space: nowrap; }

@-webkit-keyframes slideUpIn {
  0% {
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8); }
  100% {
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1); } }

@keyframes slideUpIn {
  0% {
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8); }
  100% {
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1); } }

.rs-flex-box-grid {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap; }

.rs-flex-box-grid-item {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto; }

.rs-flex-box-grid-top {
  -ms-flex-align: start;
  align-items: flex-start; }

.rs-flex-box-grid-middle {
  -ms-flex-align: center;
  align-items: center; }

.rs-flex-box-grid-bottom {
  -ms-flex-align: end;
  align-items: flex-end; }

.rs-flex-box-grid-start {
  -ms-flex-pack: start;
  justify-content: flex-start; }

.rs-flex-box-grid-center {
  -ms-flex-pack: center;
  justify-content: center; }

.rs-flex-box-grid-end {
  -ms-flex-pack: end;
  justify-content: flex-end; }

.rs-flex-box-grid-space-between {
  -ms-flex-pack: justify;
  justify-content: space-between; }

.rs-flex-box-grid-space-around {
  -ms-flex-pack: distribute;
  justify-content: space-around; }

.rs-flex-box-grid-item-1 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 4.16666667%; }

.rs-flex-box-grid-item-order-1 {
  -ms-flex-order: 1;
  order: 1; }

.rs-flex-box-grid-item-2 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 8.33333333%; }

.rs-flex-box-grid-item-order-2 {
  -ms-flex-order: 2;
  order: 2; }

.rs-flex-box-grid-item-3 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 12.5%; }

.rs-flex-box-grid-item-order-3 {
  -ms-flex-order: 3;
  order: 3; }

.rs-flex-box-grid-item-4 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 16.66666667%; }

.rs-flex-box-grid-item-order-4 {
  -ms-flex-order: 4;
  order: 4; }

.rs-flex-box-grid-item-5 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 20.83333333%; }

.rs-flex-box-grid-item-order-5 {
  -ms-flex-order: 5;
  order: 5; }

.rs-flex-box-grid-item-6 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 25%; }

.rs-flex-box-grid-item-order-6 {
  -ms-flex-order: 6;
  order: 6; }

.rs-flex-box-grid-item-7 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 29.16666667%; }

.rs-flex-box-grid-item-order-7 {
  -ms-flex-order: 7;
  order: 7; }

.rs-flex-box-grid-item-8 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 33.33333333%; }

.rs-flex-box-grid-item-order-8 {
  -ms-flex-order: 8;
  order: 8; }

.rs-flex-box-grid-item-9 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 37.5%; }

.rs-flex-box-grid-item-order-9 {
  -ms-flex-order: 9;
  order: 9; }

.rs-flex-box-grid-item-10 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 41.66666667%; }

.rs-flex-box-grid-item-order-10 {
  -ms-flex-order: 10;
  order: 10; }

.rs-flex-box-grid-item-11 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 45.83333333%; }

.rs-flex-box-grid-item-order-11 {
  -ms-flex-order: 11;
  order: 11; }

.rs-flex-box-grid-item-12 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 50%; }

.rs-flex-box-grid-item-order-12 {
  -ms-flex-order: 12;
  order: 12; }

.rs-flex-box-grid-item-13 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 54.16666667%; }

.rs-flex-box-grid-item-order-13 {
  -ms-flex-order: 13;
  order: 13; }

.rs-flex-box-grid-item-14 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 58.33333333%; }

.rs-flex-box-grid-item-order-14 {
  -ms-flex-order: 14;
  order: 14; }

.rs-flex-box-grid-item-15 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 62.5%; }

.rs-flex-box-grid-item-order-15 {
  -ms-flex-order: 15;
  order: 15; }

.rs-flex-box-grid-item-16 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 66.66666667%; }

.rs-flex-box-grid-item-order-16 {
  -ms-flex-order: 16;
  order: 16; }

.rs-flex-box-grid-item-17 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 70.83333333%; }

.rs-flex-box-grid-item-order-17 {
  -ms-flex-order: 17;
  order: 17; }

.rs-flex-box-grid-item-18 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 75%; }

.rs-flex-box-grid-item-order-18 {
  -ms-flex-order: 18;
  order: 18; }

.rs-flex-box-grid-item-19 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 79.16666667%; }

.rs-flex-box-grid-item-order-19 {
  -ms-flex-order: 19;
  order: 19; }

.rs-flex-box-grid-item-20 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 83.33333333%; }

.rs-flex-box-grid-item-order-20 {
  -ms-flex-order: 20;
  order: 20; }

.rs-flex-box-grid-item-21 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 87.5%; }

.rs-flex-box-grid-item-order-21 {
  -ms-flex-order: 21;
  order: 21; }

.rs-flex-box-grid-item-22 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 91.66666667%; }

.rs-flex-box-grid-item-order-22 {
  -ms-flex-order: 22;
  order: 22; }

.rs-flex-box-grid-item-23 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 95.83333333%; }

.rs-flex-box-grid-item-order-23 {
  -ms-flex-order: 23;
  order: 23; }

.rs-flex-box-grid-item-24 {
  position: relative;
  display: block;
  min-height: 1px;
  width: 100%; }

.rs-flex-box-grid-item-order-24 {
  -ms-flex-order: 24;
  order: 24; }

.rs-footer {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto; }

.rs-form-inline > *,
.rs-form-inline > .rs-btn {
  margin-right: 20px;
  margin-bottom: 24px;
  vertical-align: top; }

.rs-form-inline .rs-form-control-label {
  vertical-align: top;
  margin-bottom: auto;
  margin-right: 12px;
  margin-top: 8px;
  display: inline-block; }

.rs-form-fluid .rs-form-control-wrapper {
  width: 100%; }

.rs-form-fluid .rs-form-control-wrapper > .rs-input-number,
.rs-form-fluid .rs-form-control-wrapper > .rs-input {
  width: 100%; }

.rs-form-fluid.rs-form-vertical .rs-form-group .rs-input-group {
  width: 100%; }

.rs-form-control-wrapper {
  position: relative; }

.rs-form-control-wrapper > .rs-input-number,
.rs-form-control-wrapper > .rs-input {
  width: 300px; }

.rs-form-control-wrapper.read-only {
  pointer-events: none;
  -ms-touch-action: none;
  touch-action: none; }

.rs-form-vertical .rs-form-group .rs-input-group {
  width: 300px; }

.rs-form-vertical .rs-form-group .rs-form-control-wrapper {
  display: inline-block;
  max-width: 100%; }

.rs-form-horizontal .rs-form-group .rs-form-control-wrapper {
  float: left; }

.rs-form-horizontal .rs-form-group .rs-form-control-wrapper + .rs-form-help-text {
  clear: both; }

.rs-form-horizontal .rs-form-group .rs-form-control-wrapper + .rs-form-help-text:not(.rs-form-help-text-tooltip) {
  margin-left: 182px; }

.rs-form-inline .rs-form-group .rs-form-control-wrapper {
  display: inline-block; }

.rs-form-inline .rs-form-group .rs-sr-only + .rs-form-control-wrapper {
  margin-left: 0; }

.rs-form:not(.rs-form-inline) .rs-form-group:not(:last-child) {
  margin-bottom: 24px; }

.rs-form-group .rs-input {
  display: inline-block; }

.rs-form-group textarea.rs-input {
  vertical-align: bottom; }

.rs-form-vertical .rs-form-group .rs-form-control-label {
  display: block; }

.rs-form-vertical .rs-form-group .rs-form-help-text:not(.rs-form-help-text-tooltip) {
  padding-top: 4px; }

.rs-form-horizontal .rs-form-group::before,
.rs-form-horizontal .rs-form-group::after {
  content: ' ';
  display: table; }

.rs-form-horizontal .rs-form-group::after {
  clear: both; }

.rs-form-horizontal .rs-form-group .rs-form-control-label {
  float: left;
  display: inline-block;
  min-height: 20px;
  line-height: 1.42857143;
  font-size: 14px;
  padding: 8px 0;
  width: 170px;
  margin-right: 12px;
  text-align: right; }

.rs-form-horizontal .rs-form-group .rs-btn-toolbar {
  padding-left: 182px; }

.rs-form-inline .rs-form-group {
  display: inline-block; }

@-webkit-keyframes errorMessageSlideUpIn {
  0% {
    -webkit-transform: translate3d(0, 2px, 0);
    transform: translate3d(0, 2px, 0);
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes errorMessageSlideUpIn {
  0% {
    -webkit-transform: translate3d(0, 2px, 0);
    transform: translate3d(0, 2px, 0);
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-webkit-keyframes errorMessageSlideDownIn {
  0% {
    -webkit-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes errorMessageSlideDownIn {
  0% {
    -webkit-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-webkit-keyframes errorMessageSlideLeftIn {
  0% {
    -webkit-transform: translate3d(-2px, 0, 0);
    transform: translate3d(-2px, 0, 0);
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes errorMessageSlideLeftIn {
  0% {
    -webkit-transform: translate3d(-2px, 0, 0);
    transform: translate3d(-2px, 0, 0);
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-webkit-keyframes errorMessageSlideRightIn {
  0% {
    -webkit-transform: translate3d(2px, 0, 0);
    transform: translate3d(2px, 0, 0);
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes errorMessageSlideRightIn {
  0% {
    -webkit-transform: translate3d(2px, 0, 0);
    transform: translate3d(2px, 0, 0);
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.rs-form-error-message {
  position: absolute;
  padding: 3px 7px;
  background-color: #fff;
  background-color: var(--rs-form-errormessage-bg);
  border: 1px solid #e5e5ea;
  border: 1px solid var(--rs-form-errormessage-border);
  border-radius: 6px;
  -webkit-filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.1));
  z-index: 5;
  color: #f44336;
  color: var(--rs-form-errormessage-text);
  font-size: 12px;
  line-height: 20px;
  display: none;
  white-space: nowrap; }

.rs-form-error-message-wrapper {
  position: absolute; }

.rs-form-error-message-show {
  display: block;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out; }

.rs-form-error-message-arrow::before,
.rs-form-error-message-arrow::after {
  content: '';
  position: absolute;
  border-color: transparent;
  border-style: solid; }

.rs-form-error-message-placement-bottom-start,
.rs-form-error-message-placement-bottom-end {
  bottom: 0; }

.rs-form-error-message-placement-bottom-start .rs-form-error-message,
.rs-form-error-message-placement-bottom-end .rs-form-error-message {
  top: 0; }

.rs-form-error-message-placement-bottom-start .rs-form-error-message-show,
.rs-form-error-message-placement-bottom-end .rs-form-error-message-show {
  -webkit-animation-name: errorMessageSlideDownIn;
  animation-name: errorMessageSlideDownIn; }

.rs-form-error-message-placement-bottom-start .rs-form-error-message-arrow::before,
.rs-form-error-message-placement-bottom-end .rs-form-error-message-arrow::before,
.rs-form-error-message-placement-bottom-start .rs-form-error-message-arrow::after,
.rs-form-error-message-placement-bottom-end .rs-form-error-message-arrow::after {
  border-width: 0 6px 6px;
  border-bottom-color: #e5e5ea;
  border-bottom-color: var(--rs-form-errormessage-border);
  top: -6px; }

.rs-form-error-message-placement-bottom-start .rs-form-error-message-arrow::after,
.rs-form-error-message-placement-bottom-end .rs-form-error-message-arrow::after {
  top: -5px;
  border-bottom-color: #fff;
  border-bottom-color: var(--rs-form-errormessage-bg); }

.rs-form-error-message-placement-top-start,
.rs-form-error-message-placement-top-end {
  top: 0; }

.rs-form-error-message-placement-top-start .rs-form-error-message,
.rs-form-error-message-placement-top-end .rs-form-error-message {
  bottom: 0; }

.rs-form-error-message-placement-top-start .rs-form-error-message-show,
.rs-form-error-message-placement-top-end .rs-form-error-message-show {
  -webkit-animation-name: errorMessageSlideUpIn;
  animation-name: errorMessageSlideUpIn; }

.rs-form-error-message-placement-top-start .rs-form-error-message-arrow::before,
.rs-form-error-message-placement-top-end .rs-form-error-message-arrow::before,
.rs-form-error-message-placement-top-start .rs-form-error-message-arrow::after,
.rs-form-error-message-placement-top-end .rs-form-error-message-arrow::after {
  border-width: 6px 6px 0;
  border-top-color: #e5e5ea;
  border-top-color: var(--rs-form-errormessage-border);
  bottom: -6px; }

.rs-form-error-message-placement-top-start .rs-form-error-message-arrow::after,
.rs-form-error-message-placement-top-end .rs-form-error-message-arrow::after {
  bottom: -5px;
  border-top-color: #fff;
  border-top-color: var(--rs-form-errormessage-bg); }

.rs-form-error-message-placement-bottom-start,
.rs-form-error-message-placement-top-start {
  left: 0; }

.rs-form-error-message-placement-bottom-start .rs-form-error-message,
.rs-form-error-message-placement-top-start .rs-form-error-message {
  left: 0; }

.rs-form-error-message-placement-bottom-start .rs-form-error-message-arrow::before,
.rs-form-error-message-placement-top-start .rs-form-error-message-arrow::before,
.rs-form-error-message-placement-bottom-start .rs-form-error-message-arrow::after,
.rs-form-error-message-placement-top-start .rs-form-error-message-arrow::after {
  left: 10px; }

.rs-form-error-message-placement-bottom-end,
.rs-form-error-message-placement-top-end {
  right: 0; }

.rs-form-error-message-placement-bottom-end .rs-form-error-message,
.rs-form-error-message-placement-top-end .rs-form-error-message {
  right: 0; }

.rs-form-error-message-placement-bottom-end .rs-form-error-message-arrow::before,
.rs-form-error-message-placement-top-end .rs-form-error-message-arrow::before,
.rs-form-error-message-placement-bottom-end .rs-form-error-message-arrow::after,
.rs-form-error-message-placement-top-end .rs-form-error-message-arrow::after {
  right: 10px; }

/* rtl:begin:ignore */
/* stylelint-disable-next-line */
.rs-form-error-message-placement-left-start,
.rs-form-error-message-placement-left-end {
  left: 0; }

.rs-form-error-message-placement-left-start .rs-form-error-message,
.rs-form-error-message-placement-left-end .rs-form-error-message {
  right: 0; }

.rs-form-error-message-placement-left-start .rs-form-error-message-show,
.rs-form-error-message-placement-left-end .rs-form-error-message-show {
  -webkit-animation-name: errorMessageSlideRightIn;
  animation-name: errorMessageSlideRightIn; }

.rs-form-error-message-placement-left-start .rs-form-error-message-arrow::before,
.rs-form-error-message-placement-left-end .rs-form-error-message-arrow::before,
.rs-form-error-message-placement-left-start .rs-form-error-message-arrow::after,
.rs-form-error-message-placement-left-end .rs-form-error-message-arrow::after {
  border-width: 6px 0 6px 6px;
  border-left-color: #e5e5ea;
  border-left-color: var(--rs-form-errormessage-border);
  right: -6px; }

.rs-form-error-message-placement-left-start .rs-form-error-message-arrow::after,
.rs-form-error-message-placement-left-end .rs-form-error-message-arrow::after {
  right: -5px;
  border-left-color: #fff;
  border-left-color: var(--rs-form-errormessage-bg); }

.rs-form-error-message-placement-right-start,
.rs-form-error-message-placement-right-end {
  right: 0; }

.rs-form-error-message-placement-right-start .rs-form-error-message,
.rs-form-error-message-placement-right-end .rs-form-error-message {
  left: 0; }

.rs-form-error-message-placement-right-start .rs-form-error-message-show,
.rs-form-error-message-placement-right-end .rs-form-error-message-show {
  -webkit-animation-name: errorMessageSlideLeftIn;
  animation-name: errorMessageSlideLeftIn; }

.rs-form-error-message-placement-right-start .rs-form-error-message-arrow::before,
.rs-form-error-message-placement-right-end .rs-form-error-message-arrow::before,
.rs-form-error-message-placement-right-start .rs-form-error-message-arrow::after,
.rs-form-error-message-placement-right-end .rs-form-error-message-arrow::after {
  border-width: 6px 6px 6px 0;
  border-right-color: #e5e5ea;
  border-right-color: var(--rs-form-errormessage-border);
  left: -6px; }

.rs-form-error-message-placement-right-start .rs-form-error-message-arrow::after,
.rs-form-error-message-placement-right-end .rs-form-error-message-arrow::after {
  left: -5px;
  border-right-color: #fff;
  border-right-color: var(--rs-form-errormessage-bg); }

.rs-form-error-message-placement-left-start,
.rs-form-error-message-placement-right-start {
  top: 0; }

.rs-form-error-message-placement-left-start .rs-form-error-message,
.rs-form-error-message-placement-right-start .rs-form-error-message {
  top: 0; }

.rs-form-error-message-placement-left-start .rs-form-error-message-arrow::before,
.rs-form-error-message-placement-right-start .rs-form-error-message-arrow::before,
.rs-form-error-message-placement-left-start .rs-form-error-message-arrow::after,
.rs-form-error-message-placement-right-start .rs-form-error-message-arrow::after {
  top: 4px; }

.rs-form-error-message-placement-left-end,
.rs-form-error-message-placement-right-end {
  bottom: 0; }

.rs-form-error-message-placement-left-end .rs-form-error-message,
.rs-form-error-message-placement-right-end .rs-form-error-message {
  bottom: 0; }

.rs-form-error-message-placement-left-end .rs-form-error-message-arrow::before,
.rs-form-error-message-placement-right-end .rs-form-error-message-arrow::before,
.rs-form-error-message-placement-left-end .rs-form-error-message-arrow::after,
.rs-form-error-message-placement-right-end .rs-form-error-message-arrow::after {
  bottom: 4px; }

/* rtl:end:ignore */
.rs-grid-container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 5px;
  padding-right: 5px; }

.rs-grid-container::before,
.rs-grid-container::after {
  content: ' ';
  display: table; }

.rs-grid-container::after {
  clear: both; }

@media (min-width: 576px) {
  .rs-grid-container {
    width: 550px; } }

@media (min-width: 768px) {
  .rs-grid-container {
    width: 730px; } }

@media (min-width: 992px) {
  .rs-grid-container {
    width: 970px; } }

@media (min-width: 1200px) {
  .rs-grid-container {
    width: 970px; } }

@media (min-width: 1400px) {
  .rs-grid-container {
    width: 970px; } }

.rs-grid-container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 5px;
  padding-right: 5px; }

.rs-grid-container-fluid::before,
.rs-grid-container-fluid::after {
  content: ' ';
  display: table; }

.rs-grid-container-fluid::after {
  clear: both; }

.rs-col {
  float: left;
  position: relative;
  min-height: 1px;
  padding-left: 5px;
  padding-right: 5px; }

.rs-col-xs-1 {
  width: 4.16666667%; }

.rs-col-xs-pull-1 {
  right: 4.16666667%; }

.rs-col-xs-push-1 {
  left: 4.16666667%; }

.rs-col-xs-offset-1 {
  margin-left: 4.16666667%; }

.rs-col-xs-2 {
  width: 8.33333333%; }

.rs-col-xs-pull-2 {
  right: 8.33333333%; }

.rs-col-xs-push-2 {
  left: 8.33333333%; }

.rs-col-xs-offset-2 {
  margin-left: 8.33333333%; }

.rs-col-xs-3 {
  width: 12.5%; }

.rs-col-xs-pull-3 {
  right: 12.5%; }

.rs-col-xs-push-3 {
  left: 12.5%; }

.rs-col-xs-offset-3 {
  margin-left: 12.5%; }

.rs-col-xs-4 {
  width: 16.66666667%; }

.rs-col-xs-pull-4 {
  right: 16.66666667%; }

.rs-col-xs-push-4 {
  left: 16.66666667%; }

.rs-col-xs-offset-4 {
  margin-left: 16.66666667%; }

.rs-col-xs-5 {
  width: 20.83333333%; }

.rs-col-xs-pull-5 {
  right: 20.83333333%; }

.rs-col-xs-push-5 {
  left: 20.83333333%; }

.rs-col-xs-offset-5 {
  margin-left: 20.83333333%; }

.rs-col-xs-6 {
  width: 25%; }

.rs-col-xs-pull-6 {
  right: 25%; }

.rs-col-xs-push-6 {
  left: 25%; }

.rs-col-xs-offset-6 {
  margin-left: 25%; }

.rs-col-xs-7 {
  width: 29.16666667%; }

.rs-col-xs-pull-7 {
  right: 29.16666667%; }

.rs-col-xs-push-7 {
  left: 29.16666667%; }

.rs-col-xs-offset-7 {
  margin-left: 29.16666667%; }

.rs-col-xs-8 {
  width: 33.33333333%; }

.rs-col-xs-pull-8 {
  right: 33.33333333%; }

.rs-col-xs-push-8 {
  left: 33.33333333%; }

.rs-col-xs-offset-8 {
  margin-left: 33.33333333%; }

.rs-col-xs-9 {
  width: 37.5%; }

.rs-col-xs-pull-9 {
  right: 37.5%; }

.rs-col-xs-push-9 {
  left: 37.5%; }

.rs-col-xs-offset-9 {
  margin-left: 37.5%; }

.rs-col-xs-10 {
  width: 41.66666667%; }

.rs-col-xs-pull-10 {
  right: 41.66666667%; }

.rs-col-xs-push-10 {
  left: 41.66666667%; }

.rs-col-xs-offset-10 {
  margin-left: 41.66666667%; }

.rs-col-xs-11 {
  width: 45.83333333%; }

.rs-col-xs-pull-11 {
  right: 45.83333333%; }

.rs-col-xs-push-11 {
  left: 45.83333333%; }

.rs-col-xs-offset-11 {
  margin-left: 45.83333333%; }

.rs-col-xs-12 {
  width: 50%; }

.rs-col-xs-pull-12 {
  right: 50%; }

.rs-col-xs-push-12 {
  left: 50%; }

.rs-col-xs-offset-12 {
  margin-left: 50%; }

.rs-col-xs-13 {
  width: 54.16666667%; }

.rs-col-xs-pull-13 {
  right: 54.16666667%; }

.rs-col-xs-push-13 {
  left: 54.16666667%; }

.rs-col-xs-offset-13 {
  margin-left: 54.16666667%; }

.rs-col-xs-14 {
  width: 58.33333333%; }

.rs-col-xs-pull-14 {
  right: 58.33333333%; }

.rs-col-xs-push-14 {
  left: 58.33333333%; }

.rs-col-xs-offset-14 {
  margin-left: 58.33333333%; }

.rs-col-xs-15 {
  width: 62.5%; }

.rs-col-xs-pull-15 {
  right: 62.5%; }

.rs-col-xs-push-15 {
  left: 62.5%; }

.rs-col-xs-offset-15 {
  margin-left: 62.5%; }

.rs-col-xs-16 {
  width: 66.66666667%; }

.rs-col-xs-pull-16 {
  right: 66.66666667%; }

.rs-col-xs-push-16 {
  left: 66.66666667%; }

.rs-col-xs-offset-16 {
  margin-left: 66.66666667%; }

.rs-col-xs-17 {
  width: 70.83333333%; }

.rs-col-xs-pull-17 {
  right: 70.83333333%; }

.rs-col-xs-push-17 {
  left: 70.83333333%; }

.rs-col-xs-offset-17 {
  margin-left: 70.83333333%; }

.rs-col-xs-18 {
  width: 75%; }

.rs-col-xs-pull-18 {
  right: 75%; }

.rs-col-xs-push-18 {
  left: 75%; }

.rs-col-xs-offset-18 {
  margin-left: 75%; }

.rs-col-xs-19 {
  width: 79.16666667%; }

.rs-col-xs-pull-19 {
  right: 79.16666667%; }

.rs-col-xs-push-19 {
  left: 79.16666667%; }

.rs-col-xs-offset-19 {
  margin-left: 79.16666667%; }

.rs-col-xs-20 {
  width: 83.33333333%; }

.rs-col-xs-pull-20 {
  right: 83.33333333%; }

.rs-col-xs-push-20 {
  left: 83.33333333%; }

.rs-col-xs-offset-20 {
  margin-left: 83.33333333%; }

.rs-col-xs-21 {
  width: 87.5%; }

.rs-col-xs-pull-21 {
  right: 87.5%; }

.rs-col-xs-push-21 {
  left: 87.5%; }

.rs-col-xs-offset-21 {
  margin-left: 87.5%; }

.rs-col-xs-22 {
  width: 91.66666667%; }

.rs-col-xs-pull-22 {
  right: 91.66666667%; }

.rs-col-xs-push-22 {
  left: 91.66666667%; }

.rs-col-xs-offset-22 {
  margin-left: 91.66666667%; }

.rs-col-xs-23 {
  width: 95.83333333%; }

.rs-col-xs-pull-23 {
  right: 95.83333333%; }

.rs-col-xs-push-23 {
  left: 95.83333333%; }

.rs-col-xs-offset-23 {
  margin-left: 95.83333333%; }

.rs-col-xs-24 {
  width: 100%; }

.rs-col-xs-pull-24 {
  right: 100%; }

.rs-col-xs-push-24 {
  left: 100%; }

.rs-col-xs-offset-24 {
  margin-left: 100%; }

.rs-col-xs-pull-0 {
  right: auto; }

.rs-col-xs-push-0 {
  left: auto; }

@media (min-width: 576px) {
  .rs-col-sm-1 {
    width: 4.16666667%; }
  .rs-col-sm-pull-1 {
    right: 4.16666667%; }
  .rs-col-sm-push-1 {
    left: 4.16666667%; }
  .rs-col-sm-offset-1 {
    margin-left: 4.16666667%; }
  .rs-col-sm-2 {
    width: 8.33333333%; }
  .rs-col-sm-pull-2 {
    right: 8.33333333%; }
  .rs-col-sm-push-2 {
    left: 8.33333333%; }
  .rs-col-sm-offset-2 {
    margin-left: 8.33333333%; }
  .rs-col-sm-3 {
    width: 12.5%; }
  .rs-col-sm-pull-3 {
    right: 12.5%; }
  .rs-col-sm-push-3 {
    left: 12.5%; }
  .rs-col-sm-offset-3 {
    margin-left: 12.5%; }
  .rs-col-sm-4 {
    width: 16.66666667%; }
  .rs-col-sm-pull-4 {
    right: 16.66666667%; }
  .rs-col-sm-push-4 {
    left: 16.66666667%; }
  .rs-col-sm-offset-4 {
    margin-left: 16.66666667%; }
  .rs-col-sm-5 {
    width: 20.83333333%; }
  .rs-col-sm-pull-5 {
    right: 20.83333333%; }
  .rs-col-sm-push-5 {
    left: 20.83333333%; }
  .rs-col-sm-offset-5 {
    margin-left: 20.83333333%; }
  .rs-col-sm-6 {
    width: 25%; }
  .rs-col-sm-pull-6 {
    right: 25%; }
  .rs-col-sm-push-6 {
    left: 25%; }
  .rs-col-sm-offset-6 {
    margin-left: 25%; }
  .rs-col-sm-7 {
    width: 29.16666667%; }
  .rs-col-sm-pull-7 {
    right: 29.16666667%; }
  .rs-col-sm-push-7 {
    left: 29.16666667%; }
  .rs-col-sm-offset-7 {
    margin-left: 29.16666667%; }
  .rs-col-sm-8 {
    width: 33.33333333%; }
  .rs-col-sm-pull-8 {
    right: 33.33333333%; }
  .rs-col-sm-push-8 {
    left: 33.33333333%; }
  .rs-col-sm-offset-8 {
    margin-left: 33.33333333%; }
  .rs-col-sm-9 {
    width: 37.5%; }
  .rs-col-sm-pull-9 {
    right: 37.5%; }
  .rs-col-sm-push-9 {
    left: 37.5%; }
  .rs-col-sm-offset-9 {
    margin-left: 37.5%; }
  .rs-col-sm-10 {
    width: 41.66666667%; }
  .rs-col-sm-pull-10 {
    right: 41.66666667%; }
  .rs-col-sm-push-10 {
    left: 41.66666667%; }
  .rs-col-sm-offset-10 {
    margin-left: 41.66666667%; }
  .rs-col-sm-11 {
    width: 45.83333333%; }
  .rs-col-sm-pull-11 {
    right: 45.83333333%; }
  .rs-col-sm-push-11 {
    left: 45.83333333%; }
  .rs-col-sm-offset-11 {
    margin-left: 45.83333333%; }
  .rs-col-sm-12 {
    width: 50%; }
  .rs-col-sm-pull-12 {
    right: 50%; }
  .rs-col-sm-push-12 {
    left: 50%; }
  .rs-col-sm-offset-12 {
    margin-left: 50%; }
  .rs-col-sm-13 {
    width: 54.16666667%; }
  .rs-col-sm-pull-13 {
    right: 54.16666667%; }
  .rs-col-sm-push-13 {
    left: 54.16666667%; }
  .rs-col-sm-offset-13 {
    margin-left: 54.16666667%; }
  .rs-col-sm-14 {
    width: 58.33333333%; }
  .rs-col-sm-pull-14 {
    right: 58.33333333%; }
  .rs-col-sm-push-14 {
    left: 58.33333333%; }
  .rs-col-sm-offset-14 {
    margin-left: 58.33333333%; }
  .rs-col-sm-15 {
    width: 62.5%; }
  .rs-col-sm-pull-15 {
    right: 62.5%; }
  .rs-col-sm-push-15 {
    left: 62.5%; }
  .rs-col-sm-offset-15 {
    margin-left: 62.5%; }
  .rs-col-sm-16 {
    width: 66.66666667%; }
  .rs-col-sm-pull-16 {
    right: 66.66666667%; }
  .rs-col-sm-push-16 {
    left: 66.66666667%; }
  .rs-col-sm-offset-16 {
    margin-left: 66.66666667%; }
  .rs-col-sm-17 {
    width: 70.83333333%; }
  .rs-col-sm-pull-17 {
    right: 70.83333333%; }
  .rs-col-sm-push-17 {
    left: 70.83333333%; }
  .rs-col-sm-offset-17 {
    margin-left: 70.83333333%; }
  .rs-col-sm-18 {
    width: 75%; }
  .rs-col-sm-pull-18 {
    right: 75%; }
  .rs-col-sm-push-18 {
    left: 75%; }
  .rs-col-sm-offset-18 {
    margin-left: 75%; }
  .rs-col-sm-19 {
    width: 79.16666667%; }
  .rs-col-sm-pull-19 {
    right: 79.16666667%; }
  .rs-col-sm-push-19 {
    left: 79.16666667%; }
  .rs-col-sm-offset-19 {
    margin-left: 79.16666667%; }
  .rs-col-sm-20 {
    width: 83.33333333%; }
  .rs-col-sm-pull-20 {
    right: 83.33333333%; }
  .rs-col-sm-push-20 {
    left: 83.33333333%; }
  .rs-col-sm-offset-20 {
    margin-left: 83.33333333%; }
  .rs-col-sm-21 {
    width: 87.5%; }
  .rs-col-sm-pull-21 {
    right: 87.5%; }
  .rs-col-sm-push-21 {
    left: 87.5%; }
  .rs-col-sm-offset-21 {
    margin-left: 87.5%; }
  .rs-col-sm-22 {
    width: 91.66666667%; }
  .rs-col-sm-pull-22 {
    right: 91.66666667%; }
  .rs-col-sm-push-22 {
    left: 91.66666667%; }
  .rs-col-sm-offset-22 {
    margin-left: 91.66666667%; }
  .rs-col-sm-23 {
    width: 95.83333333%; }
  .rs-col-sm-pull-23 {
    right: 95.83333333%; }
  .rs-col-sm-push-23 {
    left: 95.83333333%; }
  .rs-col-sm-offset-23 {
    margin-left: 95.83333333%; }
  .rs-col-sm-24 {
    width: 100%; }
  .rs-col-sm-pull-24 {
    right: 100%; }
  .rs-col-sm-push-24 {
    left: 100%; }
  .rs-col-sm-offset-24 {
    margin-left: 100%; }
  .rs-col-sm-pull-0 {
    right: auto; }
  .rs-col-sm-push-0 {
    left: auto; } }

@media (min-width: 768px) {
  .rs-col-md-1 {
    width: 4.16666667%; }
  .rs-col-md-pull-1 {
    right: 4.16666667%; }
  .rs-col-md-push-1 {
    left: 4.16666667%; }
  .rs-col-md-offset-1 {
    margin-left: 4.16666667%; }
  .rs-col-md-2 {
    width: 8.33333333%; }
  .rs-col-md-pull-2 {
    right: 8.33333333%; }
  .rs-col-md-push-2 {
    left: 8.33333333%; }
  .rs-col-md-offset-2 {
    margin-left: 8.33333333%; }
  .rs-col-md-3 {
    width: 12.5%; }
  .rs-col-md-pull-3 {
    right: 12.5%; }
  .rs-col-md-push-3 {
    left: 12.5%; }
  .rs-col-md-offset-3 {
    margin-left: 12.5%; }
  .rs-col-md-4 {
    width: 16.66666667%; }
  .rs-col-md-pull-4 {
    right: 16.66666667%; }
  .rs-col-md-push-4 {
    left: 16.66666667%; }
  .rs-col-md-offset-4 {
    margin-left: 16.66666667%; }
  .rs-col-md-5 {
    width: 20.83333333%; }
  .rs-col-md-pull-5 {
    right: 20.83333333%; }
  .rs-col-md-push-5 {
    left: 20.83333333%; }
  .rs-col-md-offset-5 {
    margin-left: 20.83333333%; }
  .rs-col-md-6 {
    width: 25%; }
  .rs-col-md-pull-6 {
    right: 25%; }
  .rs-col-md-push-6 {
    left: 25%; }
  .rs-col-md-offset-6 {
    margin-left: 25%; }
  .rs-col-md-7 {
    width: 29.16666667%; }
  .rs-col-md-pull-7 {
    right: 29.16666667%; }
  .rs-col-md-push-7 {
    left: 29.16666667%; }
  .rs-col-md-offset-7 {
    margin-left: 29.16666667%; }
  .rs-col-md-8 {
    width: 33.33333333%; }
  .rs-col-md-pull-8 {
    right: 33.33333333%; }
  .rs-col-md-push-8 {
    left: 33.33333333%; }
  .rs-col-md-offset-8 {
    margin-left: 33.33333333%; }
  .rs-col-md-9 {
    width: 37.5%; }
  .rs-col-md-pull-9 {
    right: 37.5%; }
  .rs-col-md-push-9 {
    left: 37.5%; }
  .rs-col-md-offset-9 {
    margin-left: 37.5%; }
  .rs-col-md-10 {
    width: 41.66666667%; }
  .rs-col-md-pull-10 {
    right: 41.66666667%; }
  .rs-col-md-push-10 {
    left: 41.66666667%; }
  .rs-col-md-offset-10 {
    margin-left: 41.66666667%; }
  .rs-col-md-11 {
    width: 45.83333333%; }
  .rs-col-md-pull-11 {
    right: 45.83333333%; }
  .rs-col-md-push-11 {
    left: 45.83333333%; }
  .rs-col-md-offset-11 {
    margin-left: 45.83333333%; }
  .rs-col-md-12 {
    width: 50%; }
  .rs-col-md-pull-12 {
    right: 50%; }
  .rs-col-md-push-12 {
    left: 50%; }
  .rs-col-md-offset-12 {
    margin-left: 50%; }
  .rs-col-md-13 {
    width: 54.16666667%; }
  .rs-col-md-pull-13 {
    right: 54.16666667%; }
  .rs-col-md-push-13 {
    left: 54.16666667%; }
  .rs-col-md-offset-13 {
    margin-left: 54.16666667%; }
  .rs-col-md-14 {
    width: 58.33333333%; }
  .rs-col-md-pull-14 {
    right: 58.33333333%; }
  .rs-col-md-push-14 {
    left: 58.33333333%; }
  .rs-col-md-offset-14 {
    margin-left: 58.33333333%; }
  .rs-col-md-15 {
    width: 62.5%; }
  .rs-col-md-pull-15 {
    right: 62.5%; }
  .rs-col-md-push-15 {
    left: 62.5%; }
  .rs-col-md-offset-15 {
    margin-left: 62.5%; }
  .rs-col-md-16 {
    width: 66.66666667%; }
  .rs-col-md-pull-16 {
    right: 66.66666667%; }
  .rs-col-md-push-16 {
    left: 66.66666667%; }
  .rs-col-md-offset-16 {
    margin-left: 66.66666667%; }
  .rs-col-md-17 {
    width: 70.83333333%; }
  .rs-col-md-pull-17 {
    right: 70.83333333%; }
  .rs-col-md-push-17 {
    left: 70.83333333%; }
  .rs-col-md-offset-17 {
    margin-left: 70.83333333%; }
  .rs-col-md-18 {
    width: 75%; }
  .rs-col-md-pull-18 {
    right: 75%; }
  .rs-col-md-push-18 {
    left: 75%; }
  .rs-col-md-offset-18 {
    margin-left: 75%; }
  .rs-col-md-19 {
    width: 79.16666667%; }
  .rs-col-md-pull-19 {
    right: 79.16666667%; }
  .rs-col-md-push-19 {
    left: 79.16666667%; }
  .rs-col-md-offset-19 {
    margin-left: 79.16666667%; }
  .rs-col-md-20 {
    width: 83.33333333%; }
  .rs-col-md-pull-20 {
    right: 83.33333333%; }
  .rs-col-md-push-20 {
    left: 83.33333333%; }
  .rs-col-md-offset-20 {
    margin-left: 83.33333333%; }
  .rs-col-md-21 {
    width: 87.5%; }
  .rs-col-md-pull-21 {
    right: 87.5%; }
  .rs-col-md-push-21 {
    left: 87.5%; }
  .rs-col-md-offset-21 {
    margin-left: 87.5%; }
  .rs-col-md-22 {
    width: 91.66666667%; }
  .rs-col-md-pull-22 {
    right: 91.66666667%; }
  .rs-col-md-push-22 {
    left: 91.66666667%; }
  .rs-col-md-offset-22 {
    margin-left: 91.66666667%; }
  .rs-col-md-23 {
    width: 95.83333333%; }
  .rs-col-md-pull-23 {
    right: 95.83333333%; }
  .rs-col-md-push-23 {
    left: 95.83333333%; }
  .rs-col-md-offset-23 {
    margin-left: 95.83333333%; }
  .rs-col-md-24 {
    width: 100%; }
  .rs-col-md-pull-24 {
    right: 100%; }
  .rs-col-md-push-24 {
    left: 100%; }
  .rs-col-md-offset-24 {
    margin-left: 100%; }
  .rs-col-md-pull-0 {
    right: auto; }
  .rs-col-md-push-0 {
    left: auto; } }

@media (min-width: 992px) {
  .rs-col-lg-1 {
    width: 4.16666667%; }
  .rs-col-lg-pull-1 {
    right: 4.16666667%; }
  .rs-col-lg-push-1 {
    left: 4.16666667%; }
  .rs-col-lg-offset-1 {
    margin-left: 4.16666667%; }
  .rs-col-lg-2 {
    width: 8.33333333%; }
  .rs-col-lg-pull-2 {
    right: 8.33333333%; }
  .rs-col-lg-push-2 {
    left: 8.33333333%; }
  .rs-col-lg-offset-2 {
    margin-left: 8.33333333%; }
  .rs-col-lg-3 {
    width: 12.5%; }
  .rs-col-lg-pull-3 {
    right: 12.5%; }
  .rs-col-lg-push-3 {
    left: 12.5%; }
  .rs-col-lg-offset-3 {
    margin-left: 12.5%; }
  .rs-col-lg-4 {
    width: 16.66666667%; }
  .rs-col-lg-pull-4 {
    right: 16.66666667%; }
  .rs-col-lg-push-4 {
    left: 16.66666667%; }
  .rs-col-lg-offset-4 {
    margin-left: 16.66666667%; }
  .rs-col-lg-5 {
    width: 20.83333333%; }
  .rs-col-lg-pull-5 {
    right: 20.83333333%; }
  .rs-col-lg-push-5 {
    left: 20.83333333%; }
  .rs-col-lg-offset-5 {
    margin-left: 20.83333333%; }
  .rs-col-lg-6 {
    width: 25%; }
  .rs-col-lg-pull-6 {
    right: 25%; }
  .rs-col-lg-push-6 {
    left: 25%; }
  .rs-col-lg-offset-6 {
    margin-left: 25%; }
  .rs-col-lg-7 {
    width: 29.16666667%; }
  .rs-col-lg-pull-7 {
    right: 29.16666667%; }
  .rs-col-lg-push-7 {
    left: 29.16666667%; }
  .rs-col-lg-offset-7 {
    margin-left: 29.16666667%; }
  .rs-col-lg-8 {
    width: 33.33333333%; }
  .rs-col-lg-pull-8 {
    right: 33.33333333%; }
  .rs-col-lg-push-8 {
    left: 33.33333333%; }
  .rs-col-lg-offset-8 {
    margin-left: 33.33333333%; }
  .rs-col-lg-9 {
    width: 37.5%; }
  .rs-col-lg-pull-9 {
    right: 37.5%; }
  .rs-col-lg-push-9 {
    left: 37.5%; }
  .rs-col-lg-offset-9 {
    margin-left: 37.5%; }
  .rs-col-lg-10 {
    width: 41.66666667%; }
  .rs-col-lg-pull-10 {
    right: 41.66666667%; }
  .rs-col-lg-push-10 {
    left: 41.66666667%; }
  .rs-col-lg-offset-10 {
    margin-left: 41.66666667%; }
  .rs-col-lg-11 {
    width: 45.83333333%; }
  .rs-col-lg-pull-11 {
    right: 45.83333333%; }
  .rs-col-lg-push-11 {
    left: 45.83333333%; }
  .rs-col-lg-offset-11 {
    margin-left: 45.83333333%; }
  .rs-col-lg-12 {
    width: 50%; }
  .rs-col-lg-pull-12 {
    right: 50%; }
  .rs-col-lg-push-12 {
    left: 50%; }
  .rs-col-lg-offset-12 {
    margin-left: 50%; }
  .rs-col-lg-13 {
    width: 54.16666667%; }
  .rs-col-lg-pull-13 {
    right: 54.16666667%; }
  .rs-col-lg-push-13 {
    left: 54.16666667%; }
  .rs-col-lg-offset-13 {
    margin-left: 54.16666667%; }
  .rs-col-lg-14 {
    width: 58.33333333%; }
  .rs-col-lg-pull-14 {
    right: 58.33333333%; }
  .rs-col-lg-push-14 {
    left: 58.33333333%; }
  .rs-col-lg-offset-14 {
    margin-left: 58.33333333%; }
  .rs-col-lg-15 {
    width: 62.5%; }
  .rs-col-lg-pull-15 {
    right: 62.5%; }
  .rs-col-lg-push-15 {
    left: 62.5%; }
  .rs-col-lg-offset-15 {
    margin-left: 62.5%; }
  .rs-col-lg-16 {
    width: 66.66666667%; }
  .rs-col-lg-pull-16 {
    right: 66.66666667%; }
  .rs-col-lg-push-16 {
    left: 66.66666667%; }
  .rs-col-lg-offset-16 {
    margin-left: 66.66666667%; }
  .rs-col-lg-17 {
    width: 70.83333333%; }
  .rs-col-lg-pull-17 {
    right: 70.83333333%; }
  .rs-col-lg-push-17 {
    left: 70.83333333%; }
  .rs-col-lg-offset-17 {
    margin-left: 70.83333333%; }
  .rs-col-lg-18 {
    width: 75%; }
  .rs-col-lg-pull-18 {
    right: 75%; }
  .rs-col-lg-push-18 {
    left: 75%; }
  .rs-col-lg-offset-18 {
    margin-left: 75%; }
  .rs-col-lg-19 {
    width: 79.16666667%; }
  .rs-col-lg-pull-19 {
    right: 79.16666667%; }
  .rs-col-lg-push-19 {
    left: 79.16666667%; }
  .rs-col-lg-offset-19 {
    margin-left: 79.16666667%; }
  .rs-col-lg-20 {
    width: 83.33333333%; }
  .rs-col-lg-pull-20 {
    right: 83.33333333%; }
  .rs-col-lg-push-20 {
    left: 83.33333333%; }
  .rs-col-lg-offset-20 {
    margin-left: 83.33333333%; }
  .rs-col-lg-21 {
    width: 87.5%; }
  .rs-col-lg-pull-21 {
    right: 87.5%; }
  .rs-col-lg-push-21 {
    left: 87.5%; }
  .rs-col-lg-offset-21 {
    margin-left: 87.5%; }
  .rs-col-lg-22 {
    width: 91.66666667%; }
  .rs-col-lg-pull-22 {
    right: 91.66666667%; }
  .rs-col-lg-push-22 {
    left: 91.66666667%; }
  .rs-col-lg-offset-22 {
    margin-left: 91.66666667%; }
  .rs-col-lg-23 {
    width: 95.83333333%; }
  .rs-col-lg-pull-23 {
    right: 95.83333333%; }
  .rs-col-lg-push-23 {
    left: 95.83333333%; }
  .rs-col-lg-offset-23 {
    margin-left: 95.83333333%; }
  .rs-col-lg-24 {
    width: 100%; }
  .rs-col-lg-pull-24 {
    right: 100%; }
  .rs-col-lg-push-24 {
    left: 100%; }
  .rs-col-lg-offset-24 {
    margin-left: 100%; }
  .rs-col-lg-pull-0 {
    right: auto; }
  .rs-col-lg-push-0 {
    left: auto; } }

@media (min-width: 1200px) {
  .rs-col-xl-1 {
    width: 4.16666667%; }
  .rs-col-xl-pull-1 {
    right: 4.16666667%; }
  .rs-col-xl-push-1 {
    left: 4.16666667%; }
  .rs-col-xl-offset-1 {
    margin-left: 4.16666667%; }
  .rs-col-xl-2 {
    width: 8.33333333%; }
  .rs-col-xl-pull-2 {
    right: 8.33333333%; }
  .rs-col-xl-push-2 {
    left: 8.33333333%; }
  .rs-col-xl-offset-2 {
    margin-left: 8.33333333%; }
  .rs-col-xl-3 {
    width: 12.5%; }
  .rs-col-xl-pull-3 {
    right: 12.5%; }
  .rs-col-xl-push-3 {
    left: 12.5%; }
  .rs-col-xl-offset-3 {
    margin-left: 12.5%; }
  .rs-col-xl-4 {
    width: 16.66666667%; }
  .rs-col-xl-pull-4 {
    right: 16.66666667%; }
  .rs-col-xl-push-4 {
    left: 16.66666667%; }
  .rs-col-xl-offset-4 {
    margin-left: 16.66666667%; }
  .rs-col-xl-5 {
    width: 20.83333333%; }
  .rs-col-xl-pull-5 {
    right: 20.83333333%; }
  .rs-col-xl-push-5 {
    left: 20.83333333%; }
  .rs-col-xl-offset-5 {
    margin-left: 20.83333333%; }
  .rs-col-xl-6 {
    width: 25%; }
  .rs-col-xl-pull-6 {
    right: 25%; }
  .rs-col-xl-push-6 {
    left: 25%; }
  .rs-col-xl-offset-6 {
    margin-left: 25%; }
  .rs-col-xl-7 {
    width: 29.16666667%; }
  .rs-col-xl-pull-7 {
    right: 29.16666667%; }
  .rs-col-xl-push-7 {
    left: 29.16666667%; }
  .rs-col-xl-offset-7 {
    margin-left: 29.16666667%; }
  .rs-col-xl-8 {
    width: 33.33333333%; }
  .rs-col-xl-pull-8 {
    right: 33.33333333%; }
  .rs-col-xl-push-8 {
    left: 33.33333333%; }
  .rs-col-xl-offset-8 {
    margin-left: 33.33333333%; }
  .rs-col-xl-9 {
    width: 37.5%; }
  .rs-col-xl-pull-9 {
    right: 37.5%; }
  .rs-col-xl-push-9 {
    left: 37.5%; }
  .rs-col-xl-offset-9 {
    margin-left: 37.5%; }
  .rs-col-xl-10 {
    width: 41.66666667%; }
  .rs-col-xl-pull-10 {
    right: 41.66666667%; }
  .rs-col-xl-push-10 {
    left: 41.66666667%; }
  .rs-col-xl-offset-10 {
    margin-left: 41.66666667%; }
  .rs-col-xl-11 {
    width: 45.83333333%; }
  .rs-col-xl-pull-11 {
    right: 45.83333333%; }
  .rs-col-xl-push-11 {
    left: 45.83333333%; }
  .rs-col-xl-offset-11 {
    margin-left: 45.83333333%; }
  .rs-col-xl-12 {
    width: 50%; }
  .rs-col-xl-pull-12 {
    right: 50%; }
  .rs-col-xl-push-12 {
    left: 50%; }
  .rs-col-xl-offset-12 {
    margin-left: 50%; }
  .rs-col-xl-13 {
    width: 54.16666667%; }
  .rs-col-xl-pull-13 {
    right: 54.16666667%; }
  .rs-col-xl-push-13 {
    left: 54.16666667%; }
  .rs-col-xl-offset-13 {
    margin-left: 54.16666667%; }
  .rs-col-xl-14 {
    width: 58.33333333%; }
  .rs-col-xl-pull-14 {
    right: 58.33333333%; }
  .rs-col-xl-push-14 {
    left: 58.33333333%; }
  .rs-col-xl-offset-14 {
    margin-left: 58.33333333%; }
  .rs-col-xl-15 {
    width: 62.5%; }
  .rs-col-xl-pull-15 {
    right: 62.5%; }
  .rs-col-xl-push-15 {
    left: 62.5%; }
  .rs-col-xl-offset-15 {
    margin-left: 62.5%; }
  .rs-col-xl-16 {
    width: 66.66666667%; }
  .rs-col-xl-pull-16 {
    right: 66.66666667%; }
  .rs-col-xl-push-16 {
    left: 66.66666667%; }
  .rs-col-xl-offset-16 {
    margin-left: 66.66666667%; }
  .rs-col-xl-17 {
    width: 70.83333333%; }
  .rs-col-xl-pull-17 {
    right: 70.83333333%; }
  .rs-col-xl-push-17 {
    left: 70.83333333%; }
  .rs-col-xl-offset-17 {
    margin-left: 70.83333333%; }
  .rs-col-xl-18 {
    width: 75%; }
  .rs-col-xl-pull-18 {
    right: 75%; }
  .rs-col-xl-push-18 {
    left: 75%; }
  .rs-col-xl-offset-18 {
    margin-left: 75%; }
  .rs-col-xl-19 {
    width: 79.16666667%; }
  .rs-col-xl-pull-19 {
    right: 79.16666667%; }
  .rs-col-xl-push-19 {
    left: 79.16666667%; }
  .rs-col-xl-offset-19 {
    margin-left: 79.16666667%; }
  .rs-col-xl-20 {
    width: 83.33333333%; }
  .rs-col-xl-pull-20 {
    right: 83.33333333%; }
  .rs-col-xl-push-20 {
    left: 83.33333333%; }
  .rs-col-xl-offset-20 {
    margin-left: 83.33333333%; }
  .rs-col-xl-21 {
    width: 87.5%; }
  .rs-col-xl-pull-21 {
    right: 87.5%; }
  .rs-col-xl-push-21 {
    left: 87.5%; }
  .rs-col-xl-offset-21 {
    margin-left: 87.5%; }
  .rs-col-xl-22 {
    width: 91.66666667%; }
  .rs-col-xl-pull-22 {
    right: 91.66666667%; }
  .rs-col-xl-push-22 {
    left: 91.66666667%; }
  .rs-col-xl-offset-22 {
    margin-left: 91.66666667%; }
  .rs-col-xl-23 {
    width: 95.83333333%; }
  .rs-col-xl-pull-23 {
    right: 95.83333333%; }
  .rs-col-xl-push-23 {
    left: 95.83333333%; }
  .rs-col-xl-offset-23 {
    margin-left: 95.83333333%; }
  .rs-col-xl-24 {
    width: 100%; }
  .rs-col-xl-pull-24 {
    right: 100%; }
  .rs-col-xl-push-24 {
    left: 100%; }
  .rs-col-xl-offset-24 {
    margin-left: 100%; }
  .rs-col-xl-pull-0 {
    right: auto; }
  .rs-col-xl-push-0 {
    left: auto; } }

@media (min-width: 1400px) {
  .rs-col-xxl-1 {
    width: 4.16666667%; }
  .rs-col-xxl-pull-1 {
    right: 4.16666667%; }
  .rs-col-xxl-push-1 {
    left: 4.16666667%; }
  .rs-col-xxl-offset-1 {
    margin-left: 4.16666667%; }
  .rs-col-xxl-2 {
    width: 8.33333333%; }
  .rs-col-xxl-pull-2 {
    right: 8.33333333%; }
  .rs-col-xxl-push-2 {
    left: 8.33333333%; }
  .rs-col-xxl-offset-2 {
    margin-left: 8.33333333%; }
  .rs-col-xxl-3 {
    width: 12.5%; }
  .rs-col-xxl-pull-3 {
    right: 12.5%; }
  .rs-col-xxl-push-3 {
    left: 12.5%; }
  .rs-col-xxl-offset-3 {
    margin-left: 12.5%; }
  .rs-col-xxl-4 {
    width: 16.66666667%; }
  .rs-col-xxl-pull-4 {
    right: 16.66666667%; }
  .rs-col-xxl-push-4 {
    left: 16.66666667%; }
  .rs-col-xxl-offset-4 {
    margin-left: 16.66666667%; }
  .rs-col-xxl-5 {
    width: 20.83333333%; }
  .rs-col-xxl-pull-5 {
    right: 20.83333333%; }
  .rs-col-xxl-push-5 {
    left: 20.83333333%; }
  .rs-col-xxl-offset-5 {
    margin-left: 20.83333333%; }
  .rs-col-xxl-6 {
    width: 25%; }
  .rs-col-xxl-pull-6 {
    right: 25%; }
  .rs-col-xxl-push-6 {
    left: 25%; }
  .rs-col-xxl-offset-6 {
    margin-left: 25%; }
  .rs-col-xxl-7 {
    width: 29.16666667%; }
  .rs-col-xxl-pull-7 {
    right: 29.16666667%; }
  .rs-col-xxl-push-7 {
    left: 29.16666667%; }
  .rs-col-xxl-offset-7 {
    margin-left: 29.16666667%; }
  .rs-col-xxl-8 {
    width: 33.33333333%; }
  .rs-col-xxl-pull-8 {
    right: 33.33333333%; }
  .rs-col-xxl-push-8 {
    left: 33.33333333%; }
  .rs-col-xxl-offset-8 {
    margin-left: 33.33333333%; }
  .rs-col-xxl-9 {
    width: 37.5%; }
  .rs-col-xxl-pull-9 {
    right: 37.5%; }
  .rs-col-xxl-push-9 {
    left: 37.5%; }
  .rs-col-xxl-offset-9 {
    margin-left: 37.5%; }
  .rs-col-xxl-10 {
    width: 41.66666667%; }
  .rs-col-xxl-pull-10 {
    right: 41.66666667%; }
  .rs-col-xxl-push-10 {
    left: 41.66666667%; }
  .rs-col-xxl-offset-10 {
    margin-left: 41.66666667%; }
  .rs-col-xxl-11 {
    width: 45.83333333%; }
  .rs-col-xxl-pull-11 {
    right: 45.83333333%; }
  .rs-col-xxl-push-11 {
    left: 45.83333333%; }
  .rs-col-xxl-offset-11 {
    margin-left: 45.83333333%; }
  .rs-col-xxl-12 {
    width: 50%; }
  .rs-col-xxl-pull-12 {
    right: 50%; }
  .rs-col-xxl-push-12 {
    left: 50%; }
  .rs-col-xxl-offset-12 {
    margin-left: 50%; }
  .rs-col-xxl-13 {
    width: 54.16666667%; }
  .rs-col-xxl-pull-13 {
    right: 54.16666667%; }
  .rs-col-xxl-push-13 {
    left: 54.16666667%; }
  .rs-col-xxl-offset-13 {
    margin-left: 54.16666667%; }
  .rs-col-xxl-14 {
    width: 58.33333333%; }
  .rs-col-xxl-pull-14 {
    right: 58.33333333%; }
  .rs-col-xxl-push-14 {
    left: 58.33333333%; }
  .rs-col-xxl-offset-14 {
    margin-left: 58.33333333%; }
  .rs-col-xxl-15 {
    width: 62.5%; }
  .rs-col-xxl-pull-15 {
    right: 62.5%; }
  .rs-col-xxl-push-15 {
    left: 62.5%; }
  .rs-col-xxl-offset-15 {
    margin-left: 62.5%; }
  .rs-col-xxl-16 {
    width: 66.66666667%; }
  .rs-col-xxl-pull-16 {
    right: 66.66666667%; }
  .rs-col-xxl-push-16 {
    left: 66.66666667%; }
  .rs-col-xxl-offset-16 {
    margin-left: 66.66666667%; }
  .rs-col-xxl-17 {
    width: 70.83333333%; }
  .rs-col-xxl-pull-17 {
    right: 70.83333333%; }
  .rs-col-xxl-push-17 {
    left: 70.83333333%; }
  .rs-col-xxl-offset-17 {
    margin-left: 70.83333333%; }
  .rs-col-xxl-18 {
    width: 75%; }
  .rs-col-xxl-pull-18 {
    right: 75%; }
  .rs-col-xxl-push-18 {
    left: 75%; }
  .rs-col-xxl-offset-18 {
    margin-left: 75%; }
  .rs-col-xxl-19 {
    width: 79.16666667%; }
  .rs-col-xxl-pull-19 {
    right: 79.16666667%; }
  .rs-col-xxl-push-19 {
    left: 79.16666667%; }
  .rs-col-xxl-offset-19 {
    margin-left: 79.16666667%; }
  .rs-col-xxl-20 {
    width: 83.33333333%; }
  .rs-col-xxl-pull-20 {
    right: 83.33333333%; }
  .rs-col-xxl-push-20 {
    left: 83.33333333%; }
  .rs-col-xxl-offset-20 {
    margin-left: 83.33333333%; }
  .rs-col-xxl-21 {
    width: 87.5%; }
  .rs-col-xxl-pull-21 {
    right: 87.5%; }
  .rs-col-xxl-push-21 {
    left: 87.5%; }
  .rs-col-xxl-offset-21 {
    margin-left: 87.5%; }
  .rs-col-xxl-22 {
    width: 91.66666667%; }
  .rs-col-xxl-pull-22 {
    right: 91.66666667%; }
  .rs-col-xxl-push-22 {
    left: 91.66666667%; }
  .rs-col-xxl-offset-22 {
    margin-left: 91.66666667%; }
  .rs-col-xxl-23 {
    width: 95.83333333%; }
  .rs-col-xxl-pull-23 {
    right: 95.83333333%; }
  .rs-col-xxl-push-23 {
    left: 95.83333333%; }
  .rs-col-xxl-offset-23 {
    margin-left: 95.83333333%; }
  .rs-col-xxl-24 {
    width: 100%; }
  .rs-col-xxl-pull-24 {
    right: 100%; }
  .rs-col-xxl-push-24 {
    left: 100%; }
  .rs-col-xxl-offset-24 {
    margin-left: 100%; }
  .rs-col-xxl-pull-0 {
    right: auto; }
  .rs-col-xxl-push-0 {
    left: auto; } }

@media (max-width: 575px) {
  .rs-hidden-xs {
    display: none; } }

@media (min-width: 576px) and (max-width: 767px) {
  .rs-hidden-sm {
    display: none; } }

@media (min-width: 768px) and (max-width: 991px) {
  .rs-hidden-md {
    display: none; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .rs-hidden-lg {
    display: none; } }

@media (min-width: 1200px) and (max-width: 1399px) {
  .rs-hidden-xl {
    display: none; } }

@media (min-width: 1400px) {
  .rs-hidden-xxl {
    display: none; } }

.rs-header {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto; }

.rs-form-help-text {
  display: block;
  color: #8e8e93;
  color: var(--rs-text-secondary);
  min-height: 20px;
  line-height: 1.66666667;
  font-size: 12px; }

.rs-form-help-text-tooltip {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 10px;
  margin-top: 8px; }

.rs-btn-icon > .rs-icon {
  vertical-align: bottom; }

.rs-btn-icon-with-text > .rs-icon {
  position: absolute;
  top: 0;
  display: block;
  background-color: #f2f2f5;
  background-color: var(--rs-iconbtn-addon);
  -webkit-transition: color 0.15s ease-out, background-color 0.15s ease-out;
  transition: color 0.15s ease-out, background-color 0.15s ease-out; }

.rs-btn-icon-with-text:hover > .rs-icon,
.rs-btn-icon-with-text:focus > .rs-icon {
  background-color: #d9d9d9;
  background-color: var(--rs-iconbtn-activated-addon); }

.rs-btn-icon-with-text:active > .rs-icon,
.rs-btn-icon-with-text.rs-btn-active > .rs-icon {
  background-color: #c5c6c7;
  background-color: var(--rs-iconbtn-pressed-addon); }

.rs-btn-icon-with-text:disabled > .rs-icon,
.rs-btn-icon-with-text.rs-btn-disabled > .rs-icon {
  background-color: #f2f2f5;
  background-color: var(--rs-iconbtn-addon); }

.rs-theme-high-contrast .rs-btn-icon-with-text:disabled,
.rs-theme-high-contrast .rs-btn-icon-with-text.rs-btn-disabled {
  opacity: 0.5; }

.rs-btn-icon-with-text.rs-btn-primary > .rs-icon {
  background-color: #2589f5;
  background-color: var(--rs-iconbtn-primary-addon); }

.rs-btn-icon-with-text.rs-btn-primary:hover > .rs-icon,
.rs-btn-icon-with-text.rs-btn-primary:focus > .rs-icon {
  background-color: #1675e0;
  background-color: var(--rs-iconbtn-primary-activated-addon); }

.rs-btn-icon-with-text.rs-btn-primary:active > .rs-icon,
.rs-btn-icon-with-text.rs-btn-primary.rs-btn-active > .rs-icon {
  background-color: #0a5dc2;
  background-color: var(--rs-iconbtn-primary-pressed-addon); }

.rs-btn-icon-with-text.rs-btn-primary:disabled > .rs-icon,
.rs-btn-icon-with-text.rs-btn-primary.rs-btn-disabled > .rs-icon {
  background-color: #2589f5;
  background-color: var(--rs-iconbtn-primary-addon); }

.rs-theme-high-contrast .rs-btn-icon-with-text.rs-btn-primary:disabled,
.rs-theme-high-contrast .rs-btn-icon-with-text.rs-btn-primary.rs-btn-disabled {
  opacity: 0.5; }

.rs-btn-icon-circle {
  border-radius: 50% !important; }

.rs-input {
  display: block;
  width: 100%;
  color: #575757;
  color: var(--rs-text-primary);
  background-color: #fff;
  background-color: var(--rs-input-bg);
  background-image: none;
  border: 1px solid #e5e5ea;
  border: 1px solid var(--rs-border-primary);
  -webkit-transition: border-color ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s;
  border-radius: 6px;
  /* stylelint-disable */
  padding: 7px 11px;
  font-size: 14px;
  line-height: 1.42857143;
  /* stylelint-enable */ }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-input {
    height: 36px; } }

textarea.rs-input {
  height: auto; }

.rs-theme-high-contrast .rs-input {
  -webkit-transition: none;
  transition: none; }

.rs-input::-webkit-input-placeholder {
  color: #8e8e93;
  color: var(--rs-text-secondary); }

.rs-input::-moz-placeholder {
  color: #8e8e93;
  color: var(--rs-text-secondary); }

.rs-input::-ms-input-placeholder {
  color: #8e8e93;
  color: var(--rs-text-secondary); }

.rs-input:-ms-input-placeholder {
  color: #8e8e93;
  color: var(--rs-text-secondary); }

.rs-input::placeholder {
  color: #8e8e93;
  color: var(--rs-text-secondary); }

.rs-input:focus,
.rs-input:hover:not(:disabled) {
  border-color: #3498ff;
  border-color: var(--rs-input-focus-border); }

.rs-input:focus {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring); }

.rs-theme-high-contrast .rs-input:focus {
  outline-offset: 2px; }

.rs-input:disabled {
  background-color: #f7f7fa;
  background-color: var(--rs-input-disabled-bg);
  color: #c5c6c7;
  color: var(--rs-text-disabled); }

textarea.rs-input {
  overflow: auto;
  resize: vertical; }

.rs-input-xs {
  /* stylelint-disable */
  padding: 1px 11px;
  font-size: 12px;
  line-height: 1.66666667;
  /* stylelint-enable */ }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-input-xs {
    height: 24px; } }

textarea.rs-input-xs {
  height: auto; }

.rs-input-lg {
  /* stylelint-disable */
  padding: 9px 11px;
  font-size: 16px;
  line-height: 1.375;
  /* stylelint-enable */ }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-input-lg {
    height: 42px; } }

textarea.rs-input-lg {
  height: auto; }

.rs-input-sm {
  /* stylelint-disable */
  padding: 4px 11px;
  font-size: 14px;
  line-height: 1.42857143;
  /* stylelint-enable */ }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-input-sm {
    height: 30px; } }

textarea.rs-input-sm {
  height: auto; }

.rs-input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  border-radius: 6px;
  -webkit-transition: border-color ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s;
  width: 100%;
  cursor: text;
  color: #575757;
  color: var(--rs-text-primary);
  border: 1px solid #e5e5ea;
  border: 1px solid var(--rs-border-primary); }

.rs-theme-high-contrast .rs-input-group {
  -webkit-transition: none;
  transition: none; }

.rs-input-group:not(.rs-input-group-disabled):hover,
.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus,
.rs-input-group:focus-within {
  border-color: #3498ff;
  border-color: var(--rs-input-focus-border); }

.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus,
.rs-input-group:focus-within {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring); }

.rs-theme-high-contrast .rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus,
.rs-theme-high-contrast .rs-input-group:focus-within {
  outline-offset: 2px; }

.rs-input-group .rs-input-group {
  outline: none !important; }

.rs-input-group .rs-input-number,
.rs-input-group .rs-input-group-addon,
.rs-input-group .rs-input-group-btn,
.rs-input-group .rs-picker-date .rs-picker-toggle {
  border: none;
  border-radius: 0;
  outline: none; }

.rs-input-group:not(.rs-input-group-inside) .rs-input {
  border: none;
  border-radius: 0;
  outline: none; }

.rs-input-group:not(.rs-input-group-inside) .rs-input-number:not(:last-child) .rs-input-number-btn-group-vertical {
  border-radius: 0; }

.rs-input-group:not(.rs-input-group-inside) .rs-input-number:not(:last-child) .rs-input-number-touchspin-up {
  border-top-right-radius: 0; }

.rs-input-group:not(.rs-input-group-inside) .rs-input-number:not(:last-child) .rs-input-number-touchspin-down {
  border-bottom-right-radius: 0; }

.rs-input-group:not(.rs-input-group-inside) .rs-input-number:not(:first-child) .rs-input {
  border-radius: 0 !important; }

.rs-input-group:not(.rs-input-group-inside) > :first-child,
.rs-input-group:not(.rs-input-group-inside) .rs-auto-complete:first-child .rs-input,
.rs-input-group:not(.rs-input-group-inside) .rs-picker-date:first-child .rs-picker-toggle,
.rs-input-group:not(.rs-input-group-inside) .rs-form-control-wrapper:first-child > .rs-input {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px; }

.rs-input-group:not(.rs-input-group-inside) > :last-child,
.rs-input-group:not(.rs-input-group-inside) .rs-auto-complete:last-child .rs-input,
.rs-input-group:not(.rs-input-group-inside) .rs-picker-date:last-child .rs-picker-toggle,
.rs-input-group:not(.rs-input-group-inside) .rs-form-control-wrapper:last-child > .rs-input {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px; }

.rs-input-group > .rs-input,
.rs-input-group > .rs-form-control-wrapper,
.rs-input-group > .rs-auto-complete {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto; }

.rs-input-group .rs-form-control-wrapper > .rs-input {
  width: 100%; }

.rs-input-group > .rs-input,
.rs-input-group > .rs-auto-complete {
  position: relative;
  z-index: 2; }

.rs-input-group > .rs-input:focus {
  z-index: 3; }

.rs-input-group input.rs-input ~ .rs-input-group-addon,
.rs-input-group .rs-form-control-wrapper ~ .rs-input-group-addon {
  border-left: none;
  left: auto;
  right: 0; }

.rs-input-group input.rs-input ~ .rs-input-group-addon:not(:last-child),
.rs-input-group .rs-form-control-wrapper ~ .rs-input-group-addon:not(:last-child) {
  border-right: 0; }

.rs-input-group.rs-input-group-inside {
  width: 100%; }

.rs-input-group.rs-input-group-inside .rs-input {
  display: block;
  width: 100%;
  border: none;
  outline: none;
  padding-right: 36px; }

.rs-input-group.rs-input-group-inside .rs-input-group-btn,
.rs-input-group.rs-input-group-inside .rs-input-group-addon {
  z-index: 4;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto; }

.rs-input-group.rs-input-group-inside .rs-input-group-btn {
  color: #8e8e93;
  color: var(--rs-btn-subtle-text);
  background-color: transparent;
  border: none;
  color: inherit;
  position: absolute;
  height: 34px;
  padding: 7px 11px;
  border-radius: 0 6px 6px 0; }

.rs-input-group.rs-input-group-inside .rs-input-group-btn:hover,
.rs-input-group.rs-input-group-inside .rs-input-group-btn:focus {
  color: #575757;
  color: var(--rs-btn-subtle-hover-text);
  background-color: #e5e5ea;
  background-color: var(--rs-btn-subtle-hover-bg); }

.rs-input-group.rs-input-group-inside .rs-input-group-btn:active,
.rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-active {
  color: #272c36;
  color: var(--rs-btn-subtle-active-text);
  background-color: #e5e5ea;
  background-color: var(--rs-btn-subtle-active-bg); }

.rs-input-group.rs-input-group-inside .rs-input-group-btn:disabled,
.rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-disabled {
  color: #c5c6c7;
  color: var(--rs-btn-subtle-disabled-text);
  background: none; }

.rs-theme-high-contrast .rs-input-group.rs-input-group-inside .rs-input-group-btn:disabled,
.rs-theme-high-contrast .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-disabled {
  opacity: 0.5; }

.rs-input-group.rs-input-group-inside .rs-input-group-btn > .rs-icon {
  font-size: 14px;
  line-height: 1.42857143; }

.rs-input-group.rs-input-group-inside .rs-input-group-btn:focus {
  outline: none; }

.rs-input-group.rs-input-group-inside .rs-input-group-btn:first-child {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px; }

.rs-input-group.rs-input-group-inside .rs-input-group-btn:last-child {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px; }

.rs-input-group.rs-input-group-inside .rs-input-group-addon {
  top: 0;
  background: none;
  border: none;
  padding: 10px 12px; }

.rs-input-group.rs-input-group-inside .rs-input-group-addon.rs-input-group-btn {
  padding: 8px 13px; }

.rs-input-group.rs-input-group-inside .rs-input-group-btn ~ input.rs-input,
.rs-input-group.rs-input-group-inside .rs-input-group-addon ~ input.rs-input,
.rs-input-group.rs-input-group-inside .rs-input-group-btn ~ .rs-auto-complete > input.rs-input,
.rs-input-group.rs-input-group-inside .rs-input-group-addon ~ .rs-auto-complete > input.rs-input,
.rs-input-group.rs-input-group-inside .rs-input-group-addon ~ .rs-form-control-wrapper > input.rs-input,
.rs-input-group.rs-input-group-inside .rs-input-group-btn ~ .rs-form-control-wrapper > input.rs-input {
  padding-left: 0;
  padding-right: 12px; }

.rs-input-group.rs-input-group-inside input.rs-input ~ .rs-input-group-btn,
.rs-input-group.rs-input-group-inside .rs-auto-complete ~ .rs-input-group-addon,
.rs-input-group.rs-input-group-inside .rs-form-control-wrapper ~ .rs-input-group-addon,
.rs-input-group.rs-input-group-inside .rs-form-control-wrapper ~ .rs-input-group-btn {
  left: inherit;
  right: 0; }

.rs-input-group.rs-input-group-inside .rs-auto-complete ~ .rs-input-group-btn,
.rs-input-group.rs-input-group-inside .rs-form-control-wrapper ~ .rs-input-group-btn {
  left: inherit;
  right: 1px; }

.rs-input-group-disabled {
  background-color: #f7f7fa;
  background-color: var(--rs-input-disabled-bg);
  color: #c5c6c7;
  color: var(--rs-text-disabled);
  cursor: not-allowed; }

.rs-input-group-disabled .rs-input,
.rs-input-group-disabled .rs-input-group-btn,
.rs-input-group-disabled .rs-input-group-addon {
  color: inherit; }

.rs-input-group-lg > .rs-input {
  /* stylelint-disable */
  padding: 9px 11px;
  font-size: 16px;
  line-height: 1.375;
  /* stylelint-enable */ }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-input-group-lg > .rs-input {
    height: 42px; } }

textarea.rs-input-group-lg > .rs-input {
  height: auto; }

.rs-input-group-lg.rs-input-group-inside > .rs-input {
  padding-right: 46px; }

.rs-input-group-lg.rs-input-group > .rs-input {
  height: 40px; }

.rs-input-group-lg.rs-input-group > .rs-input-group-addon {
  height: 40px; }

.rs-input-group-lg.rs-input-group > .rs-input-group-addon > .rs-icon {
  font-size: inherit; }

.rs-input-group-lg.rs-input-group:not(.rs-input-group-inside) > .rs-input-group-addon {
  padding-top: 8px;
  padding-bottom: 8px; }

.rs-input-group-md > .rs-input {
  /* stylelint-disable */
  padding: 7px 11px;
  font-size: 14px;
  line-height: 1.42857143;
  /* stylelint-enable */ }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-input-group-md > .rs-input {
    height: 36px; } }

textarea.rs-input-group-md > .rs-input {
  height: auto; }

.rs-input-group-md.rs-input-group-inside > .rs-input {
  padding-right: 36px; }

.rs-input-group-md.rs-input-group > .rs-input {
  height: 34px; }

.rs-input-group-md.rs-input-group > .rs-input-group-addon {
  height: 34px; }

.rs-input-group-md.rs-input-group > .rs-input-group-addon > .rs-icon {
  font-size: inherit; }

.rs-input-group-md.rs-input-group:not(.rs-input-group-inside) > .rs-input-group-addon {
  padding-top: 6px;
  padding-bottom: 6px; }

.rs-input-group-sm > .rs-input {
  /* stylelint-disable */
  padding: 4px 11px;
  font-size: 14px;
  line-height: 1.42857143;
  /* stylelint-enable */ }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-input-group-sm > .rs-input {
    height: 30px; } }

textarea.rs-input-group-sm > .rs-input {
  height: auto; }

.rs-input-group-sm.rs-input-group-inside > .rs-input {
  padding-right: 30px; }

.rs-input-group-sm.rs-input-group > .rs-input {
  height: 28px; }

.rs-input-group-sm.rs-input-group > .rs-input-group-addon {
  height: 28px; }

.rs-input-group-sm.rs-input-group > .rs-input-group-addon > .rs-icon {
  font-size: inherit; }

.rs-input-group-sm.rs-input-group:not(.rs-input-group-inside) > .rs-input-group-addon {
  padding-top: 3px;
  padding-bottom: 3px; }

.rs-input-group-xs > .rs-input {
  /* stylelint-disable */
  padding: 1px 11px;
  font-size: 12px;
  line-height: 1.66666667;
  /* stylelint-enable */ }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-input-group-xs > .rs-input {
    height: 24px; } }

textarea.rs-input-group-xs > .rs-input {
  height: auto; }

.rs-input-group-xs.rs-input-group-inside > .rs-input {
  padding-right: 26px; }

.rs-input-group-xs.rs-input-group > .rs-input {
  height: 22px; }

.rs-input-group-xs.rs-input-group > .rs-input-group-addon {
  height: 22px; }

.rs-input-group-xs.rs-input-group > .rs-input-group-addon > .rs-icon {
  font-size: inherit; }

.rs-input-group.rs-input-group-lg > .rs-input-group-addon {
  /* stylelint-disable */
  padding: 9px 11px;
  font-size: 16px;
  line-height: 1.375;
  /* stylelint-enable */
  min-width: 44px;
  padding-left: 15px;
  padding-right: 15px; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-input-group.rs-input-group-lg > .rs-input-group-addon {
    height: 42px; } }

textarea.rs-input-group.rs-input-group-lg > .rs-input-group-addon {
  height: auto; }

.rs-input-group.rs-input-group-md > .rs-input-group-addon {
  /* stylelint-disable */
  padding: 7px 11px;
  font-size: 14px;
  line-height: 1.42857143;
  /* stylelint-enable */
  min-width: 36px;
  padding-left: 11px;
  padding-right: 11px; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-input-group.rs-input-group-md > .rs-input-group-addon {
    height: 36px; } }

textarea.rs-input-group.rs-input-group-md > .rs-input-group-addon {
  height: auto; }

.rs-input-group.rs-input-group-sm > .rs-input-group-addon {
  /* stylelint-disable */
  padding: 4px 11px;
  font-size: 14px;
  line-height: 1.42857143;
  /* stylelint-enable */
  min-width: 32px;
  padding-left: 9px;
  padding-right: 9px; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-input-group.rs-input-group-sm > .rs-input-group-addon {
    height: 30px; } }

textarea.rs-input-group.rs-input-group-sm > .rs-input-group-addon {
  height: auto; }

.rs-input-group.rs-input-group-xs > .rs-input-group-addon {
  /* stylelint-disable */
  padding: 1px 11px;
  font-size: 12px;
  line-height: 1.66666667;
  /* stylelint-enable */
  min-width: 28px;
  padding-left: 7px;
  padding-right: 7px; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-input-group.rs-input-group-xs > .rs-input-group-addon {
    height: 24px; } }

textarea.rs-input-group.rs-input-group-xs > .rs-input-group-addon {
  height: auto; }

.rs-input-group-inside.rs-input-group-lg > .rs-input-group-btn {
  /* stylelint-disable */
  padding: 9px 11px;
  font-size: 16px;
  line-height: 1.375;
  /* stylelint-enable */
  height: 40px;
  padding: 9px 15px;
  border-radius: 0 6px 6px 0; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-input-group-inside.rs-input-group-lg > .rs-input-group-btn {
    height: 42px; } }

textarea.rs-input-group-inside.rs-input-group-lg > .rs-input-group-btn {
  height: auto; }

.rs-input-group-inside.rs-input-group-lg > .rs-input-group-btn > .rs-icon {
  font-size: 16px;
  line-height: 1.375; }

.rs-input-group-inside.rs-input-group-md > .rs-input-group-btn {
  /* stylelint-disable */
  font-size: 14px;
  line-height: 1.42857143;
  /* stylelint-enable */
  height: 34px;
  padding: 7px 11px;
  border-radius: 0 6px 6px 0; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-input-group-inside.rs-input-group-md > .rs-input-group-btn {
    height: 36px; } }

textarea.rs-input-group-inside.rs-input-group-md > .rs-input-group-btn {
  height: auto; }

.rs-input-group-inside.rs-input-group-md > .rs-input-group-btn > .rs-icon {
  font-size: 14px;
  line-height: 1.42857143; }

.rs-input-group-inside.rs-input-group-sm > .rs-input-group-btn {
  /* stylelint-disable */
  padding: 4px 11px;
  font-size: 14px;
  line-height: 1.42857143;
  /* stylelint-enable */
  height: 28px;
  padding: 4px 9px;
  border-radius: 0 6px 6px 0; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-input-group-inside.rs-input-group-sm > .rs-input-group-btn {
    height: 30px; } }

textarea.rs-input-group-inside.rs-input-group-sm > .rs-input-group-btn {
  height: auto; }

.rs-input-group-inside.rs-input-group-sm > .rs-input-group-btn > .rs-icon {
  font-size: 12px;
  line-height: 1.66666667; }

.rs-input-group-inside.rs-input-group-xs > .rs-input-group-btn {
  /* stylelint-disable */
  padding: 1px 11px;
  font-size: 12px;
  line-height: 1.66666667;
  /* stylelint-enable */
  height: 22px;
  padding: 1px 7px;
  border-radius: 0 6px 6px 0; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-input-group-inside.rs-input-group-xs > .rs-input-group-btn {
    height: 24px; } }

textarea.rs-input-group-inside.rs-input-group-xs > .rs-input-group-btn {
  height: auto; }

.rs-input-group-inside.rs-input-group-xs > .rs-input-group-btn > .rs-icon {
  font-size: 12px;
  line-height: 1.66666667; }

.rs-input-group-addon:not(:first-child):not(:last-child),
.rs-input-group-btn:not(:first-child):not(:last-child),
.rs-input-group:not(.rs-input-group-inside) .rs-input:not(:first-child):not(:last-child) {
  border-radius: 0; }

.rs-input-group-addon {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  white-space: nowrap;
  vertical-align: middle;
  color: #575757;
  color: var(--rs-text-primary);
  padding: 8px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  text-align: center;
  background-color: #f7f7fa;
  background-color: var(--rs-btn-default-bg);
  border: 1px solid #e5e5ea;
  border: 1px solid var(--rs-border-primary);
  border-radius: 6px; }

.rs-input-group-addon.rs-input-sm {
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 6px; }

.rs-input-group-addon.rs-input-xs {
  padding: 8px 8px;
  font-size: 12px;
  border-radius: 6px; }

.rs-input-group-addon.rs-input-lg {
  padding: 10px 16px;
  font-size: 16px;
  border-radius: 6px; }

.rs-input-group-addon input[type='radio'],
.rs-input-group-addon input[type='checkbox'] {
  margin-top: 0; }

.rs-input-group-btn {
  position: relative;
  line-height: 1;
  white-space: nowrap;
  border-radius: 0; }

.rs-input-group-btn > .rs-btn {
  position: relative; }

.rs-input-group-btn > .rs-btn + .rs-btn {
  margin-left: -1px; }

.rs-input-group-btn > .rs-btn:hover,
.rs-input-group-btn > .rs-btn:focus,
.rs-input-group-btn > .rs-btn:active {
  z-index: 2; }

.rs-input-group-btn:first-child > .rs-btn,
.rs-input-group-btn:first-child > .rs-btn-group {
  margin-right: -1px;
  border-right: medium none; }

.rs-input-group-btn:last-child > .rs-btn,
.rs-input-group-btn:last-child > .rs-btn-group {
  z-index: 2;
  margin-left: -1px; }

.rs-input-number {
  background-color: #fff;
  background-color: var(--rs-input-bg); }

.rs-input-number-btn-group-vertical {
  position: relative;
  width: 20px;
  -ms-flex: 0 0 20px;
  flex: 0 0 20px;
  display: block; }

.rs-input-number-btn-group-vertical > .rs-btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%;
  position: relative;
  border: none; }

.rs-input-number-touchspin-up,
.rs-input-number-touchspin-down {
  position: relative;
  border-radius: 0;
  padding: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  height: 17px; }

.rs-input-number-touchspin-up > .rs-icon,
.rs-input-number-touchspin-down > .rs-icon {
  height: 17px; }

.rs-input-group-lg .rs-input-number-touchspin-up,
.rs-input-group-lg .rs-input-number-touchspin-down {
  height: 20px; }

.rs-input-group-lg .rs-input-number-touchspin-up > .rs-icon,
.rs-input-group-lg .rs-input-number-touchspin-down > .rs-icon {
  height: 20px; }

.rs-input-group-sm .rs-input-number-touchspin-up,
.rs-input-group-sm .rs-input-number-touchspin-down {
  height: 14px; }

.rs-input-group-sm .rs-input-number-touchspin-up > .rs-icon,
.rs-input-group-sm .rs-input-number-touchspin-down > .rs-icon {
  height: 14px; }

.rs-input-group-xs .rs-input-number-touchspin-up,
.rs-input-group-xs .rs-input-number-touchspin-down {
  height: 11px; }

.rs-input-group-xs .rs-input-number-touchspin-up > .rs-icon,
.rs-input-group-xs .rs-input-number-touchspin-down > .rs-icon {
  height: 11px; }

.rs-input-number-touchspin-up > .rs-icon,
.rs-input-number-touchspin-down > .rs-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 12px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center; }

.rs-input-number-btn-group-vertical:last-child .rs-input-number-touchspin-up {
  border-top-right-radius: 6px; }

.rs-input-number-btn-group-vertical:last-child .rs-input-number-touchspin-down {
  border-bottom-right-radius: 6px; }

.rs-input-number > .rs-input {
  border-bottom-left-radius: 6px !important;
  border-top-left-radius: 6px !important; }

.rs-input-number > .rs-input:disabled + .rs-input-number-btn-group-vertical .rs-input-number-touchspin-up,
.rs-input-number > .rs-input:disabled + .rs-input-number-btn-group-vertical .rs-input-number-touchspin-down {
  background-color: #f7f7fa;
  background-color: var(--rs-input-disabled-bg); }

.rs-input-number input[type='number'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield; }

.rs-input-number input[type='number']::-webkit-inner-spin-button {
  /* stylelint-disable */
  -webkit-appearance: none; }

.rs-picker-input {
  position: relative;
  border: 1px solid #e5e5ea;
  border: 1px solid var(--rs-border-primary);
  border-radius: 6px;
  -webkit-transition: border-color ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s;
  background-color: #fff;
  background-color: var(--rs-input-bg); }

.rs-picker-input .rs-picker-toggle {
  border: none !important;
  background: transparent !important;
  height: 34px;
  cursor: text; }

.rs-picker-input .rs-picker-toggle-clean,
.rs-picker-input .rs-picker-toggle-caret {
  top: 7px; }

.rs-picker-input .rs-picker-toggle .rs-ripple-pond {
  display: none; }

.rs-picker-input:not(.rs-picker-disabled) .rs-picker-toggle {
  position: absolute !important; }

.rs-picker-input .rs-picker-toggle.rs-btn-lg {
  height: 40px; }

.rs-picker-input .rs-picker-toggle.rs-btn-md {
  height: 34px; }

.rs-picker-input .rs-picker-toggle.rs-btn-sm {
  height: 28px; }

.rs-picker-input .rs-picker-toggle.rs-btn-xs {
  height: 22px; }

.rs-picker-tag-wrapper {
  margin-right: 32px;
  min-height: 34px; }

.rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper {
  min-height: 40px; }

.rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper {
  min-height: 28px; }

.rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper {
  min-height: 22px; }

.rs-picker-cleanable .rs-picker-tag-wrapper {
  margin-right: 44px; }

.rs-picker-search {
  border: none;
  width: 100%; }

.rs-picker-search-input {
  /* stylelint-disable */
  padding: 7px 11px;
  font-size: 14px;
  line-height: 1.42857143;
  /* stylelint-enable */
  background: none;
  outline: none;
  border: none;
  width: 100%;
  position: relative;
  padding-right: 0; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-picker-search-input {
    height: 36px; } }

textarea.rs-picker-search-input {
  height: auto; }

.rs-picker-tag .rs-picker-search-input {
  font-size: 14px;
  line-height: 1.42857143;
  padding: 2px 12px 2px 11px; }

.rs-picker-tag .rs-picker-search-input > input {
  background: none;
  outline: none;
  border: none;
  width: 100%; }

.rs-picker-focused .rs-picker-search-input {
  z-index: 6; }

.rs-list {
  position: relative;
  -webkit-box-shadow: 0 1px 0 #e5e5ea, 0 -1px 0 #e5e5ea;
  -webkit-box-shadow: 0 1px 0 var(--rs-list-border), 0 -1px 0 var(--rs-list-border);
  box-shadow: 0 1px 0 #e5e5ea, 0 -1px 0 #e5e5ea;
  box-shadow: 0 1px 0 var(--rs-list-border), 0 -1px 0 var(--rs-list-border);
  overflow-x: hidden;
  overflow-y: auto; }

.rs-list-bordered {
  border-radius: 6px;
  -webkit-box-shadow: 0 0 0 1px #e5e5ea;
  -webkit-box-shadow: 0 0 0 1px var(--rs-list-border);
  box-shadow: 0 0 0 1px #e5e5ea;
  box-shadow: 0 0 0 1px var(--rs-list-border); }

.rs-list-hover .rs-list-item:hover {
  background-color: #f2faff;
  background-color: var(--rs-list-hover-bg); }

.rs-list-sortable .rs-list-item {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

.rs-list-sortable .rs-list-item-disabled,
.rs-list-sortable .rs-list-item-disabled:active {
  cursor: not-allowed; }

.rs-list-item {
  position: relative; }

.rs-list-item-bordered {
  padding-left: 20px;
  padding-right: 20px; }

.rs-list-item-lg {
  padding-top: 20px;
  padding-bottom: 20px; }

.rs-list-item-md {
  padding-top: 13px;
  padding-bottom: 13px; }

.rs-list-item-sm {
  padding-top: 8px;
  padding-bottom: 8px; }

/* rtl:begin:ignore */
/* stylelint-disable-next-line */
.rs-list-item {
  background-color: #fff;
  background-color: var(--rs-list-bg);
  -webkit-box-shadow: 0 -1px 0 #e5e5ea, 0 1px 0 #e5e5ea;
  -webkit-box-shadow: 0 -1px 0 var(--rs-list-border), 0 1px 0 var(--rs-list-border);
  box-shadow: 0 -1px 0 #e5e5ea, 0 1px 0 #e5e5ea;
  box-shadow: 0 -1px 0 var(--rs-list-border), 0 1px 0 var(--rs-list-border); }

.rs-list-item-helper {
  position: absolute;
  background-color: #fff;
  background-color: var(--rs-list-bg);
  -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  width: calc(100% - 2px);
  border-radius: 6px;
  top: 0;
  left: 0;
  z-index: 99;
  border: none;
  cursor: move; }

.rs-modal-open .rs-list-item-helper {
  z-index: 1149; }

.rs-drawer-open .rs-list-item-helper {
  z-index: 1149; }

.rs-list-item-holder {
  background-color: rgba(242, 250, 255, 0.5);
  background-color: var(--rs-list-placeholder-bg);
  visibility: hidden; }

.rs-list-item-holder::after {
  content: '';
  position: absolute;
  visibility: visible;
  top: 1px;
  left: 1px;
  width: calc(100% - 2 * 1px);
  height: calc(100% - 2 * 1px);
  border: 1px #3498ff dashed;
  border: 1px var(--rs-list-placeholder-border) dashed; }

/* rtl:end:ignore */
.rs-loader {
  display: inline-block; }

.rs-loader::before,
.rs-loader::after {
  content: ' ';
  display: table; }

.rs-loader::after {
  clear: both; }

.rs-loader .rs-loader-spin {
  width: 18px;
  height: 18px; }

.rs-loader .rs-loader-spin::before,
.rs-loader .rs-loader-spin::after {
  width: 18px;
  height: 18px; }

.rs-loader .rs-loader-content {
  font-size: 12px;
  line-height: 1.5; }

.rs-loader .rs-loader-vertical .rs-loader .rs-loader {
  height: 48px; }

.rs-loader .rs-loader-vertical .rs-loader .rs-loader-content {
  line-height: 1.66666667; }

.rs-loader-spin {
  position: relative;
  display: inline-block;
  float: left; }

.rs-loader-spin::before,
.rs-loader-spin::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  display: block;
  border-radius: 50%; }

.rs-loader-spin::before {
  border: 3px solid rgba(247, 247, 250, 0.8);
  border: 3px solid var(--rs-loader-ring); }

.rs-loader-spin::after {
  border-width: 3px;
  border-style: solid;
  border-color: #a6a6a6 transparent transparent;
  border-color: var(--rs-loader-rotor) transparent transparent;
  -webkit-animation: loaderSpin 0.6s infinite linear;
  animation: loaderSpin 0.6s infinite linear; }

.rs-loader-content {
  float: left;
  display: inline-block; }

.rs-loader-spin + .rs-loader-content {
  margin-left: 12px; }

.rs-loader-xs .rs-loader-spin + .rs-loader-content {
  margin-left: 10px; }

.rs-loader-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  background: var(--rs-loader-backdrop); }

.rs-loader-vertical {
  width: auto;
  display: inline-block; }

.rs-loader-vertical .rs-loader {
  -ms-flex-direction: column;
  flex-direction: column; }

.rs-loader-vertical .rs-loader-spin {
  margin: 0 auto; }

.rs-loader-vertical .rs-loader-content {
  line-height: 1.66666667;
  text-align: center; }

.rs-loader-vertical .rs-loader-spin,
.rs-loader-vertical .rs-loader-content {
  display: block;
  float: none; }

.rs-loader-vertical .rs-loader-spin + .rs-loader-content {
  margin-left: 0;
  margin-top: 10px; }

.rs-loader-inverse .rs-loader-content {
  color: #f7f7fa;
  color: var(--rs-text-inverse); }

.rs-loader-inverse .rs-loader-backdrop {
  background: rgba(39, 44, 54, 0.83);
  background: var(--rs-loader-backdrop-inverse); }

.rs-loader-inverse .rs-loader-spin::before {
  border-color: rgba(247, 247, 250, 0.3);
  border-color: var(--rs-loader-ring-inverse); }

.rs-loader-inverse .rs-loader-spin::after {
  border-top-color: #fff;
  border-top-color: var(--rs-loader-rotor-inverse); }

.rs-loader-speed-fast .rs-loader-spin::after {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s; }

.rs-loader-speed-normal .rs-loader-spin::after {
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s; }

.rs-loader-speed-slow .rs-loader-spin::after {
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s; }

.rs-loader-center,
.rs-loader-backdrop-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%; }

.rs-loader-center .rs-loader,
.rs-loader-backdrop-wrapper .rs-loader {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%; }

.rs-loader-center:not(.rs-loader-vertical) .rs-loader,
.rs-loader-backdrop-wrapper:not(.rs-loader-vertical) .rs-loader {
  display: -ms-flexbox;
  display: flex; }

.rs-loader-center.rs-loader-vertical .rs-loader,
.rs-loader-backdrop-wrapper.rs-loader-vertical .rs-loader {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%; }

.rs-loader-center .rs-loader-content,
.rs-loader-backdrop-wrapper .rs-loader-content {
  z-index: 1; }

.rs-loader-lg .rs-loader-spin {
  width: 64px;
  height: 64px; }

.rs-loader-lg .rs-loader-spin::before,
.rs-loader-lg .rs-loader-spin::after {
  width: 64px;
  height: 64px; }

.rs-loader-lg .rs-loader-content {
  font-size: 16px;
  line-height: 4; }

.rs-loader-lg .rs-loader-vertical .rs-loader-lg .rs-loader {
  height: 94px; }

.rs-loader-lg .rs-loader-vertical .rs-loader-lg .rs-loader-content {
  line-height: 1.25; }

.rs-loader-md .rs-loader-spin {
  width: 36px;
  height: 36px; }

.rs-loader-md .rs-loader-spin::before,
.rs-loader-md .rs-loader-spin::after {
  width: 36px;
  height: 36px; }

.rs-loader-md .rs-loader-content {
  font-size: 14px;
  line-height: 2.57142857; }

.rs-loader-md .rs-loader-vertical .rs-loader-md .rs-loader {
  height: 66px; }

.rs-loader-md .rs-loader-vertical .rs-loader-md .rs-loader-content {
  line-height: 1.42857143; }

.rs-loader-sm .rs-loader-spin {
  width: 18px;
  height: 18px; }

.rs-loader-sm .rs-loader-spin::before,
.rs-loader-sm .rs-loader-spin::after {
  width: 18px;
  height: 18px; }

.rs-loader-sm .rs-loader-content {
  font-size: 14px;
  line-height: 1.28571429; }

.rs-loader-sm .rs-loader-vertical .rs-loader-sm .rs-loader {
  height: 48px; }

.rs-loader-sm .rs-loader-vertical .rs-loader-sm .rs-loader-content {
  line-height: 1.42857143; }

.rs-loader-xs .rs-loader-spin {
  width: 16px;
  height: 16px; }

.rs-loader-xs .rs-loader-spin::before,
.rs-loader-xs .rs-loader-spin::after {
  width: 16px;
  height: 16px; }

.rs-loader-xs .rs-loader-content {
  font-size: 12px;
  line-height: 1.33333333; }

.rs-loader-xs .rs-loader-vertical .rs-loader-xs .rs-loader {
  height: 46px; }

.rs-loader-xs .rs-loader-vertical .rs-loader-xs .rs-loader-content {
  line-height: 1.66666667; }

.rs-loader-wrapper {
  display: inline-block;
  width: auto;
  line-height: 0; }

@-webkit-keyframes loaderSpin {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes loaderSpin {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes notificationMoveIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8); }
  100% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1); } }

@keyframes notificationMoveIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8); }
  100% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1); } }

@-webkit-keyframes notificationMoveInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes notificationMoveInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@-webkit-keyframes notificationMoveInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes notificationMoveInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@-webkit-keyframes notificationMoveOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    max-height: 100px; }
  100% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    max-height: 0;
    overflow: hidden; } }

@keyframes notificationMoveOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    max-height: 100px; }
  100% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    max-height: 0;
    overflow: hidden; } }

.rs-message {
  border-radius: 6px;
  font-size: 14px;
  line-height: 1.42857143;
  position: relative;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards; }

.rs-message-container {
  padding: 20px;
  padding-right: 28px;
  display: -ms-flexbox;
  display: flex; }

.rs-message-icon-wrapper {
  -ms-flex-item-align: center;
  align-self: center;
  font-size: 0;
  margin-right: 10px; }

.rs-message-icon-wrapper .rs-icon {
  font-size: 12px;
  -ms-flex-align: center;
  align-items: center; }

.rs-message-content {
  -ms-flex-positive: 1;
  flex-grow: 1; }

.rs-message-header {
  color: #272c36;
  color: var(--rs-text-heading);
  line-height: 1.71428571;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%; }

.rs-message-header + .rs-message-body {
  margin-top: 4px; }

.rs-message-body {
  color: #575757;
  color: var(--rs-text-primary); }

.rs-message.rs-message-has-title .rs-message-icon-wrapper {
  -ms-flex-item-align: start;
  align-self: flex-start; }

.rs-message.rs-message-has-title .rs-message-icon-wrapper .rs-icon {
  font-size: 24px;
  line-height: 1; }

.rs-message:not(.rs-message-hiding) + .rs-message {
  margin-top: 10px; }

.rs-message:not(.rs-message-hiding) + .rs-message.rs-message-hiding:last-child {
  -webkit-transition: margin-top 0.1s linear 0.3s;
  transition: margin-top 0.1s linear 0.3s;
  margin-top: 0; }

.rs-message.rs-message-hiding {
  -webkit-animation: notificationMoveOut 0.3s ease-in forwards;
  animation: notificationMoveOut 0.3s ease-in forwards; }

.rs-theme-dark .rs-message .rs-btn-link,
.rs-theme-dark .rs-message a {
  color: inherit;
  text-decoration: underline; }

.rs-message-full {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 0; }

.rs-message-success {
  background-color: #eeffed;
  background-color: var(--rs-message-success-bg); }

.rs-message-success .rs-message-header {
  color: #272c36;
  color: var(--rs-message-success-header); }

.rs-message-success .rs-message-body {
  color: #575757;
  color: var(--rs-message-success-text); }

.rs-message-success .rs-message-icon-wrapper > .rs-icon,
.rs-message-success .rs-btn-close {
  color: #4caf50;
  color: var(--rs-message-success-icon); }

.rs-theme-high-contrast .rs-message-success {
  border: 1px solid var(--rs-message-success-border); }

.rs-message-info {
  background-color: #f0f9ff;
  background-color: var(--rs-message-info-bg); }

.rs-message-info .rs-message-header {
  color: #272c36;
  color: var(--rs-message-info-header); }

.rs-message-info .rs-message-body {
  color: #575757;
  color: var(--rs-message-info-text); }

.rs-message-info .rs-message-icon-wrapper > .rs-icon,
.rs-message-info .rs-btn-close {
  color: #2196f3;
  color: var(--rs-message-info-icon); }

.rs-theme-high-contrast .rs-message-info {
  border: 1px solid var(--rs-message-info-border); }

.rs-message-warning {
  background-color: #fffaf2;
  background-color: var(--rs-message-warning-bg); }

.rs-message-warning .rs-message-header {
  color: #272c36;
  color: var(--rs-message-warning-header); }

.rs-message-warning .rs-message-body {
  color: #575757;
  color: var(--rs-message-warning-text); }

.rs-message-warning .rs-message-icon-wrapper > .rs-icon,
.rs-message-warning .rs-btn-close {
  color: #ffb300;
  color: var(--rs-message-warning-icon); }

.rs-theme-high-contrast .rs-message-warning {
  border: 1px solid var(--rs-message-warning-border); }

.rs-message-error {
  background-color: #fff2f2;
  background-color: var(--rs-message-error-bg); }

.rs-message-error .rs-message-header {
  color: #272c36;
  color: var(--rs-message-error-header); }

.rs-message-error .rs-message-body {
  color: #575757;
  color: var(--rs-message-error-text); }

.rs-message-error .rs-message-icon-wrapper > .rs-icon,
.rs-message-error .rs-btn-close {
  color: #f44336;
  color: var(--rs-message-error-icon); }

.rs-theme-high-contrast .rs-message-error {
  border: 1px solid var(--rs-message-error-border); }

.rs-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1049;
  background-color: rgba(39, 44, 54, 0.3);
  background-color: var(--rs-bg-backdrop); }

.rs-modal-backdrop.rs-anim-fade {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in;
  transition: opacity 0.3s ease-in; }

.rs-modal-backdrop.rs-anim-in {
  opacity: 1; }

.rs-modal-open {
  overflow: hidden; }

.rs-modal-wrapper {
  position: fixed;
  overflow: auto;
  z-index: 1050;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.rs-modal {
  display: none;
  overflow: visible;
  outline: 0;
  margin: 30px auto 0 auto;
  position: relative;
  width: auto;
  z-index: 1050; }

.rs-modal-lg {
  width: 968px;
  max-width: calc(100% - 10px); }

.rs-modal-md {
  width: 800px;
  max-width: calc(100% - 10px); }

.rs-modal-sm {
  width: 600px;
  max-width: calc(100% - 10px); }

.rs-modal-xs {
  width: 400px;
  max-width: calc(100% - 10px); }

.rs-modal-full {
  width: calc(100% - 120px);
  max-width: calc(100% - 10px); }

.rs-modal-shake .rs-modal-dialog {
  -webkit-animation: 0.3s linear shakeHead;
  animation: 0.3s linear shakeHead; }

.rs-modal-content {
  position: relative;
  background-color: #fff;
  background-color: var(--rs-bg-overlay);
  border: none;
  border: none;
  border: var(--rs-modal-border, none);
  border-radius: 6px;
  outline: 0;
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: var(--rs-modal-shadow);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  box-shadow: var(--rs-modal-shadow);
  padding: 20px; }

.rs-modal-header {
  padding-right: 20px; }

.rs-modal-header::before,
.rs-modal-header::after {
  content: ' ';
  display: table; }

.rs-modal-header::after {
  clear: both; }

.rs-modal-header .rs-modal-header-close {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 12px;
  color: #8e8e93;
  color: var(--rs-text-secondary);
  padding: 0; }

.rs-modal-header .rs-modal-header-close:hover {
  color: #575757;
  color: var(--rs-text-primary); }

.rs-modal-title {
  font-weight: normal;
  font-size: 16px;
  line-height: 1.25;
  display: block;
  color: #272c36;
  color: var(--rs-text-heading);
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%; }

.rs-modal-body {
  position: relative;
  margin-top: 20px;
  padding-bottom: 20px; }

.rs-modal-footer {
  text-align: right;
  border-top: none; }

.rs-modal-footer::before,
.rs-modal-footer::after {
  content: ' ';
  display: table; }

.rs-modal-footer::after {
  clear: both; }

.rs-modal-footer .rs-btn + .rs-btn {
  margin-left: 10px;
  margin-bottom: 0; }

.rs-modal-footer .rs-btn-group .rs-btn + .rs-btn {
  margin-left: -1px; }

.rs-modal-footer .rs-btn-block + .rs-btn-block {
  margin-left: 0; }

.rs-modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

.rs-picker-cascader-menu .rs-check-item .rs-checkbox-checker > label {
  padding-right: 26px; }

.rs-picker-cascader-menu .rs-check-item.rs-check-item-focus .rs-checkbox-checker > label,
.rs-picker-cascader-menu .rs-check-item.rs-checkbox-checked .rs-checkbox-checker > label {
  font-weight: bold; }

.rs-picker-cascader-menu-column.rs-picker-cascader-menu-column-uncheckable .rs-check-item .rs-checkbox-checker > label {
  padding-left: 12px; }

.rs-picker-multi-cascader-menu .rs-picker-cascader-col {
  padding: 0; }

.rs-picker-multi-cascader-menu .rs-picker-cascader-row {
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0; }

.rs-nav {
  position: relative;
  outline: 0; }

.rs-nav .rs-dropdown > .rs-dropdown-toggle {
  height: 36px;
  vertical-align: bottom; }

.rs-nav-default .rs-nav-item,
.rs-nav-tabs .rs-nav-item {
  overflow: hidden;
  position: relative;
  -webkit-transition: color 0.15s ease-out, background-color 0.15s ease-out;
  transition: color 0.15s ease-out, background-color 0.15s ease-out; }

@media not all and (min-resolution: 0.001dpcm) {
  .rs-nav-default .rs-nav-item,
  .rs-nav-tabs .rs-nav-item {
    /* stylelint-disable */
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    /* stylelint-enable */ } }

.rs-nav-item {
  padding: 8px 12px;
  cursor: pointer;
  color: #8e8e93;
  color: var(--rs-navs-text);
  -webkit-transition: color 0.15s ease-out, background-color 0.15s ease-out;
  transition: color 0.15s ease-out, background-color 0.15s ease-out;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  outline: none;
  text-decoration: none; }

@media not all and (min-resolution: 0.001dpcm) {
  .rs-nav-item {
    /* stylelint-disable */
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    /* stylelint-enable */ } }

.rs-nav-item:hover,
.rs-nav-item:focus,
.rs-nav-item:active {
  text-decoration: none; }

.rs-theme-high-contrast .rs-nav-item {
  -webkit-transition: none;
  transition: none; }

.rs-nav-item:hover,
.rs-nav-item:focus,
.rs-nav-item.rs-nav-item-focus {
  color: #575757;
  color: var(--rs-navs-text-hover); }

.rs-theme-high-contrast .rs-nav-item:hover,
.rs-theme-high-contrast .rs-nav-item:focus,
.rs-theme-high-contrast .rs-nav-item.rs-nav-item-focus {
  text-decoration: underline; }

.rs-nav-item:focus-visible {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  outline-width: 2px;
  outline-offset: 0;
  z-index: 2; }

.rs-theme-high-contrast .rs-nav-item:focus-visible {
  outline-offset: 2px; }

.rs-nav-item:active {
  color: #272c36;
  color: var(--rs-navs-text-active); }

.rs-theme-high-contrast .rs-nav-item:active {
  text-decoration: underline; }

.rs-nav-item.rs-nav-item-disabled {
  cursor: not-allowed;
  pointer-events: none;
  color: #c5c6c7;
  color: var(--rs-text-disabled); }

.rs-nav-item.rs-nav-item-active {
  position: relative;
  color: #1675e0;
  color: var(--rs-navs-selected);
  z-index: 1; }

.rs-theme-high-contrast .rs-nav-item.rs-nav-item-active {
  text-decoration: underline; }

.rs-nav-item-icon {
  margin-right: 6px; }

.rs-nav-item-caret {
  font-size: 16px;
  vertical-align: text-bottom;
  margin-left: 6px; }

.rs-nav-horizontal {
  white-space: nowrap; }

.rs-nav-horizontal > .rs-nav-item {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  vertical-align: top; }

.rs-nav-horizontal .rs-nav-bar {
  position: absolute;
  bottom: 0;
  width: 100%; }

.rs-nav-reversed.rs-nav-horizontal .rs-nav-bar {
  bottom: auto;
  top: 0; }

.rs-nav-vertical > .rs-nav-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center; }

.rs-nav-vertical > .rs-dropdown {
  width: 100%; }

.rs-nav-vertical > .rs-dropdown > .rs-dropdown-toggle {
  width: 100%;
  text-align: left;
  z-index: 0; }

.rs-nav-vertical .rs-nav-bar {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0; }

.rs-nav-reversed.rs-nav-vertical .rs-nav-bar {
  right: auto; }

.rs-nav-default .rs-nav-item {
  border-radius: 6px; }

.rs-nav-default .rs-nav-item:hover,
.rs-nav-default .rs-nav-item:focus {
  background: #e5e5ea;
  background: var(--rs-navs-bg-hover); }

.rs-nav-tabs .rs-nav-item:hover,
.rs-nav-tabs .rs-nav-item:focus {
  background: #e5e5ea;
  background: var(--rs-navs-bg-hover); }

.rs-nav-tabs .rs-nav-item.rs-nav-item-active {
  border: 1px solid #d9d9d9;
  border: 1px solid var(--rs-navs-tab-border);
  background-color: #fff;
  background-color: var(--rs-bg-card);
  z-index: 1; }

.rs-nav-tabs.rs-nav-horizontal > .rs-nav-item,
.rs-nav-tabs.rs-nav-horizontal > .rs-dropdown .rs-dropdown-toggle {
  border-radius: 6px 6px 0 0; }

.rs-nav-reversed.rs-nav-tabs.rs-nav-horizontal > .rs-nav-item,
.rs-nav-reversed.rs-nav-tabs.rs-nav-horizontal > .rs-dropdown .rs-dropdown-toggle {
  border-radius: 0 0 6px 6px; }

.rs-nav-tabs.rs-nav-horizontal .rs-nav-bar {
  border-top: 1px solid #d9d9d9;
  border-top: 1px solid var(--rs-navs-tab-border); }

.rs-nav-tabs.rs-nav-horizontal .rs-nav-item.rs-nav-item-active {
  border-bottom-width: 0; }

.rs-nav-reversed.rs-nav-tabs.rs-nav-horizontal .rs-nav-item.rs-nav-item-active {
  border-bottom-width: 1px;
  border-top-width: 0; }

.rs-nav-tabs.rs-nav-vertical > .rs-nav-item,
.rs-nav-tabs.rs-nav-vertical > .rs-dropdown .rs-dropdown-toggle {
  border-radius: 6px 0 0 6px; }

.rs-nav-reversed.rs-nav-tabs.rs-nav-vertical > .rs-nav-item,
.rs-nav-reversed.rs-nav-tabs.rs-nav-vertical > .rs-dropdown .rs-dropdown-toggle {
  border-radius: 0 6px 6px 0; }

.rs-nav-tabs.rs-nav-vertical .rs-nav-bar {
  width: 1px;
  background: #d9d9d9;
  background: var(--rs-navs-tab-border); }

.rs-nav-tabs.rs-nav-vertical .rs-nav-item.rs-nav-item-active {
  border-right-width: 0; }

.rs-nav-reversed.rs-nav-tabs.rs-nav-vertical .rs-nav-item.rs-nav-item-active {
  border-right-width: 1px;
  border-left-width: 0; }

.rs-nav-subtle .rs-nav-item {
  position: relative; }

.rs-nav-subtle .rs-nav-item:hover,
.rs-nav-subtle .rs-nav-item:focus {
  color: #1675e0;
  color: var(--rs-navs-selected); }

.rs-nav-subtle .rs-nav-item.rs-nav-item-active::before {
  content: '';
  position: absolute;
  background-color: #1675e0;
  background-color: var(--rs-navs-selected);
  display: block;
  z-index: 1; }

.rs-nav-subtle.rs-nav-horizontal .rs-nav-bar {
  border-top: 2px solid #f7f7fa;
  border-top: 2px solid var(--rs-navs-subtle-border); }

.rs-nav-subtle.rs-nav-horizontal .rs-nav-item.rs-nav-item-active::before {
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px; }

.rs-nav-reversed.rs-nav-subtle.rs-nav-horizontal .rs-nav-item.rs-nav-item-active::before {
  bottom: auto;
  top: 0; }

.rs-nav-subtle.rs-nav-vertical .rs-nav-bar {
  width: 2px;
  background: #f7f7fa;
  background: var(--rs-navs-subtle-border); }

.rs-nav-subtle.rs-nav-vertical .rs-nav-item.rs-nav-item-active::before {
  right: 0;
  top: 0;
  bottom: 0;
  width: 2px; }

.rs-nav-reversed.rs-nav-subtle.rs-nav-vertical .rs-nav-item.rs-nav-item-active::before {
  right: auto;
  left: 0; }

.rs-nav-justified {
  display: -ms-flexbox;
  display: flex; }

.rs-nav-justified > .rs-nav-item,
.rs-nav-justified > .rs-dropdown {
  -ms-flex: 1 1 1%;
  flex: 1 1 1%; }

.rs-nav-justified > .rs-dropdown .rs-dropdown-toggle {
  width: 100%;
  text-align: left; }

.rs-navbar::before,
.rs-navbar::after {
  content: ' ';
  display: table; }

.rs-navbar::after {
  clear: both; }

.rs-navbar-header,
.rs-navbar-brand {
  float: left;
  display: inline-block;
  height: 56px;
  color: inherit; }

.rs-navbar-header a {
  color: inherit; }

.rs-navbar-brand {
  padding: 18px 20px; }

.rs-navbar-nav {
  float: left; }

.rs-navbar-nav.rs-navbar-right {
  float: right; }

.rs-navbar-nav:focus .rs-navbar-item:focus-visible,
.rs-navbar-nav:focus .rs-navbar-item.rs-navbar-item-focus,
.rs-navbar-nav:focus .rs-dropdown .rs-dropdown-toggle:focus-visible,
.rs-navbar-nav:focus .rs-dropdown.rs-dropdown-focus .rs-dropdown-toggle {
  z-index: 1;
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring); }

.rs-theme-high-contrast .rs-navbar-nav:focus .rs-navbar-item:focus-visible,
.rs-theme-high-contrast .rs-navbar-nav:focus .rs-navbar-item.rs-navbar-item-focus,
.rs-theme-high-contrast .rs-navbar-nav:focus .rs-dropdown .rs-dropdown-toggle:focus-visible,
.rs-theme-high-contrast .rs-navbar-nav:focus .rs-dropdown.rs-dropdown-focus .rs-dropdown-toggle {
  outline-offset: 2px; }

.rs-theme-high-contrast .rs-navbar-nav:focus .rs-navbar-item:focus-visible,
.rs-theme-high-contrast .rs-navbar-nav:focus .rs-navbar-item.rs-navbar-item-focus,
.rs-theme-high-contrast .rs-navbar-nav:focus .rs-dropdown .rs-dropdown-toggle:focus-visible,
.rs-theme-high-contrast .rs-navbar-nav:focus .rs-dropdown.rs-dropdown-focus .rs-dropdown-toggle {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  outline-width: 2px; }

.rs-theme-high-contrast .rs-theme-high-contrast .rs-navbar-nav:focus .rs-navbar-item:focus-visible,
.rs-theme-high-contrast .rs-theme-high-contrast .rs-navbar-nav:focus .rs-navbar-item.rs-navbar-item-focus,
.rs-theme-high-contrast .rs-theme-high-contrast .rs-navbar-nav:focus .rs-dropdown .rs-dropdown-toggle:focus-visible,
.rs-theme-high-contrast .rs-theme-high-contrast .rs-navbar-nav:focus .rs-dropdown.rs-dropdown-focus .rs-dropdown-toggle {
  outline-offset: 2px; }

.rs-navbar-item,
.rs-navbar-nav > .rs-dropdown .rs-dropdown-toggle {
  padding: 18px 16px;
  height: 56px;
  border-radius: 0;
  color: inherit;
  float: left;
  background-color: transparent;
  position: relative;
  overflow: hidden;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center; }

.rs-navbar-item-icon {
  font-size: 16px;
  margin-right: 5px; }

.rs-navbar-item-caret {
  font-size: 16px;
  margin-left: 6px; }

.rs-navbar-brand,
.rs-navbar-item,
.rs-navbar-nav > .rs-dropdown-item,
.rs-navbar-brand:hover,
.rs-navbar-item:hover,
.rs-navbar-nav > .rs-dropdown-item:hover,
.rs-navbar-brand:focus,
.rs-navbar-item:focus,
.rs-navbar-nav > .rs-dropdown-item:focus,
.rs-navbar-brand:active,
.rs-navbar-item:active,
.rs-navbar-nav > .rs-dropdown-item:active,
.rs-navbar-nav > .rs-dropdown .rs-dropdown-toggle {
  text-decoration: none; }

.rs-navbar-brand:focus-visible,
.rs-navbar-item:focus-visible,
.rs-navbar-nav > .rs-dropdown-item:focus-visible,
.rs-navbar-nav > .rs-dropdown .rs-dropdown-toggle:focus,
.rs-navbar-nav > .rs-dropdown .rs-dropdown-toggle:focus-visible {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  z-index: 2; }

.rs-theme-high-contrast .rs-navbar-brand:focus-visible,
.rs-theme-high-contrast .rs-navbar-item:focus-visible,
.rs-theme-high-contrast .rs-navbar-nav > .rs-dropdown-item:focus-visible {
  outline-offset: 2px; }

.rs-theme-high-contrast .rs-navbar-brand:focus-visible,
.rs-theme-high-contrast .rs-navbar-item:focus-visible,
.rs-theme-high-contrast .rs-navbar-nav > .rs-dropdown-item:focus-visible {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  outline-width: 2px; }

.rs-theme-high-contrast .rs-theme-high-contrast .rs-navbar-brand:focus-visible,
.rs-theme-high-contrast .rs-theme-high-contrast .rs-navbar-item:focus-visible,
.rs-theme-high-contrast .rs-theme-high-contrast .rs-navbar-nav > .rs-dropdown-item:focus-visible {
  outline-offset: 2px; }

.rs-navbar-item,
.rs-navbar-nav > .rs-dropdown,
.rs-navbar-nav > .rs-dropdown .rs-dropdown-toggle {
  margin: 0 !important; }

.rs-theme-high-contrast .rs-navbar-item.rs-navbar-item-active {
  text-decoration: underline; }

.rs-navbar-nav > .rs-dropdown .rs-dropdown-toggle {
  padding-right: 36px; }

.rs-theme-high-contrast .rs-navbar-nav > .rs-dropdown .rs-dropdown-toggle {
  border: none; }

.rs-theme-high-contrast .rs-navbar-nav > .rs-dropdown .rs-dropdown-toggle:focus-visible {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  outline-width: 2px; }

.rs-theme-high-contrast .rs-theme-high-contrast .rs-navbar-nav > .rs-dropdown .rs-dropdown-toggle:focus-visible {
  outline-offset: 2px; }

.rs-navbar-nav > .rs-dropdown .rs-dropdown-toggle .rs-dropdown-toggle-caret {
  top: 18px;
  right: 16px; }

.rs-navbar .rs-dropdown-item:hover {
  background-color: rgba(204, 233, 255, 0.5);
  background-color: var(--rs-menuitem-active-bg);
  color: #1675e0;
  color: var(--rs-menuitem-active-text); }

.rs-theme-high-contrast .rs-navbar .rs-dropdown-item:hover {
  text-decoration: underline;
  -webkit-box-shadow: inset 0 0 0 2px #fff;
  box-shadow: inset 0 0 0 2px #fff; }

.rs-navbar-default {
  background-color: #f7f7fa;
  background-color: var(--rs-navbar-default-bg);
  color: #575757;
  color: var(--rs-navbar-default-text); }

.rs-navbar-default .rs-navbar-item:hover,
.rs-navbar-default .rs-dropdown .rs-dropdown-toggle:hover,
.rs-navbar-default .rs-dropdown.rs-dropdown-open .rs-dropdown-toggle {
  background-color: #e5e5ea;
  background-color: var(--rs-navbar-default-hover-bg);
  color: #575757;
  color: var(--rs-navbar-default-hover-text); }

.rs-navbar-default .rs-navbar-item.rs-navbar-item-active,
.rs-navbar-default .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle {
  color: #1675e0;
  color: var(--rs-navbar-default-selected-text); }

.rs-navbar-inverse {
  background-color: #3498ff;
  background-color: var(--rs-navbar-inverse-bg);
  color: #fff;
  color: var(--rs-navbar-inverse-text); }

.rs-navbar-inverse .rs-navbar-item:hover,
.rs-navbar-inverse .rs-dropdown .rs-dropdown-toggle:hover,
.rs-navbar-inverse .rs-navbar-item:focus,
.rs-navbar-inverse .rs-dropdown .rs-dropdown-toggle:focus {
  background-color: #2589f5;
  background-color: var(--rs-navbar-inverse-hover-bg);
  color: #fff;
  color: var(--rs-navbar-inverse-hover-text); }

.rs-navbar-inverse .rs-navbar-item.rs-navbar-item-active,
.rs-navbar-inverse .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle {
  background-color: #1675e0;
  background-color: var(--rs-navbar-inverse-selected-bg); }

.rs-theme-high-contrast .rs-navbar-inverse .rs-navbar-item.rs-navbar-item-active,
.rs-theme-high-contrast .rs-navbar-inverse .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle {
  color: var(--rs-navbar-inverse-selected-text); }

.rs-navbar-subtle {
  background-color: transparent;
  color: #8e8e93;
  color: var(--rs-navbar-subtle-text); }

.rs-navbar-subtle .rs-navbar-item:hover,
.rs-navbar-subtle .rs-dropdown .rs-dropdown-toggle:hover,
.rs-navbar-subtle .rs-navbar-item:focus,
.rs-navbar-subtle .rs-dropdown .rs-dropdown-toggle:focus {
  background-color: transparent;
  color: #575757;
  color: var(--rs-navbar-subtle-hover-text); }

.rs-navbar-subtle .rs-navbar-item.rs-navbar-item-active,
.rs-navbar-subtle .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle {
  color: #1675e0;
  color: var(--rs-navbar-subtle-selected-text); }

.rs-notification {
  pointer-events: auto;
  position: relative;
  display: inline-block;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  border-radius: 6px;
  background: #fff;
  background: var(--rs-bg-overlay);
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  overflow: hidden; }

.rs-notification:not(.rs-toast-fade-exited) ~ .rs-notification {
  margin-top: 10px; }

.rs-theme-high-contrast .rs-notification {
  border: 1px solid #e5e5ea;
  border: 1px solid var(--rs-border-primary); }

.rs-notification-content {
  padding: 20px;
  max-width: 400px; }

.rs-notification-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  color: #272c36;
  color: var(--rs-text-heading);
  font-size: 16px;
  line-height: 1.5; }

.rs-notification-title > p {
  margin: 0; }

.rs-notification-title .rs-icon {
  font-size: 24px;
  width: 24px;
  line-height: 1;
  margin-right: 10px; }

.rs-notification-title-with-icon {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%; }

.rs-notification-description {
  color: #575757;
  color: var(--rs-text-primary); }

.rs-notification-title + .rs-notification-description {
  margin-top: 8px; }

.rs-notification.rs-notification-hiding {
  -webkit-animation: notificationMoveOut 0.3s ease-in forwards;
  animation: notificationMoveOut 0.3s ease-in forwards; }

.rs-notification.rs-notification-hiding ~ .rs-notification {
  margin-top: 0; }

.rs-notification-success .rs-notification-content .rs-notification-title + .rs-notification-description {
  margin-left: 34px; }

.rs-notification-success .rs-notification-content .rs-icon {
  color: #4caf50;
  color: var(--rs-state-success); }

.rs-notification-success .rs-notification-content .rs-icon::before {
  vertical-align: middle; }

.rs-notification-info .rs-notification-content .rs-notification-title + .rs-notification-description {
  margin-left: 34px; }

.rs-notification-info .rs-notification-content .rs-icon {
  color: #2196f3;
  color: var(--rs-state-info); }

.rs-notification-info .rs-notification-content .rs-icon::before {
  vertical-align: middle; }

.rs-notification-warning .rs-notification-content .rs-notification-title + .rs-notification-description {
  margin-left: 34px; }

.rs-notification-warning .rs-notification-content .rs-icon {
  color: #ffb300;
  color: var(--rs-state-warning); }

.rs-notification-warning .rs-notification-content .rs-icon::before {
  vertical-align: middle; }

.rs-notification-error .rs-notification-content .rs-notification-title + .rs-notification-description {
  margin-left: 34px; }

.rs-notification-error .rs-notification-content .rs-icon {
  color: #f44336;
  color: var(--rs-state-error); }

.rs-notification-error .rs-notification-content .rs-icon::before {
  vertical-align: middle; }

.rs-pagination-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center; }

.rs-pagination-group-lg {
  font-size: 16px; }

.rs-pagination-group-md,
.rs-pagination-group-sm {
  font-size: 14px; }

.rs-pagination-group-xs {
  font-size: 12px; }

.rs-pagination-group-grow {
  -ms-flex-positive: 1;
  flex-grow: 1; }

.rs-pagination-group .rs-pagination {
  vertical-align: middle; }

.rs-pagination-group-limit + .rs-pagination-group-total {
  margin-left: 18px; }

.rs-pagination-group-skip {
  vertical-align: middle;
  display: inline-block;
  margin-left: 10px; }

.rs-pagination-group-skip .rs-input {
  width: 46px;
  margin: 0 5px;
  display: inline-block; }

.rs-pagination {
  display: inline-block;
  padding-left: 0;
  margin-bottom: 0; }

.rs-pagination-lg .rs-pagination-btn {
  font-size: 16px;
  line-height: 22px;
  padding: 10px 16px;
  min-width: 42px; }

.rs-btn-ghost.rs-pagination-lg .rs-pagination-btn {
  padding: 9px 15px; }

.rs-btn-icon.rs-pagination-lg .rs-pagination-btn {
  padding: 11px 11px;
  line-height: 20px; }

.rs-btn-icon.rs-pagination-lg .rs-pagination-btn > .rs-icon {
  font-size: 20px; }

.rs-btn-icon-with-text.rs-pagination-lg .rs-pagination-btn {
  line-height: 22px; }

.rs-btn-icon-with-text.rs-pagination-lg .rs-pagination-btn > .rs-icon {
  padding: 11px 11px;
  width: 42px;
  height: 42px; }

.rs-btn-icon-with-text.rs-pagination-lg .rs-pagination-btn.rs-btn-icon-placement-left {
  padding: 10px 16px 10px 58px; }

.rs-btn-icon-with-text.rs-pagination-lg .rs-pagination-btn.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: none;
  border-right: none;
  border-right: var(--rs-btn-default-border, none); }

.rs-btn-icon-with-text.rs-pagination-lg .rs-pagination-btn.rs-btn-icon-placement-right {
  padding: 10px 58px 10px 16px; }

.rs-btn-icon-with-text.rs-pagination-lg .rs-pagination-btn.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: none;
  border-left: none;
  border-left: var(--rs-btn-default-border, none); }

.rs-pagination-lg .rs-pagination-btn .rs-pagination-symbol {
  height: 22px; }

.rs-pagination-lg .rs-pagination-btn-active {
  padding: 9px 15px; }

.rs-pagination-md .rs-pagination-btn {
  font-size: 14px;
  line-height: 20px;
  padding: 8px 12px;
  min-width: 36px; }

.rs-btn-ghost.rs-pagination-md .rs-pagination-btn {
  padding: 7px 11px; }

.rs-btn-icon.rs-pagination-md .rs-pagination-btn {
  padding: 10px 10px;
  line-height: 16px; }

.rs-btn-icon.rs-pagination-md .rs-pagination-btn > .rs-icon {
  font-size: 16px; }

.rs-btn-icon-with-text.rs-pagination-md .rs-pagination-btn {
  line-height: 20px; }

.rs-btn-icon-with-text.rs-pagination-md .rs-pagination-btn > .rs-icon {
  padding: 10px 10px;
  width: 36px;
  height: 36px; }

.rs-btn-icon-with-text.rs-pagination-md .rs-pagination-btn.rs-btn-icon-placement-left {
  padding: 8px 12px 8px 48px; }

.rs-btn-icon-with-text.rs-pagination-md .rs-pagination-btn.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: none;
  border-right: none;
  border-right: var(--rs-btn-default-border, none); }

.rs-btn-icon-with-text.rs-pagination-md .rs-pagination-btn.rs-btn-icon-placement-right {
  padding: 8px 48px 8px 12px; }

.rs-btn-icon-with-text.rs-pagination-md .rs-pagination-btn.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: none;
  border-left: none;
  border-left: var(--rs-btn-default-border, none); }

.rs-pagination-md .rs-pagination-btn-active {
  padding: 7px 11px; }

.rs-pagination-sm .rs-pagination-btn {
  font-size: 14px;
  line-height: 20px;
  padding: 5px 10px;
  min-width: 30px; }

.rs-btn-ghost.rs-pagination-sm .rs-pagination-btn {
  padding: 4px 9px; }

.rs-btn-icon.rs-pagination-sm .rs-pagination-btn {
  padding: 7px 7px;
  line-height: 16px; }

.rs-btn-icon.rs-pagination-sm .rs-pagination-btn > .rs-icon {
  font-size: 16px; }

.rs-btn-icon-with-text.rs-pagination-sm .rs-pagination-btn {
  line-height: 20px; }

.rs-btn-icon-with-text.rs-pagination-sm .rs-pagination-btn > .rs-icon {
  padding: 7px 7px;
  width: 30px;
  height: 30px; }

.rs-btn-icon-with-text.rs-pagination-sm .rs-pagination-btn.rs-btn-icon-placement-left {
  padding: 5px 10px 5px 40px; }

.rs-btn-icon-with-text.rs-pagination-sm .rs-pagination-btn.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: none;
  border-right: none;
  border-right: var(--rs-btn-default-border, none); }

.rs-btn-icon-with-text.rs-pagination-sm .rs-pagination-btn.rs-btn-icon-placement-right {
  padding: 5px 40px 5px 10px; }

.rs-btn-icon-with-text.rs-pagination-sm .rs-pagination-btn.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: none;
  border-left: none;
  border-left: var(--rs-btn-default-border, none); }

.rs-pagination-sm .rs-pagination-btn-active {
  padding: 4px 9px; }

.rs-pagination-xs .rs-pagination-btn {
  font-size: 12px;
  line-height: 20px;
  padding: 2px 8px;
  min-width: 24px; }

.rs-btn-ghost.rs-pagination-xs .rs-pagination-btn {
  padding: 1px 7px; }

.rs-btn-icon.rs-pagination-xs .rs-pagination-btn {
  padding: 6px 6px;
  line-height: 12px; }

.rs-btn-icon.rs-pagination-xs .rs-pagination-btn > .rs-icon {
  font-size: 12px; }

.rs-btn-icon-with-text.rs-pagination-xs .rs-pagination-btn {
  line-height: 20px; }

.rs-btn-icon-with-text.rs-pagination-xs .rs-pagination-btn > .rs-icon {
  padding: 6px 6px;
  width: 24px;
  height: 24px; }

.rs-btn-icon-with-text.rs-pagination-xs .rs-pagination-btn.rs-btn-icon-placement-left {
  padding: 2px 8px 2px 32px; }

.rs-btn-icon-with-text.rs-pagination-xs .rs-pagination-btn.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: none;
  border-right: none;
  border-right: var(--rs-btn-default-border, none); }

.rs-btn-icon-with-text.rs-pagination-xs .rs-pagination-btn.rs-btn-icon-placement-right {
  padding: 2px 32px 2px 8px; }

.rs-btn-icon-with-text.rs-pagination-xs .rs-pagination-btn.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: none;
  border-left: none;
  border-left: var(--rs-btn-default-border, none); }

.rs-pagination-xs .rs-pagination-btn-active {
  padding: 1px 7px; }

.rs-pagination-btn {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  -webkit-transition: color 0.15s ease-out, background-color 0.15s ease-out;
  transition: color 0.15s ease-out, background-color 0.15s ease-out;
  border: none;
  border: none;
  border: var(--rs-btn-default-border, none);
  color: #575757;
  color: var(--rs-btn-default-text);
  background-color: #f7f7fa;
  background-color: var(--rs-btn-default-bg);
  border-radius: 6px;
  padding: 8px 12px;
  overflow: hidden;
  color: #8e8e93;
  color: var(--rs-btn-subtle-text);
  background-color: transparent;
  border: none;
  font-size: 14px;
  line-height: 20px;
  padding: 5px 10px;
  margin: 0 2px;
  position: relative;
  float: left;
  text-decoration: none;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center; }

.rs-theme-high-contrast .rs-pagination-btn {
  -webkit-transition: none;
  transition: none; }

.rs-btn-ghost.rs-pagination-btn {
  padding: 7px 11px; }

.rs-btn-icon.rs-pagination-btn {
  padding: 10px 10px;
  line-height: 16px; }

.rs-btn-icon.rs-pagination-btn > .rs-icon {
  font-size: 16px; }

.rs-btn-icon-with-text.rs-pagination-btn {
  line-height: 20px; }

.rs-btn-icon-with-text.rs-pagination-btn > .rs-icon {
  padding: 10px 10px;
  width: 36px;
  height: 36px; }

.rs-btn-icon-with-text.rs-pagination-btn.rs-btn-icon-placement-left {
  padding: 8px 12px 8px 48px; }

.rs-btn-icon-with-text.rs-pagination-btn.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: none;
  border-right: none;
  border-right: var(--rs-btn-default-border, none); }

.rs-btn-icon-with-text.rs-pagination-btn.rs-btn-icon-placement-right {
  padding: 8px 48px 8px 12px; }

.rs-btn-icon-with-text.rs-pagination-btn.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: none;
  border-left: none;
  border-left: var(--rs-btn-default-border, none); }

.rs-pagination-btn:focus-visible {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring); }

.rs-theme-high-contrast .rs-pagination-btn:focus-visible {
  outline-offset: 2px; }

.rs-pagination-btn:hover,
.rs-pagination-btn:focus {
  color: var(--rs-btn-default-hover-text);
  background-color: #e5e5ea;
  background-color: var(--rs-btn-default-hover-bg);
  text-decoration: none; }

.rs-pagination-btn:active,
.rs-pagination-btn.rs-btn-active {
  color: #272c36;
  color: var(--rs-btn-default-active-text);
  background-color: #d9d9d9;
  background-color: var(--rs-btn-default-active-bg); }

.rs-pagination-btn:disabled,
.rs-pagination-btn.rs-btn-disabled {
  cursor: not-allowed;
  color: #c5c6c7;
  color: var(--rs-btn-default-disabled-text);
  background-color: #f7f7fa;
  background-color: var(--rs-btn-default-disabled-bg); }

.rs-theme-high-contrast .rs-pagination-btn:disabled,
.rs-theme-high-contrast .rs-pagination-btn.rs-btn-disabled {
  opacity: 0.5; }

@media not all and (min-resolution: 0.001dpcm) {
  .rs-pagination-btn {
    /* stylelint-disable */
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    /* stylelint-enable */ } }

.rs-picker-default .rs-pagination-btn {
  -webkit-transition: border-color ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s; }

.rs-theme-high-contrast .rs-picker-default .rs-pagination-btn {
  -webkit-transition: none;
  transition: none; }

.rs-picker-default:not(.rs-picker-disabled) .rs-pagination-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-pagination-btn:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-pagination-btn-active {
  border-color: #3498ff;
  border-color: var(--rs-input-focus-border); }

.rs-picker-subtle .rs-pagination-btn {
  color: #8e8e93;
  color: var(--rs-btn-subtle-text);
  background-color: transparent;
  border: none;
  -webkit-transition: none;
  transition: none; }

.rs-picker-subtle .rs-pagination-btn:hover,
.rs-picker-subtle .rs-pagination-btn:focus {
  color: #575757;
  color: var(--rs-btn-subtle-hover-text);
  background-color: #e5e5ea;
  background-color: var(--rs-btn-subtle-hover-bg); }

.rs-picker-subtle .rs-pagination-btn:active,
.rs-picker-subtle .rs-pagination-btn.rs-btn-active {
  color: #272c36;
  color: var(--rs-btn-subtle-active-text);
  background-color: #e5e5ea;
  background-color: var(--rs-btn-subtle-active-bg); }

.rs-picker-subtle .rs-pagination-btn:disabled,
.rs-picker-subtle .rs-pagination-btn.rs-btn-disabled {
  color: #c5c6c7;
  color: var(--rs-btn-subtle-disabled-text);
  background: none; }

.rs-theme-high-contrast .rs-picker-subtle .rs-pagination-btn:disabled,
.rs-theme-high-contrast .rs-picker-subtle .rs-pagination-btn.rs-btn-disabled {
  opacity: 0.5; }

.rs-picker-subtle .rs-pagination-btn-active {
  background-color: #e5e5ea;
  background-color: var(--rs-btn-subtle-hover-bg);
  color: #575757;
  color: var(--rs-btn-subtle-hover-text); }

.rs-pagination-btn:hover,
.rs-pagination-btn:focus {
  color: #575757;
  color: var(--rs-btn-subtle-hover-text);
  background-color: #e5e5ea;
  background-color: var(--rs-btn-subtle-hover-bg); }

.rs-pagination-btn:active,
.rs-pagination-btn.rs-btn-active {
  color: #272c36;
  color: var(--rs-btn-subtle-active-text);
  background-color: #e5e5ea;
  background-color: var(--rs-btn-subtle-active-bg); }

.rs-pagination-btn:disabled,
.rs-pagination-btn.rs-btn-disabled {
  color: #c5c6c7;
  color: var(--rs-btn-subtle-disabled-text);
  background: none; }

.rs-theme-high-contrast .rs-pagination-btn:disabled,
.rs-theme-high-contrast .rs-pagination-btn.rs-btn-disabled {
  opacity: 0.5; }

.rs-btn-ghost.rs-pagination-btn {
  padding: 4px 9px; }

.rs-btn-icon.rs-pagination-btn {
  padding: 7px 7px;
  line-height: 16px; }

.rs-btn-icon.rs-pagination-btn > .rs-icon {
  font-size: 16px; }

.rs-btn-icon-with-text.rs-pagination-btn {
  line-height: 20px; }

.rs-btn-icon-with-text.rs-pagination-btn > .rs-icon {
  padding: 7px 7px;
  width: 30px;
  height: 30px; }

.rs-btn-icon-with-text.rs-pagination-btn.rs-btn-icon-placement-left {
  padding: 5px 10px 5px 40px; }

.rs-btn-icon-with-text.rs-pagination-btn.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: none;
  border-right: none;
  border-right: var(--rs-btn-default-border, none); }

.rs-btn-icon-with-text.rs-pagination-btn.rs-btn-icon-placement-right {
  padding: 5px 40px 5px 10px; }

.rs-btn-icon-with-text.rs-pagination-btn.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: none;
  border-left: none;
  border-left: var(--rs-btn-default-border, none); }

.rs-pagination-btn .rs-pagination-symbol {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  height: 20px; }

.rs-theme-high-contrast .rs-pagination-btn {
  -webkit-transition: none;
  transition: none;
  color: var(--rs-pagination-item-text); }

.rs-theme-high-contrast .rs-pagination-btn:hover,
.rs-theme-high-contrast .rs-pagination-btn:focus-visible {
  color: var(--rs-pagination-item-current-text);
  text-decoration: underline; }

.rs-pagination-btn.rs-pagination-btn-active {
  color: #1675e0;
  color: var(--rs-btn-ghost-text);
  background-color: transparent;
  border: 1px solid #1675e0;
  border: 1px solid var(--rs-btn-ghost-border); }

.rs-pagination-btn.rs-pagination-btn-active:hover,
.rs-pagination-btn.rs-pagination-btn-active:focus {
  color: #0a5dc2;
  color: var(--rs-btn-ghost-hover-text);
  background-color: transparent;
  border-color: #0a5dc2;
  border-color: var(--rs-btn-ghost-hover-border);
  -webkit-box-shadow: 0 0 0 1px #0a5dc2;
  -webkit-box-shadow: 0 0 0 1px var(--rs-btn-ghost-hover-border);
  box-shadow: 0 0 0 1px #0a5dc2;
  box-shadow: 0 0 0 1px var(--rs-btn-ghost-hover-border); }

.rs-pagination-btn.rs-pagination-btn-active:active,
.rs-pagination-btn.rs-pagination-btn-active.rs-btn-active {
  color: #004299;
  color: var(--rs-btn-ghost-active-text);
  background-color: transparent;
  border-color: #004299;
  border-color: var(--rs-btn-ghost-active-border); }

.rs-pagination-btn.rs-pagination-btn-active:disabled,
.rs-pagination-btn.rs-pagination-btn-active.rs-btn-disabled {
  color: #1675e0;
  color: var(--rs-btn-ghost-text);
  background-color: transparent;
  opacity: 0.3;
  border-color: #1675e0;
  border-color: var(--rs-btn-ghost-border);
  -webkit-box-shadow: none;
  box-shadow: none; }

.rs-theme-high-contrast .rs-pagination-btn.rs-pagination-btn-active:disabled,
.rs-theme-high-contrast .rs-pagination-btn.rs-pagination-btn-active.rs-btn-disabled {
  opacity: 0.5; }

.rs-theme-high-contrast .rs-pagination-btn.rs-pagination-btn-active {
  color: var(--rs-pagination-item-current-text);
  text-decoration: underline; }

/* rtl:begin:ignore */
[dir='rtl'] .rs-pagination-btn [class*='rs-icon-page'] {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg); }

/* rtl:end:ignore */
.rs-panel {
  border-radius: 6px;
  overflow: hidden; }

.rs-panel-bordered {
  border: 1px solid #e5e5ea;
  border: 1px solid var(--rs-border-primary); }

.rs-panel-shaded {
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: var(--rs-panel-shadow);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  box-shadow: var(--rs-panel-shadow); }

.rs-panel-header,
.rs-panel-body {
  padding: 20px; }

.rs-panel-body-fill {
  padding: 0 !important; }

.rs-panel-header {
  color: #272c36;
  color: var(--rs-text-heading);
  font-size: 16px;
  line-height: 1.25; }

.rs-panel-title {
  margin: 0; }

.rs-panel-title a {
  color: inherit; }

.rs-panel-title a:hover,
.rs-panel-title a:focus,
.rs-panel-title a:active {
  text-decoration: none; }

.rs-panel-header + .rs-panel-collapse .rs-panel-body,
.rs-panel-header + .rs-panel-body {
  padding-top: 0; }

.rs-panel-collapsible > .rs-panel-header {
  cursor: pointer;
  -webkit-transition: background-color 0.3s linear, border-radius 0.3s linear;
  transition: background-color 0.3s linear, border-radius 0.3s linear;
  position: relative; }

.rs-panel-collapsible > .rs-panel-header > .rs-icon {
  color: #8e8e93;
  color: var(--rs-text-secondary);
  position: absolute;
  top: 24px;
  right: 20px;
  -webkit-transition: -webkit-transform 0.3s linear;
  transition: -webkit-transform 0.3s linear;
  transition: transform 0.3s linear;
  transition: transform 0.3s linear, -webkit-transform 0.3s linear; }

.rs-panel-group {
  border-radius: 6px;
  overflow: hidden; }

.rs-panel-group-bordered {
  border: 1px solid #e5e5ea;
  border: 1px solid var(--rs-border-primary); }

.rs-panel-group > .rs-panel {
  border: none; }

.rs-panel-group > .rs-panel + .rs-panel {
  position: relative; }

.rs-panel-group > .rs-panel + .rs-panel::before {
  content: '';
  position: absolute;
  top: 0;
  border-top: 1px solid #e5e5ea;
  border-top: 1px solid var(--rs-border-primary);
  left: 20px;
  right: 20px; }

.rs-picker-toggle-wrapper {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%; }

.rs-picker-toggle {
  min-width: 75px; }

.rs-picker-toggle.rs-btn .rs-ripple-pond {
  display: none !important; }

.rs-picker-block {
  display: block; }

.rs-picker-disabled {
  opacity: 0.3; }

.rs-picker-toggle .rs-picker-toggle-placeholder {
  color: #8e8e93;
  color: var(--rs-text-secondary); }

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #1675e0;
  color: var(--rs-picker-value); }

.rs-picker-none {
  padding: 6px 12px 12px;
  color: #8e8e93;
  color: var(--rs-text-secondary);
  cursor: default; }

.rs-picker-countable .rs-picker-toggle-value {
  display: -ms-flexbox;
  display: flex; }

.rs-picker-value-list {
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-picker-value-list {
    max-width: 100%; } }

.rs-picker-value-count {
  margin: 0 4px;
  background-color: #3498ff;
  background-color: var(--rs-picker-count-bg);
  color: #fff;
  color: var(--rs-picker-count-text);
  border-radius: 10px;
  padding: 0 8px;
  line-height: 20px; }

.rs-picker-value-separator {
  margin: 0 4px 0 0; }

.rs-picker:not(.rs-picker-disabled):hover,
.rs-picker.rs-picker-focused {
  border-color: #3498ff;
  border-color: var(--rs-input-focus-border); }

.rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
  -webkit-box-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
  -webkit-box-shadow: var(--rs-state-focus-shadow);
  box-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
  box-shadow: var(--rs-state-focus-shadow); }

.rs-picker-toggle {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  white-space: nowrap;
  -webkit-transition: color 0.15s ease-out, background-color 0.15s ease-out;
  transition: color 0.15s ease-out, background-color 0.15s ease-out;
  border: none;
  border: none;
  border: var(--rs-btn-default-border, none);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  color: #575757;
  color: var(--rs-btn-default-text);
  background-color: #f7f7fa;
  background-color: var(--rs-btn-default-bg);
  border-radius: 6px;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 12px;
  overflow: hidden;
  position: relative; }

.rs-theme-high-contrast .rs-picker-toggle {
  -webkit-transition: none;
  transition: none; }

.rs-btn-ghost.rs-picker-toggle {
  padding: 7px 11px; }

.rs-btn-icon.rs-picker-toggle {
  padding: 10px 10px;
  line-height: 16px; }

.rs-btn-icon.rs-picker-toggle > .rs-icon {
  font-size: 16px; }

.rs-btn-icon-with-text.rs-picker-toggle {
  line-height: 20px; }

.rs-btn-icon-with-text.rs-picker-toggle > .rs-icon {
  padding: 10px 10px;
  width: 36px;
  height: 36px; }

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-icon-placement-left {
  padding: 8px 12px 8px 48px; }

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: none;
  border-right: none;
  border-right: var(--rs-btn-default-border, none); }

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-icon-placement-right {
  padding: 8px 48px 8px 12px; }

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: none;
  border-left: none;
  border-left: var(--rs-btn-default-border, none); }

.rs-picker-toggle:focus-visible {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring); }

.rs-theme-high-contrast .rs-picker-toggle:focus-visible {
  outline-offset: 2px; }

.rs-picker-toggle:hover,
.rs-picker-toggle:focus {
  color: var(--rs-btn-default-hover-text);
  background-color: #e5e5ea;
  background-color: var(--rs-btn-default-hover-bg);
  text-decoration: none; }

.rs-picker-toggle:active,
.rs-picker-toggle.rs-btn-active {
  color: #272c36;
  color: var(--rs-btn-default-active-text);
  background-color: #d9d9d9;
  background-color: var(--rs-btn-default-active-bg); }

.rs-picker-toggle:disabled,
.rs-picker-toggle.rs-btn-disabled {
  cursor: not-allowed;
  color: #c5c6c7;
  color: var(--rs-btn-default-disabled-text);
  background-color: #f7f7fa;
  background-color: var(--rs-btn-default-disabled-bg); }

.rs-theme-high-contrast .rs-picker-toggle:disabled,
.rs-theme-high-contrast .rs-picker-toggle.rs-btn-disabled {
  opacity: 0.5; }

@media not all and (min-resolution: 0.001dpcm) {
  .rs-picker-toggle {
    /* stylelint-disable */
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    /* stylelint-enable */ } }

.rs-picker-default .rs-picker-toggle {
  -webkit-transition: border-color ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s; }

.rs-theme-high-contrast .rs-picker-default .rs-picker-toggle {
  -webkit-transition: none;
  transition: none; }

.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
  border-color: #3498ff;
  border-color: var(--rs-input-focus-border); }

.rs-picker-subtle .rs-picker-toggle {
  color: #8e8e93;
  color: var(--rs-btn-subtle-text);
  background-color: transparent;
  border: none;
  -webkit-transition: none;
  transition: none; }

.rs-picker-subtle .rs-picker-toggle:hover,
.rs-picker-subtle .rs-picker-toggle:focus {
  color: #575757;
  color: var(--rs-btn-subtle-hover-text);
  background-color: #e5e5ea;
  background-color: var(--rs-btn-subtle-hover-bg); }

.rs-picker-subtle .rs-picker-toggle:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-active {
  color: #272c36;
  color: var(--rs-btn-subtle-active-text);
  background-color: #e5e5ea;
  background-color: var(--rs-btn-subtle-active-bg); }

.rs-picker-subtle .rs-picker-toggle:disabled,
.rs-picker-subtle .rs-picker-toggle.rs-btn-disabled {
  color: #c5c6c7;
  color: var(--rs-btn-subtle-disabled-text);
  background: none; }

.rs-theme-high-contrast .rs-picker-subtle .rs-picker-toggle:disabled,
.rs-theme-high-contrast .rs-picker-subtle .rs-picker-toggle.rs-btn-disabled {
  opacity: 0.5; }

.rs-picker-subtle .rs-picker-toggle-active {
  background-color: #e5e5ea;
  background-color: var(--rs-btn-subtle-hover-bg);
  color: #575757;
  color: var(--rs-btn-subtle-hover-text); }

.rs-picker-default .rs-picker-toggle {
  padding: 7px 11px; }

.rs-picker-toggle-textbox {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border: 1px solid #0000;
  padding-left: 10px;
  padding-right: 32px;
  color: #575757;
  color: var(--rs-text-primary);
  background-color: #fff;
  background-color: var(--rs-input-bg);
  outline: none; }

.rs-picker-toggle.rs-btn-lg .rs-picker-toggle-textbox {
  padding-left: 14px; }

.rs-picker-toggle.rs-btn-sm .rs-picker-toggle-textbox {
  padding-left: 8px; }

.rs-picker-toggle.rs-btn-xs .rs-picker-toggle-textbox {
  padding-left: 6px; }

.rs-picker-toggle-read-only {
  opacity: 0; }

.rs-picker-default .rs-btn,
.rs-picker-default .rs-picker-toggle {
  -webkit-transition: border-color ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s; }

.rs-theme-high-contrast .rs-picker-default .rs-btn,
.rs-theme-high-contrast .rs-picker-default .rs-picker-toggle {
  -webkit-transition: none;
  transition: none; }

.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
  border-color: #3498ff;
  border-color: var(--rs-input-focus-border); }

.rs-picker-subtle .rs-btn,
.rs-picker-subtle .rs-picker-toggle {
  color: #8e8e93;
  color: var(--rs-btn-subtle-text);
  background-color: transparent;
  border: none;
  -webkit-transition: none;
  transition: none; }

.rs-picker-subtle .rs-btn:hover,
.rs-picker-subtle .rs-picker-toggle:hover,
.rs-picker-subtle .rs-btn:focus,
.rs-picker-subtle .rs-picker-toggle:focus {
  color: #575757;
  color: var(--rs-btn-subtle-hover-text);
  background-color: #e5e5ea;
  background-color: var(--rs-btn-subtle-hover-bg); }

.rs-picker-subtle .rs-btn:active,
.rs-picker-subtle .rs-picker-toggle:active,
.rs-picker-subtle .rs-btn.rs-btn-active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-active {
  color: #272c36;
  color: var(--rs-btn-subtle-active-text);
  background-color: #e5e5ea;
  background-color: var(--rs-btn-subtle-active-bg); }

.rs-picker-subtle .rs-btn:disabled,
.rs-picker-subtle .rs-picker-toggle:disabled,
.rs-picker-subtle .rs-btn.rs-btn-disabled,
.rs-picker-subtle .rs-picker-toggle.rs-btn-disabled {
  color: #c5c6c7;
  color: var(--rs-btn-subtle-disabled-text);
  background: none; }

.rs-theme-high-contrast .rs-picker-subtle .rs-btn:disabled,
.rs-theme-high-contrast .rs-picker-subtle .rs-picker-toggle:disabled,
.rs-theme-high-contrast .rs-picker-subtle .rs-btn.rs-btn-disabled,
.rs-theme-high-contrast .rs-picker-subtle .rs-picker-toggle.rs-btn-disabled {
  opacity: 0.5; }

.rs-picker-subtle .rs-btn-active,
.rs-picker-subtle .rs-picker-toggle-active {
  background-color: #e5e5ea;
  background-color: var(--rs-btn-subtle-hover-bg);
  color: #575757;
  color: var(--rs-btn-subtle-hover-text); }

/* stylelint-disable-next-line */
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn {
  padding-right: 32px; }

.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn {
  padding-right: 32px; }

.rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  top: 8px;
  right: 12px; }

.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  top: 7px; }

.rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding: 7px 12px;
  font-size: 14px;
  line-height: 1.42857143; }

.rs-picker-tag .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper {
  padding-bottom: 5px; }

.rs-picker-tag .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper .rs-tag {
  margin-top: 5px; }

.rs-picker-tag .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding-left: 12px;
  margin-top: 5px;
  font-size: 14px; }

.rs-picker-tag .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper input {
  height: 18px; }

.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn {
  padding-top: 7px;
  padding-bottom: 7px;
  -webkit-box-shadow: none;
  box-shadow: none; }

.rs-picker-date .rs-picker-toggle.rs-btn,
.rs-picker-daterange .rs-picker-toggle.rs-btn {
  padding-right: 36px; }

.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  top: 8px;
  right: 12px; }

.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  top: 7px; }

.rs-picker-default .rs-picker-toggle.rs-btn {
  padding-top: 7px;
  padding-bottom: 7px; }

.rs-picker-toggle.rs-btn-lg {
  font-size: 16px;
  line-height: 22px;
  padding: 10px 16px; }

.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-lg {
  padding-right: 36px; }

.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn-lg {
  padding-right: 36px; }

.rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
.rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
  top: 10px;
  right: 16px; }

.rs-picker-default .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
  top: 9px; }

.rs-picker-default .rs-picker-toggle.rs-btn-lg {
  padding-left: 15px; }

.rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding: 8px 16px;
  font-size: 16px;
  line-height: 1.375; }

.rs-picker-tag .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper {
  padding-bottom: 6px; }

.rs-picker-tag .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper .rs-tag {
  margin-top: 6px; }

.rs-picker-tag .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding-left: 16px;
  margin-top: 6px;
  font-size: 16px; }

.rs-picker-tag .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper input {
  height: 20px; }

.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-lg {
  padding-top: 8px;
  padding-bottom: 8px;
  -webkit-box-shadow: none;
  box-shadow: none; }

.rs-picker-date .rs-picker-toggle.rs-btn-lg,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg {
  padding-right: 42px; }

.rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
.rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
  top: 10px;
  right: 16px; }

.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
  top: 9px; }

.rs-picker-default .rs-picker-toggle.rs-btn-lg {
  padding-top: 9px;
  padding-bottom: 9px; }

.rs-btn-ghost.rs-picker-toggle.rs-btn-lg {
  padding: 9px 15px; }

.rs-btn-icon.rs-picker-toggle.rs-btn-lg {
  padding: 11px 11px;
  line-height: 20px; }

.rs-btn-icon.rs-picker-toggle.rs-btn-lg > .rs-icon {
  font-size: 20px; }

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg {
  line-height: 22px; }

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg > .rs-icon {
  padding: 11px 11px;
  width: 42px;
  height: 42px; }

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg.rs-btn-icon-placement-left {
  padding: 10px 16px 10px 58px; }

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: none;
  border-right: none;
  border-right: var(--rs-btn-default-border, none); }

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg.rs-btn-icon-placement-right {
  padding: 10px 58px 10px 16px; }

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: none;
  border-left: none;
  border-left: var(--rs-btn-default-border, none); }

.rs-picker-toggle.rs-btn-lg .rs-picker-value-count {
  line-height: 22px; }

.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-md {
  padding-right: 32px; }

.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn-md {
  padding-right: 32px; }

.rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret,
.rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean {
  top: 8px;
  right: 12px; }

.rs-picker-default .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean {
  top: 7px; }

.rs-picker-default .rs-picker-toggle.rs-btn-md {
  padding-left: 11px; }

.rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn-md ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding: 7px 12px;
  font-size: 14px;
  line-height: 1.42857143; }

.rs-picker-tag .rs-picker-toggle.rs-btn-md ~ .rs-picker-tag-wrapper {
  padding-bottom: 5px; }

.rs-picker-tag .rs-picker-toggle.rs-btn-md ~ .rs-picker-tag-wrapper .rs-tag {
  margin-top: 5px; }

.rs-picker-tag .rs-picker-toggle.rs-btn-md ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding-left: 12px;
  margin-top: 5px;
  font-size: 14px; }

.rs-picker-tag .rs-picker-toggle.rs-btn-md ~ .rs-picker-tag-wrapper input {
  height: 18px; }

.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-md {
  padding-top: 7px;
  padding-bottom: 7px;
  -webkit-box-shadow: none;
  box-shadow: none; }

.rs-picker-date .rs-picker-toggle.rs-btn-md,
.rs-picker-daterange .rs-picker-toggle.rs-btn-md {
  padding-right: 36px; }

.rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean,
.rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret {
  top: 8px;
  right: 12px; }

.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean,
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret {
  top: 7px; }

.rs-picker-default .rs-picker-toggle.rs-btn-md {
  padding-top: 7px;
  padding-bottom: 7px; }

.rs-picker-toggle.rs-btn-sm {
  font-size: 14px;
  line-height: 20px;
  padding: 5px 10px; }

.rs-btn-ghost.rs-picker-toggle.rs-btn-sm {
  padding: 4px 9px; }

.rs-btn-icon.rs-picker-toggle.rs-btn-sm {
  padding: 7px 7px;
  line-height: 16px; }

.rs-btn-icon.rs-picker-toggle.rs-btn-sm > .rs-icon {
  font-size: 16px; }

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm {
  line-height: 20px; }

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm > .rs-icon {
  padding: 7px 7px;
  width: 30px;
  height: 30px; }

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm.rs-btn-icon-placement-left {
  padding: 5px 10px 5px 40px; }

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: none;
  border-right: none;
  border-right: var(--rs-btn-default-border, none); }

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm.rs-btn-icon-placement-right {
  padding: 5px 40px 5px 10px; }

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: none;
  border-left: none;
  border-left: var(--rs-btn-default-border, none); }

.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-sm {
  padding-right: 30px; }

.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn-sm {
  padding-right: 30px; }

.rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret,
.rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean {
  top: 5px;
  right: 10px; }

.rs-picker-default .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean {
  top: 4px; }

.rs-picker-default .rs-picker-toggle.rs-btn-sm {
  padding-left: 9px; }

.rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding: 4px 10px;
  font-size: 14px;
  line-height: 1.42857143; }

.rs-picker-tag .rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper {
  padding-bottom: 2px; }

.rs-picker-tag .rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper .rs-tag {
  margin-top: 2px; }

.rs-picker-tag .rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding-left: 10px;
  margin-top: 2px;
  font-size: 14px; }

.rs-picker-tag .rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper input {
  height: 18px; }

.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-sm {
  padding-top: 4px;
  padding-bottom: 4px;
  -webkit-box-shadow: none;
  box-shadow: none; }

.rs-picker-date .rs-picker-toggle.rs-btn-sm,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm {
  padding-right: 34px; }

.rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean,
.rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret {
  top: 4px;
  right: 10px; }

.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean,
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret {
  top: 3px; }

.rs-picker-default .rs-picker-toggle.rs-btn-sm {
  padding-top: 4px;
  padding-bottom: 4px; }

.rs-picker-toggle.rs-btn-xs {
  font-size: 12px;
  line-height: 20px;
  padding: 2px 8px;
  /* stylelint-disable */
  /* stylelint-enable */ }

.rs-btn-ghost.rs-picker-toggle.rs-btn-xs {
  padding: 1px 7px; }

.rs-btn-icon.rs-picker-toggle.rs-btn-xs {
  padding: 6px 6px;
  line-height: 12px; }

.rs-btn-icon.rs-picker-toggle.rs-btn-xs > .rs-icon {
  font-size: 12px; }

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs {
  line-height: 20px; }

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs > .rs-icon {
  padding: 6px 6px;
  width: 24px;
  height: 24px; }

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs.rs-btn-icon-placement-left {
  padding: 2px 8px 2px 32px; }

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: none;
  border-right: none;
  border-right: var(--rs-btn-default-border, none); }

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs.rs-btn-icon-placement-right {
  padding: 2px 32px 2px 8px; }

.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: none;
  border-left: none;
  border-left: var(--rs-btn-default-border, none); }

.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-xs {
  padding-right: 28px; }

.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn-xs {
  padding-right: 28px; }

.rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret,
.rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean {
  top: 2px;
  right: 8px; }

.rs-picker-default .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean {
  top: 1px; }

.rs-picker-default .rs-picker-toggle.rs-btn-xs {
  padding-left: 7px; }

.rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding: 1px 8px;
  font-size: 12px;
  line-height: 1.66666667; }

.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper {
  padding-bottom: -1px; }

.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper .rs-tag {
  margin-top: -1px; }

.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding-left: 8px;
  margin-top: -1px;
  font-size: 12px; }

.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper input {
  height: 18px; }

.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs {
  padding-top: 1px;
  padding-bottom: 1px;
  -webkit-box-shadow: none;
  box-shadow: none; }

.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper {
  padding-bottom: 1px; }

.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper .rs-tag {
  margin-top: 1px; }

.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding-top: 1px;
  padding-bottom: 1px; }

.rs-picker-date .rs-picker-toggle.rs-btn-xs,
.rs-picker-daterange .rs-picker-toggle.rs-btn-xs {
  padding-right: 30px; }

.rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean,
.rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret {
  top: 2px;
  right: 8px; }

.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean,
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret {
  top: 1px; }

.rs-picker-default .rs-picker-toggle.rs-btn-xs {
  padding-top: 1px;
  padding-bottom: 1px; }

/* stylelint-disable-next-line */
.rs-picker-toggle {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%; }

.rs-picker-default .rs-picker-toggle {
  position: relative;
  z-index: 5;
  padding-right: 32px;
  display: inline-block;
  color: #575757;
  color: var(--rs-text-primary);
  border: 1px solid #e5e5ea;
  border: 1px solid var(--rs-border-primary); }

.rs-picker-disabled .rs-picker-toggle {
  cursor: not-allowed; }

.rs-picker-toggle .rs-picker-default:not(.rs-picker-disabled):hover,
.rs-picker-toggle .rs-picker-default:not(.rs-picker-disabled):focus {
  border-color: #3498ff;
  border-color: var(--rs-input-focus-border); }

.rs-picker-subtle .rs-picker-toggle {
  position: relative;
  z-index: 5;
  padding-right: 32px;
  display: inline-block; }

.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle {
  cursor: not-allowed; }

.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:hover,
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:focus,
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:active {
  background: none; }

.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:hover::after,
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:focus::after,
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:active::after {
  display: none; }

.rs-picker-toggle-label {
  color: #575757;
  color: var(--rs-text-primary); }

.rs-picker-toggle-label::after {
  content: ':';
  margin: 0 4px 0 2px; }

.rs-picker-toggle-value {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%; }

.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle {
  padding-right: 44px; }

.rs-picker-toggle-clean {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 2px;
  position: absolute;
  top: 8px;
  right: 30px;
  height: 20px;
  font-size: 12px;
  background: inherit;
  color: #8e8e93;
  color: var(--rs-text-secondary);
  -webkit-transition: 0.2s color linear;
  transition: 0.2s color linear;
  cursor: pointer; }

.rs-picker-toggle-clean.rs-btn-close {
  padding: 4px 0; }

.rs-picker-toggle-clean:hover {
  color: #f44336;
  color: var(--rs-state-error); }

.rs-picker-toggle-clean:hover svg path {
  stroke: #f44336;
  stroke: var(--rs-state-error);
  stroke-width: 1; }

.rs-picker-date .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle-clean {
  right: 38px; }

.rs-picker-toggle-caret {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 2px;
  position: absolute;
  top: 8px;
  right: 12px;
  height: 20px;
  font-size: 12px;
  color: #8e8e93;
  color: var(--rs-text-secondary); }

.rs-picker-menu {
  position: absolute;
  text-align: left;
  z-index: 7;
  border-radius: 6px;
  background-color: #fff;
  background-color: var(--rs-bg-overlay);
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: var(--rs-shadow-overlay);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  box-shadow: var(--rs-shadow-overlay);
  overflow: hidden;
  -webkit-transition: none;
  transition: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }

.rs-theme-high-contrast .rs-picker-menu {
  border: 1px solid #e5e5ea;
  border: 1px solid var(--rs-border-primary); }

.rs-modal-open .rs-picker-menu {
  z-index: 1055; }

.rs-drawer-open .rs-picker-menu {
  z-index: 1055; }

.rs-picker-menu.rs-picker-select-menu,
.rs-picker-menu.rs-picker-check-menu {
  padding-top: 6px; }

.rs-picker-menu .rs-picker-search-bar {
  position: relative;
  padding: 6px 12px 12px; }

.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
  display: block;
  width: 100%;
  color: #575757;
  color: var(--rs-text-primary);
  background-color: #fff;
  background-color: var(--rs-input-bg);
  background-image: none;
  border: 1px solid #e5e5ea;
  border: 1px solid var(--rs-border-primary);
  -webkit-transition: border-color ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s;
  border-radius: 6px;
  /* stylelint-disable */
  padding: 7px 11px;
  font-size: 14px;
  line-height: 1.42857143;
  /* stylelint-enable */
  min-width: 200px;
  padding-right: 36px; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
    height: 36px; } }

textarea.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
  height: auto; }

.rs-theme-high-contrast .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
  -webkit-transition: none;
  transition: none; }

.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input::-webkit-input-placeholder {
  color: #8e8e93;
  color: var(--rs-text-secondary); }

.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input::-moz-placeholder {
  color: #8e8e93;
  color: var(--rs-text-secondary); }

.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input::-ms-input-placeholder {
  color: #8e8e93;
  color: var(--rs-text-secondary); }

.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:-ms-input-placeholder {
  color: #8e8e93;
  color: var(--rs-text-secondary); }

.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input::placeholder {
  color: #8e8e93;
  color: var(--rs-text-secondary); }

.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:focus,
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:hover:not(:disabled) {
  border-color: #3498ff;
  border-color: var(--rs-input-focus-border); }

.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:focus {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring); }

.rs-theme-high-contrast .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:focus {
  outline-offset: 2px; }

.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:disabled {
  background-color: #f7f7fa;
  background-color: var(--rs-input-disabled-bg);
  color: #c5c6c7;
  color: var(--rs-text-disabled); }

.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-search-icon {
  position: absolute;
  width: 14px;
  color: #8e8e93;
  color: var(--rs-text-secondary);
  font-size: 14px;
  height: 20px;
  top: 14px;
  right: 24px; }

.rs-tree-menu.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-search-icon,
.rs-check-tree-menu.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-search-icon {
  top: 7px; }

.rs-picker-menu.rs-picker-inline {
  position: relative;
  -webkit-box-shadow: none;
  box-shadow: none; }

.rs-picker-default .rs-btn,
.rs-picker-input .rs-btn,
.rs-picker-default .rs-picker-toggle,
.rs-picker-input .rs-picker-toggle {
  background-color: #fff !important;
  background-color: var(--rs-input-bg) !important; }

.rs-picker-default.rs-picker-disabled .rs-picker-toggle,
.rs-picker-input.rs-picker-disabled .rs-picker-toggle,
.rs-picker-default.rs-picker-disabled .rs-btn,
.rs-picker-input.rs-picker-disabled .rs-btn {
  background-color: #f7f7fa !important;
  background-color: var(--rs-input-disabled-bg) !important; }

.rs-check-item {
  display: block; }

.rs-check-item:not(.rs-checkbox-disabled):hover,
.rs-check-item:focus,
.rs-check-item.rs-check-item-focus {
  background-color: rgba(204, 233, 255, 0.5);
  background-color: var(--rs-listbox-option-hover-bg); }

.rs-theme-high-contrast .rs-check-item:not(.rs-checkbox-disabled):hover,
.rs-theme-high-contrast .rs-check-item:focus,
.rs-theme-high-contrast .rs-check-item.rs-check-item-focus {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  outline-width: 2px;
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text);
  text-decoration: underline; }

.rs-theme-high-contrast .rs-theme-high-contrast .rs-check-item:not(.rs-checkbox-disabled):hover,
.rs-theme-high-contrast .rs-theme-high-contrast .rs-check-item:focus,
.rs-theme-high-contrast .rs-theme-high-contrast .rs-check-item.rs-check-item-focus {
  outline-offset: 2px; }

.rs-theme-high-contrast .rs-check-item:not(.rs-checkbox-disabled):hover .rs-check-tree-node-text-wrapper,
.rs-theme-high-contrast .rs-check-item:focus .rs-check-tree-node-text-wrapper,
.rs-theme-high-contrast .rs-check-item.rs-check-item-focus .rs-check-tree-node-text-wrapper {
  text-decoration: underline; }

.rs-check-item .rs-checkbox-checker {
  padding: 0; }

.rs-check-item .rs-checkbox-checker > label {
  position: relative;
  cursor: pointer;
  display: block;
  padding: 8px 12px 8px 38px;
  line-height: 20px; }

.rs-theme-high-contrast .rs-check-item .rs-checkbox-checker > label {
  -webkit-transition: none;
  transition: none; }

.rs-check-item .rs-checkbox-checker > label::before {
  background-color: #fff;
  background-color: var(--rs-bg-overlay); }

.rs-theme-high-contrast .rs-check-item .rs-checkbox-checker > label::before {
  background: none; }

.rs-checkbox-disabled.rs-check-item .rs-checkbox-checker > label {
  cursor: not-allowed; }

.grouped .rs-check-item .rs-checkbox-checker > label,
.rs-picker-check-menu-group-children .rs-check-item .rs-checkbox-checker > label {
  padding-left: 52px; }

.rs-check-item .rs-checkbox-checker .rs-checkbox-wrapper {
  left: 12px; }

.grouped .rs-check-item .rs-checkbox-checker .rs-checkbox-wrapper,
.rs-picker-check-menu-group-children .rs-check-item .rs-checkbox-checker .rs-checkbox-wrapper {
  left: 26px; }

.rs-placeholder {
  display: -ms-flexbox;
  display: flex; }

.rs-placeholder-paragraph {
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-align: start;
  align-items: flex-start; }

.rs-placeholder-paragraph-graph {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 60px;
  height: 60px;
  border-radius: 2px;
  margin-right: 20px;
  background-color: #f2f2f5;
  background-color: var(--rs-placeholder); }

.rs-placeholder-paragraph-graph-circle {
  border-radius: 50%; }

.rs-placeholder-paragraph-graph-image {
  width: 80px;
  height: 60px;
  position: relative;
  overflow: hidden; }

.rs-placeholder-paragraph-graph-image::after {
  content: '';
  width: 0;
  height: 0;
  left: 10px;
  bottom: 10px;
  position: absolute;
  border-bottom: 36px solid #fff;
  border-bottom: 36px solid var(--rs-bg-card);
  border-left: 20px solid transparent;
  border-right: 30px solid transparent; }

.rs-placeholder-paragraph-graph-image::before {
  content: '';
  width: 0;
  height: 0;
  left: 40px;
  bottom: 10px;
  position: absolute;
  border-bottom: 22px solid #fff;
  border-bottom: 22px solid var(--rs-bg-card);
  border-left: 16px solid transparent;
  border-right: 16px solid transparent; }

.rs-placeholder-paragraph-graph-image .rs-placeholder-paragraph-graph-inner {
  width: 12px;
  height: 12px;
  right: 18px;
  top: 10px;
  border-radius: 50%;
  background: #fff;
  background: var(--rs-bg-card);
  position: absolute; }

.rs-placeholder-paragraph-rows {
  width: 100%; }

.rs-placeholder-paragraph-rows > p {
  background-color: #f2f2f5;
  background-color: var(--rs-placeholder); }

.rs-placeholder-grid-col {
  -ms-flex: 1;
  flex: 1 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: end;
  align-items: flex-end; }

.rs-placeholder-grid-col > p {
  background-color: #f2f2f5;
  background-color: var(--rs-placeholder); }

.rs-placeholder-grid-col:first-child {
  -ms-flex-align: start;
  align-items: flex-start; }

.rs-placeholder-graph {
  display: inline-block;
  width: 100%;
  background-color: #f2f2f5;
  background-color: var(--rs-placeholder); }

.rs-placeholder-active.rs-placeholder-graph,
.rs-placeholder-active .rs-placeholder-paragraph-graph-image,
.rs-placeholder-active .rs-placeholder-grid-col > p,
.rs-placeholder-active .rs-placeholder-paragraph-rows > p {
  background: linear-gradient(-45deg, #f2f2f5 25%, #e5e5ea 37%, #f2f2f5 63%);
  background: linear-gradient(-45deg, var(--rs-placeholder) 25%, var(--rs-placeholder-active) 37%, var(--rs-placeholder) 63%);
  background-size: 400% 100% !important;
  -webkit-animation: placeholder-active 3s ease infinite;
  animation: placeholder-active 3s ease infinite; }

@-webkit-keyframes placeholder-active {
  0% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@keyframes placeholder-active {
  0% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

/* rtl:begin:ignore */
/* rtl:end:ignore */
.rs-popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  padding: 12px;
  font-size: 14px;
  background-color: #fff;
  background-color: var(--rs-bg-overlay);
  background-clip: padding-box;
  border-radius: 4px;
  opacity: 0;
  -webkit-filter: drop-shadow(0 1px 8px rgba(0, 0, 0, 0.12));
  -webkit-filter: drop-shadow(var(--rs-popover-shadow));
  filter: drop-shadow(0 1px 8px rgba(0, 0, 0, 0.12));
  filter: drop-shadow(var(--rs-popover-shadow));
  -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: var(--rs-popover-shadow);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
  box-shadow: var(--rs-popover-shadow); }

.rs-popover.rs-anim-fade {
  -webkit-transition: opacity 0.1s linear, -webkit-transform 0.1s ease-out;
  transition: opacity 0.1s linear, -webkit-transform 0.1s ease-out;
  transition: opacity 0.1s linear, transform 0.1s ease-out;
  transition: opacity 0.1s linear, transform 0.1s ease-out, -webkit-transform 0.1s ease-out; }

.rs-popover.rs-anim-in {
  opacity: 1;
  -webkit-transition: opacity 0.15s linear, -webkit-transform 0.15s ease-in;
  transition: opacity 0.15s linear, -webkit-transform 0.15s ease-in;
  transition: opacity 0.15s linear, transform 0.15s ease-in;
  transition: opacity 0.15s linear, transform 0.15s ease-in, -webkit-transform 0.15s ease-in; }

.rs-theme-high-contrast .rs-popover {
  border: 1px solid #e5e5ea;
  border: 1px solid var(--rs-border-primary); }

.rs-popover > .rs-popover-arrow {
  border-width: 6px; }

.rs-popover > .rs-popover-arrow,
.rs-popover > .rs-popover-arrow::before,
.rs-popover > .rs-popover-arrow::after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid; }

.rs-popover > .rs-popover-arrow::before,
.rs-popover > .rs-popover-arrow::after {
  border-width: 6px;
  content: ''; }

.rs-popover > .rs-popover-arrow::before {
  display: none; }

.rs-theme-high-contrast .rs-popover > .rs-popover-arrow::before {
  display: block; }

.rs-popover-title {
  margin: 0;
  font-size: 14px;
  line-height: 1.42857143; }

.rs-popover-content {
  font-size: 12px;
  line-height: 1.66666667; }

.rs-popover-title ~ .rs-popover-content {
  margin-top: 8px; }

.rs-popover[class*='placement-top'] {
  margin-top: -8px;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0); }

.rs-popover[class*='placement-top'].rs-anim-in {
  -webkit-transform: translate(0, -2px);
  transform: translate(0, -2px); }

.rs-popover[class*='placement-top'] > .rs-popover-arrow {
  margin-left: -6px;
  bottom: -6px; }

.rs-popover[class*='placement-top'] > .rs-popover-arrow::after {
  bottom: -6px;
  margin-left: -6px;
  border-width: 6px 6px 0;
  border-top-color: #fff;
  border-top-color: var(--rs-bg-overlay); }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-popover[class*='placement-top'] > .rs-popover-arrow::after {
    bottom: -7px; } }

@supports (-ms-ime-align: auto) {
  .rs-popover[class*='placement-top'] > .rs-popover-arrow::after {
    bottom: -7px; } }

.rs-theme-high-contrast .rs-popover[class*='placement-top'] > .rs-popover-arrow::before {
  bottom: -7px;
  margin-left: -7px;
  border-width: 7px 7px 0;
  border-top-color: #e5e5ea;
  border-top-color: var(--rs-border-primary); }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-theme-high-contrast .rs-popover[class*='placement-top'] > .rs-popover-arrow::before {
    bottom: -8px; } }

@supports (-ms-ime-align: auto) {
  .rs-theme-high-contrast .rs-popover[class*='placement-top'] > .rs-popover-arrow::before {
    bottom: -8px; } }

/* rtl:begin:ignore */
.rs-popover[class*='placement-right'] {
  margin-left: 8px;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0); }

.rs-popover[class*='placement-right'].rs-anim-in {
  -webkit-transform: translate(2px, 0);
  transform: translate(2px, 0); }

.rs-popover[class*='placement-right'] > .rs-popover-arrow {
  left: -6px;
  margin-top: -6px; }

.rs-popover[class*='placement-right'] > .rs-popover-arrow::after {
  left: -6px;
  margin-top: -6px;
  border-width: 6px 6px 6px 0;
  border-right-color: #fff;
  border-right-color: var(--rs-bg-overlay); }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-popover[class*='placement-right'] > .rs-popover-arrow::after {
    left: -7px; } }

@supports (-ms-ime-align: auto) {
  .rs-popover[class*='placement-right'] > .rs-popover-arrow::after {
    left: -7px; } }

.rs-theme-high-contrast .rs-popover[class*='placement-right'] > .rs-popover-arrow::before {
  left: -7px;
  margin-top: -7px;
  border-width: 7px 7px 7px 0;
  border-right-color: #e5e5ea;
  border-right-color: var(--rs-border-primary); }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-theme-high-contrast .rs-popover[class*='placement-right'] > .rs-popover-arrow::before {
    left: -8px; } }

@supports (-ms-ime-align: auto) {
  .rs-theme-high-contrast .rs-popover[class*='placement-right'] > .rs-popover-arrow::before {
    left: -8px; } }

.rs-popover[class*='placement-left'] {
  margin-left: -8px;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0); }

.rs-popover[class*='placement-left'].rs-anim-in {
  -webkit-transform: translate(-2px, 0);
  transform: translate(-2px, 0); }

.rs-popover[class*='placement-left'] > .rs-popover-arrow {
  right: -6px;
  margin-top: -6px; }

.rs-popover[class*='placement-left'] > .rs-popover-arrow::after {
  right: -6px;
  margin-top: -6px;
  border-width: 6px 0 6px 6px;
  border-left-color: #fff;
  border-left-color: var(--rs-bg-overlay); }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-popover[class*='placement-left'] > .rs-popover-arrow::after {
    right: -7px; } }

@supports (-ms-ime-align: auto) {
  .rs-popover[class*='placement-left'] > .rs-popover-arrow::after {
    right: -7px; } }

.rs-theme-high-contrast .rs-popover[class*='placement-left'] > .rs-popover-arrow::before {
  right: -7px;
  margin-top: -7px;
  border-width: 7px 0 7px 7px;
  border-left-color: #e5e5ea;
  border-left-color: var(--rs-border-primary); }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-theme-high-contrast .rs-popover[class*='placement-left'] > .rs-popover-arrow::before {
    right: -8px; } }

@supports (-ms-ime-align: auto) {
  .rs-theme-high-contrast .rs-popover[class*='placement-left'] > .rs-popover-arrow::before {
    right: -8px; } }

/* rtl:end:ignore */
.rs-popover[class*='placement-bottom'] {
  margin-top: 8px;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0); }

.rs-popover[class*='placement-bottom'].rs-anim-in {
  -webkit-transform: translate(0, 2px);
  transform: translate(0, 2px); }

.rs-popover[class*='placement-bottom'] > .rs-popover-arrow {
  margin-left: -6px;
  top: -6px; }

.rs-popover[class*='placement-bottom'] > .rs-popover-arrow::after {
  top: -6px;
  margin-left: -6px;
  border-width: 0 6px 6px;
  border-bottom-color: #fff;
  border-bottom-color: var(--rs-bg-overlay); }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-popover[class*='placement-bottom'] > .rs-popover-arrow::after {
    top: -7px; } }

@supports (-ms-ime-align: auto) {
  .rs-popover[class*='placement-bottom'] > .rs-popover-arrow::after {
    top: -7px; } }

.rs-theme-high-contrast .rs-popover[class*='placement-bottom'] > .rs-popover-arrow::before {
  top: -7px;
  margin-left: -7px;
  border-width: 0 7px 7px;
  border-bottom-color: #e5e5ea;
  border-bottom-color: var(--rs-border-primary); }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-theme-high-contrast .rs-popover[class*='placement-bottom'] > .rs-popover-arrow::before {
    top: -8px; } }

@supports (-ms-ime-align: auto) {
  .rs-theme-high-contrast .rs-popover[class*='placement-bottom'] > .rs-popover-arrow::before {
    top: -8px; } }

.rs-popover.placement-bottom > .rs-popover-arrow,
.rs-popover.placement-top > .rs-popover-arrow {
  left: 50%; }

.rs-popover.placement-bottom-end > .rs-popover-arrow,
.rs-popover.placement-top-end > .rs-popover-arrow {
  right: 4px; }

.rs-popover.placement-left > .rs-popover-arrow,
.rs-popover.placement-right > .rs-popover-arrow {
  top: 50%; }

.rs-popover.placement-left-end > .rs-popover-arrow,
.rs-popover.placement-right-end > .rs-popover-arrow {
  bottom: 4px; }

.rs-popover-full {
  padding: 0; }

.rs-popover-full .rs-popover-content {
  margin-top: 0; }

.rs-popover.placement-top-start.rs-popover-full > .rs-popover-arrow,
.rs-popover.placement-bottom-start.rs-popover-full > .rs-popover-arrow {
  margin-left: 6px; }

.rs-popover.placement-right-start.rs-popover-full > .rs-popover-arrow,
.rs-popover.placement-left-start.rs-popover-full > .rs-popover-arrow {
  margin-top: 6px; }

@-webkit-keyframes progress-active {
  0% {
    opacity: 0.1;
    width: 0; }
  20% {
    opacity: 0.5;
    width: 0; }
  100% {
    opacity: 0;
    width: 100%; } }

@keyframes progress-active {
  0% {
    opacity: 0.1;
    width: 0; }
  20% {
    opacity: 0.5;
    width: 0; }
  100% {
    opacity: 0;
    width: 100%; } }

@-webkit-keyframes progress-active-vertical {
  0% {
    opacity: 0.1;
    height: 0; }
  20% {
    opacity: 0.5;
    height: 0; }
  100% {
    opacity: 0;
    height: 100%; } }

@keyframes progress-active-vertical {
  0% {
    opacity: 0.1;
    height: 0; }
  20% {
    opacity: 0.5;
    height: 0; }
  100% {
    opacity: 0;
    height: 100%; } }

.rs-progress-info {
  font-size: 0; }

.rs-progress,
.rs-progress-icon-success,
.rs-progress-icon-fail,
.rs-progress-info-status {
  font-size: 16px;
  line-height: 1.25; }

.rs-progress-circle {
  position: relative;
  font-size: 0; }

.rs-progress-circle-info {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  font-size: 16px;
  line-height: 2.25;
  height: 36px;
  color: #8e8e93;
  color: var(--rs-text-secondary); }

.rs-progress-circle .rs-progress-trail {
  stroke: #e5e5ea;
  stroke: var(--rs-progress-bg); }

.rs-progress-circle .rs-progress-stroke {
  stroke: #3498ff;
  stroke: var(--rs-progress-bar);
  -webkit-transition: stroke-dashoffset 0.3s ease, stroke-dasharray 0.2s ease-in-out, stroke 0.3s;
  transition: stroke-dashoffset 0.3s ease, stroke-dasharray 0.2s ease-in-out, stroke 0.3s; }

.rs-progress-circle-success .rs-progress-circle-info {
  color: #4caf50;
  color: var(--rs-progress-bar-success); }

.rs-progress-circle-success .rs-progress-stroke {
  stroke: #4caf50;
  stroke: var(--rs-progress-bar-success); }

.rs-progress-circle-fail .rs-progress-circle-info {
  color: #f44336;
  color: var(--rs-progress-bar-fail); }

.rs-progress-circle-fail .rs-progress-stroke {
  stroke: #f44336;
  stroke: var(--rs-progress-bar-fail); }

.rs-progress-line {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 8px 12px; }

.rs-progress-line-active .rs-progress-line-bg {
  position: relative; }

.rs-progress-line-active .rs-progress-line-bg::before {
  content: '';
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  border-radius: 10px;
  -webkit-animation: progress-active 2s cubic-bezier(0.23, 1, 0.32, 1) infinite;
  animation: progress-active 2s cubic-bezier(0.23, 1, 0.32, 1) infinite; }

.rs-progress-line .rs-progress-info {
  width: 50px;
  -ms-flex: 0 0 50px;
  flex: 0 0 50px;
  height: 20px;
  padding-left: 12px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center; }

.rs-progress-line-outer {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  border-radius: 5px;
  overflow: hidden;
  vertical-align: middle; }

.rs-progress-line-inner {
  background: #e5e5ea;
  background: var(--rs-progress-bg);
  border-radius: 5px; }

.rs-progress-line-bg {
  background-color: #3498ff;
  background-color: var(--rs-progress-bar);
  border-radius: 5px;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  -webkit-transition-property: width, background-color;
  transition-property: width, background-color; }

.rs-progress-line-inner,
.rs-progress-line-bg {
  height: 10px; }

.rs-progress-line-success {
  color: #4caf50;
  color: var(--rs-progress-bar-success); }

.rs-progress-line-success .rs-progress-line-bg {
  background-color: #4caf50;
  background-color: var(--rs-progress-bar-success); }

.rs-progress-line-fail {
  color: #f44336;
  color: var(--rs-progress-bar-fail); }

.rs-progress-line-fail .rs-progress-line-bg {
  background-color: #f44336;
  background-color: var(--rs-progress-bar-fail); }

.rs-progress-line.rs-progress-line-vertical {
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  width: auto; }

.rs-progress-line.rs-progress-line-vertical .rs-progress-line-outer {
  width: auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto; }

.rs-progress-line.rs-progress-line-vertical .rs-progress-line-inner {
  min-height: 100px;
  width: 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  height: 100%; }

.rs-progress-line.rs-progress-line-vertical .rs-progress-info {
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  padding-left: 0;
  width: auto; }

.rs-progress-line.rs-progress-line-vertical .rs-progress-line-bg {
  width: 100%;
  -webkit-transition-property: height, background-color;
  transition-property: height, background-color;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  position: absolute;
  bottom: 0; }

.rs-progress-line.rs-progress-line-vertical.rs-progress-line-active .rs-progress-line-bg::before {
  -webkit-animation-name: progress-active-vertical;
  animation-name: progress-active-vertical; }

.rs-progress-icon-success,
.rs-progress-icon-fail {
  display: inline-block; }

.rs-progress-icon-success svg,
.rs-progress-icon-fail svg {
  stroke: currentColor;
  stroke-width: 0.5px;
  stroke-linecap: round; }

.rs-progress-line .rs-progress-icon-success,
.rs-progress-line .rs-progress-icon-fail {
  line-height: 0;
  font-size: 14px; }

.rs-progress-circle .rs-progress-icon-success,
.rs-progress-circle .rs-progress-icon-fail {
  line-height: 0;
  font-size: 36px; }

.rs-radio {
  position: relative;
  display: inline-block;
  vertical-align: middle; }

.rs-radio-disabled label {
  cursor: not-allowed; }

.rs-radio label {
  line-height: 1.14285714;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer; }

.rs-radio-inner::before {
  border-color: #3498ff;
  border-color: var(--rs-checkbox-checked-bg); }

.rs-radio.rs-radio-disabled label {
  cursor: not-allowed; }

.rs-radio-disabled > .rs-radio-checker > label {
  color: #c5c6c7;
  color: var(--rs-text-disabled); }

.rs-radio-inline {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: normal;
  margin-top: 0;
  margin-right: 10px; }

.rs-plaintext .rs-radio-inline:first-child {
  margin-left: 0; }

.rs-radio [type='radio']:focus ~ .rs-radio-inner::before {
  -webkit-box-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
  -webkit-box-shadow: var(--rs-state-focus-shadow);
  box-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
  box-shadow: var(--rs-state-focus-shadow); }

.rs-radio-checker {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 36px;
  min-height: 36px;
  line-height: 1;
  position: relative; }

.rs-radio-wrapper {
  position: absolute;
  width: 16px;
  height: 16px;
  display: inline-block;
  left: 10px;
  top: 10px; }

.rs-radio-wrapper [type='radio'] {
  opacity: 0;
  position: absolute;
  z-index: 1;
  width: 16px;
  height: 16px;
  cursor: pointer; }

.rs-radio-wrapper::before,
.rs-radio-wrapper::after,
.rs-radio-wrapper .rs-radio-inner::before,
.rs-radio-wrapper .rs-radio-inner::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  display: block; }

.rs-radio-wrapper::before,
.rs-radio-wrapper .rs-radio-inner::before {
  width: 16px;
  height: 16px; }

.rs-radio-wrapper::before {
  border: 1px solid #3498ff;
  border: 1px solid var(--rs-radio-checked-bg);
  background-color: transparent;
  border-radius: 50%;
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 0.7;
  visibility: hidden;
  -webkit-transition: opacity 0.2s linear, -webkit-transform 0.2s linear;
  transition: opacity 0.2s linear, -webkit-transform 0.2s linear;
  transition: transform 0.2s linear, opacity 0.2s linear;
  transition: transform 0.2s linear, opacity 0.2s linear, -webkit-transform 0.2s linear; }

.rs-theme-high-contrast .rs-radio-wrapper::before {
  -webkit-transition: none;
  transition: none; }

.rs-radio-checked .rs-radio-wrapper::before {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  opacity: 0;
  visibility: visible; }

.rs-radio-wrapper::after {
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px; }

.rs-radio-inner::before {
  border: 1px solid #d9d9d9;
  border: 1px solid var(--rs-radio-border);
  background-color: transparent;
  border-radius: 50%;
  -webkit-transition: background 0.2s linear, border 0.2s linear, -webkit-box-shadow 0.2s linear;
  transition: background 0.2s linear, border 0.2s linear, -webkit-box-shadow 0.2s linear;
  transition: background 0.2s linear, border 0.2s linear, box-shadow 0.2s linear;
  transition: background 0.2s linear, border 0.2s linear, box-shadow 0.2s linear, -webkit-box-shadow 0.2s linear; }

.rs-theme-high-contrast .rs-radio-inner::before {
  -webkit-transition: none;
  transition: none; }

.rs-radio:hover .rs-radio-inner::before {
  border-color: #3498ff;
  border-color: var(--rs-radio-checked-bg); }

.rs-radio.rs-radio-disabled .rs-radio-inner::before {
  border-color: #f7f7fa;
  border-color: var(--rs-radio-disabled-bg);
  background-color: #f7f7fa;
  background-color: var(--rs-radio-disabled-bg); }

.rs-radio.rs-radio-checked .rs-radio-inner::before {
  border-color: #3498ff;
  border-color: var(--rs-radio-checked-bg);
  background-color: #3498ff;
  background-color: var(--rs-radio-checked-bg); }

.rs-radio.rs-radio-disabled.rs-radio-checked .rs-radio-inner::before {
  opacity: 0.3; }

.rs-theme-high-contrast .rs-radio.rs-radio-disabled.rs-radio-checked .rs-radio-inner::before {
  opacity: 0.5; }

.rs-radio-inner::after {
  width: 6px;
  height: 6px;
  background-color: #fff;
  background-color: var(--rs-radio-marker);
  margin-top: 5px;
  margin-left: 5px;
  border-radius: 50%;
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: opacity 0.2s ease-in, -webkit-transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  transition: opacity 0.2s ease-in, -webkit-transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  transition: opacity 0.2s ease-in, transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  transition: opacity 0.2s ease-in, transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46), -webkit-transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46); }

.rs-theme-high-contrast .rs-radio-inner::after {
  -webkit-transition: none;
  transition: none; }

.rs-radio-checked .rs-radio-inner::after {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1; }

.rs-radio-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }

.rs-radio-group > .rs-radio {
  margin-left: -10px; }

.rs-radio-group-inline {
  -ms-flex-direction: row;
  flex-direction: row; }

.rs-radio-group-picker {
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: #575757;
  color: var(--rs-text-primary);
  border: 1px solid #e5e5ea;
  border: 1px solid var(--rs-border-primary);
  border-radius: 6px; }

.rs-radio-group-picker .rs-radio-inline {
  padding: 0 10px;
  margin: 0; }

.rs-radio-group-picker .rs-radio-inline:first-child {
  padding-left: 12px; }

.rs-radio-group-picker .rs-radio-inline:last-child {
  padding-right: 12px; }

.rs-radio-group-picker .rs-radio-checker {
  padding: 0;
  min-height: auto; }

.rs-radio-group-picker .rs-radio-wrapper {
  display: none; }

.rs-radio-group-picker .rs-radio-checker > label {
  display: inline-block;
  white-space: nowrap;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 0;
  color: #8e8e93;
  color: var(--rs-text-secondary);
  background: none;
  -webkit-transition: color 0.3s linear;
  transition: color 0.3s linear;
  padding: 7px 0 5px;
  border-bottom: 2px solid transparent; }

.rs-theme-high-contrast .rs-radio-group-picker .rs-radio-checker > label {
  -webkit-transition: none;
  transition: none; }

.rs-radio-group-picker .rs-radio-checker > label:hover,
.rs-radio-group-picker .rs-radio-checker > label:active {
  color: #1675e0;
  color: var(--rs-text-active);
  background: none; }

.rs-radio-group-picker .rs-radio-checked .rs-radio-checker > label {
  color: #1675e0;
  color: var(--rs-text-active);
  border-color: #1675e0;
  border-color: var(--rs-text-active); }

.rs-radio-group-picker .rs-radio-disabled .rs-radio-checker {
  opacity: 0.3;
  cursor: not-allowed; }

.rs-radio-group-picker .rs-radio-disabled:not(.rs-radio-checked) .rs-radio-checker > label {
  color: #8e8e93;
  color: var(--rs-text-secondary); }

.rs-radio-tile {
  border-radius: 6px;
  overflow: hidden;
  border: 2px solid #d9d9d9;
  border: 2px solid var(--rs-radio-tile-border);
  padding: 10px;
  position: relative;
  cursor: pointer; }

.rs-radio-tile-label {
  font-weight: bold; }

.rs-radio-tile-content {
  color: #8e8e93;
  color: var(--rs-text-secondary); }

.rs-radio-tile-mark {
  background: #3498ff;
  background: var(--rs-radio-tile-checked-color);
  border-bottom-left-radius: 50%;
  height: 48px;
  position: absolute;
  right: -24px;
  top: -24px;
  width: 48px;
  z-index: 3;
  opacity: 0; }

.rs-radio-tile-mark-icon {
  position: absolute;
  font-size: 16px;
  top: 25px;
  left: 7px;
  color: #fff;
  color: var(--rs-radio-tile-checked-mark-color); }

.rs-radio-tile-checked .rs-radio-tile-mark {
  opacity: 1; }

.rs-radio-tile-checked.rs-radio-tile-disabled {
  border-color: #cce9ff;
  border-color: var(--rs-radio-tile-checked-disabled-color); }

.rs-radio-tile-checked.rs-radio-tile-disabled .rs-radio-tile-mark {
  background-color: #cce9ff;
  background-color: var(--rs-radio-tile-checked-disabled-color); }

.rs-radio-tile-checked,
.rs-radio-tile:hover:not(.rs-radio-tile-disabled) {
  border: 2px solid #3498ff;
  border: 2px solid var(--rs-radio-tile-checked-color); }

.rs-radio-tile-disabled,
.rs-radio-tile-disabled .rs-radio-tile-content {
  color: #c5c6c7;
  color: var(--rs-text-disabled);
  cursor: not-allowed; }

.rs-radio-tile input {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute; }

.rs-radio-tile-icon {
  font-size: 32px;
  font-size: var(--rs-radio-tile-icon-size); }

.rs-ripple {
  position: absolute;
  display: block;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0s;
  transition: 0s; }

.rs-ripple-rippling {
  -webkit-transition: opacity 1s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.55s cubic-bezier(0.4, 0, 0.2, 1);
  transition: opacity 1s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.55s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.55s cubic-bezier(0.4, 0, 0.2, 1), opacity 1s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.55s cubic-bezier(0.4, 0, 0.2, 1), opacity 1s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.55s cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 0; }

.rs-ripple-pond {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none; }

.rs-theme-high-contrast .rs-ripple-pond {
  display: none !important; }

.rs-row {
  margin-left: -5px;
  margin-right: -5px; }

.rs-row::before,
.rs-row::after {
  content: ' ';
  display: table; }

.rs-row::after {
  clear: both; }

.rs-picker-select-menu-items {
  margin-bottom: 6px;
  overflow-y: auto; }

.rs-picker-select-menu-items ul {
  list-style: none;
  margin: 0;
  padding: 0; }

.rs-picker-select-menu-items .rs-picker-menu-group:not(:first-child) {
  margin-top: 6px;
  border-top: 1px solid #e5e5ea;
  border-top: 1px solid var(--rs-border-primary);
  padding-top: 6px; }

.rs-picker-select-menu-items .rs-picker-menu-group-title {
  padding: 8px 12px;
  padding-right: 32px;
  position: relative;
  cursor: pointer;
  color: #272c36;
  color: var(--rs-text-heading); }

.rs-picker-select-menu-items .rs-picker-menu-group-title .rs-picker-menu-group-caret {
  display: inline-block;
  margin-left: 2px;
  position: absolute;
  top: 8px;
  right: 12px;
  padding: 3px;
  color: #8e8e93;
  color: var(--rs-text-secondary); }

.rs-picker-select-menu-items .rs-picker-menu-group.folded .rs-picker-menu-group-caret {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg); }

.rs-picker-select-menu-item {
  display: block;
  padding: 8px 12px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #575757;
  color: var(--rs-text-primary);
  cursor: pointer;
  text-decoration: none;
  width: 100%; }

.rs-picker-select-menu-item:hover,
.rs-picker-select-menu-item:focus,
.rs-picker-select-menu-item.rs-picker-select-menu-item-focus {
  background-color: rgba(204, 233, 255, 0.5);
  background-color: var(--rs-listbox-option-hover-bg);
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text); }

.rs-theme-high-contrast .rs-picker-select-menu-item:hover,
.rs-theme-high-contrast .rs-picker-select-menu-item:focus,
.rs-theme-high-contrast .rs-picker-select-menu-item.rs-picker-select-menu-item-focus {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  outline-width: 2px;
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text);
  text-decoration: underline; }

.rs-theme-high-contrast .rs-theme-high-contrast .rs-picker-select-menu-item:hover,
.rs-theme-high-contrast .rs-theme-high-contrast .rs-picker-select-menu-item:focus,
.rs-theme-high-contrast .rs-theme-high-contrast .rs-picker-select-menu-item.rs-picker-select-menu-item-focus {
  outline-offset: 2px; }

.rs-picker-select-menu-item.rs-picker-select-menu-item-active {
  font-weight: bold;
  color: #1675e0;
  color: var(--rs-listbox-option-selected-text);
  background-color: #f2faff;
  background-color: var(--rs-listbox-option-selected-bg); }

.rs-picker-select-menu-item.rs-picker-select-menu-item-disabled {
  color: #c5c6c7;
  color: var(--rs-listbox-option-disabled-text);
  background: none;
  cursor: not-allowed; }

.grouped .rs-picker-select-menu-item,
.rs-picker-select-menu-group-children .rs-picker-select-menu-item {
  padding-left: 26px; }

.rs-picker-menu-group .rs-picker-select-menu-item {
  padding-left: 26px; }

.rs-picker-menu-group.folded [role='option'] {
  display: none; }

.rs-sidebar-collapse {
  -webkit-transition: width 0.15s ease-in, -webkit-box-flex 0.15s ease-in;
  -webkit-transition: flex 0.15s ease-in, width 0.15s ease-in;
  transition: flex 0.15s ease-in, width 0.15s ease-in;
  transition: flex 0.15s ease-in, width 0.15s ease-in, -ms-flex 0.15s ease-in;
  min-width: 0; }

.rs-sidenav {
  -webkit-transition: width 0.15s ease-in;
  transition: width 0.15s ease-in;
  width: 56px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }

.rs-sidenav-nav {
  list-style: none;
  padding: 0; }

.rs-sidenav-nav .rs-sidenav-item,
.rs-sidenav-nav .rs-dropdown-toggle,
.rs-sidenav-nav .rs-dropdown-item,
.rs-sidenav-nav .rs-dropdown-item-toggle {
  padding: 15px 20px;
  -webkit-transition: color 0.15s ease-out, background-color 0.15s ease-out;
  transition: color 0.15s ease-out, background-color 0.15s ease-out;
  border-radius: 0;
  color: inherit; }

.rs-theme-high-contrast .rs-sidenav-nav .rs-sidenav-item,
.rs-theme-high-contrast .rs-sidenav-nav .rs-dropdown-toggle,
.rs-theme-high-contrast .rs-sidenav-nav .rs-dropdown-item,
.rs-theme-high-contrast .rs-sidenav-nav .rs-dropdown-item-toggle {
  -webkit-transition: none;
  transition: none; }

.rs-sidenav-nav > .rs-sidenav-item,
.rs-sidenav-nav > .rs-dropdown {
  margin: 0 !important; }

.rs-sidenav-nav > .rs-dropdown .rs-dropdown-item-toggle {
  display: block;
  width: 100%;
  text-align: start;
  background: none;
  padding-right: 40px;
  position: relative; }

.rs-sidenav-collapse-in .rs-sidenav-nav > .rs-dropdown .rs-dropdown-item-toggle {
  padding-left: 56px; }

.rs-sidenav-nav > .rs-dropdown .rs-dropdown-item-toggle:focus {
  outline: 0; }

.rs-sidenav-nav > .rs-dropdown .rs-dropdown-item-toggle:focus-visible {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  z-index: 1; }

.rs-theme-high-contrast .rs-sidenav-nav > .rs-dropdown .rs-dropdown-item-toggle:focus-visible {
  outline-offset: 2px; }

.rs-theme-high-contrast .rs-sidenav-nav > .rs-dropdown .rs-dropdown-item-toggle:focus-visible {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  outline-width: 2px; }

.rs-theme-high-contrast .rs-theme-high-contrast .rs-sidenav-nav > .rs-dropdown .rs-dropdown-item-toggle:focus-visible {
  outline-offset: 2px; }

.rs-sidenav-nav > .rs-dropdown .rs-dropdown-item-toggle-icon {
  font-size: 16px;
  position: absolute;
  right: 20px;
  top: 11px; }

.rs-sidenav-nav > .rs-dropdown .rs-dropdown-item-expand-icon {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg); }

.rs-sidenav-nav > .rs-dropdown .rs-dropdown-item-collapse-icon {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg); }

.rs-theme-high-contrast .rs-sidenav-nav .rs-dropdown-toggle,
.rs-theme-high-contrast .rs-sidenav-nav .rs-dropdown-menu {
  border: none; }

.rs-sidenav-nav .rs-sidenav-item:focus-visible {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  z-index: 1; }

.rs-theme-high-contrast .rs-sidenav-nav .rs-sidenav-item:focus-visible {
  outline-offset: 2px; }

.rs-theme-high-contrast .rs-sidenav-nav .rs-sidenav-item:focus-visible {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  outline-width: 2px; }

.rs-theme-high-contrast .rs-theme-high-contrast .rs-sidenav-nav .rs-sidenav-item:focus-visible {
  outline-offset: 2px; }

.rs-sidenav-nav > .rs-dropdown .rs-dropdown-menu-item-focus,
.rs-sidenav-nav > .rs-dropdown .rs-dropdown-item-active {
  font-weight: normal;
  background: transparent; }

.rs-sidenav-nav > .rs-dropdown .rs-dropdown-menu {
  -webkit-transition: height 0.15s ease-in;
  transition: height 0.15s ease-in; }

.rs-sidenav-nav > .rs-dropdown .rs-dropdown-toggle {
  height: auto;
  padding-right: 40px; }

.rs-sidenav-nav > .rs-dropdown .rs-dropdown-toggle .rs-dropdown-toggle-caret {
  font-size: 16px;
  right: 20px;
  top: 15px; }

.rs-sidenav-nav > .rs-dropdown .rs-dropdown-toggle .rs-dropdown-toggle-caret[aria-label='angle-right'] {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg); }

.rs-sidenav-nav > .rs-sidenav-item,
.rs-sidenav-nav > .rs-dropdown .rs-dropdown-toggle {
  font-size: 16px;
  line-height: 1.25;
  padding-left: 56px;
  width: 100%;
  white-space: normal; }

.rs-sidenav-nav > .rs-sidenav-item:focus-visible,
.rs-sidenav-nav > .rs-dropdown .rs-dropdown-toggle:focus-visible {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px; }

.rs-theme-high-contrast .rs-sidenav-nav > .rs-sidenav-item:focus-visible,
.rs-theme-high-contrast .rs-sidenav-nav > .rs-dropdown .rs-dropdown-toggle:focus-visible {
  outline-offset: 2px; }

.rs-sidenav-nav > .rs-sidenav-item > .rs-icon:not(.rs-dropdown-toggle-caret),
.rs-sidenav-nav > .rs-dropdown .rs-dropdown-toggle > .rs-icon:not(.rs-dropdown-toggle-caret) {
  font-size: 16px;
  margin-right: 20px;
  position: absolute;
  left: 20px;
  top: 15px;
  line-height: 1.25;
  height: 16px; }

.rs-sidenav-nav > .rs-dropdown-focus .rs-dropdown-toggle,
.rs-sidenav-nav .rs-dropdown-toggle:focus-visible {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  z-index: 2; }

.rs-theme-high-contrast .rs-sidenav-nav > .rs-dropdown-focus .rs-dropdown-toggle,
.rs-theme-high-contrast .rs-sidenav-nav .rs-dropdown-toggle:focus-visible {
  outline-offset: 2px; }

.rs-theme-high-contrast .rs-sidenav-nav > .rs-dropdown-focus .rs-dropdown-toggle,
.rs-theme-high-contrast .rs-sidenav-nav .rs-dropdown-toggle:focus-visible {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  outline-width: 2px; }

.rs-theme-high-contrast .rs-theme-high-contrast .rs-sidenav-nav > .rs-dropdown-focus .rs-dropdown-toggle,
.rs-theme-high-contrast .rs-theme-high-contrast .rs-sidenav-nav .rs-dropdown-toggle:focus-visible {
  outline-offset: 2px; }

.rs-sidenav-item {
  position: relative;
  display: block;
  outline: 0;
  overflow: hidden; }

.rs-sidenav-item:hover,
.rs-sidenav-item:focus {
  text-decoration: none; }

.rs-sidenav-item:focus-visible,
.rs-sidenav-item.rs-sidenav-item-focus {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  outline-width: 2px;
  z-index: 2; }

.rs-theme-high-contrast .rs-sidenav-item:focus-visible,
.rs-theme-high-contrast .rs-sidenav-item.rs-sidenav-item-focus {
  outline-offset: 2px; }

.rs-sidenav-toggle {
  height: 50px;
  border-top: 1px solid;
  margin-top: auto; }

.rs-sidenav-toggle-button {
  float: right;
  width: 56px;
  height: 100%;
  border: none;
  border-radius: 0;
  color: inherit; }

.rs-sidenav-collapse-in {
  width: 100%; }

.rs-sidenav-collapse-in .rs-sidenav-item {
  border-radius: 0; }

.rs-sidenav-collapse-in .rs-dropdown {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -ms-flex-direction: column;
  flex-direction: column; }

.rs-sidenav-collapse-in .rs-dropdown-item:focus-visible,
.rs-sidenav-collapse-in .rs-dropdown-item-focus {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  background: none;
  z-index: 2; }

.rs-theme-high-contrast .rs-sidenav-collapse-in .rs-dropdown-item:focus-visible,
.rs-theme-high-contrast .rs-sidenav-collapse-in .rs-dropdown-item-focus {
  outline-offset: 2px; }

.rs-theme-high-contrast .rs-sidenav-collapse-in .rs-dropdown-item:focus-visible,
.rs-theme-high-contrast .rs-sidenav-collapse-in .rs-dropdown-item-focus {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  outline-width: 2px; }

.rs-theme-high-contrast .rs-theme-high-contrast .rs-sidenav-collapse-in .rs-dropdown-item:focus-visible,
.rs-theme-high-contrast .rs-theme-high-contrast .rs-sidenav-collapse-in .rs-dropdown-item-focus {
  outline-offset: 2px; }

.rs-sidenav-collapse-in .rs-dropdown-item:focus-visible.rs-dropdown-item-submenu,
.rs-sidenav-collapse-in .rs-dropdown-item-focus.rs-dropdown-item-submenu {
  -webkit-box-shadow: none;
  box-shadow: none; }

.rs-sidenav-collapse-in .rs-dropdown-item:focus-visible.rs-dropdown-item-submenu > .rs-dropdown-item-toggle,
.rs-sidenav-collapse-in .rs-dropdown-item-focus.rs-dropdown-item-submenu > .rs-dropdown-item-toggle {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  z-index: 2; }

.rs-theme-high-contrast .rs-sidenav-collapse-in .rs-dropdown-item:focus-visible.rs-dropdown-item-submenu > .rs-dropdown-item-toggle,
.rs-theme-high-contrast .rs-sidenav-collapse-in .rs-dropdown-item-focus.rs-dropdown-item-submenu > .rs-dropdown-item-toggle {
  outline-offset: 2px; }

.rs-theme-high-contrast .rs-sidenav-collapse-in .rs-dropdown-item:focus-visible.rs-dropdown-item-submenu > .rs-dropdown-item-toggle,
.rs-theme-high-contrast .rs-sidenav-collapse-in .rs-dropdown-item-focus.rs-dropdown-item-submenu > .rs-dropdown-item-toggle {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  outline-width: 2px; }

.rs-theme-high-contrast .rs-theme-high-contrast .rs-sidenav-collapse-in .rs-dropdown-item:focus-visible.rs-dropdown-item-submenu > .rs-dropdown-item-toggle,
.rs-theme-high-contrast .rs-theme-high-contrast .rs-sidenav-collapse-in .rs-dropdown-item-focus.rs-dropdown-item-submenu > .rs-dropdown-item-toggle {
  outline-offset: 2px; }

.rs-sidenav-collapse-in .rs-dropdown-item:not(.rs-dropdown-item-submenu),
.rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-item-toggle {
  overflow: hidden;
  position: relative; }

@media not all and (min-resolution: 0.001dpcm) {
  .rs-sidenav-collapse-in .rs-dropdown-item:not(.rs-dropdown-item-submenu),
  .rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-item-toggle {
    /* stylelint-disable */
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    /* stylelint-enable */ } }

.rs-sidenav-collapse-in .rs-dropdown-item-disabled .rs-sidenav-collapse-in .rs-dropdown-item::after {
  display: none; }

.rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-toggle-caret::before,
.rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-item-toggle > .rs-dropdown-item-toggle-icon::before {
  display: none; }

.rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-toggle-caret,
.rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-item-toggle > .rs-dropdown-item-toggle-icon {
  -webkit-transition: -webkit-transform 0.3s linear;
  transition: -webkit-transform 0.3s linear;
  transition: transform 0.3s linear;
  transition: transform 0.3s linear, -webkit-transform 0.3s linear; }

.rs-sidenav-collapse-in .rs-dropdown.rs-dropdown-expand .rs-dropdown-toggle-caret {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg); }

.rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-menu {
  display: block;
  position: static;
  float: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-animation-name: none;
  animation-name: none;
  padding: 0;
  background-color: transparent;
  border-radius: 0; }

.rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-item-submenu {
  padding: 0; }

.rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-item-submenu:hover {
  background-color: transparent; }

.rs-sidenav-collapse-in .rs-dropdown-item:not(.rs-dropdown-item-submenu),
.rs-sidenav-collapse-in .rs-dropdown-menu .rs-dropdown-menu-toggle {
  width: 100%;
  white-space: normal; }

.rs-sidenav-collapse-in .rs-dropdown-menu {
  overflow: hidden; }

.rs-sidenav-collapse-in .rs-dropdown.rs-dropdown-collapse > .rs-dropdown-menu.rs-dropdown-menu-collapse-out,
.rs-sidenav-collapse-in .rs-dropdown-item-submenu.rs-dropdown-item-collapse > .rs-dropdown-menu.rs-dropdown-menu-collapse-out {
  display: none; }

.rs-sidenav-collapse-in .rs-dropdown.rs-dropdown-menu-collapse-in > .rs-dropdown-menu.dropdown-menu-collapse-in,
.rs-sidenav-collapse-in .rs-dropdown-item-submenu.rs-dropdown-menu-collapse-in > .rs-dropdown-menu.rs-dropdown-menu-collapse-in {
  display: block; }

.rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-item-menu-icon {
  padding-top: 11px;
  padding-bottom: 11px;
  line-height: 1.42857143;
  width: 14px;
  position: absolute;
  z-index: 1; }

.rs-sidenav-collapse-in .rs-dropdown-item,
.rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-item-toggle {
  padding: 11px 20px 11px 56px; }

.rs-sidenav-collapse-in .rs-dropdown-item.rs-dropdown-item-with-icon > .rs-dropdown-item-content > .rs-dropdown-item-menu-icon {
  left: 56px; }

.rs-sidenav-collapse-in .rs-dropdown-item.rs-dropdown-item-with-icon > .rs-dropdown-item-content > .rs-dropdown-menu-toggle {
  padding-left: 76px; }

.rs-sidenav-collapse-in .rs-dropdown-item .rs-dropdown-item:not(.rs-dropdown-item-submenu),
.rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-menu .rs-dropdown-item-toggle {
  padding-left: 65px; }

.rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-menu > .rs-dropdown-item.rs-dropdown-item-with-icon > .rs-dropdown-item-content > .rs-dropdown-item-menu-icon {
  left: 65px; }

.rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-menu > .rs-dropdown-item.rs-dropdown-item-with-icon > .rs-dropdown-item-content > .rs-dropdown-menu-toggle {
  padding-left: 85px; }

.rs-sidenav-collapse-out .rs-sidenav-item,
.rs-sidenav-collapse-out .rs-dropdown .rs-dropdown-toggle {
  padding-right: 0;
  height: 50px;
  padding-left: 56px; }

.rs-sidenav-collapse-out .rs-sidenav-item:focus-visible,
.rs-sidenav-collapse-out .rs-dropdown .rs-dropdown-toggle:focus-visible {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring); }

.rs-theme-high-contrast .rs-sidenav-collapse-out .rs-sidenav-item:focus-visible,
.rs-theme-high-contrast .rs-sidenav-collapse-out .rs-dropdown .rs-dropdown-toggle:focus-visible {
  outline-offset: 2px; }

.rs-theme-high-contrast .rs-sidenav-collapse-out .rs-sidenav-item:focus-visible,
.rs-theme-high-contrast .rs-sidenav-collapse-out .rs-dropdown .rs-dropdown-toggle:focus-visible {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  outline-width: 2px; }

.rs-theme-high-contrast .rs-theme-high-contrast .rs-sidenav-collapse-out .rs-sidenav-item:focus-visible,
.rs-theme-high-contrast .rs-theme-high-contrast .rs-sidenav-collapse-out .rs-dropdown .rs-dropdown-toggle:focus-visible {
  outline-offset: 2px; }

.rs-sidenav-collapse-out .rs-dropdown-item {
  padding: 8px 27px 8px 12px; }

.rs-sidenav-collapse-out .rs-dropdown-item-submenu {
  padding: 0; }

.rs-sidenav-collapse-out .rs-dropdown-toggle-caret {
  display: none; }

.rs-sidenav-collapse-out .rs-sidenav-item-text,
.rs-sidenav-collapse-out .rs-dropdown-toggle > span {
  -webkit-animation: sideNavFoldedText 0.15s ease-in forwards;
  animation: sideNavFoldedText 0.15s ease-in forwards; }

.rs-sidenav-collapsing.rs-sidenav-collapse-in .rs-sidenav-item,
.rs-sidenav-collapsing.rs-sidenav-collapse-out .rs-sidenav-item,
.rs-sidenav-collapsing.rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-toggle,
.rs-sidenav-collapsing.rs-sidenav-collapse-out .rs-dropdown .rs-dropdown-toggle,
.rs-sidenav-collapsing.rs-sidenav-collapse-in .rs-dropdown-item,
.rs-sidenav-collapsing.rs-sidenav-collapse-out .rs-dropdown-item,
.rs-sidenav-collapsing.rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-menu-toggle,
.rs-sidenav-collapsing.rs-sidenav-collapse-out .rs-dropdown-item-submenu > .rs-dropdown-menu-toggle {
  white-space: nowrap;
  text-overflow: clip; }

.rs-sidenav-collapsing.rs-sidenav-collapse-in .rs-dropdown-toggle-caret {
  display: none; }

.rs-sidenav-collapsing.rs-sidenav-collapse-out .rs-dropdown .rs-dropdown-menu {
  padding-left: 0px; }

.rs-sidenav-collapsing.rs-sidenav-collapse-out .rs-dropdown-item {
  -webkit-animation: sideNavFoldedText 0.15s ease-in;
  animation: sideNavFoldedText 0.15s ease-in; }

.rs-sidenav-default {
  background-color: #f7f7fa;
  background-color: var(--rs-sidenav-default-bg);
  color: #575757;
  color: var(--rs-sidenav-default-text); }

.rs-sidenav-default .rs-sidenav-item,
.rs-sidenav-default .rs-dropdown-toggle,
.rs-sidenav-default .rs-sidenav-toggle-button {
  background-color: #f7f7fa;
  background-color: var(--rs-sidenav-default-bg); }

.rs-sidenav-default .rs-sidenav-item:hover,
.rs-sidenav-default .rs-dropdown .rs-dropdown-toggle:hover,
.rs-sidenav-default .rs-sidenav-toggle-button:hover,
.rs-sidenav-default .rs-dropdown.rs-dropdown-open .rs-dropdown-toggle {
  background-color: #e5e5ea;
  background-color: var(--rs-sidenav-default-hover-bg);
  color: #575757;
  color: var(--rs-sidenav-default-hover-text); }

.rs-sidenav-default .rs-sidenav-item.rs-sidenav-item-active,
.rs-sidenav-default .rs-dropdown-item.rs-dropdown-item-active,
.rs-sidenav-default .rs-dropdown.rs-dropdown-selected-within .rs-sidenav-item-icon {
  color: #1675e0;
  color: var(--rs-sidenav-default-selected-text); }

.rs-sidenav-default .rs-sidenav-toggle {
  border-top-color: #e5e5ea;
  border-top-color: var(--rs-sidenav-default-footer-border); }

.rs-sidenav-default.rs-sidenav-collapse-out .rs-dropdown-item.rs-dropdown-item-active {
  background-color: rgba(204, 233, 255, 0.5);
  background-color: var(--rs-menuitem-active-bg);
  color: #1675e0;
  color: var(--rs-menuitem-active-text); }

.rs-theme-high-contrast .rs-sidenav-default.rs-sidenav-collapse-out .rs-dropdown-item.rs-dropdown-item-active {
  text-decoration: underline;
  -webkit-box-shadow: inset 0 0 0 2px #fff;
  box-shadow: inset 0 0 0 2px #fff; }

.rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-menu-toggle,
.rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-menu-toggle,
.rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-item-toggle,
.rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-item-toggle {
  color: #575757;
  color: var(--rs-sidenav-default-text); }

.rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-menu-toggle:hover,
.rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-menu-toggle:hover,
.rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-item-toggle:hover,
.rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-item-toggle:hover,
.rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-menu-toggle:focus,
.rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-menu-toggle:focus,
.rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-item-toggle:focus,
.rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-item-toggle:focus,
.rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-menu-toggle.rs-dropdown-item-focus,
.rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-menu-toggle.rs-dropdown-item-focus,
.rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-item-toggle.rs-dropdown-item-focus,
.rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-item-toggle.rs-dropdown-item-focus {
  background-color: #e5e5ea;
  background-color: var(--rs-sidenav-default-hover-bg);
  color: #575757;
  color: var(--rs-sidenav-default-hover-text); }

.rs-sidenav-default.rs-sidenav-collapse-in .rs-sidenav-item.rs-sidenav-item-active,
.rs-sidenav-default.rs-sidenav-collapsing .rs-sidenav-item.rs-sidenav-item-active,
.rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-menu > .rs-dropdown-item.rs-dropdown-item-active,
.rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-menu > .rs-dropdown-item.rs-dropdown-item-active {
  color: #1675e0;
  color: var(--rs-sidenav-default-selected-text); }

.rs-theme-high-contrast .rs-sidenav-default.rs-sidenav-collapse-in .rs-sidenav-item.rs-sidenav-item-active,
.rs-theme-high-contrast .rs-sidenav-default.rs-sidenav-collapsing .rs-sidenav-item.rs-sidenav-item-active,
.rs-theme-high-contrast .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-menu > .rs-dropdown-item.rs-dropdown-item-active,
.rs-theme-high-contrast .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-menu > .rs-dropdown-item.rs-dropdown-item-active {
  text-decoration: underline; }

.rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown.rs-dropdown-selected-within .rs-sidenav-item-icon,
.rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown.rs-dropdown-selected-within .rs-sidenav-item-icon,
.rs-sidenav-default.rs-sidenav-collapse-out .rs-dropdown.rs-dropdown-selected-within .rs-sidenav-item-icon {
  color: #1675e0;
  color: var(--rs-sidenav-default-selected-text); }

.rs-sidenav-inverse {
  background-color: #3498ff;
  background-color: var(--rs-sidenav-inverse-bg);
  color: #fff;
  color: var(--rs-sidenav-inverse-text); }

.rs-sidenav-inverse .rs-sidenav-item,
.rs-sidenav-inverse .rs-dropdown .rs-dropdown-toggle,
.rs-sidenav-inverse .rs-sidenav-toggle-button,
.rs-sidenav-inverse .rs-dropdown-item-submenu:hover > .rs-dropdown-item-toggle {
  background-color: #3498ff;
  background-color: var(--rs-sidenav-inverse-bg); }

.rs-sidenav-inverse .rs-sidenav-item:hover,
.rs-sidenav-inverse .rs-dropdown .rs-dropdown-toggle:hover,
.rs-sidenav-inverse .rs-sidenav-toggle-button:hover,
.rs-sidenav-inverse .rs-dropdown-item-submenu:hover > .rs-dropdown-item-toggle:hover,
.rs-sidenav-inverse .rs-sidenav-item:focus,
.rs-sidenav-inverse .rs-dropdown .rs-dropdown-toggle:focus,
.rs-sidenav-inverse .rs-sidenav-toggle-button:focus,
.rs-sidenav-inverse .rs-dropdown-item-submenu:hover > .rs-dropdown-item-toggle:focus {
  background-color: #2589f5;
  background-color: var(--rs-sidenav-inverse-hover-bg); }

.rs-sidenav-inverse .rs-dropdown-item,
.rs-sidenav-inverse .rs-dropdown-item > .rs-dropdown-menu-toggle,
.rs-sidenav-inverse .rs-dropdown-item > .rs-dropdown-item-toggle,
.rs-sidenav-inverse .rs-sidenav-toggle-button {
  color: #fff;
  color: var(--rs-sidenav-inverse-text); }

.rs-sidenav-inverse .rs-dropdown-item:hover,
.rs-sidenav-inverse .rs-dropdown-item > .rs-dropdown-menu-toggle:hover,
.rs-sidenav-inverse .rs-dropdown-item > .rs-dropdown-item-toggle:hover,
.rs-sidenav-inverse .rs-sidenav-toggle-button:hover,
.rs-sidenav-inverse .rs-dropdown-item:focus,
.rs-sidenav-inverse .rs-dropdown-item > .rs-dropdown-menu-toggle:focus,
.rs-sidenav-inverse .rs-dropdown-item > .rs-dropdown-item-toggle:focus,
.rs-sidenav-inverse .rs-sidenav-toggle-button:focus,
.rs-sidenav-inverse .rs-dropdown-item.rs-dropdown-item-focus,
.rs-sidenav-inverse .rs-dropdown-item > .rs-dropdown-menu-toggle.rs-dropdown-item-focus,
.rs-sidenav-inverse .rs-dropdown-item > .rs-dropdown-item-toggle.rs-dropdown-item-focus,
.rs-sidenav-inverse .rs-sidenav-toggle-button.rs-dropdown-item-focus {
  background-color: #2589f5;
  background-color: var(--rs-sidenav-inverse-hover-bg);
  color: #fff;
  color: var(--rs-sidenav-inverse-text); }

.rs-theme-high-contrast .rs-sidenav-inverse .rs-sidenav-item.rs-sidenav-item-active,
.rs-theme-high-contrast .rs-sidenav-inverse .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle {
  color: var(--rs-sidenav-inverse-selected-text); }

.rs-sidenav-inverse .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle,
.rs-sidenav-inverse .rs-sidenav-item,
.rs-sidenav-inverse .rs-dropdown .rs-dropdown-toggle {
  color: #fff;
  color: var(--rs-sidenav-inverse-text); }

.rs-sidenav-inverse .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle:hover,
.rs-sidenav-inverse .rs-sidenav-item:hover,
.rs-sidenav-inverse .rs-dropdown .rs-dropdown-toggle:hover,
.rs-sidenav-inverse .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle:focus,
.rs-sidenav-inverse .rs-sidenav-item:focus,
.rs-sidenav-inverse .rs-dropdown .rs-dropdown-toggle:focus,
.rs-sidenav-inverse .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle.rs-dropdown-item-focus,
.rs-sidenav-inverse .rs-sidenav-item.rs-dropdown-item-focus,
.rs-sidenav-inverse .rs-dropdown .rs-dropdown-toggle.rs-dropdown-item-focus {
  color: #fff;
  color: var(--rs-sidenav-inverse-text); }

.rs-sidenav-inverse .rs-sidenav-item.rs-sidenav-item-active,
.rs-sidenav-inverse .rs-dropdown-item.rs-dropdown-item-active {
  background-color: #1675e0;
  background-color: var(--rs-sidenav-inverse-selected-bg); }

.rs-sidenav-inverse .rs-sidenav-toggle {
  border-top-color: #2589f5;
  border-top-color: var(--rs-sidenav-inverse-footer-border); }

.rs-sidenav-subtle {
  background-color: transparent; }

.rs-sidenav-subtle .rs-sidenav-item,
.rs-sidenav-subtle .rs-dropdown-toggle,
.rs-sidenav-subtle .rs-dropdown-item,
.rs-sidenav-subtle .rs-sidenav-toggle-button {
  background-color: transparent;
  color: #8e8e93;
  color: var(--rs-sidenav-subtle-text); }

.rs-sidenav-subtle .rs-sidenav-item:hover,
.rs-sidenav-subtle .rs-dropdown-toggle:hover,
.rs-sidenav-subtle .rs-dropdown-item:hover,
.rs-sidenav-subtle .rs-sidenav-toggle-button:hover,
.rs-sidenav-subtle .rs-sidenav-item:focus-visible,
.rs-sidenav-subtle .rs-dropdown-toggle:focus-visible,
.rs-sidenav-subtle .rs-dropdown-item:focus-visible,
.rs-sidenav-subtle .rs-sidenav-toggle-button:focus-visible {
  background-color: #f7f7fa;
  background-color: var(--rs-sidenav-subtle-hover-bg);
  color: #575757;
  color: var(--rs-sidenav-subtle-hover-text); }

.rs-sidenav-subtle .rs-sidenav-item.rs-sidenav-item-active,
.rs-sidenav-subtle .rs-dropdown-item.rs-dropdown-item-active,
.rs-sidenav-subtle .rs-dropdown.rs-dropdown-selected-within .rs-sidenav-item-icon {
  color: #1675e0;
  color: var(--rs-sidenav-subtle-selected-text); }

.rs-sidenav-subtle .rs-dropdown-open > .rs-dropdown-toggle.btn {
  background-color: transparent; }

.rs-sidenav-subtle .rs-dropdown-open > .rs-dropdown-toggle.btn:hover,
.rs-sidenav-subtle .rs-dropdown-open > .rs-dropdown-toggle.btn:focus {
  background-color: #f7f7fa;
  background-color: var(--rs-sidenav-subtle-hover-bg); }

.rs-sidenav-subtle .rs-sidenav-toggle {
  border-top-color: #e5e5ea;
  border-top-color: var(--rs-sidenav-subtle-footer-border); }

.rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-menu-toggle,
.rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-menu-toggle,
.rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-item-toggle,
.rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-item-toggle {
  color: #8e8e93;
  color: var(--rs-sidenav-subtle-text); }

.rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-menu-toggle:hover,
.rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-menu-toggle:hover,
.rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-item-toggle:hover,
.rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-item-toggle:hover,
.rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-menu-toggle:focus-visible,
.rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-menu-toggle:focus-visible,
.rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-item-toggle:focus-visible,
.rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown-item > .rs-dropdown-item-toggle:focus-visible {
  background-color: #f7f7fa;
  background-color: var(--rs-sidenav-subtle-hover-bg);
  color: #575757;
  color: var(--rs-sidenav-subtle-hover-text); }

.rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown-menu > .rs-dropdown-item.rs-dropdown-item-active,
.rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown-menu > .rs-dropdown-item.rs-dropdown-item-active {
  color: #1675e0;
  color: var(--rs-sidenav-subtle-selected-text); }

.rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle:hover,
.rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle:hover,
.rs-sidenav-subtle.rs-sidenav-collapse-out .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle:hover {
  background-color: #f7f7fa;
  background-color: var(--rs-sidenav-subtle-hover-bg); }

.rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle-icon,
.rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle-icon,
.rs-sidenav-subtle.rs-sidenav-collapse-out .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle-icon {
  color: #1675e0;
  color: var(--rs-sidenav-subtle-selected-text); }

@-webkit-keyframes sideNavFoldedText {
  0% {
    max-width: 200px;
    opacity: 0.8; }
  100% {
    max-width: 0;
    opacity: 0; } }

@keyframes sideNavFoldedText {
  0% {
    max-width: 200px;
    opacity: 0.8; }
  100% {
    max-width: 0;
    opacity: 0; } }

.rs-slider {
  position: relative; }

.rs-slider .rs-tooltip {
  display: none; }

.rs-slider .rs-tooltip.rs-tooltip-placement-top .rs-tooltip::after {
  margin: auto;
  left: 0;
  right: 0; }

.rs-slider-disabled {
  opacity: 0.3;
  cursor: not-allowed; }

.rs-slider-disabled .rs-slider-bar,
.rs-slider-disabled .rs-slider-handle::before {
  cursor: not-allowed; }

.rs-slider-with-mark:not(.rs-slider-vertical) {
  margin-bottom: 29px; }

.rs-slider-bar {
  height: 6px;
  border-radius: 3px;
  background-color: #f2f2f5;
  background-color: var(--rs-slider-bar);
  -webkit-transition: background-color ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s;
  cursor: pointer; }

.rs-slider:hover .rs-slider-bar {
  background-color: #e5e5ea;
  background-color: var(--rs-slider-hover-bar); }

.rs-slider-vertical .rs-slider-bar {
  height: 100%;
  width: 6px; }

.rs-slider-handle {
  position: absolute;
  top: -50%;
  outline: none; }

.rs-slider-handle::before {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #3498ff;
  border: 2px solid var(--rs-slider-thumb-border);
  background-color: #fff;
  background-color: var(--rs-slider-thumb-bg);
  margin-left: -6px;
  cursor: pointer;
  /* stylelint-disable */
  -webkit-transition: background-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out, transform 0.15s ease-in-out;
  transition: box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out, transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  /* stylelint-enable */ }

.rs-slider-handle:hover::before,
.rs-slider-handle:focus::before {
  -webkit-box-shadow: 0 0 0 8px rgba(52, 152, 255, 0.25);
  -webkit-box-shadow: var(--rs-slider-thumb-hover-shadow);
  box-shadow: 0 0 0 8px rgba(52, 152, 255, 0.25);
  box-shadow: var(--rs-slider-thumb-hover-shadow); }

.rs-slider-handle:active::before,
.rs-slider-handle.active::before {
  -webkit-transform: scale(1.2);
  transform: scale(1.2); }

.rs-slider-vertical .rs-slider-handle {
  top: unset; }

.rs-slider-vertical .rs-slider-handle::before {
  left: 3px;
  margin-top: -6px; }

.rs-slider-handle:hover .rs-tooltip,
.rs-slider-handle.active .rs-tooltip {
  display: block;
  opacity: 1;
  top: -30px; }

.rs-slider-vertical .rs-slider-handle:hover .rs-tooltip,
.rs-slider-vertical .rs-slider-handle.active .rs-tooltip {
  top: -33px;
  margin-left: 3px; }

.rs-slider-mark {
  position: absolute;
  top: 15px;
  left: -2px;
  white-space: nowrap; }

.rs-slider-mark-content {
  margin-left: -50%; }

.rs-slider-mark-last {
  left: auto;
  right: -2px; }

.rs-slider-mark-last .rs-slider-mark-content {
  margin-left: 50%; }

.rs-slider-graduator {
  width: 100%; }

.rs-slider-graduator ol,
.rs-slider-graduator li {
  list-style: none; }

.rs-slider-graduator > ol {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  width: 100%; }

.rs-slider-graduator > ol > li {
  -ms-flex: 1 1 1%;
  flex: 1 1 1%;
  position: relative; }

.rs-slider-graduator > ol > li:last-child::after,
.rs-slider-graduator > ol > li::before {
  content: '';
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #fff;
  background-color: var(--rs-slider-thumb-bg);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 2px solid #f2f2f5;
  border: 2px solid var(--rs-slider-bar);
  margin-left: -4px;
  top: -1px; }

.rs-slider-vertical .rs-slider-graduator > ol > li:last-child::after,
.rs-slider-vertical .rs-slider-graduator > ol > li::before {
  top: unset;
  bottom: -4px;
  margin-left: -1px; }

.rs-slider-graduator > ol > li:last-child::after {
  right: -4px; }

.rs-slider-vertical .rs-slider-graduator > ol > li:last-child::after {
  left: 0;
  bottom: unset;
  top: -4px; }

.rs-slider-graduator > ol > li.rs-slider-pass::before {
  border-color: #3498ff;
  border-color: var(--rs-slider-progress); }

.rs-slider-graduator > ol > li.rs-slider-active::before {
  visibility: hidden; }

.rs-slider-vertical .rs-slider-graduator {
  display: block;
  height: 100%; }

.rs-slider-vertical .rs-slider-graduator > ol {
  width: 6px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  height: 100%;
  padding: 0; }

.rs-slider-vertical .rs-slider-graduator > ol > li {
  display: block;
  padding: 0; }

.rs-slider-progress-bar {
  position: absolute;
  height: 6px;
  border-radius: 3px 0 0 3px;
  background-color: #3498ff;
  background-color: var(--rs-slider-progress); }

.rs-slider-vertical .rs-slider-progress-bar {
  width: 6px;
  border-radius: 0 0 3px 3px; }

.rs-slider-vertical {
  height: 100%; }

.rs-slider-vertical .rs-slider-mark {
  top: unset;
  bottom: -8px;
  left: 15px; }

.rs-slider-vertical .rs-slider-mark-content {
  margin-left: auto; }

.rs-slider-vertical .rs-slider-mark-last {
  bottom: unset;
  top: -8px; }

.rs-steps {
  min-height: 30px; }

.rs-steps-item {
  padding-left: 40px;
  position: relative;
  overflow: hidden; }

.rs-steps-item-icon-wrapper {
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 16px;
  line-height: 1.75;
  color: #8e8e93;
  color: var(--rs-text-secondary);
  position: absolute;
  top: 0;
  left: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #8e8e93;
  border: 1px solid var(--rs-steps-border);
  border-radius: 50%; }

.rs-steps-item-status-finish .rs-steps-item-icon-wrapper {
  border-color: #3498ff;
  border-color: var(--rs-steps-state-finish);
  color: #3498ff;
  color: var(--rs-steps-state-finish); }

.rs-steps-item-status-error .rs-steps-item-icon-wrapper {
  border-color: #f44336;
  border-color: var(--rs-steps-state-error);
  color: #f44336;
  color: var(--rs-steps-state-error); }

.rs-steps-item-status-process .rs-steps-item-icon-wrapper {
  border-color: #3498ff;
  border-color: var(--rs-steps-state-process);
  background-color: #3498ff;
  background-color: var(--rs-steps-state-process);
  color: #f7f7fa;
  color: var(--rs-text-inverse); }

.rs-steps-item-status-process .rs-steps-item-icon-wrapper .rs-icon {
  color: #3498ff;
  color: var(--rs-steps-icon-state-process); }

.rs-steps-item-icon-wrapper.rs-steps-item-custom-icon {
  border: none;
  background: none; }

.rs-steps-item-icon-wrapper > .rs-steps-item-icon {
  width: 100%;
  display: block;
  text-align: center;
  position: relative; }

.rs-steps-item-icon-wrapper > .rs-steps-item-icon .rs-icon {
  font-size: 14px;
  vertical-align: middle; }

.rs-steps-item-content {
  color: #8e8e93;
  color: var(--rs-text-secondary);
  display: inline-block;
  position: relative;
  width: 100%; }

.rs-steps-item-status-process .rs-steps-item-content {
  color: #575757;
  color: var(--rs-text-primary); }

.rs-steps-item-status-error .rs-steps-item-content {
  color: #f44336;
  color: var(--rs-text-error); }

.rs-steps-item-title {
  position: relative;
  font-size: 16px;
  line-height: 1.875;
  min-height: 16px;
  padding-right: 10px;
  display: inline-block; }

.rs-steps-item-title:empty {
  padding-right: 0; }

.rs-steps-item-description {
  margin-top: 12px; }

.rs-steps-item-tail,
.rs-steps-item-title::after {
  position: absolute;
  border-color: #8e8e93;
  border-color: var(--rs-steps-border); }

.rs-steps-item-status-process .rs-steps-item-tail,
.rs-steps-item-status-process .rs-steps-item-title::after {
  border-color: #8e8e93;
  border-color: var(--rs-steps-border); }

.rs-steps-item-status-finish .rs-steps-item-tail,
.rs-steps-item-status-finish .rs-steps-item-title::after {
  border-color: #3498ff;
  border-color: var(--rs-steps-state-finish); }

.rs-steps-next-error .rs-steps-item-tail,
.rs-steps-next-error .rs-steps-item-title::after {
  border-color: #f44336;
  border-color: var(--rs-steps-border-state-error); }

.rs-steps-horizontal {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.rs-steps-horizontal .rs-steps-item {
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  min-height: 30px; }

.rs-steps-horizontal .rs-steps-item:last-child {
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0; }

.rs-steps-horizontal .rs-steps-item:last-child .rs-steps-item-title::after {
  display: none; }

.rs-steps-horizontal .rs-steps-item:not(:first-child) {
  padding-left: 50px; }

.rs-steps-horizontal .rs-steps-item:not(:first-child) .rs-steps-item-icon-wrapper {
  left: 10px; }

.rs-steps-horizontal .rs-steps-item-content {
  height: 30px; }

.rs-steps-horizontal .rs-steps-item-title::after {
  content: '';
  top: 15px;
  left: 100%;
  width: 9999px;
  border-top-width: 1px;
  border-top-style: solid; }

.rs-steps-vertical .rs-steps-item {
  padding-bottom: 20px; }

.rs-steps-vertical .rs-steps-item:not(:first-child) {
  margin-top: 10px; }

.rs-steps-vertical .rs-steps-item:last-child .rs-steps-item-tail {
  display: none; }

.rs-steps-vertical .rs-steps-item-tail {
  top: 40px;
  bottom: 0;
  left: 15px;
  border-left-width: 1px;
  border-left-style: solid; }

.rs-steps-small {
  min-height: 24px; }

.rs-steps-small.rs-steps-horizontal .rs-steps-item {
  min-height: 24px; }

.rs-steps-small.rs-steps-horizontal .rs-steps-item:not(:first-child) {
  padding-left: 44px; }

.rs-steps-small.rs-steps-vertical .rs-steps-item-tail {
  left: 12px; }

.rs-steps-small.rs-steps-horizontal .rs-steps-item-title::after {
  top: 12px; }

.rs-steps-small .rs-steps-item {
  padding-left: 34px; }

.rs-steps-small .rs-steps-item-icon-wrapper {
  width: 24px;
  height: 24px;
  font-size: 14px;
  line-height: 1.57142857; }

.rs-steps-small .rs-steps-item-title {
  font-size: 14px;
  line-height: 1.71428571; }

.rs-steps-small .rs-steps-item-description {
  margin-top: 12px;
  font-size: 12px;
  line-height: 1.66666667; }

.rs-table {
  position: relative;
  overflow: hidden; }

.rs-table-column-resizing {
  cursor: ew-resize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.rs-table-row {
  overflow: hidden;
  position: absolute;
  height: 36px;
  width: 100%;
  top: 0; }

.rs-table-row.virtualized {
  pointer-events: none; }

@media not all and (min-resolution: 0.001dpcm) {
  .rs-table-row.virtualized {
    visibility: hidden; } }

.rs-table-row-expanded {
  position: absolute;
  height: 46px;
  bottom: 0;
  width: 100%;
  z-index: 4;
  border-top: 1px solid #f2f2f5;
  border-top: 1px solid var(--rs-border-secondary);
  padding: 10px;
  background-color: #fff;
  background-color: var(--rs-bg-card); }

.rs-table-hover .rs-table-row:hover .rs-table-cell-group {
  background-color: #fff;
  background-color: var(--rs-bg-card); }

.rs-table-hover .rs-table-row:hover .rs-table-cell {
  background-color: #fff;
  background-color: var(--rs-bg-card); }

.rs-table-row-header {
  background-color: #fff;
  background-color: var(--rs-bg-card); }

.rs-table-row-header .rs-table-cell {
  background-color: #fff;
  background-color: var(--rs-bg-card); }

.rs-table-row-header .rs-table-cell-group-shadow {
  z-index: 5;
  -webkit-box-shadow: none;
  box-shadow: none; }

.rs-table-hover .rs-table-row:not(.rs-table-row-header):hover,
.rs-table-hover .rs-table-row:hover .rs-table-cell-group,
.rs-table-hover .rs-table-row:hover .rs-table-cell {
  background-color: #f2faff;
  background-color: var(--rs-state-hover-bg); }

.rs-table-hover .rs-table-row-header:hover .rs-table-cell-group,
.rs-table-hover .rs-table-row-header:hover .rs-table-cell {
  background-color: #fff;
  background-color: var(--rs-bg-card); }

.rs-table-header-row-wrapper {
  z-index: 2;
  position: relative;
  font-size: 12px;
  color: #8e8e93;
  color: var(--rs-text-secondary); }

.rs-table-affix-header {
  z-index: 3;
  visibility: hidden; }

.rs-table-affix-header.fixed {
  visibility: visible; }

.rs-table-body-row-wrapper {
  position: relative;
  overflow: hidden;
  z-index: 0; }

.rs-table-body-info {
  width: 100%;
  text-align: center;
  top: 50%;
  position: absolute;
  margin-top: -20px;
  line-height: 40px; }

.rs-table-body-info .icon {
  margin: 0 10px; }

.rs-table-body-info-wheel-area {
  width: 100%; }

.rs-table-body-wheel-area {
  width: 100%; }

.rs-table-loader-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  background-color: var(--rs-loader-backdrop);
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
  pointer-events: none; }

@media not all and (min-resolution: 0.001dpcm) {
  .rs-table-loader-wrapper {
    visibility: hidden; } }

.rs-table-loader {
  width: 100%;
  text-align: center;
  top: 50%;
  position: absolute;
  margin-top: -20px;
  line-height: 40px; }

.rs-table-loader-icon {
  width: 18px;
  height: 18px;
  display: inline-block;
  position: relative;
  margin-right: 12px;
  padding-top: 3px; }

.rs-table-loader-icon::before,
.rs-table-loader-icon::after {
  width: 18px;
  height: 18px; }

.rs-table-loader-icon::before,
.rs-table-loader-icon::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  display: block;
  border-radius: 50%; }

.rs-table-loader-icon::before {
  border: 3px solid rgba(247, 247, 250, 0.8);
  border: 3px solid var(--rs-loader-ring); }

.rs-table-loader-icon::after {
  border-width: 3px;
  border-style: solid;
  border-color: #a6a6a6 transparent transparent;
  border-color: var(--rs-loader-rotor) transparent transparent;
  -webkit-animation: loaderSpin 0.6s infinite linear;
  animation: loaderSpin 0.6s infinite linear; }

.rs-table-loading .rs-table-loader-wrapper {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  z-index: 1; }

.rs-table-cell {
  height: 36px;
  display: block;
  overflow: hidden;
  position: absolute;
  white-space: normal;
  background-color: #fff;
  background-color: var(--rs-bg-card);
  border-bottom: 1px solid #f2f2f5;
  border-bottom: 1px solid var(--rs-border-secondary); }

.rs-table-cell.first {
  border-left-width: 0; }

.rs-table-cell-wrap1 {
  display: table; }

.rs-table-cell-wrap2 {
  display: table-row; }

.rs-table-cell-wrap3 {
  display: table-cell;
  vertical-align: middle; }

.rs-table-cell-content {
  padding: 13px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%; }

.rs-table-cell-header .rs-table-cell-content {
  padding: 10px 10px;
  line-height: 1.66666667; }

.rs-table-cell-header-sort-wrapper {
  cursor: pointer;
  margin-left: 5px;
  display: inline-block; }

.rs-table-cell-header-icon-sort {
  color: #3498ff;
  color: var(--rs-table-sort);
  font-size: 16px;
  line-height: 1.66666667; }

.rs-table-cell-rowspan {
  border-bottom: 1px solid #f2f2f5 !important;
  border-bottom: 1px solid var(--rs-border-secondary) !important; }

.rs-table-cell-full-text:hover {
  z-index: 1 !important;
  width: auto !important;
  -webkit-box-shadow: inset #3498ff 0px 0px 2px;
  -webkit-box-shadow: inset var(--rs-primary-500) 0px 0px 2px;
  box-shadow: inset #3498ff 0px 0px 2px;
  box-shadow: inset var(--rs-primary-500) 0px 0px 2px; }

.rs-table-cell-full-text:hover .rs-table-cell-content {
  width: auto !important; }

.rs-table-cell-header-sortable .rs-table-cell-content {
  cursor: pointer; }

.rs-table-column-resize-spanner {
  height: 36px;
  width: 3px;
  z-index: 3;
  position: absolute;
  cursor: ew-resize !important;
  outline: none;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

.rs-table-column-resize-spanner::after,
.rs-table-column-resize-spanner::before {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  margin: auto;
  bottom: 0;
  top: 0;
  display: none; }

.rs-table-column-resize-spanner::before {
  border-style: dashed solid dashed dashed;
  border-color: transparent #3498ff transparent transparent;
  border-color: transparent var(--rs-table-resize) transparent transparent;
  border-width: 3px;
  right: 4px; }

.rs-table-column-resize-spanner::after {
  border-style: dashed dashed dashed solid;
  border-color: transparent transparent transparent #3498ff;
  border-color: transparent transparent transparent var(--rs-table-resize);
  border-width: 3px;
  left: 4px; }

.rs-table:not(.rs-table-column-resizing) .rs-table-cell-header .rs-table-cell:hover ~ .rs-table-column-resize-spanner,
.rs-table:not(.rs-table-column-resizing) .rs-table-column-resize-spanner:hover {
  background-color: #3498ff;
  background-color: var(--rs-table-resize); }

.rs-table:not(.rs-table-column-resizing) .rs-table-cell-header .rs-table-cell:hover ~ .rs-table-column-resize-spanner::before,
.rs-table:not(.rs-table-column-resizing) .rs-table-column-resize-spanner:hover::before,
.rs-table:not(.rs-table-column-resizing) .rs-table-cell-header .rs-table-cell:hover ~ .rs-table-column-resize-spanner::after,
.rs-table:not(.rs-table-column-resizing) .rs-table-column-resize-spanner:hover::after {
  display: block; }

.rs-table-cell-group-fixed-right,
.rs-table-cell-group-fixed-left {
  position: absolute;
  z-index: 4;
  background-color: #fff;
  background-color: var(--rs-bg-card); }

.rs-table-cell-group-shadow,
.rs-table-cell-group-left-shadow {
  -webkit-box-shadow: 3px 0 5px rgba(9, 9, 9, 0.08);
  -webkit-box-shadow: 3px 0 5px var(--rs-table-shadow);
  box-shadow: 3px 0 5px rgba(9, 9, 9, 0.08);
  box-shadow: 3px 0 5px var(--rs-table-shadow); }

.rs-table-cell-group-right-shadow {
  -webkit-box-shadow: -3px 0 5px rgba(9, 9, 9, 0.08);
  -webkit-box-shadow: -3px 0 5px var(--rs-table-shadow);
  box-shadow: -3px 0 5px rgba(9, 9, 9, 0.08);
  box-shadow: -3px 0 5px var(--rs-table-shadow); }

.rs-table-mouse-area {
  display: none;
  background-color: #3498ff;
  background-color: var(--rs-table-resize);
  left: -1px;
  top: 0;
  position: absolute;
  width: 1px;
  z-index: 6; }

.rs-table-mouse-area > span {
  height: 36px;
  width: 3px;
  z-index: 3;
  cursor: ew-resize !important;
  outline: none;
  background-color: #3498ff;
  background-color: var(--rs-table-resize);
  position: absolute;
  left: -1.5px; }

.rs-table-word-wrap .rs-table-cell-content {
  white-space: normal; }

.rs-table-bordered {
  border: 1px solid #f2f2f5;
  border: 1px solid var(--rs-border-secondary); }

.rs-table-cell-bordered .rs-table-cell {
  border-right: 1px solid #f2f2f5;
  border-right: 1px solid var(--rs-border-secondary); }

.rs-table-column-group {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%; }

.rs-table-column-group-header {
  border-bottom: 1px solid #f2f2f5;
  border-bottom: 1px solid var(--rs-border-secondary);
  position: absolute;
  width: 100%; }

.rs-table-column-group-header-content {
  display: table-cell;
  padding: 10px; }

.rs-table-column-group-cell {
  position: absolute;
  border-right: 1px solid #f2f2f5;
  border-right: 1px solid var(--rs-border-secondary); }

.rs-table-cell-expand-wrapper {
  margin-right: 10px;
  display: inline-block;
  cursor: pointer; }

.rs-table-cell-expand-icon {
  cursor: pointer;
  outline: none;
  font-size: 16px; }

.rs-table-scrollbar {
  background-color: #e5e5ea;
  background-color: var(--rs-table-scrollbar-track);
  position: absolute;
  -webkit-transition: background 0.1s linear, -webkit-transform 0.1s linear;
  transition: background 0.1s linear, -webkit-transform 0.1s linear;
  transition: background 0.1s linear, transform 0.1s linear;
  transition: background 0.1s linear, transform 0.1s linear, -webkit-transform 0.1s linear;
  opacity: 0.6;
  z-index: 1; }

.rs-table-scrollbar-hide {
  display: none; }

.rs-table-scrollbar-handle {
  position: absolute;
  background-color: #575757;
  background-color: var(--rs-table-scrollbar-thumb);
  border-radius: 4px; }

.rs-table-scrollbar-pressed {
  z-index: 1; }

.rs-table-scrollbar-pressed .rs-table-scrollbar-handle {
  background-color: #272c36;
  background-color: var(--rs-table-scrollbar-thumb-active); }

.rs-table-scrollbar-horizontal {
  width: 100%;
  height: 10px;
  bottom: 0; }

.rs-table-scrollbar-horizontal.fixed {
  position: fixed; }

.rs-table-scrollbar-horizontal .rs-table-scrollbar-handle {
  height: 8px;
  left: 0;
  top: 1px; }

.rs-table-scrollbar-pressed.rs-table-scrollbar-horizontal:hover,
.rs-table-scrollbar-horizontal:hover {
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
  -webkit-transform: scaleY(1.3);
  transform: scaleY(1.3); }

.rs-table-scrollbar-vertical {
  top: 0;
  right: 0;
  width: 10px;
  bottom: 10px;
  background-color: rgba(229, 229, 234, 0.4);
  background-color: var(--rs-table-scrollbar-vertical-track); }

.rs-table-scrollbar-vertical .rs-table-scrollbar-handle {
  min-height: 20px;
  width: 8px;
  top: 0;
  left: 1px; }

.rs-table-scrollbar-pressed.rs-table-scrollbar-vertical:hover,
.rs-table-scrollbar-vertical:hover {
  -webkit-transform-origin: right center;
  transform-origin: right center;
  -webkit-transform: scaleX(1.3);
  transform: scaleX(1.3); }

.rs-tag {
  display: inline-block;
  background-color: #f7f7fa;
  background-color: var(--rs-tag-bg);
  color: #575757;
  color: var(--rs-text-primary);
  border-radius: 6px;
  line-height: 20px; }

.rs-tag-closable {
  position: relative; }

.rs-tag-icon-close {
  position: absolute;
  font-size: 12px; }

.rs-tag-default .rs-tag-icon-close:hover {
  color: #f44336;
  color: var(--rs-tag-close); }

.rs-tag-group {
  margin: -10px 0 0 -10px; }

.rs-tag-group > .rs-tag {
  margin-top: 10px;
  margin-left: 10px; }

.rs-tag-lg {
  padding: 5px 10px;
  font-size: 14px; }

.rs-tag-lg .rs-tag-icon-close {
  padding: 9px 8px; }

.rs-tag-closable.rs-tag-lg {
  padding-right: 27px; }

.rs-tag-md {
  padding: 2px 8px;
  font-size: 12px; }

.rs-tag-md .rs-tag-icon-close {
  padding: 6px 8px; }

.rs-tag-closable.rs-tag-md {
  padding-right: 25px; }

.rs-tag-sm {
  padding: 0 8px;
  font-size: 12px; }

.rs-tag-sm .rs-tag-icon-close {
  padding: 4px 5px; }

.rs-tag-closable.rs-tag-sm {
  padding-right: 25px; }

.rs-tag + .rs-tag {
  margin-left: 10px; }

.rs-tag-red {
  background-color: #f44336;
  background-color: var(--rs-red-500);
  color: #ffffff; }

.rs-tag-orange {
  background-color: #fa8900;
  background-color: var(--rs-orange-500);
  color: #ffffff; }

.rs-tag-yellow {
  background-color: #ffb300;
  background-color: var(--rs-yellow-500);
  color: #ffffff; }

.rs-tag-green {
  background-color: #4caf50;
  background-color: var(--rs-green-500);
  color: #ffffff; }

.rs-tag-cyan {
  background-color: #00bcd4;
  background-color: var(--rs-cyan-500);
  color: #ffffff; }

.rs-tag-blue {
  background-color: #2196f3;
  background-color: var(--rs-blue-500);
  color: #ffffff; }

.rs-tag-violet {
  background-color: #673ab7;
  background-color: var(--rs-violet-500);
  color: #ffffff; }

.rs-picker-tag .rs-picker-toggle {
  left: 0;
  cursor: text; }

.rs-picker-tag.rs-picker-disabled {
  cursor: not-allowed; }

.rs-picker-tag.rs-picker-disabled .rs-picker-toggle {
  position: absolute; }

.rs-picker-tag .rs-tag {
  max-width: calc(100% - 6px);
  vertical-align: top;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.rs-picker-tag .rs-picker-search-input,
.rs-picker-tag .rs-tag {
  margin-top: 5px; }

.rs-picker-tag.rs-picker-has-value .rs-picker-search-input {
  padding-left: 6px; }

.rs-picker-tag .rs-tag,
.rs-picker-tag .rs-tag + .rs-tag {
  margin-left: 6px; }

.rs-picker-tag .rs-picker-search,
.rs-picker-tag .rs-picker-search-input,
.rs-picker-tag .rs-picker-search-input > input {
  display: inline-block;
  width: auto;
  min-width: 14px; }

.rs-picker-tag .rs-picker-search-input > input {
  padding: 0; }

.rs-picker-tag .rs-picker-tag-wrapper {
  position: relative;
  z-index: 6;
  padding-bottom: 5px; }

.rs-plaintext .rs-tag {
  margin-left: 6px;
  margin-bottom: 6px; }

.rs-picker-disabled-options .rs-picker-toggle {
  padding-right: 32px !important; }

.rs-picker-disabled-options .rs-picker-tag-wrapper {
  margin-right: 32px !important; }

.rs-picker-disabled-options .rs-picker-toggle-clean {
  right: 10px !important; }

.rs-timeline {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  direction: ltr; }

.rs-timeline-item {
  position: relative;
  text-align: left; }

.rs-timeline-item:not(:last-child) .rs-timeline-item-content {
  padding-bottom: 18px; }

.rs-timeline-item-dot {
  position: absolute;
  top: 5px; }

.rs-timeline-item-dot::before {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  background-color: #d9d9d9;
  background-color: var(--rs-timeline-indicator-bg);
  border-radius: 50%; }

.rs-timeline-item-custom-dot {
  text-align: center; }

.rs-timeline-item-custom-dot::before {
  display: none; }

.rs-timeline-item-active .rs-timeline-item-dot::before {
  background-color: #3498ff;
  background-color: var(--rs-timeline-indicator-active-bg); }

.rs-timeline-item-tail {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #d9d9d9;
  background-color: var(--rs-timeline-indicator-bg); }

.rs-timeline-item:first-child .rs-timeline-item-tail {
  top: 5px; }

.rs-timeline-item:last-child .rs-timeline-item-tail {
  bottom: auto;
  height: 15px; }

.rs-timeline-endless .rs-timeline-item:last-child .rs-timeline-item-tail {
  height: auto;
  min-height: 38px; }

.rs-timeline-item:only-child .rs-timeline-item-tail {
  display: none; }

.rs-timeline-align-left .rs-timeline-item-dot {
  left: 0; }

.rs-timeline-align-left .rs-timeline-item-tail {
  left: 4px; }

.rs-timeline-align-left .rs-timeline-item {
  padding-left: 22px; }

.rs-timeline-align-left .rs-timeline-item {
  padding-left: 22px; }

.rs-timeline-align-right .rs-timeline-item-dot {
  right: 0; }

.rs-timeline-align-right .rs-timeline-item-tail {
  right: 4px; }

.rs-timeline-align-right .rs-timeline-item {
  padding-right: 22px; }

.rs-timeline-align-right .rs-timeline-item-content {
  text-align: right; }

.rs-timeline-align-alternate .rs-timeline-item-dot {
  left: calc(50% - 5px); }

.rs-timeline-align-alternate .rs-timeline-item-tail {
  left: calc(50% - 1px); }

.rs-timeline-align-alternate .rs-timeline-item-content {
  width: 50%; }

.rs-timeline-align-alternate .rs-timeline-item:nth-child(even) .rs-timeline-item-content {
  text-align: right; }

.rs-timeline-align-alternate .rs-timeline-item:nth-child(odd) {
  text-align: right; }

.rs-timeline-align-alternate .rs-timeline-item:nth-child(odd) .rs-timeline-item-content {
  display: inline-block;
  text-align: left; }

.rs-timeline-with-time .rs-timeline-item {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0; }

.rs-timeline-with-time .rs-timeline-item-dot {
  left: calc(50% - 5px); }

.rs-timeline-with-time .rs-timeline-item-tail {
  left: calc(50% - 1px); }

.rs-timeline-with-time .rs-timeline-item-time,
.rs-timeline-with-time .rs-timeline-item-content {
  -ms-flex: 1 0 50%;
  flex: 1 0 50%; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-timeline-with-time .rs-timeline-item-time,
  .rs-timeline-with-time .rs-timeline-item-content {
    max-width: 50%; } }

.rs-timeline-align-alternate .rs-timeline-item:nth-child(2n),
.rs-timeline-with-time.rs-timeline-align-right .rs-timeline-item {
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse; }

.rs-timeline-with-time.rs-timeline-align-right .rs-timeline-item {
  padding-right: 0; }

.rs-timeline-with-time.rs-timeline-align-left .rs-timeline-item-time {
  text-align: right; }

.rs-timeline-with-time.rs-timeline-align-right .rs-timeline-item-time {
  text-align: left; }

.rs-timeline-with-time.rs-timeline-align-left .rs-timeline-item-time,
.rs-timeline-with-time.rs-timeline-align-right .rs-timeline-item-content,
.rs-timeline-align-alternate .rs-timeline-item:nth-child(2n + 1) .rs-timeline-item-time,
.rs-timeline-align-alternate .rs-timeline-item:nth-child(2n) .rs-timeline-item-content {
  padding-right: 17px; }

.rs-timeline-with-time.rs-timeline-align-left .rs-timeline-item-content,
.rs-timeline-with-time.rs-timeline-align-right .rs-timeline-item-time,
.rs-timeline-align-alternate .rs-timeline-item:nth-child(2n + 1) .rs-timeline-item-content,
.rs-timeline-align-alternate .rs-timeline-item:nth-child(2n) .rs-timeline-item-time {
  padding-left: 17px; }

.rs-toggle {
  position: relative; }

.rs-toggle .rs-toggle-loader {
  width: 18px;
  height: 18px;
  left: 3px;
  top: 3px; }

.rs-toggle .rs-toggle-loader .rs-loader-spin,
.rs-toggle .rs-toggle-loader .rs-loader-spin::before,
.rs-toggle .rs-toggle-loader .rs-loader-spin::after {
  width: 18px;
  height: 18px; }

.rs-toggle .rs-toggle-presentation {
  height: 24px;
  min-width: 44px;
  border-radius: 12px; }

.rs-toggle .rs-toggle-presentation::after {
  width: 18px;
  height: 18px;
  left: 3px;
  top: 3px;
  border-radius: 9px; }

.rs-toggle .rs-toggle-presentation:active::after {
  width: 21.6px; }

.rs-toggle .rs-toggle-inner {
  margin-left: 24px;
  margin-right: 8px;
  height: 24px;
  line-height: 2;
  font-size: 12px; }

.rs-toggle .rs-toggle-inner .rs-icon {
  font-size: 12px; }

.rs-toggle.rs-toggle-checked .rs-toggle-presentation::after {
  left: 100%;
  margin-left: -21px; }

.rs-toggle.rs-toggle-checked .rs-toggle-presentation:active::after {
  margin-left: -24.6px; }

.rs-toggle.rs-toggle-checked .rs-toggle-presentation .rs-toggle-inner {
  margin-right: 24px;
  margin-left: 8px; }

.rs-toggle.rs-toggle-checked .rs-toggle-loader {
  left: 100%;
  margin-left: -21px; }

.rs-toggle-input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0; }

.rs-toggle-presentation {
  position: relative;
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: middle;
  background-color: #d9d9d9;
  background-color: var(--rs-toggle-bg);
  color: #fff;
  color: var(--rs-toggle-thumb);
  cursor: pointer;
  -webkit-transition: background-color 0.15s ease-out, width 0.15s ease-out;
  transition: background-color 0.15s ease-out, width 0.15s ease-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.rs-theme-high-contrast .rs-toggle-presentation {
  -webkit-transition: none;
  transition: none;
  -webkit-box-shadow: inset 0 0 0 1px #fff;
  -webkit-box-shadow: inset 0 0 0 1px var(--rs-toggle-thumb);
  box-shadow: inset 0 0 0 1px #fff;
  box-shadow: inset 0 0 0 1px var(--rs-toggle-thumb); }

.rs-toggle-input:focus-visible + .rs-toggle-presentation {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring); }

.rs-theme-high-contrast .rs-toggle-input:focus-visible + .rs-toggle-presentation {
  outline-offset: 2px; }

.rs-theme-high-contrast .rs-toggle-input:focus-visible + .rs-toggle-presentation {
  -webkit-box-shadow: inset 0 0 0 1px #fff, 0 0 0 3px rgba(52, 152, 255, 0.25);
  -webkit-box-shadow: inset 0 0 0 1px var(--rs-toggle-thumb), var(--rs-state-focus-shadow);
  box-shadow: inset 0 0 0 1px #fff, 0 0 0 3px rgba(52, 152, 255, 0.25);
  box-shadow: inset 0 0 0 1px var(--rs-toggle-thumb), var(--rs-state-focus-shadow); }

.rs-toggle-presentation:hover {
  background-color: #c5c6c7;
  background-color: var(--rs-toggle-hover-bg); }

.rs-toggle-presentation::after {
  content: '';
  cursor: pointer;
  position: absolute;
  background-color: currentColor;
  -webkit-transition: left 0.15s ease-out, margin-left 0.15s ease-out, width 0.15s ease-out;
  transition: left 0.15s ease-out, margin-left 0.15s ease-out, width 0.15s ease-out; }

.rs-theme-high-contrast .rs-toggle-presentation::after {
  -webkit-transition: none;
  transition: none; }

.rs-toggle-disabled .rs-toggle-presentation {
  background-color: #f7f7fa;
  background-color: var(--rs-toggle-disabled-bg);
  color: #fff;
  color: var(--rs-toggle-disabled-thumb);
  -webkit-box-shadow: inset 0 0 0 1px #fff;
  -webkit-box-shadow: inset 0 0 0 1px var(--rs-toggle-disabled-thumb);
  box-shadow: inset 0 0 0 1px #fff;
  box-shadow: inset 0 0 0 1px var(--rs-toggle-disabled-thumb);
  cursor: not-allowed; }

.rs-toggle-checked .rs-toggle-presentation {
  background-color: #3498ff;
  background-color: var(--rs-toggle-checked-bg);
  color: #fff;
  color: var(--rs-toggle-checked-thumb);
  -webkit-box-shadow: none;
  box-shadow: none; }

.rs-toggle-checked .rs-toggle-presentation:hover {
  background-color: #2589f5;
  background-color: var(--rs-toggle-checked-hover-bg); }

.rs-toggle-disabled.rs-toggle-checked .rs-toggle-presentation {
  background-color: #cce9ff;
  background-color: var(--rs-toggle-checked-disabled-bg);
  color: #fff;
  color: var(--rs-toggle-checked-disabled-thumb); }

.rs-toggle-loading .rs-toggle-presentation::after {
  display: none; }

.rs-toggle-inner {
  display: block;
  -webkit-transition: margin 0.15s ease-out;
  transition: margin 0.15s ease-out; }

.rs-theme-high-contrast .rs-toggle-inner {
  -webkit-transition: none;
  transition: none; }

.rs-toggle-loader {
  position: absolute;
  -webkit-transition: left 0.15s ease-out, margin-left 0.15s ease-out, width 0.15s ease-out;
  transition: left 0.15s ease-out, margin-left 0.15s ease-out, width 0.15s ease-out; }

.rs-toggle-loader .rs-loader-spin::before {
  border-color: rgba(247, 247, 250, 0.3);
  border-color: var(--rs-toggle-loader-ring); }

.rs-toggle-loader .rs-loader-spin::after {
  border-top-color: #fff;
  border-top-color: var(--rs-toggle-loader-rotor); }

.rs-toggle-sm .rs-toggle-loader {
  width: 10px;
  height: 10px;
  left: 2px;
  top: 2px; }

.rs-toggle-sm .rs-toggle-loader .rs-loader-spin,
.rs-toggle-sm .rs-toggle-loader .rs-loader-spin::before,
.rs-toggle-sm .rs-toggle-loader .rs-loader-spin::after {
  width: 10px;
  height: 10px; }

.rs-toggle-sm .rs-toggle-loader .rs-loader-spin::before,
.rs-toggle-sm .rs-toggle-loader .rs-loader-spin::after {
  border-width: 2px; }

.rs-toggle-sm .rs-toggle-presentation {
  height: 14px;
  min-width: 26px;
  border-radius: 7px; }

.rs-toggle-sm .rs-toggle-presentation::after {
  width: 10px;
  height: 10px;
  left: 2px;
  top: 2px;
  border-radius: 5px; }

.rs-toggle-sm .rs-toggle-presentation:active::after {
  width: 12px; }

.rs-toggle-sm .rs-toggle-inner {
  margin-left: 14px;
  margin-right: 6px;
  height: 14px;
  line-height: 1.4;
  font-size: 10px; }

.rs-toggle-sm .rs-toggle-inner .rs-icon {
  font-size: 10px; }

.rs-toggle-sm.rs-toggle-checked .rs-toggle-presentation::after {
  left: 100%;
  margin-left: -12px; }

.rs-toggle-sm.rs-toggle-checked .rs-toggle-presentation:active::after {
  margin-left: -14px; }

.rs-toggle-sm.rs-toggle-checked .rs-toggle-presentation .rs-toggle-inner {
  margin-right: 14px;
  margin-left: 6px; }

.rs-toggle-sm.rs-toggle-checked .rs-toggle-loader {
  left: 100%;
  margin-left: -12px; }

.rs-toggle-md .rs-toggle-loader {
  width: 18px;
  height: 18px;
  left: 3px;
  top: 3px; }

.rs-toggle-md .rs-toggle-loader .rs-loader-spin,
.rs-toggle-md .rs-toggle-loader .rs-loader-spin::before,
.rs-toggle-md .rs-toggle-loader .rs-loader-spin::after {
  width: 18px;
  height: 18px; }

.rs-toggle-md .rs-toggle-presentation {
  height: 24px;
  min-width: 44px;
  border-radius: 12px; }

.rs-toggle-md .rs-toggle-presentation::after {
  width: 18px;
  height: 18px;
  left: 3px;
  top: 3px;
  border-radius: 9px; }

.rs-toggle-md .rs-toggle-presentation:active::after {
  width: 21.6px; }

.rs-toggle-md .rs-toggle-inner {
  margin-left: 24px;
  margin-right: 8px;
  height: 24px;
  line-height: 2;
  font-size: 12px; }

.rs-toggle-md .rs-toggle-inner .rs-icon {
  font-size: 12px; }

.rs-toggle-md.rs-toggle-checked .rs-toggle-presentation::after {
  left: 100%;
  margin-left: -21px; }

.rs-toggle-md.rs-toggle-checked .rs-toggle-presentation:active::after {
  margin-left: -24.6px; }

.rs-toggle-md.rs-toggle-checked .rs-toggle-presentation .rs-toggle-inner {
  margin-right: 24px;
  margin-left: 8px; }

.rs-toggle-md.rs-toggle-checked .rs-toggle-loader {
  left: 100%;
  margin-left: -21px; }

.rs-toggle-lg .rs-toggle-loader {
  width: 22px;
  height: 22px;
  left: 4px;
  top: 4px; }

.rs-toggle-lg .rs-toggle-loader .rs-loader-spin,
.rs-toggle-lg .rs-toggle-loader .rs-loader-spin::before,
.rs-toggle-lg .rs-toggle-loader .rs-loader-spin::after {
  width: 22px;
  height: 22px; }

.rs-toggle-lg .rs-toggle-presentation {
  height: 30px;
  min-width: 55px;
  border-radius: 15px; }

.rs-toggle-lg .rs-toggle-presentation::after {
  width: 22px;
  height: 22px;
  left: 4px;
  top: 4px;
  border-radius: 11px; }

.rs-toggle-lg .rs-toggle-presentation:active::after {
  width: 26.4px; }

.rs-toggle-lg .rs-toggle-inner {
  margin-left: 30px;
  margin-right: 10px;
  height: 30px;
  line-height: 2.14285714;
  font-size: 14px; }

.rs-toggle-lg .rs-toggle-inner .rs-icon {
  font-size: 14px; }

.rs-toggle-lg.rs-toggle-checked .rs-toggle-presentation::after {
  left: 100%;
  margin-left: -26px; }

.rs-toggle-lg.rs-toggle-checked .rs-toggle-presentation:active::after {
  margin-left: -30.4px; }

.rs-toggle-lg.rs-toggle-checked .rs-toggle-presentation .rs-toggle-inner {
  margin-right: 30px;
  margin-left: 10px; }

.rs-toggle-lg.rs-toggle-checked .rs-toggle-loader {
  left: 100%;
  margin-left: -26px; }

/* rtl:begin:ignore */
/* rtl:end:ignore */
.rs-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-size: 12px;
  opacity: 0;
  line-height: 1.66666667;
  max-width: 250px;
  padding: 2px 10px;
  color: #fff;
  color: var(--rs-tooltip-text);
  background-color: #272c36;
  background-color: var(--rs-tooltip-bg);
  border-radius: 4px;
  overflow-wrap: break-word; }

.rs-tooltip.rs-anim-fade {
  -webkit-transition: opacity 0.1s linear, -webkit-transform 0.1s ease-out;
  transition: opacity 0.1s linear, -webkit-transform 0.1s ease-out;
  transition: opacity 0.1s linear, transform 0.1s ease-out;
  transition: opacity 0.1s linear, transform 0.1s ease-out, -webkit-transform 0.1s ease-out; }

.rs-tooltip.rs-anim-in {
  opacity: 1;
  -webkit-transition: opacity 0.15s linear, -webkit-transform 0.15s ease-in;
  transition: opacity 0.15s linear, -webkit-transform 0.15s ease-in;
  transition: opacity 0.15s linear, transform 0.15s ease-in;
  transition: opacity 0.15s linear, transform 0.15s ease-in, -webkit-transform 0.15s ease-in; }

.rs-tooltip-arrow::before,
.rs-tooltip-arrow::after {
  content: ' ';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid; }

.rs-tooltip-arrow::before {
  display: none; }

.rs-theme-high-contrast .rs-tooltip {
  border: 1px solid #e5e5ea;
  border: 1px solid var(--rs-border-primary); }

.rs-theme-high-contrast .rs-tooltip-arrow::before {
  display: block; }

.rs-tooltip[class*='placement-top'] {
  margin-top: -8px;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0); }

.rs-tooltip[class*='placement-top'].rs-anim-in {
  -webkit-transform: translate(0, -2px);
  transform: translate(0, -2px); }

.rs-tooltip[class*='placement-top']::after {
  bottom: -6px;
  margin-left: -6px;
  border-width: 6px 6px 0;
  border-top-color: #272c36;
  border-top-color: var(--rs-tooltip-bg); }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-tooltip[class*='placement-top']::after {
    bottom: -7px; } }

@supports (-ms-ime-align: auto) {
  .rs-tooltip[class*='placement-top']::after {
    bottom: -7px; } }

.rs-theme-high-contrast .rs-tooltip[class*='placement-top']::before {
  bottom: -7px;
  margin-left: -7px;
  border-width: 7px 7px 0;
  border-top-color: var(--rs-tooltip-border); }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-theme-high-contrast .rs-tooltip[class*='placement-top']::before {
    bottom: -8px; } }

@supports (-ms-ime-align: auto) {
  .rs-theme-high-contrast .rs-tooltip[class*='placement-top']::before {
    bottom: -8px; } }

.rs-tooltip[class*='placement-bottom'] {
  margin-top: 8px;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0); }

.rs-tooltip[class*='placement-bottom'].rs-anim-in {
  -webkit-transform: translate(0, 2px);
  transform: translate(0, 2px); }

.rs-tooltip[class*='placement-bottom']::after {
  top: -6px;
  margin-left: -6px;
  border-width: 0 6px 6px;
  border-bottom-color: #272c36;
  border-bottom-color: var(--rs-tooltip-bg); }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-tooltip[class*='placement-bottom']::after {
    top: -7px; } }

@supports (-ms-ime-align: auto) {
  .rs-tooltip[class*='placement-bottom']::after {
    top: -7px; } }

.rs-theme-high-contrast .rs-tooltip[class*='placement-bottom']::before {
  top: -7px;
  margin-left: -7px;
  border-width: 0 7px 7px;
  border-bottom-color: var(--rs-tooltip-border); }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-theme-high-contrast .rs-tooltip[class*='placement-bottom']::before {
    top: -8px; } }

@supports (-ms-ime-align: auto) {
  .rs-theme-high-contrast .rs-tooltip[class*='placement-bottom']::before {
    top: -8px; } }

/* rtl:begin:ignore */
.rs-tooltip[class*='placement-right'] {
  margin-left: 8px;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0); }

.rs-tooltip[class*='placement-right'].rs-anim-in {
  -webkit-transform: translate(2px, 0);
  transform: translate(2px, 0); }

.rs-tooltip[class*='placement-right']::after {
  left: -6px;
  margin-top: -6px;
  border-width: 6px 6px 6px 0;
  border-right-color: #272c36;
  border-right-color: var(--rs-tooltip-bg); }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-tooltip[class*='placement-right']::after {
    left: -7px; } }

@supports (-ms-ime-align: auto) {
  .rs-tooltip[class*='placement-right']::after {
    left: -7px; } }

.rs-theme-high-contrast .rs-tooltip[class*='placement-right']::before {
  left: -7px;
  margin-top: -7px;
  border-width: 7px 7px 7px 0;
  border-right-color: var(--rs-tooltip-border); }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-theme-high-contrast .rs-tooltip[class*='placement-right']::before {
    left: -8px; } }

@supports (-ms-ime-align: auto) {
  .rs-theme-high-contrast .rs-tooltip[class*='placement-right']::before {
    left: -8px; } }

.rs-tooltip[class*='placement-left'] {
  margin-left: -8px;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0); }

.rs-tooltip[class*='placement-left'].rs-anim-in {
  -webkit-transform: translate(-2px, 0);
  transform: translate(-2px, 0); }

.rs-tooltip[class*='placement-left']::after {
  right: -6px;
  margin-top: -6px;
  border-width: 6px 0 6px 6px;
  border-left-color: #272c36;
  border-left-color: var(--rs-tooltip-bg); }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-tooltip[class*='placement-left']::after {
    right: -7px; } }

@supports (-ms-ime-align: auto) {
  .rs-tooltip[class*='placement-left']::after {
    right: -7px; } }

.rs-theme-high-contrast .rs-tooltip[class*='placement-left']::before {
  right: -7px;
  margin-top: -7px;
  border-width: 7px 0 7px 7px;
  border-left-color: var(--rs-tooltip-border); }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-theme-high-contrast .rs-tooltip[class*='placement-left']::before {
    right: -8px; } }

@supports (-ms-ime-align: auto) {
  .rs-theme-high-contrast .rs-tooltip[class*='placement-left']::before {
    right: -8px; } }

/* rtl:end:ignore */
.rs-tooltip.placement-bottom::before,
.rs-tooltip.placement-top::before,
.rs-tooltip.placement-bottom::after,
.rs-tooltip.placement-top::after {
  left: 50%; }

.rs-tooltip.placement-bottom-start::before,
.rs-tooltip.placement-top-start::before,
.rs-tooltip.placement-bottom-start::after,
.rs-tooltip.placement-top-start::after {
  left: 10px; }

.rs-tooltip.placement-bottom-end::before,
.rs-tooltip.placement-top-end::before {
  right: 3px; }

.rs-tooltip.placement-bottom-end::after,
.rs-tooltip.placement-top-end::after {
  right: 4px; }

.rs-tooltip.placement-right::before,
.rs-tooltip.placement-left::before,
.rs-tooltip.placement-right::after,
.rs-tooltip.placement-left::after {
  top: 50%; }

.rs-tooltip.placement-right-start::before,
.rs-tooltip.placement-left-start::before,
.rs-tooltip.placement-right-start::after,
.rs-tooltip.placement-left-start::after {
  top: 10px; }

.rs-tooltip.placement-right-end::before,
.rs-tooltip.placement-left-end::before {
  bottom: 3px; }

.rs-tooltip.placement-right-end::after,
.rs-tooltip.placement-left-end::after {
  bottom: 4px; }

.rs-tree {
  max-height: 360px;
  height: 100%;
  overflow-y: auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto; }

.rs-tree.rs-tree-virtualized {
  overflow: hidden; }

.rs-tree-drag-preview {
  position: absolute;
  top: 0;
  color: #575757;
  color: var(--rs-text-primary);
  background-color: #fff;
  background-color: var(--rs-bg-overlay);
  display: inline-block;
  margin: 0;
  padding: 8px 12px;
  border-radius: 6px;
  -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
  z-index: -1; }

.rs-tree-node {
  position: relative;
  font-size: 0;
  text-align: left;
  margin: 0 0 4px 0; }

.rs-tree-node-label {
  position: relative;
  margin: 0;
  padding-left: 16px;
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  line-height: 22px; }

.rs-tree-node-label-content {
  padding: 6px 12px 6px 8px;
  display: inline-block;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent; }

.rs-tree-node-label-content:hover,
.rs-tree-node-label-content:focus,
.rs-tree-node-label-content.rs-tree-node-label-content-focus {
  background-color: rgba(204, 233, 255, 0.5);
  background-color: var(--rs-listbox-option-hover-bg);
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text); }

.rs-theme-high-contrast .rs-tree-node-label-content:hover,
.rs-theme-high-contrast .rs-tree-node-label-content:focus,
.rs-theme-high-contrast .rs-tree-node-label-content.rs-tree-node-label-content-focus {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  outline-width: 2px;
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text);
  text-decoration: underline; }

.rs-theme-high-contrast .rs-theme-high-contrast .rs-tree-node-label-content:hover,
.rs-theme-high-contrast .rs-theme-high-contrast .rs-tree-node-label-content:focus,
.rs-theme-high-contrast .rs-theme-high-contrast .rs-tree-node-label-content.rs-tree-node-label-content-focus {
  outline-offset: 2px; }

.rs-tree-node-active .rs-tree-node-label-content {
  font-weight: bold;
  color: #1675e0;
  color: var(--rs-text-link);
  background-color: #f2faff;
  background-color: var(--rs-listbox-option-selected-bg); }

.rs-tree-node-drag-over {
  background-color: rgba(204, 233, 255, 0.5);
  background-color: var(--rs-listbox-option-hover-bg); }

.rs-tree-node-dragging {
  outline: 1px dashed #3498ff;
  outline: 1px dashed var(--rs-primary-500); }

.rs-tree-node-drag-over-top.rs-tree-node-label-content {
  border-top: 2px solid #1675e0 !important;
  border-top: 2px solid var(--rs-text-link) !important; }

.rs-tree-node-drag-over-top.rs-tree-node-label-content::after {
  content: '';
  position: absolute;
  top: -2px;
  left: 12px;
  width: 0;
  height: 0;
  border-left: 6px solid #1675e0;
  border-left: 6px solid var(--rs-text-link);
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent; }

.rs-tree-node-drag-over-bottom.rs-tree-node-label-content {
  border-bottom: 2px solid #1675e0 !important;
  border-bottom: 2px solid var(--rs-text-link) !important; }

.rs-tree-node-drag-over-bottom.rs-tree-node-label-content::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 12px;
  width: 0;
  height: 0;
  border-left: 6px solid #1675e0;
  border-left: 6px solid var(--rs-text-link);
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent; }

.rs-tree-node-drag-disabled.rs-tree-node-label-content {
  cursor: not-allowed; }

.rs-tree-node-disabled {
  color: #c5c6c7;
  color: var(--rs-listbox-option-disabled-text);
  cursor: not-allowed; }

.rs-tree-node-disabled > .rs-tree-node-label > .rs-tree-node-label-content {
  cursor: not-allowed;
  color: #c5c6c7;
  color: var(--rs-listbox-option-disabled-text); }

.rs-tree-node-disabled > .rs-tree-node-label > .rs-tree-node-label-content,
.rs-tree-node-disabled > .rs-tree-node-label > .rs-tree-node-label-content:hover {
  background-color: transparent; }

.rs-tree-node-disabled.rs-tree-node-active > .rs-tree-node-label > .rs-tree-node-label-content,
.rs-tree-node-disabled.rs-tree-node-active > .rs-tree-node-label > .rs-tree-node-label-content:hover {
  color: #a6d7ff;
  color: var(--rs-listbox-option-disabled-selected-text); }

.rs-tree-node:not(.rs-tree-node-disabled):focus > .rs-tree-node-label > .rs-tree-node-label-content {
  background-color: #f2faff;
  background-color: var(--rs-listbox-option-selected-bg); }

.rs-tree-node:not(.rs-tree-node-disabled) > .rs-tree-node-label:focus > .rs-tree-node-label-content {
  background-color: #f2faff;
  background-color: var(--rs-listbox-option-selected-bg); }

.rs-tree-node-expand-icon-wrapper {
  display: inline-block;
  position: absolute;
  cursor: pointer;
  z-index: 1; }

.rs-tree-node-expand-icon-wrapper > .rs-tree-node-expand-icon {
  display: inline-block;
  padding: 8px 0;
  padding-right: 8px;
  height: 36px;
  font-size: 16px;
  line-height: 22px;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: 1.5px 14px;
  transform-origin: 1.5px 14px;
  margin-top: 6px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.rs-tree-open > .rs-tree-node .rs-tree-node-expand-icon-wrapper > .rs-tree-node-expand-icon {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  margin-left: 0;
  margin-top: 0; }

.rs-tree-node-expand-icon-wrapper > .rs-tree-node-expanded {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  margin-left: 0;
  margin-top: 0; }

.rs-tree-node-expand-icon-wrapper > .rs-tree-node-custom-icon,
.rs-tree-node-expand-icon-wrapper > .rs-tree-node-loading-icon {
  width: 14px;
  height: 36px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px; }

.rs-tree-node-children > .rs-tree-children {
  position: relative;
  display: none; }

.rs-tree-open.rs-tree-node-children > .rs-tree-children {
  display: block; }

.rs-tree-indent-line {
  height: 100%;
  width: 1px;
  position: absolute;
  top: 0;
  margin-left: -13px;
  border-left: 1px solid rgba(0, 0, 0, 0.1); }

.rs-picker-menu.rs-tree-menu {
  padding-top: 12px; }

.rs-picker-menu.rs-tree-menu .rs-picker-search-bar {
  padding-top: 0; }

.rs-picker-menu .rs-tree {
  padding: 0 12px 12px 0; }

.rs-picker-menu .rs-tree.rs-tree-virtualized {
  padding: 0; }

.rs-picker-menu .rs-tree-node {
  margin: 0; }

.rs-picker-menu .rs-tree-node > .rs-tree-node-label > .rs-tree-node-label-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  display: block;
  line-height: 20px; }

/* rtl:begin:ignore */
[dir='rtl'] .rs-tree-node-expand-icon-wrapper {
  right: 0;
  padding-right: inherit; }

/* rtl:end:ignore */
.rs-uploader-trigger input[type='file'] {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); }

.rs-uploader-file-item-disabled {
  opacity: 0.3;
  cursor: not-allowed; }

.rs-uploader-picture .rs-uploader-file-item-disabled .rs-uploader-file-item-status {
  cursor: not-allowed; }

.rs-uploader-file-item-disabled .rs-uploader-file-item-btn-remove {
  display: none; }

.rs-uploader-file-item-icon-reupload {
  cursor: pointer;
  margin-left: 10px;
  color: #575757;
  color: var(--rs-text-primary);
  font-size: 12px; }

.rs-theme-high-contrast .rs-uploader-file-item:hover .rs-uploader-file-item-title {
  color: var(--rs-uploader-item-hover-text);
  text-decoration: underline; }

.rs-uploader-text .rs-uploader-file-items {
  margin-top: 10px; }

.rs-uploader-text .rs-uploader-file-item {
  padding: 8px 30px 8px 36px;
  position: relative;
  -webkit-transition: 0.3s linear background-color;
  transition: 0.3s linear background-color; }

.rs-uploader-text .rs-uploader-file-item:hover {
  background-color: #f7f7fa;
  background-color: var(--rs-uploader-item-hover-bg); }

.rs-uploader-text .rs-uploader-file-item-icon-wrapper {
  position: absolute;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  top: 8px;
  left: 12px;
  width: 20px;
  height: 20px;
  -ms-flex-pack: center;
  justify-content: center; }

.rs-uploader-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon {
  width: 18px;
  height: 18px; }

.rs-uploader-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::before,
.rs-uploader-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::after {
  width: 18px;
  height: 18px; }

.rs-uploader-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::before,
.rs-uploader-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  display: block;
  border-radius: 50%; }

.rs-uploader-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::before {
  border: 3px solid rgba(247, 247, 250, 0.8);
  border: 3px solid var(--rs-loader-ring); }

.rs-uploader-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::after {
  border-width: 3px;
  border-style: solid;
  border-color: #a6a6a6 transparent transparent;
  border-color: var(--rs-loader-rotor) transparent transparent;
  -webkit-animation: loaderSpin 0.6s infinite linear;
  animation: loaderSpin 0.6s infinite linear; }

.rs-uploader-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon > svg {
  display: none; }

.rs-uploader-text .rs-uploader-file-item-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: #575757;
  color: var(--rs-text-primary);
  -webkit-transition: color 0.3s;
  transition: color 0.3s; }

.rs-uploader-text .rs-uploader-file-item-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto; }

.rs-uploader-text .rs-uploader-file-item-size {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #8e8e93;
  color: var(--rs-text-secondary);
  padding: 0 8px; }

.rs-uploader-text .rs-uploader-file-item-status {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto; }

.rs-uploader-text .rs-uploader-file-item-status > a {
  text-decoration: none; }

.rs-uploader-text .rs-uploader-file-item-status > a:hover,
.rs-uploader-text .rs-uploader-file-item-status > a:focus,
.rs-uploader-text .rs-uploader-file-item-status > a:active {
  text-decoration: none; }

.rs-uploader-text .rs-uploader-file-item-btn-remove {
  position: absolute;
  font-size: 12px;
  top: 12px;
  right: 12px;
  color: #8e8e93;
  color: var(--rs-text-secondary);
  cursor: pointer;
  padding: 0; }

.rs-uploader-text .rs-uploader-file-item-btn-remove:hover,
.rs-uploader-text .rs-uploader-file-item-btn-remove:focus {
  text-decoration: none; }

.rs-uploader-text .rs-uploader-file-item-panel {
  position: relative; }

.rs-uploader-text .rs-uploader-file-item-progress {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0; }

.rs-uploader-text .rs-uploader-file-item-progress-bar {
  height: 2px;
  background-color: #3498ff;
  background-color: var(--rs-primary-500);
  -webkit-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out; }

.rs-uploader-text .rs-uploader-file-item-has-error .rs-uploader-file-item-content,
.rs-uploader-text .rs-uploader-file-item-has-error .rs-uploader-file-item-title {
  color: #f44336;
  color: var(--rs-state-error); }

.rs-uploader-picture {
  display: inline-block; }

.rs-uploader-picture::before,
.rs-uploader-picture::after {
  content: ' ';
  display: table; }

.rs-uploader-picture::after {
  clear: both; }

.rs-uploader-picture .rs-uploader-trigger-btn {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  white-space: nowrap;
  -webkit-transition: color 0.15s ease-out, background-color 0.15s ease-out;
  transition: color 0.15s ease-out, background-color 0.15s ease-out;
  border: none;
  border: none;
  border: var(--rs-btn-default-border, none);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  color: #575757;
  color: var(--rs-btn-default-text);
  background-color: #f7f7fa;
  background-color: var(--rs-btn-default-bg);
  font-size: 14px;
  line-height: 20px;
  padding: 8px 12px;
  overflow: hidden;
  position: relative;
  color: #8e8e93;
  color: var(--rs-btn-subtle-text);
  background-color: transparent;
  border: none;
  width: 66px;
  height: 66px;
  margin: 0;
  padding: 0;
  border: 2px dotted #e5e5ea;
  border: 2px dotted var(--rs-border-primary);
  border-radius: 6px;
  margin-top: 10px; }

.rs-theme-high-contrast .rs-uploader-picture .rs-uploader-trigger-btn {
  -webkit-transition: none;
  transition: none; }

.rs-btn-ghost.rs-uploader-picture .rs-uploader-trigger-btn {
  padding: 7px 11px; }

.rs-btn-icon.rs-uploader-picture .rs-uploader-trigger-btn {
  padding: 10px 10px;
  line-height: 16px; }

.rs-btn-icon.rs-uploader-picture .rs-uploader-trigger-btn > .rs-icon {
  font-size: 16px; }

.rs-btn-icon-with-text.rs-uploader-picture .rs-uploader-trigger-btn {
  line-height: 20px; }

.rs-btn-icon-with-text.rs-uploader-picture .rs-uploader-trigger-btn > .rs-icon {
  padding: 10px 10px;
  width: 36px;
  height: 36px; }

.rs-btn-icon-with-text.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-icon-placement-left {
  padding: 8px 12px 8px 48px; }

.rs-btn-icon-with-text.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: none;
  border-right: none;
  border-right: var(--rs-btn-default-border, none); }

.rs-btn-icon-with-text.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-icon-placement-right {
  padding: 8px 48px 8px 12px; }

.rs-btn-icon-with-text.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: none;
  border-left: none;
  border-left: var(--rs-btn-default-border, none); }

.rs-uploader-picture .rs-uploader-trigger-btn:focus-visible {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring); }

.rs-theme-high-contrast .rs-uploader-picture .rs-uploader-trigger-btn:focus-visible {
  outline-offset: 2px; }

.rs-uploader-picture .rs-uploader-trigger-btn:hover,
.rs-uploader-picture .rs-uploader-trigger-btn:focus {
  color: var(--rs-btn-default-hover-text);
  background-color: #e5e5ea;
  background-color: var(--rs-btn-default-hover-bg);
  text-decoration: none; }

.rs-uploader-picture .rs-uploader-trigger-btn:active,
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-active {
  color: #272c36;
  color: var(--rs-btn-default-active-text);
  background-color: #d9d9d9;
  background-color: var(--rs-btn-default-active-bg); }

.rs-uploader-picture .rs-uploader-trigger-btn:disabled,
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-disabled {
  cursor: not-allowed;
  color: #c5c6c7;
  color: var(--rs-btn-default-disabled-text);
  background-color: #f7f7fa;
  background-color: var(--rs-btn-default-disabled-bg); }

.rs-theme-high-contrast .rs-uploader-picture .rs-uploader-trigger-btn:disabled,
.rs-theme-high-contrast .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-disabled {
  opacity: 0.5; }

@media not all and (min-resolution: 0.001dpcm) {
  .rs-uploader-picture .rs-uploader-trigger-btn {
    /* stylelint-disable */
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    /* stylelint-enable */ } }

.rs-picker-default .rs-uploader-picture .rs-uploader-trigger-btn {
  -webkit-transition: border-color ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s; }

.rs-theme-high-contrast .rs-picker-default .rs-uploader-picture .rs-uploader-trigger-btn {
  -webkit-transition: none;
  transition: none; }

.rs-picker-default:not(.rs-picker-disabled) .rs-uploader-picture .rs-uploader-trigger-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-uploader-picture .rs-uploader-trigger-btn:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-uploader-picture .rs-uploader-trigger-btn-active {
  border-color: #3498ff;
  border-color: var(--rs-input-focus-border); }

.rs-picker-subtle .rs-uploader-picture .rs-uploader-trigger-btn {
  color: #8e8e93;
  color: var(--rs-btn-subtle-text);
  background-color: transparent;
  border: none;
  -webkit-transition: none;
  transition: none; }

.rs-picker-subtle .rs-uploader-picture .rs-uploader-trigger-btn:hover,
.rs-picker-subtle .rs-uploader-picture .rs-uploader-trigger-btn:focus {
  color: #575757;
  color: var(--rs-btn-subtle-hover-text);
  background-color: #e5e5ea;
  background-color: var(--rs-btn-subtle-hover-bg); }

.rs-picker-subtle .rs-uploader-picture .rs-uploader-trigger-btn:active,
.rs-picker-subtle .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-active {
  color: #272c36;
  color: var(--rs-btn-subtle-active-text);
  background-color: #e5e5ea;
  background-color: var(--rs-btn-subtle-active-bg); }

.rs-picker-subtle .rs-uploader-picture .rs-uploader-trigger-btn:disabled,
.rs-picker-subtle .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-disabled {
  color: #c5c6c7;
  color: var(--rs-btn-subtle-disabled-text);
  background: none; }

.rs-theme-high-contrast .rs-picker-subtle .rs-uploader-picture .rs-uploader-trigger-btn:disabled,
.rs-theme-high-contrast .rs-picker-subtle .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-disabled {
  opacity: 0.5; }

.rs-picker-subtle .rs-uploader-picture .rs-uploader-trigger-btn-active {
  background-color: #e5e5ea;
  background-color: var(--rs-btn-subtle-hover-bg);
  color: #575757;
  color: var(--rs-btn-subtle-hover-text); }

.rs-uploader-picture .rs-uploader-trigger-btn:hover,
.rs-uploader-picture .rs-uploader-trigger-btn:focus {
  color: #575757;
  color: var(--rs-btn-subtle-hover-text);
  background-color: #e5e5ea;
  background-color: var(--rs-btn-subtle-hover-bg); }

.rs-uploader-picture .rs-uploader-trigger-btn:active,
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-active {
  color: #272c36;
  color: var(--rs-btn-subtle-active-text);
  background-color: #e5e5ea;
  background-color: var(--rs-btn-subtle-active-bg); }

.rs-uploader-picture .rs-uploader-trigger-btn:disabled,
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-disabled {
  color: #c5c6c7;
  color: var(--rs-btn-subtle-disabled-text);
  background: none; }

.rs-theme-high-contrast .rs-uploader-picture .rs-uploader-trigger-btn:disabled,
.rs-theme-high-contrast .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-disabled {
  opacity: 0.5; }

.rs-uploader-picture .rs-uploader-trigger.rs-uploader-trigger-disabled .rs-uploader-trigger-btn {
  color: #8e8e93 !important;
  color: var(--rs-btn-subtle-text) !important;
  background-color: transparent !important; }

.rs-uploader-picture .rs-uploader-trigger.rs-uploader-trigger-disabled .rs-uploader-trigger-btn::after {
  display: none; }

.rs-uploader-picture .rs-uploader-trigger,
.rs-uploader-picture .rs-uploader-file-item {
  float: left; }

.rs-uploader-picture .rs-uploader-file-items {
  display: inline; }

.rs-uploader-picture .rs-uploader-file-item {
  width: 66px;
  height: 66px;
  overflow: hidden;
  background-color: #d9d9d9;
  background-color: var(--rs-uploader-item-bg);
  border-radius: 6px;
  position: relative;
  margin-top: 10px;
  margin-right: 10px; }

.rs-uploader-picture .rs-uploader-file-item-preview {
  position: relative;
  height: 66px;
  vertical-align: middle;
  text-align: center;
  z-index: 1; }

.rs-uploader-picture .rs-uploader-file-item-preview > img {
  width: 100%;
  height: auto; }

@supports ((-o-object-fit: cover) or (object-fit: cover)) {
  .rs-uploader-picture .rs-uploader-file-item-preview > img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover; } }

.rs-uploader-picture .rs-uploader-file-item-preview .rs-uploader-file-item-icon {
  font-size: 22px;
  margin: 22px; }

.rs-uploader-picture .rs-uploader-file-item-status {
  position: absolute;
  top: 0;
  left: 0;
  width: 66px;
  height: 66px;
  text-align: center;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.8);
  background-color: var(--rs-uploader-overlay-bg);
  cursor: default;
  color: #f44336;
  color: var(--rs-state-error);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center; }

.rs-uploader-picture .rs-uploader-file-item-status > span {
  white-space: nowrap; }

.rs-uploader-picture .rs-uploader-file-item-status > a:hover {
  text-decoration: none; }

.rs-uploader-picture .rs-uploader-file-item-icon-wrapper {
  display: none; }

.rs-uploader-picture .rs-uploader-file-item-btn-remove {
  color: #8e8e93;
  color: var(--rs-text-secondary);
  cursor: pointer;
  position: absolute;
  top: 3px;
  right: 3px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.8);
  background-color: var(--rs-uploader-overlay-bg);
  border-radius: 50%;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.2s opacity;
  transition: 0.2s opacity;
  z-index: 4;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 0; }

.rs-uploader-picture .rs-uploader-file-item-btn-remove:hover,
.rs-uploader-picture .rs-uploader-file-item-btn-remove:focus {
  text-decoration: none; }

.rs-uploader-picture .rs-uploader-file-item:hover .rs-uploader-file-item-btn-remove {
  opacity: 1;
  visibility: visible; }

.rs-uploader-picture .rs-uploader-file-item-icon-loading {
  display: block;
  width: 66px;
  height: 66px;
  line-height: 66px;
  background-color: rgba(255, 255, 255, 0.8);
  background-color: var(--rs-uploader-overlay-bg);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  text-align: center; }

.rs-uploader-picture .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon {
  width: 18px;
  height: 18px;
  display: inline-block;
  position: relative; }

.rs-uploader-picture .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::before,
.rs-uploader-picture .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::after {
  width: 18px;
  height: 18px; }

.rs-uploader-picture .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::before,
.rs-uploader-picture .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  display: block;
  border-radius: 50%; }

.rs-uploader-picture .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::before {
  border: 3px solid rgba(247, 247, 250, 0.8);
  border: 3px solid var(--rs-loader-ring); }

.rs-uploader-picture .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::after {
  border-width: 3px;
  border-style: solid;
  border-color: #a6a6a6 transparent transparent;
  border-color: var(--rs-loader-rotor) transparent transparent;
  -webkit-animation: loaderSpin 0.6s infinite linear;
  animation: loaderSpin 0.6s infinite linear; }

.rs-uploader-picture .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon > svg {
  display: none; }

.rs-uploader-picture .rs-uploader-file-item-icon-reupload {
  margin-left: 0;
  display: block; }

.rs-uploader-picture-text .rs-uploader-file-item {
  position: relative;
  height: 50px;
  padding-left: 50px;
  margin-top: 10px;
  padding-right: 30px;
  border: 1px solid #e5e5ea;
  border: 1px solid var(--rs-border-primary);
  border-radius: 6px;
  overflow: hidden;
  -webkit-transition: 0.3s linear background-color;
  transition: 0.3s linear background-color; }

.rs-uploader-picture-text .rs-uploader-file-item:hover {
  background-color: #f7f7fa;
  background-color: var(--rs-uploader-item-hover-bg); }

.rs-uploader-picture-text .rs-uploader-file-item-icon-wrapper {
  display: none; }

.rs-uploader-picture-text .rs-uploader-file-item-preview {
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  height: 50px;
  vertical-align: middle;
  text-align: center;
  background-color: #e5e5ea;
  background-color: var(--rs-border-primary);
  overflow: hidden;
  border-radius: 6px 0 0 6px; }

.rs-uploader-picture-text .rs-uploader-file-item-preview > img {
  width: 100%;
  height: auto; }

@supports ((-o-object-fit: cover) or (object-fit: cover)) {
  .rs-uploader-picture-text .rs-uploader-file-item-preview > img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover; } }

.rs-uploader-picture-text .rs-uploader-file-item-preview .rs-uploader-file-item-icon {
  font-size: 22px;
  margin: 12px; }

.rs-uploader-picture-text .rs-uploader-file-item-content {
  padding: 5px 12px; }

.rs-uploader-picture-text .rs-uploader-file-item-content .rs-uploader-file-item-title,
.rs-uploader-picture-text .rs-uploader-file-item-content .rs-uploader-file-item-size {
  display: block; }

.rs-uploader-picture-text .rs-uploader-file-item-content .rs-uploader-file-item-title {
  color: #575757;
  color: var(--rs-text-primary);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%; }

.rs-uploader-picture-text .rs-uploader-file-item-content .rs-uploader-file-item-size {
  color: #8e8e93;
  color: var(--rs-text-secondary); }

.rs-uploader-picture-text .rs-uploader-file-item-btn-remove {
  position: absolute;
  top: 0;
  right: 12px;
  color: #8e8e93;
  color: var(--rs-text-secondary);
  cursor: pointer;
  height: 50px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0; }

.rs-uploader-picture-text .rs-uploader-file-item-btn-remove:hover,
.rs-uploader-picture-text .rs-uploader-file-item-btn-remove:focus {
  text-decoration: none; }

.rs-uploader-picture-text .rs-uploader-file-item-progress {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  padding-left: 50px; }

.rs-uploader-picture-text .rs-uploader-file-item-progress-bar {
  height: 2px;
  background-color: #3498ff;
  background-color: var(--rs-primary-500);
  -webkit-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out; }

.rs-uploader-picture-text .rs-uploader-file-item-icon-loading {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 50px;
  height: 50px;
  z-index: 1;
  background-color: #e5e5ea;
  background-color: var(--rs-border-primary);
  text-align: center;
  line-height: 50px; }

.rs-uploader-picture-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon {
  width: 18px;
  height: 18px;
  display: inline-block;
  position: relative; }

.rs-uploader-picture-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::before,
.rs-uploader-picture-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::after {
  width: 18px;
  height: 18px; }

.rs-uploader-picture-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::before,
.rs-uploader-picture-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  display: block;
  border-radius: 50%; }

.rs-uploader-picture-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::before {
  border: 3px solid rgba(247, 247, 250, 0.8);
  border: 3px solid var(--rs-loader-ring); }

.rs-uploader-picture-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::after {
  border-width: 3px;
  border-style: solid;
  border-color: #a6a6a6 transparent transparent;
  border-color: var(--rs-loader-rotor) transparent transparent;
  -webkit-animation: loaderSpin 0.6s infinite linear;
  animation: loaderSpin 0.6s infinite linear; }

.rs-uploader-picture-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon > svg {
  display: none; }

.rs-uploader-picture-text .rs-uploader-file-item-has-error .rs-uploader-file-item-size {
  display: none; }

.rs-uploader-picture-text .rs-uploader-file-item-has-error .rs-uploader-file-item-title,
.rs-uploader-picture-text .rs-uploader-file-item-has-error .rs-uploader-file-item-status {
  display: block;
  color: #f44336;
  color: var(--rs-state-error); }

.rs-uploader-draggable .rs-uploader-trigger-customize .rs-uploader-trigger-btn {
  display: block;
  background: #fff;
  background: var(--rs-uploader-dnd-bg);
  border: 1px dashed #e5e5ea;
  border: 1px dashed var(--rs-uploader-dnd-border); }

.rs-uploader-draggable .rs-uploader-trigger.rs-uploader-trigger-customize:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:hover,
.rs-uploader-draggable .rs-uploader-trigger-drag-over .rs-uploader-trigger-btn.rs-uploader-trigger-customize {
  border-color: #3498ff;
  border-color: var(--rs-uploader-dnd-hover-border); }

.rs-uploader-draggable .rs-uploader-trigger-disabled {
  cursor: not-allowed; }

.rs-uploader-draggable .rs-uploader-trigger-disabled .rs-uploader-trigger-btn {
  pointer-events: none; }

.rs-rate {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: #ffb300;
  color: var(--rs-rate-symbol-checked);
  list-style: none;
  vertical-align: middle;
  margin: 0;
  padding: 0; }

.rs-rate-character {
  position: relative;
  margin-right: 10px;
  padding: 3px 0;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.rs-rate-character-before {
  position: absolute;
  width: 50%;
  height: 100%;
  overflow: hidden;
  opacity: 0; }

.rs-rate-character-after {
  color: #8e8e93;
  color: var(--rs-rate-symbol);
  -webkit-filter: grayscale(1);
  filter: grayscale(1); }

.rs-theme-high-contrast .rs-rate-character-after > .rs-icon {
  fill: none;
  stroke: currentColor; }

.rs-rate-character-before,
.rs-rate-character-after {
  display: -ms-flexbox;
  display: flex;
  z-index: 1; }

.rs-rate-character-before > .rs-icon,
.rs-rate-character-after > .rs-icon {
  font-size: inherit; }

.rs-rate-character-half .rs-rate-character-before {
  opacity: 1;
  -webkit-filter: none;
  filter: none; }

.rs-rate-character-full .rs-rate-character-after {
  color: inherit;
  -webkit-filter: none;
  filter: none; }

.rs-rate-character-full .rs-rate-character-after > .rs-icon {
  fill: currentColor; }

.rs-rate-character-vertical {
  width: 100%;
  height: 50%;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  bottom: 3px; }

.rs-rate-character .rs-icon {
  position: absolute;
  height: 1em;
  width: 1em; }

.rs-rate:not(.rs-rate-readonly).rs-rate:not(.rs-rate-disabled) .rs-rate-character:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1); }

.rs-rate:not(.rs-rate-readonly).rs-rate:not(.rs-rate-disabled) .rs-rate-character:active {
  -webkit-transform: scale(1);
  transform: scale(1); }

.rs-rate-disabled {
  opacity: 0.3;
  outline: none; }

.rs-rate-disabled .rs-rate-character {
  cursor: not-allowed; }

.rs-rate-readonly .rs-rate-character {
  cursor: default;
  pointer-events: none; }

.rs-rate-lg {
  font-size: 36px; }

.rs-rate-lg .rs-rate-character {
  width: 36px;
  height: 42px; }

.rs-rate-md {
  font-size: 30px; }

.rs-rate-md .rs-rate-character {
  width: 30px;
  height: 36px; }

.rs-rate-sm {
  font-size: 24px; }

.rs-rate-sm .rs-rate-character {
  width: 24px;
  height: 30px; }

.rs-rate-xs {
  font-size: 18px; }

.rs-rate-xs .rs-rate-character {
  width: 18px;
  height: 24px; }

.rs-rate-red {
  color: #f44336;
  color: var(--rs-red-500); }

.rs-rate-orange {
  color: #fa8900;
  color: var(--rs-orange-500); }

.rs-rate-yellow {
  color: #ffb300;
  color: var(--rs-yellow-500); }

.rs-rate-green {
  color: #4caf50;
  color: var(--rs-green-500); }

.rs-rate-cyan {
  color: #00bcd4;
  color: var(--rs-cyan-500); }

.rs-rate-blue {
  color: #2196f3;
  color: var(--rs-blue-500); }

.rs-rate-violet {
  color: #673ab7;
  color: var(--rs-violet-500); }

.rs-toast {
  pointer-events: auto; }

.rs-toast-container {
  position: fixed;
  z-index: 1080;
  pointer-events: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }

.rs-toast-container .rs-toast:not(.rs-notification) {
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: var(--rs-shadow-overlay);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  box-shadow: var(--rs-shadow-overlay); }

.rs-toast-container .rs-toast-fade-entered,
.rs-toast-container .rs-toast-fade-exiting {
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards; }

.rs-toast-container .rs-toast-fade-entered {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-timing-function: cubic-bezier(0.99, 0.44, 0.44, 1.35);
  animation-timing-function: cubic-bezier(0.99, 0.44, 0.44, 1.35); }

.rs-toast-container .rs-toast-fade-exited,
.rs-toast-container .rs-toast-fade-entering {
  opacity: 0; }

.rs-toast-container .rs-toast-fade-exited {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: scaleY(0.8);
  transform: scaleY(0.8);
  max-height: 0;
  overflow: hidden; }

.rs-toast-container-top-center,
.rs-toast-container-bottom-center {
  -ms-flex-align: center;
  align-items: center;
  width: 100%; }

.rs-toast-container-top-center .rs-toast-fade-entered,
.rs-toast-container-bottom-center .rs-toast-fade-entered {
  -webkit-animation-name: notificationMoveIn;
  animation-name: notificationMoveIn; }

.rs-toast-container-bottom-start,
.rs-toast-container-top-start {
  -ms-flex-align: start;
  align-items: flex-start;
  left: 24px; }

.rs-toast-container-bottom-start .rs-toast-fade-entered,
.rs-toast-container-top-start .rs-toast-fade-entered {
  -webkit-animation-name: notificationMoveInLeft;
  animation-name: notificationMoveInLeft; }

.rs-toast-container-bottom-end,
.rs-toast-container-top-end {
  -ms-flex-align: end;
  align-items: flex-end;
  right: 24px; }

.rs-toast-container-bottom-end .rs-toast-fade-entered,
.rs-toast-container-top-end .rs-toast-fade-entered {
  margin-left: auto;
  -webkit-animation-name: notificationMoveInRight;
  animation-name: notificationMoveInRight; }

.rs-toast-container-top-center,
.rs-toast-container-top-start,
.rs-toast-container-top-end {
  top: 24px; }

.rs-toast-container-bottom-center,
.rs-toast-container-bottom-start,
.rs-toast-container-bottom-end {
  bottom: 24px; }

.rs-toast-container .rs-toast-fade-exiting {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0.64, 0.65, 0.57, 1.13);
  animation-timing-function: cubic-bezier(0.64, 0.65, 0.57, 1.13);
  -webkit-animation-name: notificationMoveOut;
  animation-name: notificationMoveOut; }

.rs-btn-close {
  background: transparent;
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px 8px 0;
  font-size: 12px;
  line-height: 1;
  border: none;
  outline: none !important;
  cursor: pointer; }

.rs-btn-close .rs-icon {
  vertical-align: bottom; }

.rs-stack {
  display: -ms-flexbox;
  display: flex; }

/*# sourceMappingURL=index.css.map */

